import React from "react";
import styles from "./styles";
import { Card, Grid } from "@material-ui/core";
import { Autocomplete, AutocompleteMultiple } from "../../../core-ui/custom";

const RoutePlanFilters = ({ loading, lists, selectedItems, listChangeHandlers }) => {
    const classes = styles();
    const { supplierBranchesList, projectsList, merchandisersList } = lists;
    const { selectedSupplierBranch, selectedProjects, selectedMerchandisers } = selectedItems;
    const { onSupplierBranchChange, onProjectsChange, onMerchandisersChange } = listChangeHandlers;

    return (
        <Card className={classes.card}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4}>
                    <Autocomplete
                        id="branches-list"
                        inputLabel={"Branches"}
                        optionLabelProp={"En_name"}
                        options={supplierBranchesList}
                        value={selectedSupplierBranch}
                        onChange={onSupplierBranchChange}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <AutocompleteMultiple
                        id="projects-list"
                        inputLabel={"Projects"}
                        optionLabelProp={"projName"}
                        options={projectsList}
                        value={selectedProjects}
                        onChange={onProjectsChange}
                        loading={loading}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <AutocompleteMultiple
                        id="merchandisers-list"
                        inputLabel={"merchandisers"}
                        optionLabelProp={"merch_name"}
                        options={merchandisersList}
                        groupBy={(option) => (option.contract ? option.contract.number : "Insource")}
                        value={selectedMerchandisers}
                        onChange={onMerchandisersChange}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </Card>
    );
};

export default RoutePlanFilters;
