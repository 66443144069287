import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS, PROJECT_TYPES } from "../../../constants/constants-lagacy";
import { Button, darken } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        display: "flex",
        position: "fixed",
        // bottom: "70px",
        bottom: "0px",
        left: "0",
        backgroundColor: "white",
        borderTop: `3px solid ${COLORS.accent}`,
        width: "100%",
        height: "65px",
        zIndex: "100",
    },

    pricesContainer: {
        flex: 3,
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
    },
    priceBox: {
        margin: "0px 10px",
        color: "black",
        fontWeight: "400",
        border: "1px solid black",
        padding: "5px",
    },

    buttonsContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        float: "right",
        textTransform: "none",
        backgroundColor: COLORS.accent,
        color: "#FFFFFF",
        margin: "0 10px",
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        borderColor: "#4BB543",
        border: "2",
        fontWeight: "400",
        "&:hover": {
            backgroundColor: darken(COLORS.accent, 0.2),
        },
    },

    saveButton: {
        backgroundColor: "#4BB543",
        "&:hover": {
            backgroundColor: darken("#4BB543", 0.2),
        },
    },

    confirmButton: {
        backgroundColor: "#999e13",
        "&:hover": {
            backgroundColor: darken("#999e13", 0.2),
        },
    },
    disabledButton: {
        backgroundColor: "grey",
        color: "#181818 !important",
    },
});

let ProjectBottomBar = (props) => {
    const classes = useStyles(props);
    let { projectType, isConfirmed, enableEdit, validInputs, onSave, onBackClicked, confirmValidated, onConfirm } =
        props;

    return (
        <div className={classes.root}>
            <div className={classes.buttonsContainer}>
                <Button size="large" className={classes.button} type="submit" onClick={onBackClicked}>
                    Back
                </Button>
                {!enableEdit ? null : (
                    <Button
                        size="large"
                        disabled={!validInputs}
                        onClick={() => onSave()}
                        className={`${classes.button} ${classes.saveButton}`}
                        classes={{ disabled: classes.disabledButton }}
                        type="submit"
                    >
                        Save Changes
                    </Button>
                )}
                {isConfirmed ? null : (
                    <Button
                        // simple
                        size="large"
                        onClick={isConfirmed || !confirmValidated ? () => {} : onConfirm}
                        className={`${classes.button} ${classes.confirmButton}`}
                        classes={{ disabled: classes.disabledButton }}
                        disabled={isConfirmed || !confirmValidated}
                        type="submit"
                    >
                        {isConfirmed ? "Confirmed" : "Confirm"}
                    </Button>
                )}
            </div>
        </div>
    );
};

ProjectBottomBar.propTypes = {
    classes: PropTypes.object,
};

export default ProjectBottomBar;
