import { darken } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    dashboard: {
        maxWidth: "1540px",
        height: "calc(100vh - 75px)",
        margin: "0 auto",
        padding: "15px",
        // backgroundColor: "#f5f6f8",

        "@media (min-width: 1050px)": {
            height: "calc(100vh - 74px)",
        },
    },

    header: {
        color: COLORS.ACCENT,
        marginBottom: 5,
    },

    pageTitle: {
        color: COLORS.VALENTINE_RED,
        fontWeight: 600,
        fontSize: "1.1rem",
        lineHeight: 1,
        margin: "16px 0 5px 0",
    },

    actionsContainer: {
        "@media (min-width: 470px)": {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },
    },

    filterBtn: {
        backgroundColor: COLORS.WHITE,
        padding: 8,
        color: COLORS.BLACK,
        boxShadow: "0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15)",

        "&:hover": {
            backgroundColor: darken(COLORS.WHITE, 0.1),
        },

        "@media (min-width: 470px)": {
            marginLeft: 5,
        },
    },

    activeFilterBtn: {
        backgroundColor: COLORS.MALIBU,

        "&:hover": {
            backgroundColor: darken(COLORS.MALIBU, 0.1),
        },
    },

    filterBtnLabel: {
        fontWeight: "bold",
        justifyContent: "flex-start",
    },

    filters: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexWrap: "wrap",
        backgroundColor: COLORS.WHITE,
        padding: 8,
        boxShadow: "0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15)",
        marginTop: 1,
    },

    tabsRoot: {
        // width: "100%",
        // marginTop: 15,
    },

    tabs: {
        // width: "100%",
    },

    autocomplete: {
        width: "250px",
        marginRight: 5,

        "& .MuiInputBase-root": {
            fontSize: "0.8rem",
        },

        "& .MuiInputLabel-outlined": {
            fontSize: "0.7rem",
            transform: "translate(14px, 12px) scale(1)",
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
            fontSize: "0.7rem",
            transform: "translate(10px, 12px) scale(1)",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            padding: "6px 4px",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "4px 4px",
        },

        "& .MuiOutlinedInput-notchedOutline legend": {
            fontSize: "0.5rem",
        },

        "& .MuiFormHelperText-root.Mui-error": {
            position: "absolute",
            top: "35px",
            left: 0,
            fontSize: "0.6rem",
            margin: 0,
        },
    },

    option: {
        padding: "2px 8px",
        fontSize: "0.8rem",
    },

    dashboardContent: {
        width: "100%",
        margin: "0",
        marginBottom: "20px",
        marginTop: 10,
        // padding: "20px",
    },

    chart: {
        padding: "5px 0",

        "@media (min-width: 600px)": {
            paddingTop: "10px",
            paddingBottom: "10px",

            "&:nth-child(odd)": {
                paddingLeft: "10px",
            },

            "&:nth-child(even)": {
                paddingRight: "10px",
            },
        },

        "@media (min-width: 960px)": {
            padding: "10px",
            "&:nth-child(odd)": {
                paddingLeft: "auto",
            },

            "&:nth-child(even)": {
                paddingRight: "auto",
            },
            "&:first-child, &:nth-child(2)": {
                paddingLeft: "0px",
            },
            "&:last-child": {
                paddingRight: "0px",
            },
        },
    },

    indicator: {
        // maxHeight: "100px",
        padding: "5px 10px",
        "&:nth-child(odd)": {
            paddingLeft: 0,
        },

        "&:nth-child(even)": {
            paddingRight: 0,
        },

        "@media (min-width: 600px)": {
            "&:nth-child(odd)": {
                paddingLeft: "10px",
            },

            "&:nth-child(even)": {
                paddingRight: "10px",
            },
            "&:first-child": {
                paddingLeft: "0px",
            },
            "&:last-child": {
                paddingRight: "0px",
            },
        },
    },

    // waffleChart: {
    //     height: "250px",
    //     width: "100%",
    // },

    // waffleLegends: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",

    //     "& > span": {
    //         display: "flex",
    //         margin: "0 30px",
    //         "&::before": {
    //             content: "''",
    //             display: "block",
    //             width: "20px",
    //             height: "20px",
    //             marginRight: "10px",
    //             backgroundColor: "red",
    //         },

    //         "&:nth-child(2)::before": {
    //             backgroundColor: "orange",
    //         },
    //         "&:nth-child(3)::before": {
    //             backgroundColor: "yellow",
    //         },
    //     },
    // },
});

export default styles;
