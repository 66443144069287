import React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";

import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../components-lagacy/Grid/GridItem.jsx";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { formatDate } from "../helpers/date-utils";
import EnhancedTable from "../components-lagacy/Table/EnhancedTable";
import { firestore as db } from "../services/firebase";
import PATHS from "../constants/paths";
import { withRouter } from "react-router";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AuthContext from "../contexts/auth-context";
import { generatePDFinNewTab } from "../helpers/pdf-utils";
import MerchandisersPdf from "../components-lagacy/PDF/merchandisersList";
import { EMPLOYEE_TYPES } from "../constants/global";

//==================================================================================
//==========================EmployeesList======================
//==================================================================================

let generateTableToolbarButton = (title, action, icon, enabled, iconStyle = {}, style = {}) => {
    return { title, action, icon, enabled, iconStyle, style };
};
let get = function (obj, key) {
    return key.split(".").reduce(function (o, x) {
        return typeof o == "undefined" || o === null ? o : o[x];
    }, obj);
};

class EmployeesList extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            activeEmployees: [],
            notActiveEmployees: [],
            registeredEmployees: [],
            notPassEmployees: [],
            passEmployees: [],
            completedEmployees: [],
            incompleteEmployees: [],
            notValidEmployees: [],
            validEmployees: [],
            showDeleteAlert: false,
            delete_Employees: [],
            checkedB: false,
            tableDisplay: 1,
            employeesDisplayed: [],
            cities: [],

            city: "",
            first_name: "",

            searchCount: 0,
            pathEditEmployeeInfo: "",
            editEmployeeId: "",
            pathAddNewEmployee: "",
            pathBack: "",

            rows: [],
            tableToolbarButtons: [],

            loading: false,
            dialogOpen: false,
            dialogHeader: "",
            dialogMsg: "",
            dialogCloseHandler: null,
        };
    }

    goToAddEmployee = () => {
        this.props.history.push({
            pathname: PATHS.ADD_EMPLOYEE,
            state: {
                id: this.props.id,
            },
        });
    };

    handleMerchandisersPdf = () => {
        generatePDFinNewTab(
            <MerchandisersPdf
                info={{
                    merchandisers: this.state.rows,
                    companyData: this.context.companyData,
                }}
            />,
            `Merchandisers List`
        );
    };

    gotToEditEmployee = (ids) => {
        if (ids.length <= 0) return false;

        this.props.history.push({
            pathname: PATHS.UPDATE_EMPLOYEE,
            state: {
                id: this.props.id,
                employeeUID: ids[0],
            },
        });
    };
    init = async () => {
        this.setState({ loading: true });
        try {
            //* get cities
            const cities = (await db.collection("City").get()).docs.map((city) => city.data());

            //* get supplier's employees
            const employees = (
                await db
                    .collection("Merchandiser")
                    .where("account_information.owner_id", "==", this.props.id)
                    .where("account_information.type", "in", [
                        EMPLOYEE_TYPES.SUPPLIER_MERCHANDISER,
                        EMPLOYEE_TYPES.SUPPLIER_SUPERVISOR,
                    ])
                    .get()
            ).docs;

            const rows = employees.map((merch) => {
                const city = cities.find((c) => c.city_id === get(merch.data(), "address.City"));
                let job = "-";
                switch (merch.data().account_information.type) {
                    case EMPLOYEE_TYPES.SUPPLIER_MERCHANDISER:
                        job = "Merchandiser";
                        break;
                    case EMPLOYEE_TYPES.SUPPLIER_SUPERVISOR:
                        job = "Supervisor";
                        break;

                    default:
                        break;
                }
                return {
                    id: merch.id,
                    en_name: `${get(merch.data(), "personal_information.name.english.first") || ""} ${
                        get(merch.data(), "personal_information.name.english.middle") || ""
                    } ${get(merch.data(), "personal_information.name.english.last") || ""}`,
                    ar_name: `${get(merch.data(), "personal_information.name.arabic.first") || ""} ${
                        get(merch.data(), "personal_information.name.arabic.middle") || ""
                    } ${get(merch.data(), "personal_information.name.arabic.last") || ""}`,
                    type: job,
                    birth: formatDate(get(merch.data(), "personal_information.birth_date")?.toDate()) || "-",
                    nationality: get(merch.data(), "nationality.nationality") || "-",
                    gender: get(merch.data(), "personal_information.gender") || "-",
                    phone: get(merch.data(), "personal_information.phone_number") || "-",
                    city: city ? city.En_name : "-",
                };
            });
            rows.sort(function (a, b) {
                if (a.en_name < b.en_name) {
                    return -1;
                }
                if (a.en_name > b.en_name) {
                    return 1;
                }
                return 0;
            });

            let tableToolbarButtons = [
                generateTableToolbarButton("Edit Employee", this.gotToEditEmployee, "", (ids) => ids.length === 1),
            ];

            //check if a supplier has at least one branch to enable adding employees
            if (await this.isSupplierHasBranch())
                tableToolbarButtons.unshift(
                    generateTableToolbarButton("Add Employee", this.goToAddEmployee, "add", () => true),
                    generateTableToolbarButton(
                        "Export Merchandisers as PDF",
                        this.handleMerchandisersPdf,
                        "pdf",
                        () => true
                    )
                );

            this.setState({ cities: cities, rows: [...rows], tableToolbarButtons: tableToolbarButtons });
        } catch (error) {
            console.log(error);
        }
        this.setState({ loading: false });
    };

    isSupplierHasBranch = async () => {
        try {
            const docs = await db.collection("Branch").where("supplier_id", "==", this.props.id).limit(1).get();
            return !docs.empty;
        } catch (error) {
            console.log(error);
        }

        return false;
    };

    componentDidMount() {
        this.init();
    }

    //==================================================================================
    render() {
        const classes = this.props.classes;
        const headRow = [
            { id: "id", numeric: false, disablePadding: false, hidden: true, label: "UID" },
            { id: "en_name", numeric: false, disablePadding: false, label: "English Name" },
            { id: "ar_name", numeric: false, disablePadding: false, label: "Arabic Name" },
            { id: "type", numeric: false, disablePadding: false, label: "Job" },
            { id: "birth", numeric: false, disablePadding: false, label: "Birth Date" },
            { id: "nationality", numeric: false, disablePadding: false, label: "Nationality" },
            { id: "gender", numeric: false, disablePadding: false, label: "Gender" },
            { id: "phone", numeric: false, disablePadding: false, label: "Phone No." },
            { id: "city", numeric: false, disablePadding: false, label: "City" },
        ];

        const actions = this.state.tableToolbarButtons;
        return (
            <div>
                <div
                    className={classNames(classes.main, classes.textCenter)}
                    style={{ width: "100%", display: "contents" }}
                >
                    <div className={classes.container}>
                        <Box
                            style={{
                                paddingTop: 15,
                                borderRadius: "5px",
                                marginTop: "20px",
                                marginBottom: "75px",
                                backgroundColor: "#FFFFFF",
                                color: "#EEEEEE",
                                width: "100%",
                            }}
                            pt={4}
                        >
                            {/* <GridContainer xs={12} sm={12} md={12} justify="center">
                                    <GridItem xs={12} sm={12} md={11}>
                                        <EnhancedTable
                                            employees={this.state.employeesDisplayed}
                                            handleShowDeleteAlertMethod={this.handleShowDeleteAlert}
                                            handleEditEmployeeInfoMethod={this.handleEditEmployeeInfo}
                                            handleAddNewEmployeeMethod={this.handleAddNewEmployee}
                                        />
                                    </GridItem>
                                </GridContainer> */}
                            <Grid container justifyContent="center" style={{ margin: "20px 0" }}>
                                <GridItem xs={12} sm={12} md={11}>
                                    <EnhancedTable
                                        title={"EMPLOYEES LIST"}
                                        columns={headRow}
                                        rows={this.state.rows}
                                        // rows={this.state.rows}
                                        actions={actions}
                                        updateSelected={() => {}}
                                        // selectionDisabled={true}
                                        loading={this.state.loading}
                                    />
                                </GridItem>
                            </Grid>
                        </Box>
                    </div>

                    <Grid item xs={12}>
                        <p></p>
                        <Paper className={classes.paper}> </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <p></p>
                        <Paper className={classes.paper}> </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <p></p>
                        <Paper className={classes.paper}> </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <p></p>
                        <Paper className={classes.paper}> </Paper>
                    </Grid>
                </div>
            </div>
        );
    }
}

EmployeesList.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
};

export default withStyles(componentsStyle)(withRouter(EmployeesList));
