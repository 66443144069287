// import { query } from "./helpers";
import { COLLECTIONS } from "./constants";
import { firestore as db } from "../firebase";
import { flatten } from "lodash";
import { arrayChunks } from "../../helpers/array-helpers";

export const queryTaskDetails = async (query, { enableOnSnapshot = false, ...otherOptions } = {}) => {
    try {
        let ref = db.collection(COLLECTIONS.TASK_DETAILS);
        if (query.docID) {
            return await ref.doc(query.docID).get();
        }

        for (const op of query) {
            const { key, value, operator } = op;
            ref = ref.where(key, operator, value);
        }

        if (enableOnSnapshot) {
            return ref.onSnapshot(otherOptions.onSnapshot, otherOptions.onError);
        }

        return (await ref.get()).docs;
    } catch (error) {
        throw error;
    }
};

export const getDetailsFromTasks = async (
    tasks,
    {
        isEnded = null,
        isRemoved = null,
        enableOnSnapshot = false,
        onSnapshot = () => {},
        onError = () => {},
        query = [],
    } = {}
) => {
    try {
        // converts tasks into chunks of 10
        const chunks = arrayChunks(tasks, 10);

        // result = [array of task details] or array of unsubs
        const result = await Promise.all(
            chunks.map(async (chunk, index) => {
                let q = [{ key: "task_id", operator: "in", value: chunk.map((task) => task.task_id) }, ...query];
                if (isRemoved !== null) q.push({ key: "removed", operator: "==", value: isRemoved });
                if (isEnded !== null) q.push({ key: "isEnded", operator: "==", value: isEnded });

                if (enableOnSnapshot) {
                    let unsub = await queryTaskDetails(q, {
                        enableOnSnapshot,
                        onSnapshot: (snapshot) => onSnapshot(snapshot, index),
                        onError: (error) => onError(error, index),
                    });
                    return unsub;
                } else {
                    let details = await queryTaskDetails(q);
                    //doc to data
                    return details.map((doc) => doc.data());
                }
            })
        );

        return flatten(result);
    } catch (error) {
        throw error;
    }
};
