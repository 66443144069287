import React from "react";

//related to meterial ui package
import styles from "./styles";
import { Header } from "../../core-ui/custom";
import EnhancedTable from "../../components-lagacy/Table/EnhancedTable";
import useBrands from "../../hooks/use-brands";
import { AddBrandDialog, Filters, UpdateBrandDialog } from "../../components";
// import { firestore } from "../../services/firebase";

const Brands = (props) => {
    const classes = styles();
    const {
        initializing,
        loading,
        initFilters,
        HEADROW,
        TABLE_TOOLBAR_ACTIONS,
        rows,
        showAddBrandDialog,
        setShowAddBrandDialog,
        showUpdateBrandDialog,
        setShowUpdateBrandDialog,
        selectedBrand,
        setSelectedBrand,
        search,
        filterValues,
    } = useBrands({ classes });
    // const init = async () => {
    //     const id = "773";
    //     firestore
    //         .collection("Product")
    //         .doc(id)
    //         .set({
    //             type: "2",
    //             manufacture_number: "abc987",
    //             manufacture_date: new Date(2021, 1, 20),
    //             model_number: "av-987",
    //             brand_id: "1",
    //             brand_name: "Toshiba",

    //             product_number: "Tosh4300",
    //             Ar_name: "توشيبا ، هارد ديسك ، 2 تيرا ، أخضر",
    //             packing: {
    //                 no_units: 1,
    //                 packing: "Carton",
    //                 packing_unit: 1,
    //                 size_unit: "Kg",
    //                 size: "0.15",
    //             },
    //             expiration_period_unit: "",
    //             status: "1",
    //             murtab_code: "Tosh4300-120",
    //             product_recall_period: "",
    //             product_id: id,
    //             barcode: "8436545624418",
    //             display_unit: "pieces",
    //             carton_barcode: "18436545624415",
    //             weight_unit: "Kg",
    //             product_image: {
    //                 product_image1:
    //                     "https://media.extra.com/i/aurora/100300198_100_01?w=2020&h=2832&img404=missing_product&v=false",
    //                 product_image2: "",
    //                 product_image3: "",
    //             },
    //             product_category: {
    //                 sub_category_id: "125",
    //                 category_id: "108",
    //                 sub_sub_category_id: "149",
    //             },
    //             min_display_moving_percentage: 30,
    //             En_name: "TOSHIBA Canvio Advance 2TB HDD Portable, Green",
    //             shelf_price: 16.95,
    //             percentage: 0.75,
    //             product_recommendations: "none",
    //             supplier_id: "120",
    //             expiration_period: "1",
    //             shelf_life: "0",
    //             shelf_life_unit: "",
    //             product_description: "2TB HDD Portable, Green",
    //             weight: "0.15",
    //             priority: 1,
    //             outer_unit: 1,
    //             product_recall_period_unit: "",
    //         });
    // };

    // useEffect(() => {
    //     init();
    // });

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.pageHeader}>Brands</Header>
            <div className={classes.content}>
                {!initializing && <Filters init={initFilters} onSubmit={search} submitText="Search" />}
                {/* {initializing && <CircularLoader />} */}
                <div className={classes.tableWrapper}>
                    <EnhancedTable
                        title={"Brands"}
                        columns={HEADROW}
                        rows={rows}
                        actions={TABLE_TOOLBAR_ACTIONS}
                        updateSelected={() => {}}
                        selectionDisabled
                        loading={initializing || loading}
                    />
                </div>
            </div>
            <AddBrandDialog
                open={showAddBrandDialog}
                onClose={(e, eventType, isBrandCreated = false) => {
                    setShowAddBrandDialog(false);
                    if (isBrandCreated) search({ ...filterValues });
                }}
            />
            <UpdateBrandDialog
                brand={selectedBrand}
                open={showUpdateBrandDialog}
                onClose={(e, eventType, isBrandUpdated = false) => {
                    setShowUpdateBrandDialog(false);
                    setSelectedBrand(null);
                    if (isBrandUpdated) search({ ...filterValues });
                }}
            />
        </section>
    );
};

export default Brands;
