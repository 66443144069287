import React from "react";

//related to meterial ui package
import styles from "./styles";
import { CircularLoader, Header } from "../../core-ui/custom";
import EnhancedTable from "../../components-lagacy/Table/EnhancedTable";
import { Filters } from "../../components";
import usePromotionPrices from "../../hooks/use-promotion-prices";

const PromotionPrices = () => {
    const classes = styles();
    const { initializing, loading, initFilters, HEADROW, TABLE_TOOLBAR_ACTIONS, rows, search } = usePromotionPrices({
        classes,
    });

    if (initializing) return <CircularLoader className={classes.circularLoader} />;

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.pageHeader}>Promotion Prices</Header>
            <div className={classes.content}>
                <Filters init={initFilters} onSubmit={search} submitText="Search" />
                <div className={classes.tableWrapper}>
                    <EnhancedTable
                        title={"Promotion Prices"}
                        columns={HEADROW}
                        rows={rows}
                        actions={TABLE_TOOLBAR_ACTIONS}
                        updateSelected={() => {}}
                        selectionDisabled
                        loading={initializing || loading}
                    />
                </div>
            </div>
        </section>
    );
};

export default PromotionPrices;
