import React from "react";
import styles from "./styles";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Button } from "@material-ui/core";
import NavigationExpandedMenu from "../navigation-expanded.menu";
import { Link } from "react-router-dom-v5-compat";

const NavigationMenuItem = (props) => {
    const classes = styles();
    const { label, menu, link, level, activeMenuPath, index, onClick } = props;
    const active = activeMenuPath[level] === index;

    const handleButtonClick = () => {
        if (menu?.length > 0) {
            onClick(level, index);
        } else if (link) {
            onClick(0, -1);
        }
    };
    const buttonConfig = {
        endIcon: null,
        onClick: handleButtonClick,
    };

    if (menu?.length > 0) {
        if (active) buttonConfig.endIcon = <ExpandLessIcon className={classes.arrowIcon} />;
        else buttonConfig.endIcon = <ExpandMoreIcon className={classes.arrowIcon} />;
    }

    let el = <Button {...buttonConfig}>{label}</Button>;

    if (link) {
        el = (
            <Link to={link} onClick={handleButtonClick}>
                <Button>{label}</Button>
            </Link>
        );
    }

    return (
        <li className={classes.mainMenuItem}>
            {el}
            {active && menu?.length > 0 && (
                <NavigationExpandedMenu menu={menu} activeMenuPath={activeMenuPath} onItemClick={onClick} />
            )}
        </li>
    );
};

export default NavigationMenuItem;
