import { Table, TableHead, TableCell, TableBody, TableRow, Button } from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { flatten } from "lodash";
import React, { useEffect, useReducer, useState, View } from "react";
import Paper from "@material-ui/core/Paper";
import { Filters, GalleryImageCard } from "../../components";
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import { useAuth } from "../../contexts/auth-context";
import { CircularLoader, Header } from "../../core-ui/custom";
import { getToday } from "../../helpers/date-utils";
import { getLinkedOutletBranchesBySupplierID } from "../../services/firestore/Branch_Outlet_Branch";
import { getAllCities } from "../../services/firestore/City";
import { getOutletBranches, getOutletBranchesByCompany } from "../../services/firestore/Outlet_Branch";
import { getProducts, getSupplierProducts } from "../../services/firestore/Product";
import { getSupplierProjects } from "../../services/firestore/Project";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";
import { getLinkedOutlets } from "../../services/firestore/Supplier_Outlet_Product";
import { queryTaskDetails } from "../../services/firestore/Task_Details";
import { queryTasks_V2 } from "../../services/firestore/Task";
import { TASKS_STATUS } from "../../constants/constants-lagacy";
import { generatePDFinNewTab } from "../../helpers/pdf-utils";
import DailyMerchandisingReportPdf from "../../components-lagacy/PDF/dailyMerchandisingReport";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { getMerchandisersBySupplierBranch } from "../../services/firestore/Merchandiser";
import { getMerchandiserName } from "../../helpers/merchandiser-utils";
import { getRoutes } from "../../services/firestore/Route";
import { firestore } from "../../services/firebase";

const INITIAL_FILTERS = {
    startDate: {
        label: "Date",
        component: "date",
        initialValue: getToday(),
    },
    cities: {
        label: "City",
        options: [],
        initialValue: null,
    },
    supplierBranches: {
        label: "Supplier Branch",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    outlets: {
        label: "Outlet",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    outletBranches: {
        label: "Outlet Branch",
        options: [],
        initialValue: null,
    },
    projects: {
        label: "Project",
        options: [],
        initialValue: null,
    },
    merchandisers: {
        label: "Merchandiser",
        options: [],
        initialValue: null,
    },
    visit: {
        label: "Visit Number",
        component: "text",
        initialValue: null,
    },
};

const dataSourcesReducer = (state, { type, payload }) => {
    switch (type) {
        case "MULTI_SET":
            return {
                ...state,
                ...payload,
            };
        case "SET":
            return {
                ...state,
                [payload.key]: payload.value,
            };
        case "RESET":
            return {
                ...state,
                [payload.key]: payload.initialValue,
            };
        default:
            return state;
    }
};

const initialState = {
    cities: [],
    projects: [],
    outlets: [],
    supplierBranches: [],
    outletBranches: [],
    linkedOutletBranches: [],
    merchandisers: [],
};

const DailyMerchandisingReport = (props) => {
    const classes = styles();
    const { companyData } = useAuth();
    // states
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dataSources, dispatchDataSources] = useReducer(dataSourcesReducer, initialState);
    const [mainTasks, setMainTasks] = useState([]);
    const [rows, setRows] = useState([]);
    let counter = 0;

    const init = async () => {
        try {
            const { company_id } = companyData;
            // load filters options
            const [projects, cities, supplierBranches, outlets] = (
                await Promise.all([
                    // getSupplierProducts(company_id),
                    getSupplierProjects(company_id),
                    getAllCities(),
                    getBranchesBySupplierID(company_id),
                    getLinkedOutlets(company_id),
                ])
            ).map((docs) => docs.map((doc) => doc.data()));

            const linkedOutletBranches = await getLinkedOutletBranchesBySupplierID(company_id);
            //flatten linked outlet branches
            const outletBranches = flatten(
                linkedOutletBranches.map((branch) => branch.outletBranches.map((docs) => docs.data()))
            );

            dispatchDataSources({
                type: "MULTI_SET",
                payload: {
                    projects: projects.map((p) => ({ value: p.project_id, label: p.projName })),
                    cities: cities.map((c) => ({ value: `${c.city_id}`, label: c.En_name })),
                    supplierBranches: supplierBranches.map((sb) => ({ value: sb.branch_id, label: sb.En_name })),
                    outlets: outlets.map((o) => ({ value: o.outlet_id, label: o.En_short_name })),
                    linkedOutletBranches: outletBranches,
                },
            });
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    };

    const search = async (values) => {
        try {
            setMainTasks([]);
            setLoading(true);
            const { startDate, supplierBranches, outlets, outletBranches, projects, merchandisers, visit } = values;
            const startofDate = new Date(startDate);
            const endofDate = new Date(startDate);
            startofDate.setHours(0, 0, 0, 0);
            endofDate.setHours(23, 59, 59, 999);
            const query = [
                { key: "date_time_from", operator: ">=", value: startofDate },
                { key: "date_time_from", operator: "<=", value: endofDate },
                { key: "supplier_id", operator: "==", value: Number(companyData.company_id) },
                { key: "state", operator: "in", value: [TASKS_STATUS.UNDER_PROCESS, TASKS_STATUS.FINISHED] },
            ];

            if (supplierBranches?.value)
                query.push({ key: "supplier_branch_id", operator: "==", value: supplierBranches.value });
            if (outlets?.value) query.push({ key: "outlet_id", operator: "==", value: outlets.value });
            if (outletBranches?.value)
                query.push({ key: "outlet_branch_id", operator: "==", value: outletBranches.value });
            if (projects?.value) query.push({ key: "project_id", operator: "==", value: projects.value });
            if (merchandisers?.value) query.push({ key: "uid", operator: "==", value: merchandisers.value });
            let mainTasks = [];

            // visit number query
            const visitQuery = [
                { key: "supplier_id", operator: "==", value: Number(companyData.company_id) },
                { key: "task_id", operator: "==", value: Number(visit) },
                { key: "state", operator: "==", value: [TASKS_STATUS.UNDER_PROCESS, TASKS_STATUS.FINISHED] },
            ];

            // import mainTasks when visit number is chosen
            if (Number(visit)) {
                mainTasks = (await queryTasks_V2(visitQuery)).map((doc) => doc.data());
            } else {
                mainTasks = (await queryTasks_V2(query)).map((doc) => doc.data());
            }

            // get task details
            mainTasks = await Promise.all(
                mainTasks.map(async (task) => {
                    const taskDetailsQuery = [
                        {
                            key: "task_id",
                            operator: "==",
                            value: task.task_id,
                        },
                    ];

                    let taskDetails = (await queryTaskDetails(taskDetailsQuery)).map((doc) => doc.data());
                    task.taskDetails = taskDetails;
                    return task;
                })
            );

            //unique outlet branch ids
            let outletBranchIds = mainTasks.map((task) => `${task.outlet_branch_id}`);
            outletBranchIds = [...new Set(outletBranchIds)];
            const outletBranchesList = (await getOutletBranches(outletBranchIds)).map((doc) => doc.data());

            //unique product ids
            let allTaskDetails = mainTasks.reduce((prev, curr) => {
                prev = [...prev, ...curr.taskDetails];
                return prev;
            }, []);
            let productIds = allTaskDetails.map((taskDetail) => taskDetail.product_id);
            productIds = [...new Set(productIds)];
            const products = (await getProducts(productIds)).map((doc) => doc.data());

            //assign product info and outlet branch info into their appropriate place
            mainTasks.forEach((task) => {
                //outlet branch
                const outletBranch = outletBranchesList.find((ob) => ob.branch_id === `${task.outlet_branch_id}`);
                task.outletBranch = outletBranch;

                //products
                task.taskDetails.forEach((td) => {
                    const product = products.find((prod) => prod.product_id === td.product_id);
                    td.product = product;
                });
            });

            //amnah's code
            mainTasks.sort(function (a, b) {
                if (a.started_at != null && b.started_at != null) {
                    return a.started_at - b.started_at;
                }
                if (a.started_at === null) {
                    return 1;
                }
                if (b.started_at === null) {
                    return -1;
                }
            });

            //! opt required for products
            let supplierProducts = (await getSupplierProducts(companyData.company_id)).map((doc) => doc.data());

            const rows = supplierProducts.map((product) => {
                let productTasks = mainTasks.filter((task) => {
                    let detail = task.taskDetails.find((sb) => `${sb.product_id}` === `${product.product_id}`);
                    return detail;
                });

                productTasks = productTasks.map((task) => {
                    const newTask = { ...task };
                    //init properties for tables
                    newTask.quantity_on_extra_display = 0;
                    newTask.total_quantity_on_extra_display = 0;
                    newTask.quantity_on_shelf = 0;
                    newTask.total_quantity_on_shelf = 0;
                    newTask.warehouse_draw = 0;

                    newTask.available_on_shelf_before = false;
                    newTask.available_on_shelf_after = false;
                    newTask.available_on_extra_display_before = false;
                    newTask.available_on_extra_display_after = false;

                    let productTaskDetails = newTask.taskDetails.filter(
                        (sb) => `${sb.product_id}` === `${product.product_id}`
                    );
                    for (const ptd of productTaskDetails) {
                        const quantity_on = ptd.quantity_on ?? 0;
                        const total_quantity = (ptd.quantity_added ?? 0) + quantity_on;

                        const available_before = ptd.quantity_availability;
                        const available_after = ptd.added_availability;

                        if (ptd.extra_display) {
                            newTask.quantity_on_extra_display += quantity_on;
                            newTask.total_quantity_on_extra_display += total_quantity;

                            newTask.available_on_extra_display_before =
                                newTask.available_on_extra_display_before || available_before;
                            newTask.available_on_extra_display_after =
                                newTask.available_on_extra_display_after || available_after;
                        } else {
                            newTask.quantity_on_shelf += quantity_on;
                            newTask.total_quantity_on_shelf += total_quantity;

                            newTask.available_on_shelf_before = newTask.available_on_shelf_before || available_before;
                            newTask.available_on_shelf_after = newTask.available_on_shelf_after || available_after;
                        }

                        newTask.warehouse_draw = ptd.warehouse_actual_draw || 0;
                    }

                    return newTask;
                });

                return {
                    productdata: product,
                    packing: `${product.outer_unit <= 1 ? "" : product.outer_unit + "x"}${
                        product.packing.no_units <= 1 ? "" : product.packing.no_units + "x"
                    }${product.packing.size}${product.packing.size_unit}`,
                    id: product.id,
                    productName: product.En_name,
                    barcode: product.barcode,
                    productNo: product.product_number,
                    tasks: productTasks,
                };
            });
            rows.sort(function (a, b) {
                if (a.productName < b.productName) {
                    return -1;
                }
                if (a.productName > b.productName) {
                    return 1;
                }
                return 0;
            });
            setMainTasks(mainTasks);
            setRows(rows);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const handleDailyMerchandisingReportPdf = () => {
        generatePDFinNewTab(
            <DailyMerchandisingReportPdf
                info={{
                    rows: rows,
                    companyData: companyData,
                    mainTasks: mainTasks,
                }}
            />,
            `Daily Merchandising Report`
        );
    };
    function timestamp(time) {
        let date = time.toDate();
        const hour = `${date.getHours() < 10 ? "0" : ""}${date.getHours()}`;
        const minute = `${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}`;
        const second = `${date.getSeconds() < 10 ? "0" : ""}${date.getSeconds()}`;
        return `${hour}:${minute}:${second}`;
    }

    const handleOutletsChange = async (option, context) => {
        try {
            if (!option) {
                dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: [] } });
                context.dispatchFilters({
                    type: "CLEAR_FILTER",
                    payload: { id: "outletBranches" },
                });
                return;
            }

            let outletBranches = (await getOutletBranchesByCompany(option.value)).map((doc) => doc.data());
            //filter out unlinked outlet branches
            outletBranches = outletBranches.filter((ob) =>
                dataSources.linkedOutletBranches.find((lob) => lob.branch_id === ob.branch_id)
            );

            outletBranches = outletBranches.map((ob) => ({ value: ob.branch_id, label: ob.En_short_name }));

            dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: outletBranches } });
            context.dispatchFilters({
                type: "SET_FILTER_OPTIONS",
                payload: { id: "outletBranches", options: outletBranches },
            });
        } catch (error) {
            throw error;
        }
    };

    const handleSupplierBranchChange = async (option, context) => {
        try {
            if (!option) {
                dispatchDataSources({ type: "SET", payload: { key: "merchandisers", value: [] } });
                context.dispatchFilters({
                    type: "CLEAR_FILTER",
                    payload: { id: "merchandisers" },
                });
            }

            let merchandisers = (await getMerchandisersBySupplierBranch(option.value)).map((doc) => doc.data());
            merchandisers = merchandisers.map((merch) => ({
                value: merch.merchandiser_id,
                label: getMerchandiserName(merch),
            }));

            dispatchDataSources({ type: "SET", payload: { key: "merchandisers", value: merchandisers } });
            context.dispatchFilters({
                type: "SET_FILTER_OPTIONS",
                payload: { id: "merchandisers", options: merchandisers },
            });
        } catch (error) {
            throw error;
        }
    };

    const initFilters = (context) => {
        INITIAL_FILTERS.cities.options = dataSources.cities;
        INITIAL_FILTERS.supplierBranches.options = dataSources.supplierBranches;
        INITIAL_FILTERS.supplierBranches.onChange = handleSupplierBranchChange;
        INITIAL_FILTERS.outlets.options = dataSources.outlets;
        INITIAL_FILTERS.outlets.onChange = handleOutletsChange;
        INITIAL_FILTERS.projects.options = dataSources.projects;

        INITIAL_FILTERS.merchandisers.options = dataSources.merchandisers;

        context.setFilters(INITIAL_FILTERS);
    };

    function createHeaderData(name, attributes) {
        return { name, attributes };
    }

    const firstRowheaders = [
        createHeaderData("", {
            rowSpan: 4,
            className: `${classes.header} ${classes.productHeader} ${classes.indexHeader}`,
        }),
        createHeaderData("Product No.", {
            rowSpan: 4,
            className: `${classes.header} ${classes.productHeader} ${classes.prodNumberHeader}`,
        }),
        createHeaderData("Barcode", {
            rowSpan: 4,
            className: `${classes.header} ${classes.productHeader} ${classes.barcodeHeader}`,
        }),
        createHeaderData("Product Name", {
            rowSpan: 4,
            className: `${classes.header} ${classes.productHeader} ${classes.prodNameHeader}`,
        }),
        createHeaderData("Packing", {
            rowSpan: 4,
            className: `${classes.header} ${classes.productHeader} ${classes.prodPackingHeader}`,
        }),
    ];

    const thirdRowHeaders = [
        createHeaderData("Qty on", { colSpan: 2 }),
        createHeaderData(
            <>
                W/H <br /> Draw
            </>,
            { rowSpan: 2 }
        ),
        createHeaderData("Qty Dspl'ed", { colSpan: 2 }),
    ];
    const thirdRowHeadersAvailability = [
        createHeaderData("Available on", { colSpan: 2 }),
        createHeaderData(
            <>
                W/H <br /> Draw
            </>,
            { rowSpan: 2 }
        ),
        createHeaderData("Added on", { colSpan: 2 }),
    ];
    const fourthRowHeaders = [
        createHeaderData("Dspl", {}),
        createHeaderData("E.D.", {}),
        createHeaderData("Dspl", {}),
        createHeaderData("E.D.", {}),
    ];

    useEffect(() => {
        init();
    }, []);

    return (
        <section className={classes.mdr}>
            <Header className={classes.pageHeader}>
                <span className={classes.headerColor}>Daily Merchandising Report</span>
            </Header>
            {!initializing && <Filters init={initFilters} onSubmit={search} />}
            {initializing || loading ? <CircularLoader style={{ textAlign: "center" }} /> : null}
            {!initializing && !loading && mainTasks.length > 0 && (
                <>
                    <Button className={classes.pdfButton} title="print pdf" onClick={handleDailyMerchandisingReportPdf}>
                        <div className={classes.pdfIcon}>
                            <PictureAsPdfIcon />
                        </div>
                    </Button>
                    {/* <Button className={classes.pdfButton} title="export Excel">
                                <div className={classes.excelIcon}>
                                    <ListAltSharpIcon />
                                </div>
                            </Button> */}
                    <div className={classes.tableContainer}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {firstRowheaders.map((header, index) => (
                                        <TableCell key={`prod-thr1-${index}`} {...header.attributes}>
                                            {header.name}
                                        </TableCell>
                                    ))}

                                    {mainTasks.map((task, index) => {
                                        let className = `${classes.header} ${classes.firstRowHeader}`;
                                        if (index % 2 === 0) className += ` ${classes.darkHeader}`;
                                        return (
                                            <TableCell key={`thr1-${index}`} className={className} colSpan={5}>
                                                <b>{task.outletBranch.En_short_name}</b>
                                                <br /> #{task.task_id}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                <TableRow>
                                    {mainTasks.map((task, index) => {
                                        let className = `${classes.header} ${classes.secondRowHeader}`;
                                        if (index % 2 === 0) className += ` ${classes.darkHeader}`;
                                        return (
                                            <TableCell key={`thr2-${index}`} className={className} colSpan={5}>
                                                Time in: {task.started_at === null ? "-" : timestamp(task.started_at)}{" "}
                                                Time out:{" "}
                                                {task.finished_at === null ? "-" : timestamp(task.finished_at)}{" "}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                <TableRow>
                                    {mainTasks.map((task, index) => {
                                        const availability = task.options?.availability.active;
                                        let headers = thirdRowHeaders;
                                        if (availability) headers = thirdRowHeadersAvailability;
                                        return headers.map((header, cellIndex) => {
                                            let className = `${classes.header} ${classes.thirdRowHeader}`;
                                            if (index % 2 === 0) className += ` ${classes.darkHeader}`;
                                            return (
                                                <TableCell
                                                    key={`thr3-${index}-${cellIndex}`}
                                                    {...header.attributes}
                                                    className={className}
                                                >
                                                    {header.name}
                                                </TableCell>
                                            );
                                        });
                                    })}
                                </TableRow>
                                <TableRow>
                                    {mainTasks.map((_, index) =>
                                        fourthRowHeaders.map((header, cellIndex) => {
                                            let className = `${classes.header} ${classes.fourthRowHeader}`;
                                            if (index % 2 === 0) className += ` ${classes.darkHeader}`;
                                            return (
                                                <TableCell
                                                    key={`thr4-${index}-${cellIndex}`}
                                                    {...header.attributes}
                                                    className={className}
                                                >
                                                    {header.name}
                                                </TableCell>
                                            );
                                        })
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => {
                                    let className = `${classes.productCell}`;
                                    if (index % 2 === 0) className += ` ${classes.lightBlue}`;
                                    else className += ` ${classes.white}`;
                                    counter++;
                                    return (
                                        <TableRow
                                            key={`tdr-${index}`}
                                            className={index % 2 === 0 ? classes.lightBlue : classes.white}
                                        >
                                            <TableCell className={`${className} ${classes.indexCell}`}>
                                                {counter}
                                            </TableCell>
                                            <TableCell className={`${className} ${classes.prodNoCell}`}>
                                                {row.productNo}
                                            </TableCell>
                                            <TableCell className={`${className} ${classes.barcodeCell}`}>
                                                {row.barcode}
                                            </TableCell>
                                            <TableCell className={`${className} ${classes.productNameCell}`}>
                                                {row.productName}
                                            </TableCell>
                                            <TableCell className={`${className} ${classes.packingCell}`}>
                                                {row.packing}
                                            </TableCell>

                                            {mainTasks.map((task) => {
                                                const availability = task.options?.availability.active;
                                                const foundTask = row.tasks.find(
                                                    (rowTask) => rowTask.task_id === task.task_id
                                                );

                                                let shelf_before = foundTask ? foundTask.quantity_on_shelf : "-";
                                                let shelf_after = foundTask ? foundTask.total_quantity_on_shelf : "-";
                                                let extra_display_before = foundTask
                                                    ? foundTask.quantity_on_extra_display
                                                    : "-";
                                                let extra_display_after = foundTask
                                                    ? foundTask.total_quantity_on_extra_display
                                                    : "-";

                                                if (foundTask && availability) {
                                                    shelf_before = foundTask.available_on_shelf_before ? "Yes" : "No";
                                                    shelf_after = foundTask.available_on_shelf_after ? "Yes" : "No";
                                                    extra_display_before = foundTask.available_on_shelf_before
                                                        ? "Yes"
                                                        : "No";
                                                    extra_display_after = foundTask.available_on_shelf_after
                                                        ? "Yes"
                                                        : "No";
                                                }
                                                const Item = () => (
                                                    <>
                                                        <TableCell className={` ${classes.qtyCell}`}>
                                                            {shelf_before}
                                                        </TableCell>
                                                        <TableCell className={` ${classes.qtyCell}`}>
                                                            {extra_display_before}
                                                        </TableCell>
                                                        <TableCell className={` ${classes.qtyCell}`}>
                                                            {foundTask ? foundTask.warehouse_draw : "-"}
                                                        </TableCell>
                                                        <TableCell className={`${classes.qtyCell}`}>
                                                            {shelf_after}
                                                        </TableCell>
                                                        <TableCell className={` ${classes.qtyCell}`}>
                                                            {extra_display_after}
                                                        </TableCell>
                                                    </>
                                                );

                                                return (
                                                    <Item key={`tdr-${task.task_id}-${row.productdata?.product_id}`} />
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </>
            )}
            {!initializing && !loading && mainTasks.length <= 0 && (
                <div className={classes.notFound}>No tasks that are under procoss or finished were found.</div>
            )}
        </section>
    );
};

DailyMerchandisingReport.propTypes = {
    classes: PropTypes.object,
};

export default DailyMerchandisingReport;
