import * as Yup from "yup";

const arabicRegex = /^[.,[/\\\](){}\u0600-\u06FF_-،\d\sxX]+$/;
const englishRegex = /^[.,[/\\\](){}A-Za-z_-\d\s]+$/;
// const MAX_IMAGE_SIZE = 1_048_576; //1MB
const MAX_IMAGE_SIZE = 2_097_152; //2MB
const SUPPORTED_IMAGE_FORMATS = ["image/jpeg", "image/png", "image/gif"];

Yup.addMethod(Yup.mixed, "imageFormat", function () {
    return this.test(
        "image-format",
        "Only allowed .jpg, .png, .gif formats",
        (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value?.type)
    );
});

Yup.addMethod(Yup.mixed, "imageSize", function () {
    return this.test("image-size", "Max size: 2 MB", (value) => !value || value?.size <= MAX_IMAGE_SIZE);
});

export const FORM_SCHEMA = Yup.object().shape({
    Ar_name: Yup.string()
        .trim()
        .matches(arabicRegex, "Must be in Arabic letters")
        .max(255, "You've the maximum of characters (255 max)")
        .required("Required"),

    En_name: Yup.string()
        .trim()
        .matches(englishRegex, "Must be in English letters")
        .max(255, "You've the maximum of characters (255 max)")
        .required("Required"),

    product_description: Yup.string().trim(),

    product_category: Yup.object().shape({
        sub_category_id: Yup.object().typeError("Required").shape({
            label: Yup.string(),
            value: Yup.string(),
        }),
        category_id: Yup.object().typeError("Required").shape({
            label: Yup.string(),
            value: Yup.string(),
        }),
        sub_sub_category_id: Yup.object().typeError("Required").shape({
            label: Yup.string(),
            value: Yup.string(),
        }),
    }),

    brand_id: Yup.object().nullable().shape({
        label: Yup.string(),
        value: Yup.string(),
    }),

    model_number: Yup.string().trim(),
});

export const PERIOD_UNITS = [
    { label: "Year", value: "year" },
    { label: "Month", value: "month" },
    { label: "Week", value: "week" },
    { label: "Day", value: "day" },
];

export const SIZE_UNITS = [
    { label: "l - Liter", value: "L" },
    { label: "ml - Milliliter", value: "ml" },
    { label: "kg - Kilogram", value: "kg" },
    { label: "g - Gram", value: "g" },
    { label: "lb - Pound", value: "lb" },
    { label: "oz - Ounce", value: "oz" },
];

export const PACKING_UNITS = [
    { label: "Carton", value: "Carton" },
    { label: "Shrink", value: "Shrink" },
    { label: "Paket", value: "Paket" },
];

export const DISPLAY_UNITS = [
    { label: "Both", value: "both" },
    { label: "Outer Unit", value: "outer_unit" },
    { label: "Pieces", value: "pieces" },
];

export const STATUS = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
];

export const PRODUCT_TYPES = [
    { label: "FMCG", value: "1" },
    { label: "Electronics", value: "2" },
];

export const ELECTRONICS_ID = "108";

export const INITIAL_VALUES = {
    supplier_id: null,
    product_id: null,
    En_name: "",
    Ar_name: "",

    product_description: "",
    product_id: "",

    product_category: {
        // category_id: "110",
        // sub_category_id: "140",
        // sub_sub_category_id: "158",
        category_id: null,
        sub_category_id: null,
        sub_sub_category_id: null,
    },

    brand_id: null,
    status: STATUS[0],

    model_number: "",
};
