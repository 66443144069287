import React from "react";

//related to meterial ui package
import styles from "./styles";
import { Header } from "../../core-ui/custom";
import EnhancedTable from "../../components-lagacy/Table/EnhancedTable";
import { AddUserDialog, EnhancedFilters, UpdateUserDialog } from "../../components";
import useUsers from "./use-users";
// import { firestore } from "../../services/firebase";

const Users = () => {
    const classes = styles();
    const {
        initializing,
        loading,
        filters,
        HEADROW,
        TABLE_TOOLBAR_ACTIONS,
        rows,
        showAddUserDialog,
        setShowAddUserDialog,
        showUpdateUserDialog,
        setShowUpdateUserDialog,
        selectedUser,
        setSelectedUser,
        searchUsers,
    } = useUsers({ classes });

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.pageHeader}>Users</Header>
            <div className={classes.content}>
                <EnhancedFilters filters={filters} onSubmit={searchUsers} submitText="Search" />
                {/* <Filters init={initFilters} onSubmit={searchUsers} submitText="Search" /> */}
                <div className={classes.tableWrapper}>
                    <EnhancedTable
                        title={"User Accounts"}
                        columns={HEADROW}
                        rows={rows}
                        actions={TABLE_TOOLBAR_ACTIONS}
                        updateSelected={() => {}}
                        selectionDisabled
                        loading={initializing || loading}
                    />
                </div>
            </div>
            <AddUserDialog
                open={showAddUserDialog}
                onClose={(e, eventType, isUserCreated = false) => {
                    setShowAddUserDialog(false);
                    if (!isUserCreated) return;
                    let values = Object.keys(filters).reduce((res, key) => ({ ...res, [key]: filters[key].value }), {});
                    searchUsers(values);
                }}
            />
            <UpdateUserDialog
                user={selectedUser}
                open={showUpdateUserDialog}
                onClose={(e, eventType, isUserUpdated = false) => {
                    setShowUpdateUserDialog(false);
                    setSelectedUser(null);

                    if (!isUserUpdated) return;
                    let values = Object.keys(filters).reduce((res, key) => ({ ...res, [key]: filters[key].value }), {});
                    searchUsers(values);
                }}
            />
        </section>
    );
};

export default Users;
