import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Button, Header, TransitionAlert } from "../../core-ui/custom";

import * as Yup from "yup";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { getLinkedSuppliers } from "../../services/firestore/MP_Supplier_Link";
import { getMerchandiser, getMPMerchandisers } from "../../services/firestore/Merchandiser";
import { useAuth } from "../../contexts/auth-context";
import { getMerchandiserName } from "../../helpers/merchandiser-utils";
import { getRelativeDateFromToday, getToday } from "../../helpers/date-utils";
import {
    addMerchandiserExclusivityContract,
    queryMerchandiserExclusivityContracts,
    updateMerchandiserExclusivityContract,
} from "../../services/firestore/Merchandiser_Exclusivity_Contract";
import { Card, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { AutocompleteMultiple, Autocomplete, DateTimePicker, SubmitButton, TextField } from "../../core-ui/forms-ui";
import { changeRouteMerchandiser, queryRoutes } from "../../services/firestore/Route";
import { getProject } from "../../services/firestore/Project";
import {
    CONTRACT_MERCH_STATUS,
    MERCH_CONTRACT_STATUS,
    MERCH_CONTRACT_STATUS_LABEL,
} from "../../constants/constants-lagacy";
import { useHistory } from "react-router";
import { getSupplier } from "../../services/firestore/Supplier";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";
import { getMP } from "../../services/firestore/MP";
import UpdateExclusiveMerchandiserDialog from "../../components-lagacy/Dialog/UpdateExclusiveMerchandiserDialog";

const CONTRACT_STATUS_OPTIONS = [
    { label: MERCH_CONTRACT_STATUS_LABEL[MERCH_CONTRACT_STATUS.ACTIVE], value: MERCH_CONTRACT_STATUS.ACTIVE },
    { label: MERCH_CONTRACT_STATUS_LABEL[MERCH_CONTRACT_STATUS.REJECTED], value: MERCH_CONTRACT_STATUS.REJECTED },
    { label: MERCH_CONTRACT_STATUS_LABEL[MERCH_CONTRACT_STATUS.PENDING], value: MERCH_CONTRACT_STATUS.PENDING },
];

let INITIAL_VALUES = {
    mp: "",
    contract_number: "",
    contract_name: "",
    start_date: getToday(), // if date is defiend as '' yup will throw a invalid date error
    end_date: getRelativeDateFromToday(7),
    merchandisers: [],
    status: CONTRACT_STATUS_OPTIONS[0],
};

const UpdateExclusivityContract = (props) => {
    const classes = styles();
    const { companyData } = useAuth();
    const history = useHistory();
    const [formValues, setFormValues] = useState(null);
    const [branches, setBranches] = useState([]);
    const [mp, setMP] = useState([]);
    const [contract, setContract] = useState(null);
    const [contractMerchandisers, setContractMerchandisers] = useState([]);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedMerchIndex, setSelectedMerchIndex] = useState(-1);
    const [alerts, setAlerts] = useState([]);

    const addAlert = (severity, message) => {
        setAlerts((prevAlerts) => [...prevAlerts, { severity, message, open: true }]);
    };

    const init = useCallback(async () => {
        try {
            const { company_id } = companyData;

            //get supplier branches
            const branches = (await getBranchesBySupplierID(company_id)).map((doc) => doc.data());

            //contarct info
            let currentContract = (
                await queryMerchandiserExclusivityContracts({ docID: history.location.state.contractID })
            ).data();

            //get all merchandisers in the contract
            let merchandisers = await Promise.all(
                currentContract.merchandisers.map(async (merch) => {
                    const merchandiser = (await getMerchandiser(merch.id)).data();
                    return {
                        ...merch,
                        data: merchandiser,
                    };
                })
            );

            if (merchandisers.length <= 0) {
                addAlert("error", "No merchandisers found");
                return;
            }

            //get mp info from contract
            let mp = await getMP(currentContract.mp_id);

            setContract(currentContract);
            setBranches(branches);
            setMP(mp.data());
            setContractMerchandisers(merchandisers);

            setFormValues({
                ...INITIAL_VALUES,
                mp: mp.data().company_name,
                contract_number: currentContract.number,
                contract_name: currentContract.name,
                start_date: currentContract.start_date.toDate(),
                end_date: currentContract.end_date.toDate(),
                status: CONTRACT_STATUS_OPTIONS.find((option) => option.value === currentContract.status),
            });
        } catch (error) {
            console.log(error);
        }
    }, [companyData]);

    useEffect(() => {
        init();
    }, [init]);

    const handleMerchCardClick = (merchIndex) => {
        setSelectedMerchIndex(merchIndex);
        setIsDialogOpen(true);
    };

    const handleClosingDialog = () => {
        setIsDialogOpen(false);
    };

    const handleDialogSave = async (currentMerchandiser, selectedBranch) => {
        try {
            const { supplier_branch_id, id } = currentMerchandiser;

            if (supplier_branch_id === selectedBranch?.branch_id) return;

            if (supplier_branch_id) {
                //get merchandiser routes with the current supplier branch
                const routes = (
                    await queryRoutes([
                        { field: "merchandiser_id", operator: "==", value: id },
                        { field: "supplier_branch_id", operator: "==", value: supplier_branch_id },
                    ])
                ).map((doc) => doc.data());

                await Promise.all(
                    routes.map(async (route) => {
                        route.merchandiser_id = null;
                        await changeRouteMerchandiser(route.id, route.merchandiser_id);
                    })
                );
            }

            //update contract
            const newCurrentContract = {
                ...contract,
                merchandisers: contract.merchandisers.map((merch) => {
                    if (merch.id === currentMerchandiser.id) {
                        return {
                            ...merch,
                            supplier_branch_id: selectedBranch?.branch_id || null,
                        };
                    }
                    return merch;
                }),
            };
            await updateMerchandiserExclusivityContract(newCurrentContract.id, {
                merchandisers: newCurrentContract.merchandisers,
            });
            addAlert("success", "Merchandiser updated successfully");
            setIsDialogOpen(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (values, helpers) => {
        try {
            let { status } = values;
            await updateMerchandiserExclusivityContract(contract.id, { status: status.value });

            addAlert("success", "Contract successfully created");
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <section className={classes.UpdateExclusivityContract}>
            {alerts.map((alert, index) => (
                <TransitionAlert
                    key={`alert-${index}`}
                    severity={alert.severity}
                    variant="filled"
                    open={alert.open}
                    setOpen={(open) => {
                        setAlerts((prevAlerts) => {
                            let newAlerts = [...prevAlerts];
                            newAlerts[index].open = open;
                            return newAlerts;
                        });
                    }}
                >
                    {alert.message}
                </TransitionAlert>
            ))}
            <Header>Edit Exclusivity Contract</Header>
            <Formik initialValues={formValues || INITIAL_VALUES} enableReinitialize={true} onSubmit={handleSubmit}>
                {(Formik) => (
                    <Form>
                        <Card className={classes.headerInputCard}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField label="Service Provider" name="mp" disabled />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField label="Number" name="contract_number" disabled />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField label="Name" name="contract_name" disabled />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DateTimePicker
                                        name="start_date"
                                        label="Start Date"
                                        minDate={getToday()}
                                        maxDate={Formik.values.end_date}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DateTimePicker
                                        name="end_date"
                                        label="End Date"
                                        minDate={Formik.values.start_date}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Autocomplete
                                        disableClearable
                                        name="status"
                                        options={CONTRACT_STATUS_OPTIONS}
                                        name="status"
                                        label="Status"
                                        // loading={isInitializing}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <SubmitButton className={classes.submitButton}>Save</SubmitButton>
                                </Grid>
                            </Grid>
                        </Card>

                        <Header>Merchandisers</Header>
                        <Grid container spacing={1}>
                            {contractMerchandisers.map((merchandiser, index) => (
                                <Grid item xs={12} sm={6} md={3} key={merchandiser.value}>
                                    <Card
                                        className={classes.merchandiserCard}
                                        onClick={() => handleMerchCardClick(index)}
                                    >
                                        {getMerchandiserName(merchandiser.data)}
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        <UpdateExclusiveMerchandiserDialog
                            open={isDialogOpen}
                            onClose={handleClosingDialog}
                            onSubmit={handleDialogSave}
                            title="Update Exclusive Merchandiser"
                            submitText="Save"
                            merchandiser={selectedMerchIndex !== -1 ? contractMerchandisers[selectedMerchIndex] : null}
                            branches={branches}
                        />
                    </Form>
                )}
            </Formik>
        </section>
    );
};

UpdateExclusivityContract.propTypes = {
    classes: PropTypes.object,
};

export default UpdateExclusivityContract;
