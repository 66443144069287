import React from "react";
import { Header } from "../../core-ui/custom";
import { generateTableToolbarButton } from "../../helpers/table-helpers";
import PATHS from "../../constants/paths";
import styles from "./styles";
import { goTo } from "../../services/history";
import { Card } from "@material-ui/core";
import EnhancedTable from "../../components-lagacy/Table/EnhancedTable";
import { useCallback } from "react";
import { useEffect } from "react";
import { queryMerchandiserExclusivityContracts } from "../../services/firestore/Merchandiser_Exclusivity_Contract";
import { useAuth } from "../../contexts/auth-context";
import { getMP } from "../../services/firestore/MP";

const tableHeaders = [
    { id: "number", numeric: false, disablePadding: false, hidden: false, label: "No." },
    { id: "name", numeric: false, disablePadding: false, hidden: false, label: "Name" },
    { id: "mp", numeric: false, disablePadding: false, label: "Service Provider" },
    { id: "start_date", numeric: false, disablePadding: false, label: "Start" },
    { id: "end_date", numeric: false, disablePadding: false, label: "End" },
    { id: "statusLabel", numeric: false, disablePadding: false, label: "Status" },
];

const toolbars = [
    generateTableToolbarButton(
        "Edit Contract",
        (contractIDs) => {
            goTo(PATHS.UPDATE_EXCLUSIVITY_CONTRACT, { contractID: contractIDs[0] });
        },
        "Edit",
        (selected) => selected.length === 1
    ),
];

const getContractStatusLabel = (status) => {
    switch (status) {
        case 0:
            return "Inactive";
        case 1:
            return "Active";
        case 2:
            return "Pending";
        case 3:
            return "Rejected";
        case 4:
            return "Cancelled";
        case 5:
            return "Expired";
        default:
            return "";
    }
};

const ExclusivityContracts = () => {
    const classes = styles();
    const [loading, setLoading] = React.useState(true);
    const [rows, setRows] = React.useState([]);
    const { companyData } = useAuth();

    const init = useCallback(async () => {
        setLoading(true);
        try {
            const { company_id } = companyData;
            let merch_contracts = await queryMerchandiserExclusivityContracts([
                { key: "supplier_id", value: company_id },
            ]);
            merch_contracts = merch_contracts.map((contract) => ({
                ...contract.data(),
                start_date: contract.data().start_date.toDate(),
                end_date: contract.data().end_date.toDate(),
            }));

            let mpIDs = [...new Set(merch_contracts.map((contract) => contract.mp_id))];
            let mps = await Promise.all(mpIDs.map((mp_id) => getMP(mp_id)));
            mps = mps.map((mp) => mp.data());

            let rows = merch_contracts.map((contract) => {
                let mp = mps.find((mp) => mp.company_id === contract.mp_id);
                return {
                    ...contract,
                    mp: mp.company_name,
                    statusLabel: getContractStatusLabel(contract.status),
                };
            });
            setRows(rows);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }, [companyData]);

    useEffect(() => {
        init();
    }, [init]);

    return (
        <section className={classes.exclusivityContracts}>
            <Header className={classes.header}>Exclusivity Contracts</Header>
            <Card>
                <EnhancedTable
                    title={"Contracts"}
                    columns={tableHeaders}
                    rows={rows}
                    actions={toolbars}
                    updateSelected={() => {}}
                    // selectionDisabled={true}
                    loading={loading}
                />
            </Card>
        </section>
    );
};

export default ExclusivityContracts;
