import React, { useEffect, useState } from "react";
import styles from "./styles";

import { Grid } from "@material-ui/core";
import { MultiStepForm, SignUpStepIcon, StepForm } from "../../components";
import {
    STEP_FOUR_VALIDATION_SCHEMA,
    STEP_ONE_VALIDATION_SCHEMA,
    STEP_THREE_VALIDATION_SCHEMA,
    STEP_TWO_VALIDATION_SCHEMA,
} from "../../constants/edit-account-validation-schemas";
import { Autocomplete, DateTimePicker, FileUpload, ImageFileButton, TextField } from "../../core-ui/forms-ui";
import { getCitiesList } from "../../helpers/firebase-helpers";
import {
    convertUpdateAccountValuesForSupplier,
    convertUpdateAccountValuesForUser,
    convertURLToFile,
} from "../../helpers/converters";
import { LoadingOverlay, TransitionAlert } from "../../core-ui/custom";
import { useAuth } from "../../contexts/auth-context";
import { getUser, updateUser } from "../../services/firestore/User";
import { getCity } from "../../services/firestore/City";
import { useCallback } from "react";
import { getSupplier, updateSupplier } from "../../services/firestore/Supplier";

const FORM_INITIAL_VALUES = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",

    company_name: "",
    company_short_name_en: "",
    company_short_name_ar: "",
    company_city: { label: "Jeddah", value: "102" },
    company_location: "",
    company_website_link: "",
    company_email: "",
    company_phone_number: "",
    company_fax_number: "",
    company_logo_image: null,

    commercial_registry_number: "",
    commercial_registry_expiry_date: "",
    municipal_license_number: "",
    vat_number: "",
    vat_image: null,
    commercial_registry_image: null,

    contact_first_name: "",
    contact_last_name: "",
    contact_mobile_number: "",
    contact_phone_number: "",
    contact_external_phone_number: "",
    contact_email: "",
    job_title: "",
};

const getFieldsValues = async (userID, companyID) => {
    try {
        //get user and company data
        const [userData, companyData] = await (
            await Promise.all([getUser(userID), getSupplier(companyID)])
        ).map((doc) => doc.data());
        const city = (await getCity(`${companyData.city_id}`)).data();

        const logoFile = await convertURLToFile(companyData.logo, "logo");
        const taxFile = await convertURLToFile(companyData.commercial_register.tax_image, "vat");
        const commercialRegistryFile = await convertURLToFile(
            companyData.commercial_register.commercial_register_image,
            "commercial-registry"
        );

        return {
            first_name: userData.first_name,
            last_name: userData.surname,
            phone_number: userData.phone,
            email: userData.email,

            company_name: companyData.company_name,
            company_short_name_en: companyData.En_short_name,
            company_short_name_ar: companyData.Ar_short_name,
            company_city: { label: city.En_name, value: `${city.city_id}` },
            company_location: companyData.location,
            company_website_link: companyData.contact_information.website,
            company_email: companyData.contact_information.company_email,
            company_phone_number: companyData.contact_information.company_phone,
            company_fax_number: companyData.contact_information.fax,
            company_logo_image: logoFile,

            commercial_registry_number: companyData.commercial_register.commNumber,
            commercial_registry_expiry_date: companyData.commercial_register.expiry_date.toDate?.() || "",
            municipal_license_number: companyData.commercial_register.municipal_license,
            vat_number: companyData.commercial_register.tax_number,
            vat_image: taxFile,
            commercial_registry_image: commercialRegistryFile,

            contact_first_name: companyData.contact_person.first_name,
            contact_last_name: companyData.contact_person.surname,
            contact_mobile_number: companyData.contact_person.mobile,
            contact_phone_number: companyData.contact_person.phone,
            contact_external_phone_number: companyData.contact_person.ext_phone,
            contact_email: companyData.contact_person.email,
            job_title: companyData.contact_person.job_title,
        };
    } catch (error) {
        throw error;
    }
};

const UpdateAccountInfo = () => {
    const classes = styles();
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState(FORM_INITIAL_VALUES);
    const { userData, companyData } = useAuth();
    const [alertState, setAlertState] = useState({
        msg: "",
        isOpened: false,
        severity: "info",
    });

    const init = useCallback(async () => {
        try {
            setLoading(true);

            let citiesList = await getCitiesList();
            setCities(citiesList);

            //update fields
            const newValues = await getFieldsValues(userData.uid, companyData.company_id);
            setInitialValues(newValues);
        } catch (error) {
            console.error(error.message);
        }
        setLoading(false);
    }, [userData.uid, companyData.company_id]);

    const setAlertOpened = (isOpened) => setAlertState((prev) => ({ ...prev, isOpened: isOpened }));

    const handleSubmit = async (values, helpers) => {
        try {
            setLoading(true);
            const updatedCompanyData = convertUpdateAccountValuesForSupplier(values);
            const updatedUserData = convertUpdateAccountValuesForUser(values);

            await Promise.all([
                updateSupplier(companyData.company_id, updatedCompanyData),
                updateUser(userData.uid, updatedUserData),
            ]);

            setAlertState({ msg: "Account info is upadated successfully.", severity: "success", isOpened: true });
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        init();
    }, [init]);

    return (
        <section className={classes.SignupPage}>
            <TransitionAlert
                severity={alertState.severity}
                variant="filled"
                open={alertState.isOpened}
                setOpen={setAlertOpened}
            >
                {alertState.msg}
            </TransitionAlert>
            <section className={classes.formSection}>
                {loading && <LoadingOverlay test="Processing" />}

                <MultiStepForm
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    stepIcons={SignUpStepIcon}
                    dirtyRequired={false}
                >
                    <StepForm stepName="Account" validationSchema={STEP_ONE_VALIDATION_SCHEMA}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="first_name" label="First Name*" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="last_name" label="Last Name*" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    name="email"
                                    label="Email*"
                                    type="email"
                                    showValidationIcon
                                    validateOnChange
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField name="phone_number" label="Phone*" type="tel" />
                            </Grid>
                        </Grid>
                    </StepForm>
                    <StepForm stepName="Company" validationSchema={STEP_TWO_VALIDATION_SCHEMA}>
                        <Grid container spacing={2}>
                            <Grid item xs={10} sm={10} md={11}>
                                <TextField name="company_name" label="Company Name*" />
                            </Grid>
                            <Grid item xs={2} sm={2} md={1}>
                                {/* <FileUpload name="company_logo_image" label="Company's Logo" /> */}
                                <ImageFileButton name="company_logo_image" label="Company's Logo" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="company_short_name_en" label="Company Short Name (English)*" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="company_short_name_ar" label="Company Short Name (Arabic)*" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <Autocomplete options={cities} name="company_city" label="City*" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="company_location" label="Company's Address" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="company_website_link" label="Website's URL" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="comapny_email" label="Email" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="company_phone_number" label="Phone Number" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="company_fax_number" label="Fax Number" />
                            </Grid>
                        </Grid>
                    </StepForm>
                    <StepForm stepName="Commercial Registry" validationSchema={STEP_THREE_VALIDATION_SCHEMA}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="commercial_registry_number" label="Commercial Registry Number" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <DateTimePicker
                                    name="commercial_registry_expiry_date"
                                    label="Commercial Registry Expiry Date"
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <FileUpload name="commercial_registry_image" label="Commercial Registry Image" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="vat_number" label="VAT Number" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <FileUpload name="vat_image" label="VAT Image" />
                            </Grid>
                        </Grid>
                    </StepForm>
                    <StepForm
                        stepName="Contact"
                        // onSubmit={(values) => console.log(`contact Step values: ${values}`)}
                        validationSchema={STEP_FOUR_VALIDATION_SCHEMA}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="contact_first_name" label="First Name" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField name="contact_last_name" label="Last Name" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField name="contact_email" label="Email" type="email" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField name="contact_mobile_number" label="Mobile Number" type="tel" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField name="contact_phone_number" label="Phone Number" type="tel" />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    name="contact_external_phone_number"
                                    label="External Phone Number"
                                    type="tel"
                                />
                            </Grid>
                        </Grid>
                    </StepForm>
                </MultiStepForm>
            </section>
        </section>
    );
};

export default UpdateAccountInfo;
