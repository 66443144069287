import styles from "./styles";
import { CircularLoader } from "../../../core-ui/custom";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useMemo, useState } from "react";

const DEFAULT_CENTER = {
    lat: 21.4885,
    lng: 39.1873,
};

const Map = ({ onClick, initialMapCenter, initialPins = [] }) => {
    const classes = styles();
    const center = useMemo(() => initialMapCenter || DEFAULT_CENTER, []);
    const [pins, setPins] = useState(initialPins);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const clickHandler = ({ latLng }) => {
        if (onClick) onClick({ latLng, pins, setPins });
    };

    if (!isLoaded) return <CircularLoader />;

    return (
        <GoogleMap onClick={clickHandler} zoom={17} center={center} mapContainerClassName={classes.mapContainer}>
            {pins.map((pin) => {
                const { id, lat, lng } = pin;
                return <Marker key={id} position={{ lat, lng }} />;
            })}
        </GoogleMap>
    );
};

export default Map;
