import React from "react";
import TransferList from "../../components-lagacy/TransferList/TransferList";

const setupConfig = (list, title, onTransfer, direction, onFilter) => {
    const set = (newList) => {
        onTransfer(newList, direction);
    };

    if (direction === "checked") return { list, set };

    const filter = (e, newList) => {
        onFilter(e, newList, direction);
    };

    return { list, set, title, onFilter: filter };
};

const EnhancedTransferList = (props) => {
    const {
        leftList = [],
        rightList = [],
        checkedList = [],
        leftTitle = "Choices",
        rightTitle = "Chosen",
        disabled = false,
        onTransfer = (newList, direction) => {},
        onFilter = (e, list, direction) => {},
    } = props;

    let leftConfig = setupConfig(leftList, leftTitle, onTransfer, "left", onFilter);
    let rightConfig = setupConfig(rightList, rightTitle, onTransfer, "right", onFilter);
    let checkedConfig = setupConfig(checkedList, "", onTransfer, "checked", onFilter);

    leftConfig = {
        leftList: leftConfig.list,
        setLeft: leftConfig.set,
        leftTitle: leftTitle,
        onLeftListFilter: leftConfig.onFilter,
    };

    rightConfig = {
        rightList: rightConfig.list,
        setRight: rightConfig.set,
        rightTitle: rightTitle,
        onRightListFilter: rightConfig.onFilter,
    };

    checkedConfig = {
        checkedList: checkedConfig.list,
        setChecked: checkedConfig.set,
    };

    return <TransferList left={leftConfig} right={rightConfig} checked={checkedConfig} disabled={disabled} />;
};

export default EnhancedTransferList;
