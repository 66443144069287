import React, { useMemo } from "react";
import { FormControl, IconButton, makeStyles } from "@material-ui/core";
import { Button } from "../../custom";
import isArray from "lodash/fp/isArray";
import isString from "lodash/fp/isString";
import ClearIcon from "@material-ui/icons/Clear";
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        position: "relative",
        "&:hover $deleteBtn": {
            opacity: 1,
            pointerEvents: "all",
            cursor: "pointer",
            boxShadow: "0px 0px 3px black",
        },
    },
    formControl: {
        // margin: theme.spacing(1),
    },
    input: {
        display: "none",
    },
    label: {
        height: "100%",
    },

    button: ({ hoverText }) => ({
        "&:hover:after": {
            content: hoverText ? `'${hoverText}'` : "",
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "white",
            fontSize: "0.65rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
        },
    }),

    deleteBtn: {
        width: 25,
        height: 25,
        position: "absolute",
        top: -7,
        right: -7,
        color: "#e10000",
        backgroundColor: "lightgrey",
        borderRadius: "50%",
        padding: 5,
        pointerEvents: "none",
        opacity: 0,
        zIndex: 100,
    },
}));

const acceptVariants = {
    word: ".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    images: ".jpg, .png, .gif",
    excel: ".xlsx,.xls,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

const makeAcceptString = (accept) => {
    if (!accept || !accept.length) {
        return "";
    }

    if (isString(accept)) {
        return acceptVariants[accept] ? acceptVariants[accept] : accept;
    }

    if (isArray(accept)) {
        const len = accept.length;
        return accept.reduce((acc, curr, currIndex) => {
            if (acceptVariants[curr]) {
                acc += acceptVariants[curr];
            } else if (curr && curr.length && curr.trim().length) {
                acc += curr.trim();
            }

            if (len > 1 && currIndex < len - 1) {
                acc += ",";
            }

            return acc;
        }, "");
    }

    return "";
};

const FileButton = ({
    accept,
    multiple = false,
    id = `file-input-${Math.random()}`,
    onChange,
    onClear = () => {},
    children,
    iconButton = false,
    clearable = true,
    className = "",
    hoverText = "Change Image",
    ...otherProps
}) => {
    const classes = useStyles({ hoverText });

    const acceptString = useMemo(() => makeAcceptString(accept), [accept]);

    let button = (
        <Button variant="contained" component="span" className={`${classes.button} ${className}`} {...otherProps}>
            {children || "Upload"}
        </Button>
    );

    if (iconButton) {
        button = (
            <IconButton
                variant="contained"
                component="span"
                className={`${classes.button} ${className}`}
                {...otherProps}
            >
                {children || "Upload"}
            </IconButton>
        );
    }
    return (
        <div className={classes.container}>
            {iconButton && clearable && <ClearIcon className={classes.deleteBtn} onClick={onClear} />}
            <FormControl className={classes.formControl}>
                <input
                    id={id}
                    accept={acceptString}
                    className={classes.input}
                    multiple={multiple}
                    type="file"
                    onChange={onChange}
                />
                <label htmlFor={id}>{button}</label>
            </FormControl>
        </div>
    );
};

export default FileButton;
