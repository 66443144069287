import React from "react";

//related to meterial ui package
import styles from "./styles";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import { useState } from "react";
import { useAuth } from "../../contexts/auth-context";
import * as Yup from "yup";
import { formatDate, getToday } from "../../helpers/date-utils";
import { useEffect } from "react";
import { useCallback } from "react";
import { queryProjects } from "../../services/firestore/Project";
import { PROJECT_TYPES } from "../../constants/global";
import { Form, Formik } from "formik";
import { Autocomplete, DateTimePicker, SubmitButton } from "../../core-ui/forms-ui";
import { queryProjectBranch } from "../../services/firestore/Project/Project_Branch";
import { getSupplierBranchInfo } from "../../services/firestore/Supplier_Branch";
import { getOutletBranch } from "../../services/firestore/Outlet_Branch";
import { queryProjectOutletBranch } from "../../services/firestore/Project/Project_Outlet_Branch";
import { queryProjectSchedule } from "../../services/firestore/Project/Project_Schedule";
import CloseIcon from "@material-ui/icons/Close";
import { COLLECTIONS } from "../../services/firestore/constants";
import { getMerchandiserName, getMerchandisersBySupplierBranch } from "../../services/firestore/Merchandiser";
import { setTask } from "../../services/firestore/Task";
import { queryRoutes } from "../../services/firestore/Route";
import Swal from "sweetalert2";
import COLORS from "../../constants/colors";

const FORM_SCHEMA = Yup.object().shape({
    project: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.string(),
        })
        .nullable()
        .required("Project is required."),
    supplierBranch: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.string(),
        })
        .nullable()
        .required("Supplier branch is required."),
    outletBranch: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.string(),
        })
        .nullable()
        .required("Outlet branch is Required."),
    merchandiser: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.string(),
        })
        .nullable()
        .required("Merchandiser is Required."),
    date: Yup.date("Invalid date.").required("Required"),
});

let INITIAL_FORM_VALUES = {
    project: null,
    supplierBranch: null,
    outletBranch: null,
    merchandiser: null,
    date: getToday(),
};

const TaskCreationDialog = ({ open, onClose }) => {
    const classes = styles();
    const { companyData } = useAuth();
    const [loading, setLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [projectsList, setProjectsList] = useState([]);
    const [supplierBranchesList, setSupplierBranchesList] = useState([]);
    const [outletBranchesList, setOutletBranchesList] = useState([]);
    const [merchandisersList, setMerchandiserList] = useState([]);
    const [schedule, setSchedule] = useState(null);

    const init = useCallback(async () => {
        try {
            //load projects
            const projects = (
                await queryProjects([
                    { key: "supplier_id", operator: "==", value: companyData.company_id },
                    { key: "project_type", operator: "==", value: PROJECT_TYPES.SUPPLIER_INSOURCE },
                ])
            ).map((doc) => ({
                value: doc.id,
                label: `${doc.data().project_id} - ${doc.data().projName}`,
                data: doc.data(),
            }));

            setProjectsList(projects);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        init();
    }, [init]);

    const onProjectChange = async (selectedProject) => {
        try {
            setLoading(true);
            // get project branch
            let projectBranches = (await queryProjectBranch(selectedProject.value, [])).map((doc) => doc.data());

            // get branches info
            projectBranches = await Promise.all(
                projectBranches.map(async (projectBranch) => ({
                    ...projectBranch,
                    info: (await getSupplierBranchInfo(projectBranch.branch_id)).data(),
                }))
            );

            setSupplierBranchesList(
                projectBranches.map((br) => ({
                    value: br.branch_id,
                    label: br.info.En_name,
                    data: br,
                }))
            );
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const onSupplierBranchChange = async (selectedBranch) => {
        try {
            setLoading(true);
            const { project_id, branch_id } = selectedBranch.data;

            // get project Outlet branch and merchandisers
            let [projectOutletBranches, merchandisers] = await Promise.all([
                queryProjectOutletBranch(project_id, branch_id, []),
                getMerchandisersBySupplierBranch(branch_id),
            ]);

            //doc to data
            projectOutletBranches = projectOutletBranches.map((doc) => doc.data());
            merchandisers = merchandisers.map((doc) => ({
                value: doc.id,
                label: getMerchandiserName(doc.data()),
                data: doc.data(),
            }));

            // get outlet branches info
            projectOutletBranches = await Promise.all(
                projectOutletBranches.map(async (projectOutletBranch) => ({
                    ...projectOutletBranch,
                    info: (await getOutletBranch(projectOutletBranch.branch_id)).data(),
                }))
            );

            setOutletBranchesList(
                projectOutletBranches.map((ob) => ({
                    value: ob.branch_id,
                    label: ob.info.En_short_name,
                    data: ob,
                }))
            );

            setMerchandiserList(merchandisers);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const onOutletBranchChange = async (selectedOutletBranch, formik) => {
        try {
            setLoading(true);
            const { branch_id, supplier_branch_id, project_id } = selectedOutletBranch.data;
            //get schedule
            let schedule = await queryProjectSchedule(project_id, supplier_branch_id, branch_id, {
                docID: "100",
            });

            //assign default merchandiser if any
            const merchandiser = merchandisersList.find((item) => item.value === schedule.data().merchandiser_id);
            formik.setFieldValue("merchandiser", merchandiser);

            setSchedule(schedule);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const handleSubmit = async (values) => {
        try {
            setCreating(true);
            const { project, supplierBranch, outletBranch, merchandiser, date } = values;
            const { route_id } = schedule.data();
            const products = (await schedule.ref.collection(COLLECTIONS.PROJECT_PRODUCT).get()).docs.map((doc) =>
                doc.data()
            );
            const route = route_id ? (await queryRoutes({ docID: route_id })).data() : null;

            const data = {
                route,
                schedule: schedule.data(),
                outletBranch: outletBranch.data,
                supplierBranch: supplierBranch.data,
                project: project.data,
                merchandiser: merchandiser.data,
                products,
                date,
            };

            const task = await setTask(data);
            Swal.fire({
                title: "Visit Has Been Created!",
                text: `Visit number ${task.task_id} has been successfully created at 
                ${outletBranch.label} - ${formatDate(date)}`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
            });
            onClose(null, "submit", true);
        } catch (error) {
            console.log(error);
        }
        setCreating(false);
    };

    return (
        <Dialog maxWidth="lg" fullWidth open={open} onClose={onClose} className={classes.dialogRoot}>
            <DialogTitle disableTypography>
                <Typography className={classes.titleText} variant="h5">
                    Create a Visit
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers className={classes.cardContent}>
                <Formik
                    initialValues={INITIAL_FORM_VALUES}
                    enableReinitialize
                    validationSchema={FORM_SCHEMA}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={2}>
                                    <Autocomplete
                                        label="Project"
                                        name="project"
                                        options={projectsList}
                                        disableClearable
                                        onChange={onProjectChange}
                                        className={classes.input}
                                        classes={{
                                            option: classes.option,
                                        }}
                                        loading={loading}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <Autocomplete
                                        label="Supplier Branch"
                                        name="supplierBranch"
                                        options={supplierBranchesList}
                                        disableClearable
                                        onChange={onSupplierBranchChange}
                                        className={classes.input}
                                        classes={{
                                            option: classes.option,
                                        }}
                                        loading={loading}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={2}>
                                    <Autocomplete
                                        label="Outlet Branch"
                                        name="outletBranch"
                                        options={outletBranchesList}
                                        disableClearable
                                        onChange={(value) => onOutletBranchChange(value, props)}
                                        className={classes.input}
                                        classes={{
                                            option: classes.option,
                                        }}
                                        loading={loading}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <Autocomplete
                                        label="Merchandiser"
                                        name="merchandiser"
                                        options={merchandisersList}
                                        disableClearable
                                        className={classes.input}
                                        classes={{
                                            option: classes.option,
                                        }}
                                        loading={loading}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <DateTimePicker
                                        name="date"
                                        label="Date"
                                        minDate={getToday()}
                                        className={classes.input}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <SubmitButton type="submit" className={classes.submitBtn} submitting={creating}>
                                        Create
                                    </SubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default TaskCreationDialog;
