import { generateNewDocID } from "../../helpers/firebase-helpers";
import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

export const queryCompetitorProducts = async (q) => {
    try {
        return await query(COLLECTIONS.COMPETITOR_PRODUCTS, q);
    } catch (error) {
        throw error;
    }
};

export let getSupplierCompetitorProducts = async (supplierID) => {
    let products = [];

    try {
        products = (await db.collection(COLLECTIONS.COMPETITOR_PRODUCTS).where("supplier_id", "==", supplierID).get())
            .docs;
    } catch (error) {
        console.log(error);
    }

    return products;
};

export let getCompetitorProduct = async (id) => {
    let product = null;
    try {
        product = await db.collection(COLLECTIONS.COMPETITOR_PRODUCTS).doc(id).get();
    } catch (error) {
        console.log(error);
    }

    return product;
};

export let getCompetitorProducts = async (IDs) => {
    let products = [];

    try {
        products = await Promise.all(IDs.map(getCompetitorProduct));
    } catch (error) {
        console.log(error);
    }
    return products;
};

export const addCompetitorProduct = async (values) => {
    try {
        const newID = await generateNewDocID(COLLECTIONS.COMPETITOR_PRODUCTS, "product_id");
        values.product_id = newID;

        // product image = file object or string (URL)
        // for (const key in values.product_image) {
        //     if (Object.hasOwnProperty.call(values.product_image, key)) {
        //         let image = values.product_image[key] || null;
        //         if (image && typeof image === "object") {
        //             const filename = `${newID}-${values.En_name}-${key}.${image.name.split(".").pop()}`;
        //             //upload and return a url
        //             values.product_image[key] = await uploadImage(`Competitor_Products/${values.supplier_id}`, image, filename);
        //         }
        //     }
        // }

        await db.collection(COLLECTIONS.COMPETITOR_PRODUCTS).doc(newID).set(values);
        return values;
    } catch (error) {
        throw error;
    }
};
export const setCompetitorProduct = async (values) => {
    try {
        // product image = file object or string (URL)
        // for (const key in values.product_image) {
        //     if (Object.hasOwnProperty.call(values.product_image, key)) {
        //         let image = values.product_image[key] || null;
        //         if (image && typeof image === "object") {
        //             const filename = `${values.product_id}-${values.En_name}-${key}.${image.name.split(".").pop()}`;
        //             //upload and return a url
        //             values.product_image[key] = await uploadImage(`Products/${values.supplier_id}`, image, filename);
        //         }
        //     }
        // }

        await db.collection(COLLECTIONS.COMPETITOR_PRODUCTS).doc(values.product_id).update(values);
        return values;
    } catch (error) {
        throw error;
    }
};
