import React, { Component } from "react";
//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import ProjectManagementTabs from "../tabs/ProjectManagmentTabs";
import ProjectCoverage from "./ProjectCoverage";
import ProjectManagementContent from "./ProjectManagementContent";
import ProjectRoutePlanManagement from "./ProjectRoutePlanManagement";

//utils
import TransitionAlert from "../../../core-ui/custom/transition-alert";
import FullPageLoader from "../../../components-lagacy/Loader/FullPageLoader";
import { getWholeProject, syncProject } from "../../../services/firestore/Project";
import { getSupplier } from "../../../helpers/firestore-utils";
import { generateTaskHeadersFromProject } from "../../../services/firestore/Task";
import { getMerchandisersBySupplierBranch, getOutsourcedMerchandisers } from "../../../services/firestore/Merchandiser";

// import { firestore as db } from "../../../services/firebase";
import { PROJECT_TYPES } from "../../../constants/constants-lagacy";
import { getToday } from "../../../helpers/date-utils";
import { getSupplierBranchInfo } from "../../../services/firestore/Supplier_Branch";
import { functions } from "../../../services/firebase";
import ProjectGeneralInfo from "./ProjectGeneralInfo";
import { ManageInsourceProductMerchandisingProject } from "../../../pages";
import { calculateScheduleOptionsFrequancies } from "../../../services/firestore/Project_Schedules";

const useStyles = (theme) => ({
    container: {
        width: "100%",
        padding: "10px 20px",
        position: "relative",
    },

    contentCard: {
        width: "100%",
        // maxWidth: "1290px",
    },
    loader: {
        marginTop: "35px",
    },

    stickyAlert: {
        position: "sticky",
        top: 10,
        zIndex: 10,
    },
});

class ProjectController extends Component {
    state = {
        loading: true,
        tabIndex: 0,
        projectFullData: null,
        isConfirmed: false,
        projectMerchandisers: [],
        supplier: null,

        alertOpened: false,
        alertMsg: "",
        alertProps: {
            variant: "filled",
            severity: "success",
        },
    };

    init = async () => {
        this.setState({ loading: true });
        try {
            const supplierID = this.props.supplierID;
            let requests = [getWholeProject(this.props.projectID, true), getSupplier(supplierID)];
            let [projectFullData, supplier] = await Promise.all(requests);

            if (!projectFullData.data.options) {
                projectFullData.data.options = {
                    availability: { active: false },
                    quantity: { active: true },
                    pictures: { active: false },
                    inventory: { active: true, interval: "daily" },
                    near_expiration: { active: true, interval: "daily" },
                    warehouse: { active: true },
                    shelf_price: { active: true, interval: "daily" },
                };
            }
            ////get route plans along with merchandisers info
            // get merchandisers with their supplier branch
            let projectMerchandisers = [];
            if (projectFullData.data.project_type === PROJECT_TYPES.SUPPLIER_INSOURCE) {
                // const cities = await this.getCitiesFromSupplierBranches(projectFullData.data.branches);
                // let mpBranches = (await Promise.all(cities.map(this.getMPBranchesByCity))).reduce((arr, branches) => {
                //     return [...arr, ...branches];
                // }, []);
                // mpBranches = mpBranches.map((p) => p.data());

                const branches = (
                    await Promise.all(
                        projectFullData.supplierBranches.map((branch) => getSupplierBranchInfo(branch.data.branch_id))
                    )
                ).map((p) => p.data());

                projectMerchandisers = await Promise.all(branches.map(this.getProjectMerchandisers));
                // //flatten
                projectMerchandisers = projectMerchandisers.reduce(
                    (arr, merchandisers) => [...arr, ...merchandisers],
                    []
                );
            }

            this.setState({
                supplier: supplier,
                projectFullData: projectFullData,
                projectMerchandisers: projectMerchandisers,
                isConfirmed: projectFullData.data.isConfirmed,
                loading: false,
            });
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    };

    // getCitiesFromSupplierBranches = async (branches) => {
    //     try {
    //         let cities = await Promise.all(
    //             branches.map(async (branchID) => {
    //                 return (await db.collection("Branch").doc(branchID).get()).data().city_id;
    //             })
    //         );
    //         cities = [...new Set(cities)];

    //         return cities;
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    // getMPBranchesByCity = async (cityID) => {
    //     try {
    //         return (await db.collection("MP_Branch").where("city_id", "==", cityID).get()).docs;
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    getProjectMerchandisers = async (branch) => {
        let merchandisers = [];
        let outsourcedMerchandisers = [];
        const { supplierID } = this.props;
        const id = branch.branch_id;
        try {
            merchandisers = await getMerchandisersBySupplierBranch(id);
            outsourcedMerchandisers = await getOutsourcedMerchandisers(supplierID, getToday(), id);

            merchandisers = await Promise.all(
                merchandisers.map(async (merch) => {
                    // let route = await getMerchandiserRoute(merch.id);
                    // if (!route.exists) throw new Error(`could not find merchandiser's route plan ${merch.id}`);

                    // return { info: merch.data(), route: route.data(), branch: MPBranch };
                    return { info: merch.data(), contract: null, branch };
                })
            );

            return [...merchandisers, ...outsourcedMerchandisers];
        } catch (error) {
            throw error;
        }
    };

    openRoutePlan = () => () => {
        this.setState({ tabIndex: 2 });
    };

    updateProjectData = (updatedProjectData) => {
        this.setState({ projectData: updatedProjectData });
    };

    updateMerchandisers = (updatedMerchandisers) => {
        this.setState({ projectMerchandisers: updatedMerchandisers });
    };

    removeDocsFromProject = (projectFullData) => {
        let data = {
            data: projectFullData.data,
            supplierBranches: projectFullData.supplierBranches.map((sb) => ({
                data: sb.data,
                info: sb.info.data(),
                outletBranches: sb.outletBranches.map((ob) => ({
                    data: ob.data,
                    info: ob.info.data(),
                    schedules: ob.schedules.map((s) => ({
                        data: s.data,
                        products: s.products.map((p) => ({
                            data: p.data,
                            info: p.info.data(),
                        })),
                    })),
                })),
            })),
        };

        return data;
    };

    sync = async (
        newProjectFullData,
        updatedMerchandisers = null,
        msg = "The Project's data has been successfully saved."
    ) => {
        try {
            //update options
            const { date_from, date_to, options } = newProjectFullData.data;
            const supplierBranches = newProjectFullData.supplierBranches;
            for (const sb of supplierBranches) {
                const outletBranches = sb.outletBranches;
                for (const ob of outletBranches) {
                    const schedules = ob.schedules;
                    for (const sch of schedules) {
                        const { merchandising_days } = sch.data;
                        const newOptions = calculateScheduleOptionsFrequancies(
                            options,
                            merchandising_days,
                            date_from,
                            date_to
                        );
                        sch.data.options = newOptions;
                        sch.toBeUpdated = true;
                    }
                }
            }
            let updated = await syncProject(newProjectFullData);
            console.log("updated");
            if (updated.data.isConfirmed && this.props.projectType === PROJECT_TYPES.SUPPLIER_INSOURCE) {
                let generate = functions.httpsCallable("generateTaskHeadersFromProject");
                //remove docs from project object
                let updatedData = this.removeDocsFromProject(updated);
                console.log("updatedData", updatedData);
                const res = await generate({ project: updatedData });
                console.log("res", res);
                // console.log("updated", updated);
                // console.log("generateTaskHeadersFromProject");
            }
            console.log(updated.data);
            this.setState({
                projectFullData: { ...updated },
                // projectMerchandisers: updatedMerchandisers,
                alertMsg: msg,
                alertOpened: true,
            });
        } catch (error) {
            console.log(error);
        }
    };

    setAlertOpen = (bool) => {
        this.setState({ alertOpened: bool });
    };

    componentDidMount() {
        this.init();
    }

    render() {
        const classes = this.props.classes;
        return (
            <div
                style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column" }}
                className={classes.container}
            >
                <TransitionAlert
                    variant="filled"
                    open={this.state.alertOpened}
                    setOpen={this.setAlertOpen}
                    className={classes.stickyAlert}
                    timer={3000}
                >
                    {this.state.alertMsg}
                </TransitionAlert>
                {this.state.loading ? (
                    <FullPageLoader loading={this.state.loading} className={classes.loader} />
                ) : (
                    <>
                        {/*         Tabs         */}
                        <ProjectManagementTabs
                            selfService={this.props.selfService}
                            projectType={this.props.projectType}
                            isConfirmed={this.state.isConfirmed}
                            tabIndex={this.state.tabIndex}
                            onTabSelect={(event, newIndex) => this.setState({ tabIndex: newIndex })}
                        />

                        {/*        Management        */}

                        {this.state.tabIndex === 0 && !this.state.loading ? (
                            <ManageInsourceProductMerchandisingProject
                                project={this.state.projectFullData}
                                syncProject={this.sync}
                            />
                        ) : null}
                        {/* {this.state.tabIndex === 0 && !this.state.loading ? (
                            <ProjectGeneralInfo
                                projectFullData={this.state.projectFullData}
                                merchandisers={this.state.projectMerchandisers}
                                supplier={this.state.supplier}
                                selfService={this.props.selfService}
                                projectType={this.props.projectType}
                                syncProject={this.sync}
                                openRoutePlan={this.openRoutePlan}
                                updateProjectData={this.updateProjectData}
                                updateMerchandisers={this.updateMerchandisers}
                                onBackClicked={this.props.onBackClicked}
                            />
                        ) : null} */}
                        {/*        Coverage        */}
                        {this.state.tabIndex === 1 &&
                        (!this.state.isConfirmed || this.props.projectType === PROJECT_TYPES.SUPPLIER_INSOURCE) &&
                        !this.state.loading ? (
                            <ProjectCoverage
                                projectFullData={this.state.projectFullData}
                                merchandisers={this.state.projectMerchandisers}
                                sync={this.sync}
                                selfService={this.props.selfService}
                                projectType={this.props.projectType}
                            />
                        ) : null}

                        {/*        Management        */}

                        {(this.state.tabIndex === 2 ||
                            (this.state.isConfirmed && this.props.projectType !== PROJECT_TYPES.SUPPLIER_INSOURCE)) &&
                        !this.state.loading ? (
                            <ProjectManagementContent
                                projectFullData={this.state.projectFullData}
                                merchandisers={this.state.projectMerchandisers}
                                supplier={this.state.supplier}
                                selfService={this.props.selfService}
                                projectType={this.props.projectType}
                                syncProject={this.sync}
                                openRoutePlan={this.openRoutePlan}
                                updateProjectData={this.updateProjectData}
                                updateMerchandisers={this.updateMerchandisers}
                                onBackClicked={this.props.onBackClicked}
                            />
                        ) : null}

                        {/*        Route Plan        */}
                        {this.state.tabIndex === 3 && !this.state.loading && this.state.projectFullData ? (
                            <ProjectRoutePlanManagement
                                projectFullData={this.state.projectFullData}
                                merchandisers={this.state.projectMerchandisers}
                                supplier={this.state.supplier}
                                selfService={this.props.selfService}
                                projectType={this.props.projectType}
                                sync={this.sync}
                                updateProjectData={this.updateProjectData}
                            />
                        ) : null}
                    </>
                )}
            </div>
        );
    }
}

ProjectController.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(useStyles)(ProjectController);
