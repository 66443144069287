import React from "react";

//related to meterial ui package
import styles from "./styles";
import { CircularLoader, Header } from "../../core-ui/custom";
import EnhancedTable from "../../components-lagacy/Table/EnhancedTable";
import { Filters } from "../../components";
import useCompetitorProducts from "../../hooks/use-competitor.products";

const CompetitorProducts = () => {
    const classes = styles();
    const { initializing, loading, initFilters, HEADROW, TABLE_TOOLBAR_ACTIONS, rows, search } = useCompetitorProducts({
        classes,
    });

    if (initializing) return <CircularLoader className={classes.circularLoader} />;

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.pageHeader}>Competitor Products</Header>
            <div className={classes.content}>
                <Filters init={initFilters} onSubmit={search} submitText="Search" />
                <div className={classes.tableWrapper}>
                    <EnhancedTable
                        title={"Competitor Products"}
                        columns={HEADROW}
                        rows={rows}
                        actions={TABLE_TOOLBAR_ACTIONS}
                        updateSelected={() => {}}
                        selectionDisabled
                        loading={initializing || loading}
                    />
                </div>
            </div>
        </section>
    );
};

export default CompetitorProducts;
