import { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/auth-context";
import EditIcon from "@material-ui/icons/Edit";
import { queryCategories } from "../services/firestore/Category";
import { querySubCategories } from "../services/firestore/SubCategory";
import { querySubSubCategories } from "../services/firestore/Sub_SubCategory";
import PATHS from "../constants/paths";
import { exportExcel } from "../services/excel";
import { queryCompetitorBrands } from "../services/firestore/Competitor_Brands";
import { queryCompetitorProducts } from "../services/firestore/Competitor_Products";

const FILTERS = {
    search: {
        label: "Search Name Or Code",
        component: "text",
        initialValue: "",
    },
    brand: {
        label: "Brand",
        options: [],
        initialValue: null,
    },
    category: {
        label: "Category",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    subcategory: {
        label: "SubCategory",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    subsubcategory: {
        label: "Sub SubCategory",
        options: [],
        initialValue: null,
    },
    status: {
        label: "Status",
        options: [
            { label: "Active", value: "1" },
            { label: "Inactive", value: "0" },
        ],
        initialValue: null,
    },
};

const HEADROW = [
    { id: "id", label: "No.", hidden: true, excelHidden: true, style: { textAlign: "center" } },
    { id: "en_name", label: "Name (English)", style: { textAlign: "left" } },
    { id: "ar_name", label: "Name (Arabic)", style: { textAlign: "right", direction: "rtl" } },
    { id: "status", label: "Status", style: { textAlign: "left" } },
    { id: "action", label: "", excelHidden: true },
];

const EXCEL_COLUMNS = [
    { key: "brand", header: "Brand", type: "normal", width: "auto" },
    { key: "en_name", header: "Name (English)", type: "normal", width: "auto" },
    { key: "ar_name", header: "Name (Arabic)", type: "normal", width: "auto" },
    { key: "category", header: "Category", type: "normal", width: "auto" },
    { key: "sub_category", header: "SubCategory", type: "normal", width: "auto" },
    { key: "sub_sub_category", header: "Sub SubCategory", type: "normal", width: "auto" },
    { key: "model_number", header: "Model Number", type: "normal", width: "auto" },
    { key: "product_description", header: "Description", type: "normal", width: 50 },
    { key: "status", header: "Status", type: "normal", width: "auto" },
];

const initFilterValues = () => {
    let values = {};
    for (const key in FILTERS) {
        if (Object.hasOwnProperty.call(FILTERS, key)) {
            const filter = FILTERS[key];
            values[key] = filter.initialValue;
        }
    }
    return values;
};

const useCompetitorProducts = ({ classes }) => {
    const { companyData, userData } = useAuth();
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const { search: queryString } = useLocation();

    const [categoriesList, setCategoriesList] = useState([]);
    const [subcategoriesList, setSubcategoriesList] = useState([]);
    const [subsubcategoriesList, setSubsubcategoriesList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);

    const [filterValues, setFilterValues] = useState(initFilterValues());
    const [rows, setRows] = useState([]);

    const generateExcel = async () => {
        try {
            exportExcel({
                filename: "Competitor Products",
                columns: EXCEL_COLUMNS,
                rows: rows,
                worksheetTitle: "Competitor Products",
                user: userData,
                company: companyData,
            });
        } catch (error) {
            console.log(error);
        }
    };

    const TABLE_TOOLBAR_ACTIONS = [
        {
            title: "Add New Brand",
            action: () => history.push(PATHS.ADD_COMPETITOR_PRODUCT),
            icon: "add",
            enabled: () => true,
        },
        {
            title: "Excel",
            action: generateExcel,
            icon: "excel",
            enabled: () => true,
        },
    ];

    const generateActionButton = useCallback(
        (row) => {
            return (
                <Link
                    to={{
                        pathname: `${PATHS.UPDATE_COMPETITOR_PRODUCT}/${row.product_id}`,
                    }}
                    className={classes.editLink}
                >
                    <EditIcon />
                </Link>
            );
        },
        [classes.editLink]
    );

    const loadOptions = useCallback(async () => {
        try {
            setInitializing(true);
            const { company_id } = companyData;
            let [categories, subcategories, subsubcategories, brands] = await Promise.all([
                queryCategories([]),
                querySubCategories([]),
                querySubSubCategories([]),
                queryCompetitorBrands([{ key: "supplier_id", operator: "==", value: company_id }]),
            ]);

            //docs to lists
            brands = brands.map((doc) => ({
                label: doc.data().en_name,
                value: doc.id,
                data: doc.data(),
            }));
            categories = categories.map((doc) => ({
                label: doc.data().En_name,
                value: doc.id,
                data: doc.data(),
            }));
            subcategories = subcategories.map((c) => ({
                label: c.data().En_name,
                value: c.id,
                data: c.data(),
            }));
            subsubcategories = subsubcategories.map((c) => ({
                label: c.data().En_name,
                value: c.id,
                data: c.data(),
            }));

            setCategoriesList(categories);
            setSubcategoriesList(subcategories);
            setSubsubcategoriesList(subsubcategories);
            setBrandsList(brands);
            setInitializing(false);
            return { brands, categories };
        } catch (error) {
            setInitializing(false);
            throw error;
        }
    }, [companyData]);

    const handleCategoryChange = useCallback(
        async (option, context) => {
            try {
                if (!option) {
                    context.dispatchFilters({ type: "CLEAR_FILTER", payload: { id: "subcategory" } });
                    context.dispatchFilters({ type: "CLEAR_FILTER", payload: { id: "subsubcategory" } });
                    return;
                }

                let subcategories = subcategoriesList.filter((sc) => sc.data.category_id === option.value);
                // let subcategories = await querySubCategories([{ key: "category_id", operator: "==", value: option.value }]);

                // subcategories = subcategories.map((doc) => ({
                //     value: doc.id,
                //     label: doc.data().En_name,
                //     data: doc.data(),
                // }));

                context.dispatchFilters({
                    type: "SET_FILTER_OPTIONS",
                    payload: { id: "subcategory", options: subcategories },
                });

                return subcategories;
            } catch (error) {
                throw error;
            }
        },
        [subcategoriesList]
    );

    const handleSubCategoryChange = useCallback(
        async (option, context) => {
            try {
                if (!option) {
                    context.dispatchFilters({ type: "CLEAR_FILTER", payload: { id: "subsubcategory" } });
                    return;
                }

                let subsubcategories = subsubcategoriesList.filter((ssc) => ssc.data.sub_category_id === option.value);
                // let subsubcategories = await querySubSubCategories([
                //     { key: "sub_category_id", operator: "==", value: option.value },
                // ]);

                // subsubcategories = subsubcategories.map((doc) => ({
                //     value: doc.id,
                //     label: doc.data().En_name,
                //     data: doc.data(),
                // }));

                context.dispatchFilters({
                    type: "SET_FILTER_OPTIONS",
                    payload: { id: "subsubcategory", options: subsubcategories },
                });

                return subsubcategories;
            } catch (error) {
                throw error;
            }
        },
        [subsubcategoriesList]
    );

    // after the filters component is mounted, load the data
    const initFilters = useCallback(
        async (context) => {
            try {
                const params = new URLSearchParams(queryString);

                FILTERS.brand.options = brandsList;
                FILTERS.category.options = categoriesList;

                FILTERS.category.onChange = handleCategoryChange;
                FILTERS.subcategory.onChange = handleSubCategoryChange;

                //check if there is any search params in URL
                if (params.get("search")) FILTERS.search.initialValue = params.get("search");
                if (params.get("brand")) {
                    const id = params.get("brand");
                    FILTERS.brand.initialValue = brandsList.find((b) => b.value === id);
                }
                if (params.get("category")) {
                    const id = params.get("category");
                    FILTERS.category.initialValue = categoriesList.find((c) => c.value === id);
                }
                if (params.get("status")) {
                    const id = params.get("status");
                    FILTERS.status.initialValue = FILTERS.status.options.find((s) => s.value === id);
                }

                context.setFilters(FILTERS);

                //load outlet branch options
                if (FILTERS.category.initialValue !== null) {
                    const options = await handleCategoryChange(FILTERS.category.initialValue, context);
                    FILTERS.subcategory.options = options;
                    if (params.get("sub_category")) {
                        const id = params.get("sub_category");
                        FILTERS.subcategory.initialValue = options.find((sc) => sc.value === id) || null;
                    }
                }
                if (FILTERS.subcategory.initialValue !== null) {
                    const options = await handleSubCategoryChange(FILTERS.subcategory.initialValue, context);
                    FILTERS.subsubcategory.options = options;
                    if (params.get("sub_sub_category")) {
                        const id = params.get("sub_sub_category");
                        FILTERS.subsubcategory.initialValue = options.find((ssc) => ssc.value === id) || null;
                    }
                }

                const initialValues = {
                    search: FILTERS.search.initialValue,
                    brand: FILTERS.brand.initialValue,
                    category: FILTERS.category.initialValue,
                    subcategory: FILTERS.subcategory.initialValue,
                    subsubcategory: FILTERS.subsubcategory.initialValue,
                    status: FILTERS.status.initialValue,
                };
                // load products with default filters
                context.submit(initialValues);
            } catch (error) {
                console.log(error);
            }
        },
        [brandsList, categoriesList, handleCategoryChange, handleSubCategoryChange, queryString]
    );

    const setQueryParams = useCallback(
        (filters) => {
            const { search, brand, category, subcategory, subsubcategory, status } = filters;
            const urlParams = new URLSearchParams({});

            if (search) urlParams.append("search", search);
            if (brand) urlParams.append("brand", brand.value);
            if (category) urlParams.append("category", category.value);
            if (subcategory) urlParams.append("sub_category", subcategory.value);
            if (subsubcategory) urlParams.append("sub_sub_category", subsubcategory.value);
            if (status) urlParams.append("status", status.value);

            history.replace({ search: urlParams.toString() });
        },
        [history]
    );

    const search = useCallback(
        async ({ search, brand, category, subcategory, subsubcategory, status }) => {
            try {
                setLoading(true);
                setQueryParams({ search, brand, category, subcategory, subsubcategory, status });
                const { company_id } = companyData;
                let query = [{ key: "supplier_id", operator: "==", value: company_id }];

                if (brand) query.push({ key: "brand_id", operator: "==", value: brand.value });
                if (category)
                    query.push({ key: "product_category.category_id", operator: "==", value: category.value });
                if (subcategory)
                    query.push({ key: "product_category.sub_category_id", operator: "==", value: subcategory.value });
                if (subsubcategory)
                    query.push({
                        key: "product_category.sub_sub_category_id",
                        operator: "==",
                        value: subsubcategory.value,
                    });
                if (status)
                    query.push({
                        key: "status",
                        operator: "==",
                        value: status.value,
                    });

                let products = (await queryCompetitorProducts(query)).map((doc) => doc.data());

                if (search) {
                    products = products.filter((product) => {
                        const en_name = product.En_name.toLowerCase();
                        const ar_name = product.Ar_name.toLowerCase();
                        return en_name.includes(search.toLowerCase()) || ar_name.includes(search.toLowerCase());
                    });
                }

                //init rows
                const rows = products.map((product) => {
                    return {
                        id: product.product_id,
                        en_name: product.En_name,
                        ar_name: product.Ar_name,
                        status: FILTERS.status.options.find((s) => s.value === product.status)?.label ?? "-",
                        action: generateActionButton(product),
                        cellProps: {
                            ar_name: { className: classes.arabicText },
                            action: { className: classes.action },
                        },

                        //for excel
                        brand: brandsList.find((b) => b.value === product.brand_id)?.label ?? "",
                        category:
                            categoriesList.find((c) => c.value === product.product_category.category_id)?.label ?? "",
                        sub_category:
                            subcategoriesList.find((c) => c.value === product.product_category.sub_category_id)
                                ?.label ?? "",
                        sub_sub_category:
                            subsubcategoriesList.find((c) => c.value === product.product_category.sub_sub_category_id)
                                ?.label ?? "",

                        model_number: product.model_number ?? "",
                        product_description: product.product_description,
                    };
                });

                setRows(rows);
                setFilterValues((prev) => ({
                    ...prev,
                    search: search,
                }));
                setLoading(false);
            } catch (error) {
                setLoading(false);
                throw error;
            }
        },
        [
            brandsList,
            categoriesList,
            classes.action,
            classes.arabicText,
            companyData,
            generateActionButton,
            setQueryParams,
            subcategoriesList,
            subsubcategoriesList,
        ]
    );

    useEffect(() => {
        loadOptions();
    }, [loadOptions]);

    return {
        initializing,
        loading,
        initFilters,
        HEADROW,
        TABLE_TOOLBAR_ACTIONS,
        rows,
        search,
        filterValues,
    };
};

export default useCompetitorProducts;
