import { alpha, makeStyles } from "@material-ui/core";
import React from "react";
import { CircularLoader } from "..";
import COLORS from "../../../constants/colors";

const styles = makeStyles({
    loadingWrap: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: alpha(COLORS.WHITE, 0.8),
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        zIndex: 1000,
    },

    loading: {
        width: "50px",
        height: "50px",
    },

    text: {
        textAlign: "center",
        fontSize: "1.2rem",
        fontStyle: "italic",
        fontWeight: 600,
    },
});
const LoadingOverlay = (props) => {
    const classes = styles();
    const { text = "Loading" } = props;
    return (
        <div className={classes.loadingWrap}>
            <CircularLoader className={classes.loading} />
            <p className={classes.text}>{text}</p>
        </div>
    );
};

export default LoadingOverlay;
