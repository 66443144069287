import React, { Component } from "react";
//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import useStyles from "./styles";
import EnhancedTable from "../../../components-lagacy/Table/EnhancedTable";
import { Box, Grid } from "@material-ui/core";
import GridContainer from "../../../components-lagacy/Grid/GridContainer.jsx";
import GridItem from "../../../components-lagacy/Grid/GridItem.jsx";

//utils
import { COLORS, PROJECT_TYPES } from "../../../constants/constants-lagacy";
import { firestore as db } from "../../../services/firebase";
import { getVAT } from "../../../helpers/firestore-utils";
import { formatDate } from "../../../helpers/date-utils";

let generateTableToolbarButton = (title, action, icon, enabled, iconStyle = {}, style = {}) => {
    return { title, action, icon, enabled, iconStyle, style };
};

class Content extends Component {
    state = {
        user: null,
        rows: [],
        tableToolbarButtons: [],
        loading: false,
    };

    loadRowsData = async () => {
        this.setState({ loading: true });
        let rows = [];
        try {
            //get projects' data
            const supplierId = this.props.supplierId;

            let projects = (
                await db
                    .collection("Project")
                    .where("supplier_id", "==", supplierId)
                    .where("project_type", "in", [PROJECT_TYPES.OPEN_MP_INSOURCE, PROJECT_TYPES.MP_OUTSOURCE])
                    .get()
            ).docs;

            //process data for each project
            for (const project of projects) {
                let mpName = "eMaham";
                if (project.data().mp_id) {
                    mpName = (await db.collection("MP").doc(project.data().mp_id).get()).data().En_short_name;
                }

                const mp_confirmation = project.data().mp_confirmation ? "Confirmed" : "Not Confirmed";
                const supplier_confirmation = project.data().supplier_confirmation ? "Confirmed" : "Not Confirmed";

                rows.push({
                    project: project,
                    id: project.id,
                    project_name: project.data().projName,
                    mp_name: mpName,
                    project_date: formatDate(project.data().project_date.toDate()),
                    project_period: `${formatDate(project.data().date_from.toDate())} - ${formatDate(
                        project.data().date_to.toDate()
                    )}`,

                    supplier_confirmation: supplier_confirmation,
                    mp_confirmation: mp_confirmation,
                });
            }

            this.setState((prevState) => ({
                rows: [...rows],
            }));
        } catch (error) {
            console.log(error);
        }

        this.setState({ loading: false });
    };

    handleProjectDetailsClick = (ids) => {
        if (ids.length !== 1) {
            return false;
        }

        const row = this.state.rows.find((row) => row.id === ids[0]);
        if (!row) return false;

        this.props.gotToProjectDetails(row.project.data());
    };

    componentDidMount() {
        this.loadRowsData();
        this.setState({
            tableToolbarButtons: [
                generateTableToolbarButton(
                    "Manage Project",
                    this.handleProjectDetailsClick,
                    "",
                    (ids) => ids.length === 1
                ),
            ],
        });
    }

    render() {
        const classes = this.props.classes;
        const headRow = [
            { id: "id", numeric: false, disablePadding: false, label: "No." },
            { id: "project_name", numeric: false, disablePadding: false, label: "Project Name" },
            { id: "mp_name", numeric: false, disablePadding: false, label: "Service Provider" },
            { id: "project_date", numeric: false, disablePadding: false, label: "Date" },
            { id: "project_period", numeric: false, disablePadding: false, label: "Period" },
            { id: "supplier_confirmation", numeric: false, disablePadding: false, label: "Confirmation" },
            { id: "mp_confirmation", numeric: false, disablePadding: false, label: "Provider Confirmation" },
        ];

        const actions = this.state.tableToolbarButtons;

        return (
            <div className={`${classes.main} ${classes.textCenter}`} style={{ width: "100%", display: "contents" }}>
                <div
                    style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}
                    className={classes.container}
                >
                    <Box
                        style={{
                            paddingTop: 15,
                            borderRadius: "5px",
                            marginTop: "20px",
                            marginBottom: "75px",
                            backgroundColor: "#FFFFFF",
                            color: "#EEEEEE",
                            width: "90%",
                            boxShadow: "#96a1a9 0 4px 5px 0px",
                        }}
                        pt={4}
                    >
                        <GridContainer justifyContent="center" alignItems="center">
                            <div className={classes.title} style={{ width: "3%" }}>
                                <div
                                    style={{
                                        fontWeight: "700",
                                        // backgroundColor: "#91041C",
                                        color: "#EEEEEE",
                                        height: "30px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "2px solid #575757",
                                        // borderRightColor: "#91041C",
                                        // borderBottomColor: "#91041C",
                                        backgroundColor: COLORS.primary,
                                        borderRightColor: COLORS.primary,
                                        borderBottomColor: COLORS.primary,
                                    }}
                                ></div>
                            </div>
                            <div className={classes.title} style={{ width: "90%", marginLeft: "2px" }}>
                                <h4
                                    style={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        backgroundColor: COLORS.background,
                                        color: COLORS.primary,
                                        // color: "#91041C",
                                        height: "30px",
                                        paddingLeft: "20px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "1px solid #000000",
                                    }}
                                >
                                    Outsource Projects
                                </h4>
                            </div>

                            <Grid container justifyContent="center" style={{ width: "93%", margin: "20px 0" }}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <EnhancedTable
                                        title={"Projects"}
                                        columns={headRow}
                                        rows={this.state.rows}
                                        actions={actions}
                                        updateSelected={() => {}}
                                        loading={this.state.loading}
                                    />
                                </GridItem>
                            </Grid>
                        </GridContainer>
                    </Box>
                </div>
            </div>
        );
    }
}

Content.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(useStyles)(Content);
