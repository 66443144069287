import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const tabDimentions = {
    width: "100px",
    height: "35px",
};

const styles = makeStyles({
    tabsContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },

    tabs: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        position: "relative",
        // padding: "0.75rem",
        // overflowX: "auto",

        "& *": {
            zIndex: 2,
        },
        "& input": {
            display: "none",
        },

        // "& input[type=radio]:checked + label > $notification": {
        //     backgroundColor: COLORS.FRENSH_BLUE,
        //     color: COLORS.WHITE,
        // },
    },

    tab: {
        width: "100%",
        zIndex: 10,
        position: "relative",
        overflow: "hidden",
        transition: "0.5s ease-in-out",
        cursor: "pointer",
        padding: "8px",
        margin: "5px 0",
        backgroundColor: COLORS.WHITE,
        boxShadow: "0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15)",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "@media (min-width: 403px)": {
            width: "auto",
            margin: "0 5px",

            "&:first-child, &:last-child": {
                margin: 0,
            },
        },

        "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: "-50px",
            borderRight: "50px solid transparent",
            borderBottom: `59px solid ${COLORS.FRENSH_BLUE}`,
            transform: "translateX(-100%)",
            transition: "0.5s ease-in-out",
            zIndex: -1,
        },

        "&:after": {
            content: "''",
            position: "absolute",
            top: 0,
            left: "-50px",
            right: 0,
            bottom: 0,
            borderLeft: "50px solid transparent",
            borderTop: `59px solid ${COLORS.FRENSH_BLUE}`,
            transform: "translateX(100%)",
            transition: "0.5s ease-in-out",
            zIndex: -1,
        },

        "&:hover": {
            color: COLORS.WHITE,

            "&:before": { transform: "translateX(-49%)" },
            "&:after": { transform: "translateX(49%)" },
            "& $tabLabel": { color: COLORS.WHITE },
        },
    },

    selectedTab: {
        color: COLORS.WHITE,
        backgroundColor: COLORS.ACCENT,
        "& label": { color: COLORS.WHITE },
        "&:hover:before": { transform: "translateX(-100%)" },
        "&:hover:after": { transform: "translateX(100%)" },
    },

    tabIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        marginRight: 2,
    },

    tabLabel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: COLORS.BLACK,
        fontSize: "0.85rem",
        // fontWeight: "bold",
        borderRadius: "99px",
        cursor: "pointer",
        transition: "color 0.15s ease-in",
    },

    // notification: {
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     width: "2rem",
    //     height: "2rem",
    //     marginLeft: "0.75rem",
    //     borderRadius: "50%",
    //     backgroundColor: COLORS.LIGHT_EMINENCE,
    //     transition: "0.15s ease-in",
    // },

    // glider: {
    //     ...tabDimentions,
    //     position: "absolute",
    //     left: 0,
    //     display: "flex",
    //     backgroundColor: COLORS.FRENSH_BLUE,
    //     zindex: 1,
    //     borderRadius: "99px",
    //     transition: "0.25s ease-out",
    // },
});

export default styles;
