import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const exportExcel = async ({
    columns,
    rows,
    user,
    company,
    worksheetTitle = "New Worksheet",
    filename = "excel",
}) => {
    const workbook = new ExcelJS.Workbook();

    //metadata
    if (user) {
        workbook.creator = `${user.first_name} ${user.surname}`;
        workbook.lastModifiedBy = `${user.first_name} ${user.surname}`;
    }
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    const worksheet = workbook.addWorksheet(worksheetTitle);

    //columns definition starting from top row
    insertColumns(worksheet, columns);

    //insert metadata into rows above the columns row
    insertMetadata(worksheet, user, company);
    //insert table title
    insertTableTitle(worksheet, worksheetTitle);
    //insert rows
    await insertRows(workbook, worksheet, rows, columns);

    //calculate and set the maximum width based on content for each column except the onces whose width are set
    autoWidth(worksheet, columns, 10, 30);
    //apply styles
    applyStyles(worksheet);

    const data = await workbook.xlsx.writeBuffer();
    const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    saveAs(blob, `${filename}.xlsx`);
};

const insertColumns = (worksheet, columns) => {
    let excelColumns = [];
    for (const col of columns) {
        if (col.excelHidden) continue;
        excelColumns.push({ header: col.header, key: col.key });
    }
    worksheet.columns = excelColumns;

    //resize image columns
    columns.forEach((col) => {
        if (col.type !== "image") return;
        worksheet.getColumn(col.key).width = 200;
    });
};

const insertMetadata = (worksheet, user, company) => {
    // Insert an 3 empty rows at beginning
    worksheet.spliceRows(1, 0, [], [], []);

    // insert metadata
    worksheet.getRow(1).values = ["Date", new Date(), "Created By", `${user.first_name} ${user.surname}`];
    worksheet.getRow(2).values = ["Company", company.company_name];
};

const insertTableTitle = (worksheet, worksheetTitle) => {
    // merge by start row, start column, end row, end column
    worksheet.mergeCells(3, 1, 3, worksheet.columnCount);
    worksheet.getCell("C3").value = worksheetTitle;
};

const insertRows = async (workbook, worksheet, rows, columns) => {
    for (const row of rows) {
        let data = {};
        for (const col of columns) {
            if (col.type === "image") {
                let image = "";
                worksheet.getRow(worksheet.rowCount).height = 125;
                if (row[col.key]) {
                    const r = await fetch(row[col.key]);
                    image = workbook.addImage({
                        buffer: r.arrayBuffer(),
                        extension: "jpeg",
                    });

                    worksheet.addImage(image, {
                        tl: { col: worksheet.columnCount - 4 + 0.5, row: worksheet.rowCount + 0.5 },
                        ext: { width: 200, height: 200 },
                    });
                } else {
                    data[col.key] = "";
                }
            } else {
                data[col.key] = row[col.key];
            }
        }
        const addedRow = worksheet.addRow(data);
        addedRow.alignment = { wrapText: true, vertical: "middle" };
    }
};

const applyStyles = (worksheet) => {
    //metadata styles
    let metadataRows = worksheet.getRows(1, 2);
    metadataRows[0].alignment = { vertical: "middle", horizontal: "center" };
    metadataRows[0].getCell(1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffe8e3" } };
    metadataRows[0].getCell(3).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffe8e3" } };
    metadataRows[0].getCell(2).font = { name: "Helvetica Neue", size: 12, bold: true };
    metadataRows[0].getCell(4).font = { name: "Helvetica Neue", size: 12, bold: true };

    metadataRows[1].alignment = { vertical: "middle", horizontal: "center" };
    metadataRows[1].getCell(1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffe8e3" } };
    metadataRows[1].getCell(2).font = { name: "Helvetica Neue", size: 12, bold: true };

    //table title styles
    worksheet.getRow(3).height = 20;
    const titleCell = worksheet.getCell("A3");
    titleCell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "e7e7e7" } };
    titleCell.font = { name: "Helvetica Neue", size: 15, bold: true };
    titleCell.alignment = { vertical: "middle", horizontal: "center" };

    //columns styles
    worksheet.getRow(4).height = 20;
    worksheet.getRow(4).eachCell({ includeEmpty: false }, (cell, colNumber) => {
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "f5f9ff" },
        };

        cell.font = {
            name: "Helvetica Neue",
            color: { argb: "0070c0" },
            size: 13,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    //rows styles
};

const autoWidth = (worksheet, columns, minimalWidth = 10, maximumWidth = null) => {
    worksheet.columns.forEach((column) => {
        let col = columns.find((col) => col.key === column.key);
        if (col.width !== "auto") {
            column.width = col.width;
            return;
        }
        let maxColumnLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
            if (maximumWidth && maximumWidth < maxColumnLength) maxColumnLength = maximumWidth;
            else
                maxColumnLength = Math.max(
                    maxColumnLength,
                    minimalWidth,
                    cell.value ? cell.value.toString().length : 0
                );
        });
        column.width = maxColumnLength + 2;
    });
};

// const autoHeight = (worksheet) => {
//     const lineHeight = 12 // height per line is roughly 12
//     worksheet.eachRow((row) => {
//         let maxLine = 1
//         row.eachCell((cell) => {
//             maxLine = Math.max(cell.value.split('\n').length - 1, maxLine)
//         })
//         row.height = lineHeight * maxLine
//     })
// }

const user = { first_name: "Luqman", surname: "Alattas" };
const company = { company_name: "Derwaza" };

export const generateCompetitorSelloutExcel = async () => {
    const workbook = new ExcelJS.Workbook();

    //metadata
    if (user) {
        workbook.creator = `${user.first_name} ${user.surname}`;
        workbook.lastModifiedBy = `${user.first_name} ${user.surname}`;
    }
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();

    const worksheet = workbook.addWorksheet("Sheet 1");

    // insert metadata
    worksheet.getRow(1).values = ["Date", new Date(), "Created By", `${user.first_name} ${user.surname}`];
    worksheet.getRow(2).values = ["Company", company.company_name];
    //insert table title
    // merge by start row, start column, end row, end column
    worksheet.mergeCells(4, 1, 4, worksheet.columnCount);
    worksheet.getCell("C4").value = "Competitors Sales";
    //insert first table
    let category = "TV";
    let columns = ["LG", "Samsung", "TCL", "Sony", "Others", "Total"];
    let row = [2, 6, 8, 7, 1, 20];
    // merge by start row, start column, end row, end column
    worksheet.mergeCells(5, 1, 5, columns.length);
    worksheet.getCell("C5").value = category;
    worksheet.addRow(columns);
    worksheet.addRow(row);

    //insert first table
    category = "Fridge";
    columns = ["LG", "Hitachi", "Haier", "Others", "Total"];
    row = [2, 3, 2, 3, 10];
    // merge by start row, start column, end row, end column
    worksheet.mergeCells(9, 1, 9, columns.length);
    worksheet.getCell("C9").value = category;
    worksheet.addRow(columns);
    worksheet.addRow(row);

    worksheet.columns.forEach((column) => {
        column.width = 15;
    });

    //metadata styles
    let metadataRows = worksheet.getRows(1, 2);
    metadataRows[0].alignment = { vertical: "middle", horizontal: "center" };
    metadataRows[0].getCell(1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffe8e3" } };
    metadataRows[0].getCell(3).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffe8e3" } };
    metadataRows[0].getCell(2).font = { name: "Helvetica Neue", size: 12, bold: true };
    metadataRows[0].getCell(4).font = { name: "Helvetica Neue", size: 12, bold: true };

    metadataRows[1].alignment = { vertical: "middle", horizontal: "center" };
    metadataRows[1].getCell(1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "ffe8e3" } };
    metadataRows[1].getCell(2).font = { name: "Helvetica Neue", size: 12, bold: true };

    //table title styles
    worksheet.getRow(4).height = 20;
    const titleCell = worksheet.getCell("A4");
    titleCell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "e7e7e7" } };
    titleCell.font = { name: "Helvetica Neue", size: 15, bold: true };
    titleCell.alignment = { vertical: "middle", horizontal: "center" };

    //columns styles
    worksheet.getRow(5).height = 20;
    worksheet.getRow(5).eachCell({ includeEmpty: false }, (cell, colNumber) => {
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "f5f9ff" },
        };

        cell.font = {
            name: "Helvetica Neue",
            color: { argb: "0070c0" },
            size: 13,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    worksheet.getRow(6).eachCell({ includeEmpty: false }, (cell, colNumber) => {
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "faf01f" },
        };

        cell.font = {
            name: "Helvetica Neue",
            color: { argb: "000000" },
            size: 13,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    //columns styles
    worksheet.getRow(9).height = 20;
    worksheet.getRow(9).eachCell({ includeEmpty: false }, (cell, colNumber) => {
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "f5f9ff" },
        };

        cell.font = {
            name: "Helvetica Neue",
            color: { argb: "0070c0" },
            size: 13,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    worksheet.getRow(10).eachCell({ includeEmpty: false }, (cell, colNumber) => {
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "faf01f" },
        };

        cell.font = {
            name: "Helvetica Neue",
            color: { argb: "000000" },
            size: 13,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    const data = await workbook.xlsx.writeBuffer();
    const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    saveAs(blob, `competitor-sales.xlsx`);
};
