import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/auth-context";
import { Button } from "../core-ui/custom";
import { queryBrands } from "../services/firestore/Brands";
import EditIcon from "@material-ui/icons/Edit";

const FILTERS = {
    search: {
        label: "Search Name",
        component: "text",
        initialValue: "",
    },
};

const HEADROW = [
    { id: "id", label: "No.", hidden: true },
    { id: "en_name", label: "Name (English)" },
    { id: "ar_name", label: "Name (Arabic)" },
    { id: "action", label: "Actions", style: { width: 20, textAlign: "right" } },
];

const useBrands = ({ classes }) => {
    const { companyData } = useAuth();
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const { search: queryString } = useLocation();

    const [filterValues, setFilterValues] = useState({});
    const [rows, setRows] = useState([]);
    const [showAddBrandDialog, setShowAddBrandDialog] = useState(false);
    const [showUpdateBrandDialog, setShowUpdateBrandDialog] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(null);

    const TABLE_TOOLBAR_ACTIONS = [
        {
            title: "Add New Brand",
            action: () => setShowAddBrandDialog(true),
            icon: "add",
            enabled: () => true,
        },
    ];

    const init = useCallback(async () => {
        try {
            setInitializing(true);

            setInitializing(false);
        } catch (error) {
            setInitializing(false);
            throw error;
        }
    }, []);

    useEffect(() => {
        init();
    }, [init]);

    const generateActionButton = useCallback(
        (brand) => {
            return (
                <Button
                    className={classes.editButton}
                    onClick={() => {
                        setShowUpdateBrandDialog(true);
                        setSelectedBrand(brand);
                    }}
                >
                    <EditIcon />
                </Button>
            );
        },
        [classes.editButton]
    );

    const search = useCallback(
        async ({ search }) => {
            try {
                setLoading(true);
                const { company_id } = companyData;
                let query = [{ key: "supplier_id", operator: "==", value: company_id }];

                const urlParams = new URLSearchParams({});

                let brands = (await queryBrands(query)).map((doc) => doc.data());

                if (search) {
                    brands = brands.filter((brand) => {
                        const en_name = brand.en_name.toLowerCase();
                        const ar_name = brand.ar_name.toLowerCase();

                        return en_name.includes(search.toLowerCase()) || ar_name.includes(search.toLowerCase());
                    });
                    urlParams.append("search", search);
                }

                //init rows
                const rows = brands.map((brand) => {
                    return {
                        id: brand.id,
                        en_name: brand.en_name,
                        ar_name: brand.ar_name,
                        action: generateActionButton(brand),
                    };
                });

                setRows(rows);
                setFilterValues((prev) => ({
                    ...prev,
                    search: search,
                }));
                history.replace({ search: urlParams.toString() });
                setLoading(false);
            } catch (error) {
                setLoading(false);
                throw error;
            }
        },
        [companyData, generateActionButton, history]
    );

    // after the filters component is mounted, load the data
    const initFilters = useCallback(
        (context) => {
            try {
                const params = new URLSearchParams(queryString);

                //check if there is any search params in URL
                if (params.get("search")) FILTERS.search.initialValue = params.get("search");

                context.setFilters(FILTERS);
                // load tasks and merchandisers with default filters
                search({
                    search: FILTERS.search.initialValue,
                });
            } catch (error) {
                console.log(error);
            }
        },
        [queryString, search]
    );

    return {
        initializing,
        loading,
        initFilters,
        HEADROW,
        TABLE_TOOLBAR_ACTIONS,
        rows,
        showAddBrandDialog,
        setShowAddBrandDialog,
        showUpdateBrandDialog,
        setShowUpdateBrandDialog,
        selectedBrand,
        setSelectedBrand,
        search,
        filterValues,
    };
};

export default useBrands;
