import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CardBody from "../core-ui/material-kit-ui/cards/CardBody";
import GridItem from "../components-lagacy/Grid/GridItem";
import PropTypes from "prop-types";
import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
import withStyles from "@material-ui/core/styles/withStyles";

import Paper from "@material-ui/core/Paper";
// nodejs library that concatenates classes
import classNames from "classnames";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Redirect } from "react-router-dom";
import { COLORS } from "../constants/constants-lagacy";

import PATHS from "../constants/paths";
import { firestore as db } from "../services/firebase";
import { PLATFORM } from "../constants/global";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: "100%",
        height: 280,
        backgroundColor: theme.palette.background.paper,
        overflow: "auto",
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

function TransferList(props) {
    const classes = useStyles();
    const { handleAddProductsMethod, goToOutletsSupplierListPageMethod } = props;
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(props.left);
    const [right, setRight] = React.useState(props.right);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAddProducts = () => {
        handleAddProductsMethod(right);
    };

    const handleBranchesListPageMethod = () => {
        goToOutletsSupplierListPageMethod();
    };

    const customList = (title, items) => (
        <Card>
            <CardHeader
                style={{
                    background: COLORS.primary,
                    color: COLORS.font,
                    //"rgba(145, 4, 28,0.83)", "#91041C"
                }}
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        style={{
                            color: "#FFFFFF",
                        }}
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ "aria-label": "all items selected" }}
                    />
                }
                title={title}
                subheader={
                    <p style={{ color: "#FFFFFF", fontSize: "13px" }}>{`${numberOfChecked(items)}/${
                        items.length
                    } selected`}</p>
                }
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value.En_short_name}-label`;

                    return (
                        <ListItem
                            style={{ padding: "5px 0", margin: "0" }}
                            key={value.product_id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    style={{
                                        color: COLORS.primary,
                                    }}
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                />
                            </ListItemIcon>
                            {/* <ListItemText id={labelId} primary={`${value.code}`} /> */}
                            <ListItemText id={labelId}>
                                <div style={{ color: COLORS.accent }}>{value.code}</div>
                                {value.En_short_name}
                            </ListItemText>
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <Grid container direction="column">
            <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
                <Grid item xs={5}>
                    {left === undefined ? customList("Products", []) : customList("Products", left)}
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={5}>
                    {right === undefined ? customList("Selected Products", []) : customList("Selected Products", right)}
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
                <Grid item>
                    <Grid container direction="row" alignItems="center">
                        <Button
                            // size="lg"
                            style={{
                                float: "right",
                                textTransform: "none",
                                backgroundColor: COLORS.accent,
                                color: "#FFFFFF",
                                marginTop: 14,
                                padding: 10,
                                paddingLeft: 20,
                                paddingRight: 20,
                                marginRight: 20,
                                borderColor: "#91041C",
                                border: "2",
                                fontWeight: "400",
                            }}
                            type="submit"
                            onClick={handleBranchesListPageMethod}
                        >
                            {" "}
                            Cancel{" "}
                        </Button>

                        <Button
                            style={{
                                float: "right",
                                textTransform: "none",
                                backgroundColor: "#4BB543",
                                color: "#FFFFFF",
                                marginTop: 14,
                                padding: 10,
                                paddingLeft: 20,
                                paddingRight: 20,
                                borderColor: "#4BB543",
                                border: "2",
                                fontWeight: "400",
                            }}
                            type="submit"
                            onClick={handleAddProducts}
                        >
                            {" "}
                            Submit{" "}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

//==================================================================================
//==========================ProductsOutlet======================
//==================================================================================
class ProductsOutlet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            city: "",
            branch_id: "",
            branch_name: "",
            supplier_id: "",

            right: [],
            left: [],
            DeleteProductsOld: [],
            checkRight: false,
            checkLeft: false,
            //allProducts:[],
            // outletProducts:[],

            //right: [{En_short_name:'L', product_id:'1'}],
            // left: [{En_short_name:'R', product_id:'2'},{En_short_name:'L', product_id:'1'}],
            pathBack: "",
        };
    }

    componentDidMount() {
        let datat2 = [];
        var suppliersRef = db.collection("Supplier_Outlet_Product").doc(this.props.id);
        suppliersRef
            .get()
            .then((snapshot) => {
                datat2 = snapshot.data();
                this.setState(() => ({
                    supplier_id: datat2.company_id,
                    branch_name: datat2.En_name,
                    allProducts: [],
                    outletProducts: [],
                }));
            })
            .then(() => {
                let datat3 = [];
                var productsRef = db.collection("Product").where("supplier_id", "==", this.props.id);
                productsRef.get().then((snapshot) => {
                    snapshot.forEach((doc) => {
                        if (doc.data().status === "1") {
                            let datat = doc.data();
                            datat3.push(datat);
                        }
                    });
                    this.setState(() => ({ allProducts: datat3 }));
                    let datat4 = [];
                    var outletProductsRef = db
                        .collection("Supplier_Outlet_Product")
                        .doc(this.props.id)
                        .collection("Outlet_Product")
                        .doc(this.props.outletID)
                        .collection("Product");
                    outletProductsRef
                        .get()
                        .then((snapshot) => {
                            snapshot.forEach((doc) => {
                                let datat = doc.data();
                                datat4.push(datat);
                            });
                            this.setState(() => ({ outletProducts: datat4 }));
                            var right = [];
                            var DeleteProductsOld = [];
                            for (let i = 0; i < datat4.length; i++) {
                                let index = datat3.findIndex((post) => {
                                    return post.product_id === datat4[i].product_id;
                                });
                                if (index !== -1) {
                                    const code = datat3[index].product_number;
                                    let name = datat3[index].En_name;
                                    let id = datat4[i].product_id;
                                    right.push({ En_short_name: name, product_id: id, code: code });
                                } else {
                                    let name = datat4[i].En_name;
                                    let id = datat4[i].product_id;
                                    DeleteProductsOld.push({ En_short_name: name, product_id: id, code: "-" });
                                }
                            }

                            this.setState({
                                right: [...right],
                                DeleteProductsOld: [...DeleteProductsOld],
                                checkRight: true,
                            });
                            var left = [];

                            for (let i = 0; i < datat3.length; i++) {
                                const code = datat3[i].product_number;
                                let name = datat3[i].En_name;
                                let id = datat3[i].product_id;
                                left.push({ En_short_name: name, product_id: id, code: code });
                            }
                            this.setState(() => ({ left: [...left], checkLeft: true }));
                        })
                        .catch((err) => {
                            console.log("Error getting documents", err);
                        });
                });
            }); // all (allProducts, outletProducts, right, left)
    }

    handleChange = (event, id) => {
        this.setState({ [id]: event.target.value });
    };
    handleChangeFormNumber = (num) => {
        this.setState({ form_number: num });
    };
    handleChangeExpiryDate = (date) => {
        this.setState({ expiry_date: date });
    };

    handleAddProducts = (productsSelected) => {
        if (!(this.state.outletProducts === undefined)) {
            var AddProducts = this.not(productsSelected, this.state.right);
            var DeleteProducts = this.not(this.state.right, productsSelected);
            //delete products that deleted from product collection
            for (let j = 0; j < this.state.DeleteProductsOld.length; j++) {
                DeleteProducts.push(this.state.DeleteProductsOld[j]);
            }
            let supplier_id = this.props.id;

            //add outlet info. to Outlet_Product collection
            let branchOutletProductRef = db
                .collection("Supplier_Outlet_Product")
                .doc(this.props.id)
                .collection("Outlet_Product")
                .doc(this.props.outletID)
                .collection("Product");
            for (let i = 0; i < AddProducts.length; i++) {
                branchOutletProductRef
                    .doc(AddProducts[i].product_id)
                    .set({
                        platform: PLATFORM,
                        product_id: AddProducts[i].product_id,
                        En_name: AddProducts[i].En_short_name,
                        supplier_id: supplier_id,
                        outlet_id: this.props.outletID,
                    })
                    .then(() => {
                        if (i === AddProducts.length - 1) {
                            this.setState({
                                outletProducts: [],
                                allProducts: [],
                            });
                            if (DeleteProducts.length === 0) {
                                alert("Nicely done!");
                                this.goToOutletsSupplierListPage();
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        alert("There is something wrong!");
                    });
            }

            for (let i = 0; i < DeleteProducts.length; i++) {
                //add outlet info. to Outlet_Product collection
                branchOutletProductRef
                    .doc(DeleteProducts[i].product_id)
                    .delete()
                    .catch((error) => {
                        console.error(error);
                        alert("There is something wrong!");
                    });

                if (i === DeleteProducts.length - 1) {
                    alert("Nicely done!");
                    this.goToOutletsSupplierListPage();
                }
            }

            if (AddProducts.length === 0 && DeleteProducts.length === 0) {
                this.goToOutletsSupplierListPage();
            }
        }
    };
    //==================================================================================

    not = (a, b) => {
        return a.filter((value) => b.indexOf(value) === -1);
    };

    intersection = (a, b) => {
        return a.filter((value) => b.indexOf(value) !== -1);
    };

    union = (a, b) => {
        return [...a, ...not(b, a)];
    };

    goToOutletsSupplierListPage = (e) => {
        this.setState({ pathBack: PATHS.LINKED_OUTLETS });
    };
    //==================================================================================

    render() {
        const { classes } = this.props;
        if (this.state.pathBack.length > 0) {
            return <Redirect push={true} to={{ pathname: this.state.pathBack }} />;
        } else {
            const left = [];

            for (let i = 0; i < this.state.left.length; i++) {
                const index = this.state.right.findIndex((post) => {
                    return post.product_id === this.state.left[i].product_id;
                });

                if (index === -1) {
                    left.push(this.state.left[i]);
                }
            }

            const right = this.state.right;

            if (this.state.checkLeft && this.state.checkRight) {
                return (
                    <div
                        /*style={{backgroundColor:"#262626", margin:"0px", padding:"0px", height:'100vh'}}*/ className={
                            classes.pageHeader
                        }
                    >
                        <div>
                            <div className={classNames(classes.container)}>
                                <div style={{ width: "100%" }}>
                                    <Box pt={4} pb={8}>
                                        <Grid>
                                            <Card pt={20} color="primary">
                                                <div
                                                    style={{
                                                        width: "95%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginLeft: "25px",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className={classes.title} style={{ width: "3%" }}>
                                                        <div
                                                            style={{
                                                                fontWeight: "700",
                                                                // backgroundColor: "#91041C",
                                                                color: "#EEEEEE",
                                                                height: "30px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "2px solid #575757",
                                                                // borderRightColor: "#91041C",
                                                                // borderBottomColor: "#91041C",
                                                                backgroundColor: COLORS.primary,
                                                                borderRightColor: COLORS.primary,
                                                                borderBottomColor: COLORS.primary,
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div
                                                        className={classes.title}
                                                        style={{ width: "96.7%", marginLeft: "2px" }}
                                                    >
                                                        <h4
                                                            style={{
                                                                justifyContent: "flex-start",
                                                                fontWeight: "700",
                                                                // backgroundColor: "#EEEEEE",
                                                                // color: "#91041C",
                                                                backgroundColor: COLORS.background,
                                                                color: COLORS.primary,
                                                                height: "30px",
                                                                paddingLeft: "10px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "1px solid #000000",
                                                            }}
                                                        >
                                                            {"Products Listed in "}
                                                            <span style={{ color: COLORS.accent }}>
                                                                {this.props.outletName}
                                                            </span>
                                                            {""}{" "}
                                                        </h4>
                                                    </div>
                                                </div>
                                                <CardBody align="center" justify="center">
                                                    <GridItem xs={12} sm={12} md={10}>
                                                        <TransferList
                                                            left={left}
                                                            right={right}
                                                            handleAddProductsMethod={this.handleAddProducts}
                                                            goToOutletsSupplierListPageMethod={
                                                                this.goToOutletsSupplierListPage
                                                            }
                                                        />
                                                    </GridItem>

                                                    {/* <div style={{ width: "100%" }}>
                                                            <Button
                                                                simple
                                                                onClick={() => this.goToOutletsSupplierListPage()}
                                                                size="lg"
                                                                // disabled={!this.validateThirdForm()}
                                                                style={{
                                                                    marginTop: 10,
                                                                    marginLeft: "10px",
                                                                    padding: 10,
                                                                    paddingLeft: 20,
                                                                    paddingRight: 20,
                                                                    textTransform: "none",
                                                                    backgroundColor: "transparent",
                                                                    color: "#91041C",
                                                                    fontWeight: "400",
                                                                    float: "left",
                                                                }}
                                                                type="submit"
                                                            >
                                                                <ChevronLeft /> Back{" "}
                                                            </Button>
                                                        </div> */}
                                                </CardBody>
                                            </Card>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div
                        /*style={{backgroundColor:"#262626", margin:"0px", padding:"0px", height:'100vh'}}*/ className={
                            classes.pageHeader
                        }
                    >
                        <div>
                            <div className={classNames(classes.container)}>
                                <div style={{ width: "100%" }}>
                                    <Grid item xs={12}>
                                        <p></p>
                                        <Paper className={classes.paper}> </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p></p>
                                        <Paper className={classes.paper}> </Paper>
                                    </Grid>
                                    <div align="center" justify="center">
                                        <CircularProgress style={{ marginTop: "20px" }} className={classes.progress} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                        </div>
                    </div>
                );
            }
        }
    }
}
ProductsOutlet.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(componentsStyle)(ProductsOutlet);

/*
<Button
            style={{marginTop:'15px', marginLeft:'10px'}}
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAddProductsMethod(right)}
          >
            Submit
          </Button>
*/
