import React from "react";

//related to meterial ui package
import styles from "./styles";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import { useState } from "react";
import { useAuth } from "../../contexts/auth-context";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { SubmitButton, TextField } from "../../core-ui/forms-ui";

import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";
import COLORS from "../../constants/colors";
import { setBrand } from "../../services/firestore/Brands";

const arabicRegex = /[\u0600-\u06FF]/;

const FORM_SCHEMA = Yup.object().shape({
    en_name: Yup.string().required("required field").max(50, "Must be 50 characters or less"),
    ar_name: Yup.string()
        .required("required field")
        .matches(arabicRegex, "Must be in Arabic letters")
        .max(50, "Must be 50 characters or less"),
});

let INITIAL_FORM_VALUES = {
    en_name: null,
    ar_name: null,
};

const AddBrandDialog = ({ open, onClose }) => {
    const classes = styles();
    const { companyData } = useAuth();
    const [creating, setCreating] = useState(false);

    const handleSubmit = async (values) => {
        try {
            setCreating(true);
            const { en_name, ar_name } = values;

            const data = {
                supplier_id: companyData.company_id,
                en_name,
                ar_name,
            };

            await setBrand(data);
            Swal.fire({
                title: `Brand Has Been Created!`,
                text: `Brand ${en_name} has been successfully created.`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
            });
            onClose(null, "submit", true);
        } catch (error) {
            console.log(error);
        }
        setCreating(false);
    };

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} className={classes.dialogRoot}>
            <DialogTitle disableTypography>
                <Typography className={classes.titleText} variant="h5">
                    Create a Brand
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers className={classes.cardContent}>
                <Formik
                    initialValues={INITIAL_FORM_VALUES}
                    enableReinitialize
                    validationSchema={FORM_SCHEMA}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={5} md={5}>
                                    <TextField name={"en_name"} label="Name (English)" className={classes.input} />
                                </Grid>
                                <Grid item xs={12} sm={5} md={5}>
                                    <TextField name={"ar_name"} label="Name (Arabic)" className={classes.input} />
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}>
                                    <SubmitButton type="submit" className={classes.submitBtn} submitting={creating}>
                                        Create
                                    </SubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AddBrandDialog;
