// import { cloneDeep } from "lodash";
import { generateNewDocID } from "../../helpers/firebase-helpers";
import { firestore as db } from "../firebase";
import { uploadImage } from "../storage";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

export const queryProducts = async (q) => {
    try {
        return await query(COLLECTIONS.PRODUCTS, q);
    } catch (error) {
        throw error;
    }
};

export let getSupplierProducts = async (supplierID) => {
    let products = [];

    try {
        products = (await db.collection("Product").where("supplier_id", "==", supplierID).get()).docs;
    } catch (error) {
        console.log(error);
    }

    return products;
};

export let getProduct = async (id) => {
    let product = null;
    try {
        product = await db.collection("Product").doc(id).get();
    } catch (error) {
        console.log(error);
    }

    return product;
};

export let getProducts = async (IDs) => {
    let products = [];

    try {
        products = await Promise.all(IDs.map(getProduct));
    } catch (error) {
        console.log(error);
    }
    return products;
};

export const addProduct = async (values) => {
    try {
        //check if the product already exists with the same product number
        const existingProducts = await queryProducts([
            { key: "product_number", operator: "==", value: values.product_number },
            { key: "supplier_id", operator: "==", value: values.supplier_id },
        ]);
        if (existingProducts.length > 0) {
            throw new Error(`Product with product number ${values.product_number} already exists`);
        }
        // const newID = await generateNewDocID(COLLECTIONS.PRODUCTS, "product_id");
        const doc = db.collection(COLLECTIONS.PRODUCTS).doc();
        values.product_id = doc.id;
        values.murtab_code = `${values.product_number}-${values.supplier_id}`;
        // console.log(newID);

        // product image = file object or string (URL)
        for (const key in values.product_image) {
            if (Object.hasOwnProperty.call(values.product_image, key)) {
                let image = values.product_image[key] || null;
                if (image && typeof image === "object") {
                    const filename = `${doc.id}-${values.En_name}-${key}.${image.name.split(".").pop()}`;
                    //upload and return a url
                    values.product_image[key] = await uploadImage(`Products/${values.supplier_id}`, image, filename);
                }
            }
        }

        // await db.collection(COLLECTIONS.PRODUCTS).doc(newID).set(values);
        await doc.set(values);
        return values;
    } catch (error) {
        throw error;
    }
};
export const setProduct = async (values) => {
    try {
        // check if the product already exists with the same product number of different doc id
        const existingProducts = await queryProducts([
            { key: "product_number", operator: "==", value: values.product_number },
            { key: "supplier_id", operator: "==", value: values.supplier_id },
            { key: "product_id", operator: "!=", value: values.product_id },
        ]);
        if (existingProducts.length > 0) {
            throw new Error(`Product with product number ${values.product_number} already exists`);
        }
        // product image = file object or string (URL)
        for (const key in values.product_image) {
            if (Object.hasOwnProperty.call(values.product_image, key)) {
                let image = values.product_image[key] || null;
                if (image && typeof image === "object") {
                    const filename = `${values.product_id}-${values.En_name}-${key}.${image.name.split(".").pop()}`;
                    //upload and return a url
                    values.product_image[key] = await uploadImage(`Products/${values.supplier_id}`, image, filename);
                }
            }
        }

        await db.collection(COLLECTIONS.PRODUCTS).doc(values.product_id).update(values);
        return values;
    } catch (error) {
        throw error;
    }
};
