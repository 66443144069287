import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

export const queryBrands = async (q) => {
    try {
        return await query(COLLECTIONS.BRANDS, q);
    } catch (error) {
        throw error;
    }
};

export const setBrand = async (data) => {
    try {
        const doc = db.collection(COLLECTIONS.BRANDS).doc();
        await doc.set({
            ...data,
            id: doc.id,
            status: "1",
        });
    } catch (error) {
        throw error;
    }
};

export const updateBrand = async (id, data) => {
    try {
        const doc = db.collection(COLLECTIONS.BRANDS).doc(id);
        await doc.update({
            ...data,
        });
    } catch (error) {
        throw error;
    }
};
