import React, { useState } from "react";
import { IconButton, makeStyles, TextField } from "@material-ui/core";
import { useField } from "formik";
import COLORS from "../../../constants/colors";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useRootStyle = makeStyles({
    root: {
        "& label.Mui-focused": {
            color: COLORS.FRENSH_BLUE,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: COLORS.FRENSH_BLUE,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&:hover fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&.Mui-focused fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
        },
    },
});

const PasswordField = ({ name, ...otherProps }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [field, meta] = useField(name);
    const rootStyle = useRootStyle();

    const handleVisibilityClick = () => {
        setShowPassword((prevState) => !prevState);
    };

    const inputConfig = {
        endAdornment: (
            <IconButton onClick={handleVisibilityClick}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
        ),
    };

    const handleChange = (e) => {
        field.onChange(e);
    };

    const config = {
        ...field,
        ...otherProps,
        onChange: handleChange,
        variant: "outlined",
        fullWidth: true,
        classes: {
            root: rootStyle.root,
        },
        type: showPassword ? "text" : "password",
        InputProps: {
            ...inputConfig,
        },
    };

    if (meta && meta.touched && meta.error) {
        config.error = true;
        config.helperText = meta.error;
    }

    return <TextField {...config} />;
};

export default PasswordField;
