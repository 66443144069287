import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

export const queryLinkedOutlets = async (q) => {
    try {
        return query(COLLECTIONS.LINKED_OUTLETS, q);
    } catch (error) {
        throw error;
    }
};

export const addLinkedOutlet = async (data) => {
    try {
        const ref = db.collection(COLLECTIONS.LINKED_OUTLETS).doc();

        data.id = ref.id;
        await ref.set(data);
        return data;
    } catch (error) {
        throw error;
    }
};

export const updateLinkedOutlet = async (id, data) => {
    try {
        await db.collection(COLLECTIONS.LINKED_OUTLETS).doc(id).update(data);
    } catch (error) {
        throw error;
    }
};
