import React, { useState, useCallback, useEffect } from "react";

//related to meterial ui package
import styles from "./styles";
import { Header, Seperator } from "../../core-ui/custom";
import { FORM_SCHEMA, INITIAL_VALUES } from "./schema";
import { Form, Formik } from "formik";
import { Card, Grid, Typography } from "@material-ui/core";
import { DateTimePicker, FileUpload, RadioGroup, SubmitButton, TextField } from "../../core-ui/forms-ui";
import { Asterisk } from "../../components";
import { getToday } from "../../helpers/date-utils";
import COLORS from "../../constants/colors";
import { PROJECT_TYPES } from "../../constants/global";
import { createNewProject } from "../../services/firestore/Project";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import PATHS from "../../constants/paths";
import useAddProject from "../../hooks/use-add-project";

const AddInsourceProductMerchandisingProject = (props) => {
    const classes = styles();
    const history = useHistory();

    const [saving, setSaving] = useState(false);

    const { castValues } = useAddProject();

    const init = useCallback(async () => {
        try {
        } catch (error) {
            throw error;
        }
    }, []);

    useEffect(() => {
        init();
    }, [init]);

    const handleSubmit = async (values, helpers) => {
        try {
            setSaving(true);
            console.log(values);
            const newValues = castValues(values);
            const data = { ...newValues };

            const project = await createNewProject(data);

            const result = await Swal.fire({
                type: "success",
                confirmButtonText: "Manage your project",
                title: "Success",
                text: `A new Project ${project.project_id} has been created successfully.`,
            });

            if (!result.value) {
                return false;
            } else {
                history.push({
                    pathname: PATHS.MANAGE_INSOURCE_PROJECT,
                    state: {
                        selfService: true,
                        projectType: project.project_type,
                        id: project.supplier_id,
                        // mpId: this.props.id,
                        projectId: project.project_id,
                    },
                });
            }
        } catch (error) {
            console.log(error);
            setSaving(false);
        }
    };

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.pageHeader}>Create New Project</Header>
            <Formik
                initialValues={INITIAL_VALUES}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={FORM_SCHEMA}
            >
                {(formik) => {
                    return (
                        <Form>
                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Project Information
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                name="projName"
                                                label={<Asterisk>Project Name</Asterisk>}
                                                className={classes.input}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <DateTimePicker
                                                name="date_from"
                                                label={<Asterisk>Start Date</Asterisk>}
                                                className={classes.input}
                                                minDate={getToday()}
                                                maxDate={formik.values.date_to}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <DateTimePicker
                                                name="date_to"
                                                label={<Asterisk>End Date</Asterisk>}
                                                className={classes.input}
                                                minDate={formik.values.date_from}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <FileUpload
                                                name="authorization_letter"
                                                label={"Authorization Letter"}
                                                className={classes.input}
                                                accept="application/pdf"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                name="remarks"
                                                label={"Remarks"}
                                                multiline
                                                minRows={4}
                                                className={classes.input}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>
                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Project Options
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                name="formType"
                                                label="Type of form in each visit"
                                                options={[
                                                    { value: "pictures", label: "Only Pictures", color: "#b1a209" },
                                                    { value: "simple", label: "Simple", color: "#00af00" },
                                                    { value: "quantity", label: "Detailed" },
                                                ]}
                                            />
                                        </Grid>
                                        <Seperator />
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                name="near_expiration"
                                                label="Near Expiration Check"
                                                options={[
                                                    {
                                                        value: "none",
                                                        label: "None",
                                                        color: "#ff0000",
                                                        textColor: COLORS.WHITE,
                                                    },
                                                    { value: "daily", label: "Daily" },
                                                    { value: "weekly", label: "Weekly" },
                                                    { value: "monthly", label: "Monthly" },
                                                ]}
                                            />
                                        </Grid>

                                        <Seperator />
                                        {/* 
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                name="warehouse"
                                                label="Warehouse Request & Draw"
                                                options={[
                                                    { value: "no", label: "No", color: "#ff0000" },
                                                    { value: "yes", label: "Yes", color: "#00af00" },
                                                ]}
                                            />
                                        </Grid>
                                        <Seperator /> */}
                                        {formik.values.warehouse === "daily" && (
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    name="inventory"
                                                    label="Warehouse Inventory Check"
                                                    options={[
                                                        {
                                                            value: "none",
                                                            label: "None",
                                                            color: "#ff0000",
                                                            textColor: COLORS.WHITE,
                                                        },
                                                        { value: "daily", label: "Daily" },
                                                        { value: "weekly", label: "Weekly" },
                                                        { value: "monthly", label: "Monthly" },
                                                    ]}
                                                />
                                            </Grid>
                                        )}

                                        <Seperator />

                                        <Grid item xs={12}>
                                            <RadioGroup
                                                name="shelf_price"
                                                label="Shelf Price Check"
                                                options={[
                                                    {
                                                        value: "none",
                                                        label: "None",
                                                        color: "#ff0000",
                                                        textColor: COLORS.WHITE,
                                                    },
                                                    { value: "daily", label: "Daily" },
                                                    { value: "weekly", label: "Weekly" },
                                                    { value: "monthly", label: "Monthly" },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                                <SubmitButton
                                    type="submit"
                                    className={classes.submitBtn}
                                    loadingClassName={classes.circularProgress}
                                    submitting={saving}
                                >
                                    Create
                                </SubmitButton>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </section>
    );
};

export default AddInsourceProductMerchandisingProject;
