/* eslint-disable no-use-before-define */

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Typography } from "@material-ui/core";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutocompleteMultiple = ({ inputLabel = "", optionLabelProp = "title", ...otherProps }) => {
    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            getOptionLabel={(option) => option[optionLabelProp]}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option[optionLabelProp]}
                </React.Fragment>
            )}
            // style={{ width: 500 }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder={inputLabel} />}
            renderTags={(value, getTagProps) => (
                <Typography
                    component={"span"}
                    style={{ marginLeft: "10px", color: "gray" }}
                >{`${value.length} selected`}</Typography>
            )}
            {...otherProps}
        />
    );
};

export default AutocompleteMultiple;
