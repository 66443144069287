import { makeStyles } from "@material-ui/styles";

export default makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "center",
        position: "relative",
        minHeight: "150px",
    },

    header: {
        padding: "10px 20px",
    },

    stickyAlert: {
        position: "sticky",
        top: 10,
        zIndex: 10,
    },

    card: {
        margin: "10px 20px",
        overflow: "visible",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "center",
    },

    saveBtn: {
        width: 108,
        marginTop: 10,
        alignSelf: "center",
    },
});
