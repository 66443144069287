import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

const CARD_HEIGHT = 330;
const CARD_CONTENT_HEIGHT = 230;
const CARD_HEADER_HEIGHT = (CARD_HEIGHT - CARD_CONTENT_HEIGHT) / 2;
const CARD_FOOTER_HEIGHT = CARD_HEADER_HEIGHT;

const styles = makeStyles({
    card: {
        height: "230px",
        overflow: "visible",
    },
    cardContent: {
        // height: "calc(100% - 50px)",
        height: "100%",
        width: "100%",
        padding: "5px 0",
        paddingBottom: "0 !important",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    card: {
        position: "relative",
        height: `${CARD_HEIGHT}px`,
    },

    cardHeader: {
        height: `${CARD_HEADER_HEIGHT}px`,
    },

    cardContent: {
        height: `${CARD_CONTENT_HEIGHT}px`,
        position: "relative",
    },

    cardActionArea: {
        height: `${CARD_FOOTER_HEIGHT}px`,
        textAlign: "right",
    },

    link: {
        marginRight: 16,
    },

    button: {
        padding: 0,
        marginRight: 16,
        color: COLORS.ACCENT,
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
});

export default styles;
