import { cloneDeep } from "lodash";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import COLORS from "../constants/colors";
import { useAuth } from "../contexts/auth-context";
import { FORM_SCHEMA, INITIAL_VALUES, STATUS } from "../pages/add-promotion-prices/schema";
import { getSupplierProducts } from "../services/firestore/Product";
import { addPromotionPrices } from "../services/firestore/Promotion_Prices";

const useAddPromotionPrices = () => {
    const { companyData } = useAuth();

    const [initializing, setInitializing] = useState(true);
    const [saving, setSaving] = useState(false);
    const [initValues, setInitValues] = useState(INITIAL_VALUES);
    const [products, setProducts] = useState([]);

    const init = useCallback(async () => {
        try {
            setInitializing(true);
            const products = (await getSupplierProducts(companyData.company_id)).map((doc) => ({
                label: doc.data().En_name,
                value: doc.id,
                data: doc.data(),
            }));

            if (products.length > 0) {
                setInitValues((prev) => ({
                    ...prev,
                    products: [{ product_id: products[0], price: 0 }],
                }));
            }

            setProducts(products);
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    }, []);

    useEffect(() => {
        init();
    }, []);

    const castValues = (values) => {
        //get values from auto complete
        const newValues = cloneDeep(FORM_SCHEMA.cast(values));
        newValues.status = newValues.status.value ?? STATUS[0].value;
        newValues.products = {};
        for (let index = 0; index < values.products.length; index++) {
            const product = values.products[index];
            if (!product.product_id?.value) throw new Error(`product un-seletected at ${index}`);

            newValues.products[product.product_id.value] = {
                product_id: product.product_id.value,
                price: product.price,
            };
        }

        return newValues;
    };

    const handleSubmit = async (values, helpers) => {
        try {
            setSaving(true);

            const newValues = castValues(values);

            const data = { ...newValues, supplier_id: companyData.company_id };
            const newPrices = await addPromotionPrices(data);

            const result = await Swal.fire({
                title: `A new promotion prices has been added!`,
                text: `${newPrices.name} has been successfully added.`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
            });

            if (!result.value) {
                return false;
            } else {
                helpers.resetForm();
            }
        } catch (error) {
            console.log(error);
        }
        setSaving(false);
    };

    return {
        initValues,
        handleSubmit,
        initializing,
        saving,
        products,
    };
};

export default useAddPromotionPrices;
