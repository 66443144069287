import { USER_TYPES } from "./global";
import PATHS from "./paths";

const DEFAULT_ACCESS = {
    [USER_TYPES.ADMIN]: false,
    [USER_TYPES.BRANCH_MANAGER]: false,
};

const HOME = {
    link: PATHS.DASHBOARD,
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    label: "Dashboard",
    // menu: [],
};
const SERVICES_MENU = {
    label: "Services",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    active: false,
    menu: [
        {
            label: "Insource",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            active: false,
            menu: [
                {
                    label: "Merchandising",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    active: false,
                    menu: [
                        {
                            label: "Product Merchandising",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                            // link: PATHS.INSOURCE_PROJECTS,
                            link: PATHS.INSOURCE_PRODUCT_MERCHANDISING_PROJECTS,
                        },
                        {
                            label: "Outlet Merchandising",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Promoting",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                            link: PATHS.PROMOTING_PROJECTS,
                        },
                    ],
                },
                {
                    label: "Route Plan Management",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.ROUTE_PLAN_MANAGEMENT,
                },
                {
                    label: "One Time Services",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    active: false,
                    menu: [
                        {
                            label: "Auditing",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Inventory",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Competitor Activity",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Stand Installation and Setup",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Outlet Setup",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Wrapping Services",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Promoter",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                    ],
                },
            ],
        },
        {
            label: "Outsource",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            active: false,
            menu: [
                {
                    label: "Merchandising",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    active: false,
                    menu: [
                        {
                            label: "Product Merchandising",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                            link: PATHS.OUTSOURCE_PROJECTS,
                        },
                        {
                            label: "Outlet Merchandising",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                            // link: '/dashboard/insource-outlet-merchandising-projects'
                        },
                        {
                            label: "Promoting",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                            // link: '/dashboard/insource-promoting-projects'
                        },
                    ],
                },
                {
                    label: "One Time Services",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    active: false,
                    menu: [
                        {
                            label: "Auditing",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Inventory",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Competitor Activity",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Stand Installation and Setup",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Outlet Setup",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Wrapping Services",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                        {
                            label: "Promoter",
                            access: {
                                ...DEFAULT_ACCESS,
                                [USER_TYPES.ADMIN]: true,
                                [USER_TYPES.BRANCH_MANAGER]: true,
                            },
                        },
                    ],
                },
            ],
        },
    ],
};

const OPERATIONS_MENU = {
    label: "Operations",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    menu: [
        {
            label: "Insource",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            menu: [
                {
                    label: "Visits Management",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.INSOURCE_VISITS_MANAGEMENT,
                },
                // {
                //     label: "Route Visits Management",
                //     link: PATHS.ROUTE_VISITS_MANAGEMENT,
                // },
                {
                    label: "Visits Pool",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.VISITS_POOL,
                },
                // {
                //     label: "Reports",
                //     menu: [
                //         { label: "Daily Merchandising Report" },
                //         { label: "Expiry and Damage Reports" },
                //         { label: "Drawn and Back Reports" },
                //         { label: "Drawn From Markets Reports" },
                //         { label: "Rent Display Reports" },
                //         { label: "Coverage and Distribution Reports" },
                //     ],
                // },
            ],
        },
        {
            label: "Outsource",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            menu: [
                {
                    label: "Visits Management",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.OUTSOURCE_VISITS_MANAGEMENT,
                },
                // {
                //     label: "Coverage Gallery",
                //     link: "/dashboard/outsource-coverage-gallery",
                // },
                // {
                //     label: "Reports",
                //     menu: [
                //         { label: "Daily Merchandising Report" },
                //         { label: "Expiry and Damage Reports" },
                //         { label: "Drawn and Back Reports" },
                //         { label: "Drawn From Markets Reports" },
                //         { label: "Rent Display Reports" },
                //         { label: "Coverage and Distribution Reports" },
                //     ],
                // },
            ],
        },
        {
            label: "Reports",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            menu: [
                {
                    label: "Daily Merchandising Report",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.DAILY_MERCHANDISING_REPORT,
                },
                {
                    label: "Monthly Merchandising Report",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Merchandiser Attendance Report",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.MERCHANDISER_ATTENDANCE_REPORT,
                },
                {
                    label: "Expiry and Damage Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.EXPIRY_AND_DAMAGE_REPORT,
                },
                {
                    label: "Near Expiry Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.NEAR_EXPIRY_REPORT,
                },
                {
                    label: "Out Of Stock Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.OUT_OF_STOCK_REPORT,
                },
                {
                    label: "Drawn and Back Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Drawn From Markets Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Compititor Prices For a Product",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Product Distribution By Outlet",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Outlet Stock Inventory Report",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Coverage and Distribution Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Auditing report",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
            ],
        },
        {
            label: "Gallery",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            link: PATHS.GALLERY,
        },
    ],
};
const FINANCE_MENU = {
    label: "Finance",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    menu: [
        {
            label: "Payments History",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
        },
    ],
};

const MANAGEMENT_MENU = {
    label: "Management",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    menu: [
        {
            label: "Users",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: false,
            },
            link: PATHS.USERS,
        },
        {
            label: "Employees",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            menu: [
                {
                    label: "Employees List",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.EMPLOYEES,
                },
                {
                    label: "Merchandisers Exclusivity Contracts",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.EXCLUSIVITY_CONTRACTS,
                },
            ],
        },
        {
            label: "Products",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            menu: [
                {
                    label: "Brands Management",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.BRANDS,
                },
                {
                    label: "Products Management",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.PRODUCTS,
                },
                {
                    label: "Promotion Prices",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.PROMOTION_PRICES,
                },
                {
                    label: "Link Products with Outlets",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.LINK_PRODUCTS_WITH_OUTLETS,
                },
                {
                    label: "Link Products with Outlet Branches",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.LINK_PRODUCTS_WITH_OUTLET_BRANCHES,
                },
            ],
        },
        {
            label: "Competitors",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            menu: [
                {
                    label: "Competitor Brands Management",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.COMPETITOR_BRANDS,
                },
                {
                    label: "Competitor Products Management",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.COMPETITOR_PRODUCTS,
                },
            ],
        },
        {
            label: "Extra Display Contracts",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            link: PATHS.EXTRA_DISPLAY_CONTRACTS,
        },
    ],
};

const DATA_MENU = {
    label: "Co. Data",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    menu: [
        {
            label: "Branches",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            link: PATHS.BRANCHES,
        },
        {
            label: "Outlets",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            menu: [
                {
                    label: "Outlets Selection",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.LINK_OUTLETS,
                },
                {
                    label: "Outlet Branches Selection",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.LINK_OUTLET_BRANCHES,
                },
            ],
        },
    ],
};

const HELP_MENU = {
    label: "Help",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    menu: [
        {
            label: "Getting Started",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            link: PATHS.HELP,
        },
        // { label: "Tutorials", link: "support.emaham.com" },
    ],
};

// const NAV_MENU = [{ ...SERVICES_MENU }];
const NAV_MENU = [
    { ...HOME },
    { ...SERVICES_MENU },
    { ...OPERATIONS_MENU },
    { ...FINANCE_MENU },
    { ...MANAGEMENT_MENU },
    { ...DATA_MENU },
    { ...HELP_MENU },
];

export default NAV_MENU;
