import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

const styles = makeStyles({
    pageRoot: {
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: COLORS.WHITE,
    },
});

export default styles;
