import { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/auth-context";
import { queryBrands } from "../services/firestore/Brands";
import EditIcon from "@material-ui/icons/Edit";
import { queryCategories } from "../services/firestore/Category";
import { querySubCategories } from "../services/firestore/SubCategory";
import { querySubSubCategories } from "../services/firestore/Sub_SubCategory";
import PATHS from "../constants/paths";
import { queryProducts } from "../services/firestore/Product";
import { exportExcel } from "../services/excel";
import { formatDate } from "../helpers/date-utils";
import COLORS from "../constants/colors";
import Swal from "sweetalert2";
import { firestore, functions } from "../services/firebase";
import { getExtension } from "../helpers/files-utils";
import { uploadExcel } from "../services/storage";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const FILTERS = {
    search: {
        label: "Search Name Or Code",
        component: "text",
        initialValue: "",
    },
    brand: {
        label: "Brand",
        options: [],
        initialValue: null,
    },
    category: {
        label: "Category",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    subcategory: {
        label: "SubCategory",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    subsubcategory: {
        label: "Sub SubCategory",
        options: [],
        initialValue: null,
    },
    status: {
        label: "Status",
        options: [
            { label: "Active", value: "1" },
            { label: "Inactive", value: "0" },
        ],
        initialValue: null,
    },
};

const HEADROW = [
    { id: "id", label: "No.", hidden: true, excelHidden: true, style: { textAlign: "center" } },
    { id: "product_number", label: "Code", style: { textAlign: "left" } },
    { id: "en_name", label: "Name (English)", style: { textAlign: "left" } },
    { id: "ar_name", label: "Name (Arabic)", style: { textAlign: "right", direction: "rtl" } },
    { id: "packing", label: "Packing", style: { textAlign: "left" } },
    { id: "status", label: "Status", style: { textAlign: "left" } },
    { id: "action", label: "", excelHidden: true },
];

const EXCEL_COLUMNS = [
    { key: "product_number", header: "Code", type: "normal", width: "auto" },
    { key: "brand", header: "Brand", type: "normal", width: "auto" },
    { key: "en_name", header: "Name (English)", type: "normal", width: "auto" },
    { key: "ar_name", header: "Name (Arabic)", type: "normal", width: "auto" },
    { key: "packing", header: "Packing", type: "normal", width: "auto" },
    { key: "weight", header: "Packing Physical Weight", type: "normal", width: "auto" },
    { key: "barcode", header: "Barcode", type: "normal", width: "auto" },
    { key: "carton_barcode", header: "Carton Barcode", type: "normal", width: "auto" },
    { key: "category", header: "Category", type: "normal", width: "auto" },
    { key: "sub_category", header: "SubCategory", type: "normal", width: "auto" },
    { key: "sub_sub_category", header: "Sub SubCategory", type: "normal", width: "auto" },
    { key: "display_unit", header: "Display Unit", type: "normal", width: "auto" },
    { key: "shelf_price", header: "Shelf Price", type: "normal", width: "auto" },
    { key: "minimum_quantity_alert", header: "Minimum Qty to Alert", type: "normal", width: "auto" },
    { key: "shelf_life", header: "Shelf Life", type: "normal", width: "auto" },
    { key: "expiration_period", header: "Expiration Period", type: "normal", width: "auto" },
    { key: "product_recall_period", header: "Recall Period", type: "normal", width: "auto" },
    { key: "manufacture_date", header: "Manufacture Date", type: "normal", width: "auto" },
    { key: "manufacture_number", header: "Manufacture Number", type: "normal", width: "auto" },
    { key: "model_number", header: "Model Number", type: "normal", width: "auto" },
    { key: "product_recommendations", header: "Recommendations", type: "normal", width: 50 },
    { key: "product_description", header: "Description", type: "normal", width: 50 },
    { key: "product_image1", header: "Image 1", type: "image", width: 33 },
    { key: "product_image2", header: "Image 2", type: "image", width: 33 },
    { key: "product_image3", header: "Image 3", type: "image", width: 33 },
    { key: "status", header: "Status", type: "normal", width: "auto" },
];

const initFilterValues = () => {
    let values = {};
    for (const key in FILTERS) {
        if (Object.hasOwnProperty.call(FILTERS, key)) {
            const filter = FILTERS[key];
            values[key] = filter.initialValue;
        }
    }
    return values;
};

const useProducts = ({ classes }) => {
    const { companyData, userData } = useAuth();
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [importing, setImporting] = useState(false);

    const history = useHistory();
    const { search: queryString } = useLocation();

    const [categoriesList, setCategoriesList] = useState([]);
    const [subcategoriesList, setSubcategoriesList] = useState([]);
    const [subsubcategoriesList, setSubsubcategoriesList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);

    const [filterValues, setFilterValues] = useState(initFilterValues());
    const [rows, setRows] = useState([]);

    const generateExcel = async () => {
        try {
            exportExcel({
                filename: "Products",
                columns: EXCEL_COLUMNS,
                rows: rows,
                worksheetTitle: "Products",
                user: userData,
                company: companyData,
            });
        } catch (error) {
            console.log(error);
        }
    };

    const importProducts = () => {
        try {
            // open file dialog programmatically
            const input = document.createElement("input");
            input.type = "file";
            input.accept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            input.click();

            input.onchange = async (e) => {
                if (e.target.files.length <= 0) return;
                const file = e.target.files[0];
                if (!file) return;

                // display swal confirm dialog
                const result = await Swal.fire({
                    title: "Import Products",
                    text: "Are you sure you want to import products?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Import",
                    confirmButtonColor: COLORS.SUBMIT,
                    cancelButtonText: "Cancel",
                    customClass: { container: classes.swal },
                });

                if (!result.value) return;

                setImporting(true);
                const extension = getExtension(file);

                await uploadExcel(`products-excel/${companyData.company_id}/`, file, `products.${extension}`);
                const importProductsExcel = functions.httpsCallable("importProductsByExcel");
                const response = await importProductsExcel({
                    path: `products-excel/${companyData.company_id}/products.${extension}`,
                });

                if (response.data.success) {
                    MySwal.fire({
                        title: "Products Imported",
                        html: (
                            <div>
                                <p>{response.data.message}</p>
                                {response.data.duplicateProducts?.length > 0 && (
                                    <>
                                        <p>{`Duplicate Products with similar product number were found in not imported: ${response.data.duplicateProducts.length}`}</p>
                                        <ul>
                                            {response.data.duplicateProducts.map((product, index) => {
                                                return (
                                                    <li
                                                        key={`duplicate-product-${index}`}
                                                    >{`${product.product_number} - ${product.product_en_name}`}</li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                )}
                            </div>
                        ),
                        icon: "success",
                        confirmButtonText: "Ok",
                        customClass: { container: classes.swal },
                    });
                } else {
                    MySwal.fire({
                        title: "Products Import Failed",
                        html: (
                            <div>
                                <p>{response.data.message}</p>
                            </div>
                        ),
                        icon: "error",
                        confirmButtonText: "Ok",
                        customClass: { container: classes.swal },
                    });
                }
                setImporting(false);
            };
        } catch (error) {
            MySwal.fire({
                title: "Products Import Failed",
                html: (
                    <div>
                        <p>{error.message}</p>
                    </div>
                ),
                icon: "error",
                confirmButtonText: "Ok",
                customClass: { container: classes.swal },
            });
            console.error(error);
            setImporting(false);
        }
    };

    const TABLE_TOOLBAR_ACTIONS = [
        {
            title: "Add New Brand",
            action: () => history.push(PATHS.ADD_PRODUCT),
            icon: "add",
            enabled: () => true,
        },
        {
            title: "Import Products",
            action: importProducts,
            icon: "publish",
            enabled: () => true,
            loading: importing,
        },
        {
            title: "Excel",
            action: generateExcel,
            icon: "excel",
            enabled: () => true,
        },
    ];

    const generateActionButton = (row) => {
        return (
            <Link
                to={{
                    pathname: `${PATHS.UPDATE_PRODUCT}/${row.product_id}`,
                }}
                className={classes.editLink}
            >
                <EditIcon />
            </Link>
        );
    };

    const loadOptions = useCallback(async () => {
        try {
            setInitializing(true);
            const { company_id } = companyData;
            let [categories, subcategories, subsubcategories, brands] = await Promise.all([
                queryCategories([]),
                querySubCategories([]),
                querySubSubCategories([]),
                queryBrands([{ key: "supplier_id", operator: "==", value: company_id }]),
            ]);

            //docs to lists
            brands = brands.map((doc) => ({
                label: doc.data().en_name,
                value: doc.id,
                data: doc.data(),
            }));
            categories = categories.map((doc) => ({
                label: doc.data().En_name,
                value: doc.id,
                data: doc.data(),
            }));
            subcategories = subcategories.map((c) => ({
                label: c.data().En_name,
                value: c.id,
                data: c.data(),
            }));
            subsubcategories = subsubcategories.map((c) => ({
                label: c.data().En_name,
                value: c.id,
                data: c.data(),
            }));

            setCategoriesList(categories);
            setSubcategoriesList(subcategories);
            setSubsubcategoriesList(subsubcategories);
            setBrandsList(brands);
            setInitializing(false);
            return { brands, categories };
        } catch (error) {
            setInitializing(false);
            throw error;
        }
    }, [companyData]);

    const handleCategoryChange = useCallback(
        async (option, context) => {
            try {
                if (!option) {
                    context.dispatchFilters({ type: "CLEAR_FILTER", payload: { id: "subcategory" } });
                    context.dispatchFilters({ type: "CLEAR_FILTER", payload: { id: "subsubcategory" } });
                    return;
                }

                let subcategories = subcategoriesList.filter((sc) => sc.data.category_id === option.value);
                // let subcategories = await querySubCategories([{ key: "category_id", operator: "==", value: option.value }]);

                // subcategories = subcategories.map((doc) => ({
                //     value: doc.id,
                //     label: doc.data().En_name,
                //     data: doc.data(),
                // }));

                context.dispatchFilters({
                    type: "SET_FILTER_OPTIONS",
                    payload: { id: "subcategory", options: subcategories },
                });

                return subcategories;
            } catch (error) {
                throw error;
            }
        },
        [subcategoriesList]
    );

    const handleSubCategoryChange = useCallback(
        async (option, context) => {
            try {
                if (!option) {
                    context.dispatchFilters({ type: "CLEAR_FILTER", payload: { id: "subsubcategory" } });
                    return;
                }

                let subsubcategories = subsubcategoriesList.filter((ssc) => ssc.data.sub_category_id === option.value);
                // let subsubcategories = await querySubSubCategories([
                //     { key: "sub_category_id", operator: "==", value: option.value },
                // ]);

                // subsubcategories = subsubcategories.map((doc) => ({
                //     value: doc.id,
                //     label: doc.data().En_name,
                //     data: doc.data(),
                // }));

                context.dispatchFilters({
                    type: "SET_FILTER_OPTIONS",
                    payload: { id: "subsubcategory", options: subsubcategories },
                });

                return subsubcategories;
            } catch (error) {
                throw error;
            }
        },
        [subsubcategoriesList]
    );

    // after the filters component is mounted, load the data
    const initFilters = useCallback(
        async (context) => {
            try {
                const params = new URLSearchParams(queryString);

                FILTERS.brand.options = brandsList;
                FILTERS.category.options = categoriesList;

                FILTERS.category.onChange = handleCategoryChange;
                FILTERS.subcategory.onChange = handleSubCategoryChange;

                //check if there is any search params in URL
                if (params.get("search")) FILTERS.search.initialValue = params.get("search");
                if (params.get("brand")) {
                    const id = params.get("brand");
                    FILTERS.brand.initialValue = brandsList.find((b) => b.value === id);
                }
                if (params.get("category")) {
                    const id = params.get("category");
                    FILTERS.category.initialValue = categoriesList.find((c) => c.value === id);
                }
                if (params.get("status")) {
                    const id = params.get("status");
                    FILTERS.status.initialValue = FILTERS.status.options.find((s) => s.value === id);
                }

                context.setFilters(FILTERS);

                //load outlet branch options
                if (FILTERS.category.initialValue !== null) {
                    const options = await handleCategoryChange(FILTERS.category.initialValue, context);
                    FILTERS.subcategory.options = options;
                    if (params.get("sub_category")) {
                        const id = params.get("sub_category");
                        FILTERS.subcategory.initialValue = options.find((sc) => sc.value === id) || null;
                    }
                }
                if (FILTERS.subcategory.initialValue !== null) {
                    const options = await handleSubCategoryChange(FILTERS.subcategory.initialValue, context);
                    FILTERS.subsubcategory.options = options;
                    if (params.get("sub_sub_category")) {
                        const id = params.get("sub_sub_category");
                        FILTERS.subsubcategory.initialValue = options.find((ssc) => ssc.value === id) || null;
                    }
                }

                const initialValues = {
                    search: FILTERS.search.initialValue,
                    brand: FILTERS.brand.initialValue,
                    category: FILTERS.category.initialValue,
                    subcategory: FILTERS.subcategory.initialValue,
                    subsubcategory: FILTERS.subsubcategory.initialValue,
                    status: FILTERS.status.initialValue,
                };
                // load products with default filters
                context.submit(initialValues);
            } catch (error) {
                console.log(error);
            }
        },
        [queryString, brandsList, categoriesList, handleCategoryChange, handleSubCategoryChange]
    );

    const setQueryParams = (filters) => {
        const { search, brand, category, subcategory, subsubcategory, status } = filters;
        const urlParams = new URLSearchParams({});

        if (search) urlParams.append("search", search);
        if (brand) urlParams.append("brand", brand.value);
        if (category) urlParams.append("category", category.value);
        if (subcategory) urlParams.append("sub_category", subcategory.value);
        if (subsubcategory) urlParams.append("sub_sub_category", subsubcategory.value);
        if (status) urlParams.append("status", status.value);

        history.replace({ search: urlParams.toString() });
    };

    const search = async ({ search, brand, category, subcategory, subsubcategory, status }) => {
        try {
            setLoading(true);
            setQueryParams({ search, brand, category, subcategory, subsubcategory, status });
            const { company_id } = companyData;
            let query = [{ key: "supplier_id", operator: "==", value: company_id }];

            if (brand) query.push({ key: "brand_id", operator: "==", value: brand.value });
            if (category) query.push({ key: "product_category.category_id", operator: "==", value: category.value });
            if (subcategory)
                query.push({ key: "product_category.sub_category_id", operator: "==", value: subcategory.value });
            if (subsubcategory)
                query.push({
                    key: "product_category.sub_sub_category_id",
                    operator: "==",
                    value: subsubcategory.value,
                });
            if (status)
                query.push({
                    key: "status",
                    operator: "==",
                    value: status.value,
                });

            let products = (await queryProducts(query)).map((doc) => doc.data());

            if (search) {
                products = products.filter((product) => {
                    const en_name = product.En_name.toLowerCase();
                    const ar_name = product.Ar_name.toLowerCase();
                    const product_number = product.product_number.toLowerCase();
                    return (
                        en_name.includes(search.toLowerCase()) ||
                        ar_name.includes(search.toLowerCase()) ||
                        product_number.includes(search.toLowerCase())
                    );
                });
            }

            //init rows
            const rows = products.map((product) => {
                let packing = "";
                if (product.packing.packing_unit > 1) packing = `${product.packing.packing_unit}⨉`;
                if (product.outer_unit > 1) packing = `${packing}${product.outer_unit}⨉`;
                if (product.packing.no_units > 1) packing = `${packing}${product.packing.no_units}⨉`;
                if (Number(product.packing.size) > 0)
                    packing = `${packing}${product.packing.size}${product.packing.size_unit}`;

                return {
                    id: product.product_id,
                    product_number: product.product_number,
                    en_name: product.En_name,
                    ar_name: product.Ar_name,
                    packing: packing,
                    status: FILTERS.status.options.find((s) => s.value === product.status)?.label ?? "-",
                    action: generateActionButton(product),
                    cellProps: { ar_name: { className: classes.arabicText }, action: { className: classes.action } },

                    //for excel
                    brand: brandsList.find((b) => b.value === product.brand_id)?.label ?? "",
                    weight: product.weight,
                    barcode: product.barcode,
                    carton_barcode: product.carton_barcode,
                    category: categoriesList.find((c) => c.value === product.product_category.category_id)?.label ?? "",
                    sub_category:
                        subcategoriesList.find((c) => c.value === product.product_category.sub_category_id)?.label ??
                        "",
                    sub_sub_category:
                        subsubcategoriesList.find((c) => c.value === product.product_category.sub_sub_category_id)
                            ?.label ?? "",
                    display_unit: product.display_unit,
                    shelf_price: `${product.shelf_price.toFixed(2)} SR`,
                    minimum_quantity_alert: `${product.minimum_quantity_alert ?? ""}${
                        product.minimum_quantity_alert >= 0 ? " Units" : ""
                    }`,
                    shelf_life: `${product.shelf_life} ${product.shelf_life_unit}${product.shelf_life > 1 ? "s" : ""}`,
                    expiration_period: `${product.expiration_period} ${product.expiration_period_unit}${
                        product.expiration_period > 1 ? "s" : ""
                    }`,
                    product_recall_period: `${product.product_recall_period} ${product.product_recall_period_unit}${
                        product.product_recall_period > 1 ? "s" : ""
                    }`,
                    manufacture_date: formatDate(product.manufacture_date?.toDate(), "/") ?? "",
                    manufacture_number: product.manufacture_number ?? "",
                    model_number: product.model_number ?? "",
                    product_recommendations: product.product_recommendations,
                    product_description: product.product_description,

                    product_image1: product.product_image.product_image1 ?? "",
                    product_image2: product.product_image.product_image2 ?? "",
                    product_image3: product.product_image.product_image3 ?? "",
                };
            });

            setRows(rows);
            setFilterValues((prev) => ({
                ...prev,
                search: search,
            }));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    useEffect(() => {
        loadOptions();
    }, [loadOptions]);

    return {
        initializing,
        loading,
        initFilters,
        HEADROW,
        TABLE_TOOLBAR_ACTIONS,
        rows,
        search,
        filterValues,
    };
};

export default useProducts;
