import * as Yup from "yup";
import { STATUS, USER_TYPES } from "../../constants/global";

const phoneRegExp =
    /^((\+?\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

Yup.addMethod(Yup.string, "phone", function (msg) {
    return this.matches(phoneRegExp, msg);
});

export const USER_TYPES_LIST = [{ label: "Branch Manager", value: USER_TYPES.BRANCH_MANAGER }];
export const STATUS_LIST = [
    { label: "Active", value: STATUS.ACTIVE },
    { label: "Inactive", value: STATUS.INACTIVE },
];

export const FORM_SCHEMA = Yup.object().shape({
    branch_ids: Yup.array().min(1, "Required"),

    first_name: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
    surname: Yup.string().max(20, "Must be 20 characters or less").required("Required"),
    phone: Yup.string().phone("Invalid phone number"),
    status: Yup.object().typeError("Required").shape({
        label: Yup.string(),
        value: Yup.string(),
    }),
});

export const INITIAL_FORM_VALUES = {
    first_name: "",
    surname: "",
    phone: "",
    email: "",

    user_type: USER_TYPES_LIST[0],

    status: STATUS_LIST[0],

    branch_id: null,
    branch_ids: [],
};
