import React from "react";
import styles from "./styles";

import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import { useEffect } from "react";
import { useState } from "react";
import { Collapse } from "react-collapse";
import HamburgerMenu from "react-hamburger-menu";
import Logo from "../../core-ui/custom/logo";
import { widthCheck } from "../../helpers/breakpoint-checker";
import useWindowSize from "../../hooks/use-window-size";
import COLORS from "../../constants/colors";
import { Button } from "@material-ui/core";
import NavigationMenu from "../dashboard-navigation/navigation-menu";
import NavigationAccountContent from "../dashboard-navigation/navigation-account-content";
import { useAuth } from "../../contexts/auth-context";

import { useCallback } from "react";
import { Link } from "react-router-dom";
import PATHS from "../../constants/paths";

const hamburgerConfig = {
    width: 30,
    height: 20,
    color: COLORS.WHITE,
};

const BREAK_POINTS = [1050, 0];

const DashboardHeader = () => {
    const [width] = useWindowSize();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuItemExpanded, setMenuItemExpanded] = useState(false);
    const [accountContentOpen, setAccountContentOpen] = useState(false);
    const [currentBreakPoint, setCurrentBreakPoint] = useState(widthCheck(width, BREAK_POINTS));
    const [breakpointOccured, setBreakpointOccured] = useState(false);
    const { companyData, userData } = useAuth();
    const classes = styles();

    const collapseConfig = {
        isOpened: isMenuOpen,
        theme: {
            collapse: breakpointOccured ? classes.noTransition : classes.collapse,
            content: classes.collapseContent,
        },
    };

    useEffect(() => {
        const bp = widthCheck(width, BREAK_POINTS);
        if (bp !== currentBreakPoint) {
            setBreakpointOccured(true);
            setIsMenuOpen(BREAK_POINTS[bp] >= 1050);
            setCurrentBreakPoint(bp);

            setTimeout(() => {
                setBreakpointOccured(false);
            }, 150);
        }
    }, [width, currentBreakPoint]);

    const handleHamburgerClick = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    const handleExpandingItemClick = useCallback((isExpanded) => {
        setMenuItemExpanded(isExpanded);
    }, []);

    const handleAccountClick = () => {
        setAccountContentOpen((prevState) => !prevState);
    };

    return (
        <header className={`${menuItemExpanded ? classes.active : ""}`}>
            <div className={`${classes.headerWrap} ${menuItemExpanded ? classes.expanded : ""}`}>
                <div className={classes.header}>
                    <div className={classes.logoWrap}>
                        <div className={classes.navbarToggle}>
                            <HamburgerMenu
                                isOpen={isMenuOpen}
                                menuClicked={handleHamburgerClick}
                                {...hamburgerConfig}
                            />
                        </div>
                        <div className={classes.emahamLogo}>
                            <Link to={PATHS.DASHBOARD}>
                                <Logo variant="light" />
                            </Link>
                        </div>

                        <div className={classes.account}>
                            <Button className={classes.accountBtn} disableRipple onClick={handleAccountClick}>
                                <div className={classes.accountDetails}>
                                    <span
                                        className={classes.username}
                                    >{`${userData.first_name} ${userData.surname}`}</span>
                                    <span className={classes.company}>{companyData.En_short_name}</span>
                                </div>
                                <PersonOutlineIcon className={classes.personIcon} />
                            </Button>
                            {accountContentOpen && (
                                <NavigationAccountContent setOpen={(value) => setAccountContentOpen(value)} />
                            )}
                        </div>
                    </div>
                    <div className={classes.menuWrap}>
                        <Collapse {...collapseConfig}>
                            {!breakpointOccured && <NavigationMenu onItemClick={handleExpandingItemClick} />}
                        </Collapse>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default DashboardHeader;
