import React, { useState, useCallback, useEffect } from "react";

//related to meterial ui package
import styles from "./styles";
import { Seperator } from "../../core-ui/custom";
import { FORM_SCHEMA, INITIAL_VALUES } from "./schema";
import { Form, Formik } from "formik";
import { Card, Grid, Typography } from "@material-ui/core";
import { DateTimePicker, FileUpload, RadioGroup, SubmitButton, TextField } from "../../core-ui/forms-ui";
import { Asterisk } from "../../components";
import { getToday } from "../../helpers/date-utils";
import COLORS from "../../constants/colors";
import { updateProject } from "../../services/firestore/Project";
import Swal from "sweetalert2";
import { storage } from "../../services/firebase";

const getOptionInterval = (option) => {
    // for lagacy project documents
    if (!option) return "daily";

    if (!option.active) return "none";
    return option.interval;
};

const getFormType = (options) => {
    //for lagacy project documents
    if (!options) return "quantity";

    if (options.availability.active) return "simple";
    if (options.quantity.active) return "quantity";
    if (options.pictures.active) return "pictures";
};

//! only temp
const ManageInsourceProductMerchandisingProject = ({ project, syncProject }) => {
    const classes = styles();
    // const { companyData } = useAuth();
    // const history = useHistory();

    //any autocomplete values here are null to make autocomplate controllable (untrcontrollable = undifined)
    const [initialValues, setInitialValues] = useState({
        ...INITIAL_VALUES,
        remarks: project.data.remarks,
        projName: project.data.projName,
        project_id: project.data.project_id,
        date_from: project.data.date_from,
        date_to: project.data.date_to,
        authorization_letter: project.data.authorization_letter,
        formType: getFormType(project.data.options),
        inventory: getOptionInterval(project.data.options.inventory),
        near_expiration: getOptionInterval(project.data.options.near_expiration),
        warehouse: getOptionInterval(project.data.options?.warehouse),
        shelf_price: getOptionInterval(project.data.options.shelf_price),
        damaged: getOptionInterval(project.data.options.damaged),
    });

    const [saving, setSaving] = useState(false);

    const init = useCallback(async () => {
        try {
            let ref = null;
            if (project.data.authorization_letter) {
                ref = storage.refFromURL(project.data.authorization_letter);
            }
            setInitialValues((prev) => ({
                ...prev,
                authorization_letter: ref ?? null,
            }));
        } catch (error) {
            throw error;
        }
    }, []);

    useEffect(() => {
        init();
    }, [init]);

    const handleSubmit = async (values, helpers) => {
        try {
            setSaving(true);
            const data = { ...values };
            const updated = await updateProject(project.data.project_id, data);

            //!temp
            project.data = {
                ...project.data,
                projName: updated.projName,
                date_from: updated.date_from,
                date_to: updated.date_to,
                remarks: updated.remarks,
                authorization_letter: updated.authorization_letter,
                options: updated.options,
            };

            project.toBeUpdated = true;
            await syncProject(project);
        } catch (error) {
            console.log(error);
        }
        setSaving(false);
    };

    return (
        <section className={classes.pageRoot}>
            {/* <Header className={classes.pageHeader}>Create New Project</Header> */}
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={FORM_SCHEMA}
            >
                {(formik) => {
                    //can be File object from user selection or URL or null
                    const authLetter = formik.values.authorization_letter;
                    return (
                        <Form>
                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Project Information
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="project_id"
                                                label={<Asterisk>Project Number</Asterisk>}
                                                className={classes.input}
                                                disabled
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="projName"
                                                label={<Asterisk>Project Name</Asterisk>}
                                                className={classes.input}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <DateTimePicker
                                                name="date_from"
                                                label={<Asterisk>Start Date</Asterisk>}
                                                className={classes.input}
                                                disabled={project.data.isConfirmed}
                                                minDate={project.data.isConfirmed ? null : getToday()}
                                                minDateMessage="Minimum Date is Today"
                                                maxDate={formik.values.date_to}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <DateTimePicker
                                                name="date_to"
                                                label={<Asterisk>End Date</Asterisk>}
                                                className={classes.input}
                                                minDate={formik.values.date_from}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FileUpload
                                                name="authorization_letter"
                                                label={"Authorization Letter"}
                                                className={classes.input}
                                                accept="application/pdf"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                name="remarks"
                                                label={"Remarks"}
                                                multiline
                                                minRows={4}
                                                className={classes.input}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>
                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Project Options
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                name="formType"
                                                label="Type of form in each visit"
                                                options={[
                                                    { value: "pictures", label: "Only Pictures", color: "#b1a209" },
                                                    { value: "simple", label: "Simple", color: "#00af00" },
                                                    { value: "quantity", label: "Detailed" },
                                                ]}
                                            />
                                        </Grid>
                                        <Seperator />
                                        <Grid item xs={12}>
                                            <RadioGroup
                                                name="near_expiration"
                                                label="Near Expiration Check"
                                                options={[
                                                    {
                                                        value: "none",
                                                        label: "None",
                                                        color: "#ff0000",
                                                        textColor: COLORS.WHITE,
                                                    },
                                                    { value: "daily", label: "Daily" },
                                                    { value: "weekly", label: "Weekly" },
                                                    { value: "monthly", label: "Monthly" },
                                                ]}
                                            />
                                        </Grid>

                                        <Seperator />

                                        {/* <Grid item xs={12}>
                                            <RadioGroup
                                                name="warehouse"
                                                label="Warehouse Request & Draw"
                                                options={[
                                                    { value: "no", label: "No", color: "#ff0000" },
                                                    { value: "yes", label: "Yes", color: "#00af00" },
                                                ]}
                                            />
                                        </Grid>
                                        <Seperator /> */}
                                        {formik.values.warehouse === "daily" && (
                                            <Grid item xs={12}>
                                                <RadioGroup
                                                    name="inventory"
                                                    label="Warehouse Inventory Check"
                                                    options={[
                                                        {
                                                            value: "none",
                                                            label: "None",
                                                            color: "#ff0000",
                                                            textColor: COLORS.WHITE,
                                                        },
                                                        { value: "daily", label: "Daily" },
                                                        { value: "weekly", label: "Weekly" },
                                                        { value: "monthly", label: "Monthly" },
                                                    ]}
                                                />
                                            </Grid>
                                        )}

                                        {formik.values.warehouse === "daily" && <Seperator />}

                                        <Grid item xs={12}>
                                            <RadioGroup
                                                name="shelf_price"
                                                label="Shelf Price Check"
                                                // options={[
                                                //     { value: "no", label: "No", color: "#ff0000" },
                                                //     { value: "yes", label: "Yes", color: "#00af00" },
                                                // ]}
                                                options={[
                                                    {
                                                        value: "none",
                                                        label: "None",
                                                        color: "#ff0000",
                                                        textColor: COLORS.WHITE,
                                                    },
                                                    { value: "daily", label: "Daily" },
                                                    { value: "weekly", label: "Weekly" },
                                                    { value: "monthly", label: "Monthly" },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                                <SubmitButton
                                    type="submit"
                                    className={classes.submitBtn}
                                    loadingClassName={classes.circularProgress}
                                    submitting={saving}
                                >
                                    Save
                                </SubmitButton>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </section>
    );
};

export default ManageInsourceProductMerchandisingProject;
