import { cloneDeep } from "lodash";
import { useState } from "react";
import Swal from "sweetalert2";
import COLORS from "../constants/colors";
import { useAuth } from "../contexts/auth-context";
import { FORM_SCHEMA, INITIAL_VALUES, STATUS } from "../pages/add-competitor-product/schema";
import { addCompetitorProduct } from "../services/firestore/Competitor_Products";
import useCompetitorProduct from "./use-competitor-product";

const useAddCompetitorProduct = () => {
    const { companyData } = useAuth();

    const [initValues, setInitValues] = useState(INITIAL_VALUES);

    const onAfterInit = (categories, subcategories, subsubcategories, brands) => {
        try {
            //select unclassified by default
            let category = categories.find((c) => c.value === "110");
            let subcategory = subcategories.find((sc) => sc.data.category_id === category.value);
            let subsubcategory = subsubcategories.find((ssc) => ssc.data.sub_category_id === subcategory.value);

            setInitValues((prev) => ({
                ...prev,
                product_category: {
                    category_id: category,
                    sub_category_id: subcategory,
                    sub_sub_category_id: subsubcategory,
                },
            }));
        } catch (error) {
            throw error;
        }
    };

    const {
        initializing,
        saving,
        setSaving,
        categoriesList,
        subcategoriesList,
        subsubcategoriesList,
        brandsList,
        UNCALSSIFIED_CATEGORY,
        UNCALSSIFIED_SUBCATEGORY,
        UNCALSSIFIED_SUB_SUBCATEGORY,
    } = useCompetitorProduct({ onAfterInit });

    const castValues = (values) => {
        //get values from auto complete
        const newProduct = cloneDeep(FORM_SCHEMA.cast(values));
        newProduct.status = newProduct.status.value ?? STATUS[0].value;
        newProduct.brand_id = newProduct.brand_id?.value ?? null;
        newProduct.product_category.category_id =
            newProduct.product_category.category_id.value ??
            categoriesList.find((c) => c.value === UNCALSSIFIED_CATEGORY).value;
        newProduct.product_category.sub_category_id =
            newProduct.product_category.sub_category_id.value ??
            subcategoriesList.find((c) => c.value === UNCALSSIFIED_SUBCATEGORY).value;
        newProduct.product_category.sub_sub_category_id =
            newProduct.product_category.sub_sub_category_id.value ??
            subsubcategoriesList.find((c) => c.value === UNCALSSIFIED_SUB_SUBCATEGORY).value;

        return newProduct;
    };

    const handleSubmit = async (values, helpers) => {
        try {
            setSaving(true);

            const newValues = castValues(values);

            const data = { ...newValues, supplier_id: companyData.company_id };
            const newPRoduct = await addCompetitorProduct(data);

            const result = await Swal.fire({
                title: `A new Competitor product has been added!`,
                text: `Product ${newPRoduct.En_name} has been successfully added.`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
            });

            if (!result.value) {
                return false;
            } else {
                helpers.resetForm();
            }
        } catch (error) {
            console.log(error);
        }
        setSaving(false);
    };

    return {
        initValues,
        setInitValues,
        handleSubmit,
        initializing,
        saving,

        categoriesList,
        subcategoriesList,
        subsubcategoriesList,
        brandsList,
    };
};

export default useAddCompetitorProduct;
