import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useEffect } from "react";
import { useCallback } from "react";
import { getProduct } from "../../../services/firestore/Product";
import useTransferList from "../../../hooks/use-transfer-list";
import EnhancedTransferList from "../../enhanced-transfer-list";
import { cloneDeep } from "lodash";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: "12px",
        top: "12px",
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

let ShelfProductsDialog = ({ handleClose, open, save, schedule, disabled }) => {
    const {
        INITIAL_LIST_ITEM,
        leftList,
        setLeftList,
        rightList,
        setRightList,
        checkedList,
        setCheckedList,
        transferHandler,
        filterHandler,
    } = useTransferList();

    const setup = useCallback(async () => {
        try {
            //get products data
            const products = await Promise.all(
                schedule.outlet_products.map(async (id) => {
                    const product = (await getProduct(id)).data();
                    return product;
                })
            );

            const schedule_displays = schedule.schedule_displays;

            //*setup right side
            //filter products that are  selected
            let rightList = schedule_displays.map((display) => {
                const product = products.find((p) => p.product_id === display.product_id);
                let item = cloneDeep(INITIAL_LIST_ITEM);
                item.id = display.product_id;
                item.data = display;
                item.primaryLabel = product?.En_name ?? "-";
                item.secondaryLabel = product?.product_number ?? "-";
                return item;
            });

            //*setup left side
            //filter products that are not selected
            let leftList = products.filter((p) => {
                //if there are non is selected
                if (schedule_displays.length <= 0) return true;
                return (
                    schedule_displays.findIndex((sp) => sp.product_id === p.product_id && sp.type === "shelf") === -1
                );
            });

            leftList = leftList.map((p) => ({
                ...INITIAL_LIST_ITEM,
                id: p.product_id,
                data: null,
                primaryLabel: p.En_name,
                secondaryLabel: p.product_number,
                hidden: false,
            }));

            setLeftList(leftList);
            setRightList(rightList);
            setCheckedList([]);
        } catch (error) {
            console.log(error);
        }
    });

    useEffect(() => {
        if (open) setup();
    }, [open]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
            <DialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
                style={{ padding: "20px 56px", textAlign: "center" }}
            >
                Products
            </DialogTitle>
            <DialogContent dividers style={{ padding: "16px 24px" }}>
                <EnhancedTransferList
                    leftList={leftList}
                    rightList={rightList}
                    checkedList={checkedList}
                    onTransfer={transferHandler}
                    onFilter={filterHandler}
                />
                {/* <TransferList left={leftSide} right={rightSide} checked={checked} disabled={disabled} /> */}
            </DialogContent>
            <DialogActions style={{ padding: "8px 24px" }}>
                <Button
                    onClick={() => save(schedule, leftList, rightList, checkedList)}
                    color="primary"
                    style={{ padding: "6px 0px" }}
                >
                    {disabled ? "Close" : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ShelfProductsDialog;
