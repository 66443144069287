import { COLLECTIONS } from "../constants";
import { query } from "../helpers";

export const queryProjects = async (options) => {
    try {
        return query(COLLECTIONS.PROJECT, options);
    } catch (error) {
        throw error;
    }
};
