import { Grid } from "@material-ui/core";
import React, { useMemo } from "react";

//related to meterial ui package
import { Form, Formik } from "formik";
import { Autocomplete, DateTimePicker, SubmitButton, TextField } from "../../core-ui/forms-ui";
import { Button } from "../../core-ui/custom";
import styles from "./styles";

const EnhancedFilters = ({ filters = {}, onSubmit, submitText = "Search", classes: customClasses = {} }) => {
    const classes = styles();

    const initialValues = useMemo(() => {
        return Object.keys(filters).reduce((prev, key) => ({ ...prev, [key]: filters[key].value }), {});
    }, [filters]);

    if (Object.keys(filters).length <= 0) return null;

    return (
        <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
            {(formik) => (
                <Form>
                    <Grid container spacing={2}>
                        {Object.keys(filters).map((key) => {
                            const filter = filters[key];
                            switch (filter.component) {
                                case "text":
                                    return (
                                        <Grid
                                            key={`filter-${key}`}
                                            item
                                            xs={12}
                                            sm={4}
                                            md={3}
                                            lg={3}
                                            {...(filter.gridProps ?? {})}
                                        >
                                            <TextField
                                                name={key}
                                                label={filter.label}
                                                className={classes.input}
                                                {...(filter.inputProps ?? {})}
                                            />
                                        </Grid>
                                    );
                                case "date":
                                    return (
                                        <Grid
                                            key={`filter-${key}`}
                                            item
                                            xs={12}
                                            sm={4}
                                            md={3}
                                            lg={3}
                                            {...(filter.gridProps ?? {})}
                                        >
                                            <DateTimePicker
                                                name={key}
                                                label={filter.label}
                                                className={classes.input}
                                                {...(filter.inputProps ?? {})}
                                            />
                                        </Grid>
                                    );
                                default:
                                    return (
                                        <Grid
                                            key={`filter-${key}`}
                                            item
                                            xs={12}
                                            sm={4}
                                            md={3}
                                            lg={3}
                                            {...(filter.gridProps ?? {})}
                                        >
                                            <Autocomplete
                                                options={filter.options}
                                                name={key}
                                                label={filter.label}
                                                className={classes.input}
                                                classes={{ option: classes.option }}
                                                {...(filter.inputProps ?? {})}
                                            />
                                        </Grid>
                                    );
                            }
                        })}
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                            <Button className={classes.clearFiltersButton} onClick={() => formik.resetForm()}>
                                Reset
                            </Button>
                            <SubmitButton type="submit" className={classes.submitButton} disabled={!formik.isValid}>
                                {submitText}
                            </SubmitButton>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default EnhancedFilters;
