import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    dashboard: {
        // height: "10vh",
        // width: "100%",
        // backgroundColor: "#f5f5f5",
        // display: "flex",
        // flexDirection: "column",
        // justifyContent: "center",
        // alignItems: "center",
        maxWidth: "1540px",
        margin: "0 auto",
        padding: "0 15px",
    },

    dashboardContent: {
        height: "100%",
        width: "100%",
        margin: "0",
        marginBottom: "20px",
        // padding: "20px",
    },

    header: {
        color: COLORS.ACCENT,
    },

    chart: {
        // maxHeight: "100px",
    },

    // waffleChart: {
    //     height: "250px",
    //     width: "100%",
    // },

    // waffleLegends: {
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",

    //     "& > span": {
    //         display: "flex",
    //         margin: "0 30px",
    //         "&::before": {
    //             content: "''",
    //             display: "block",
    //             width: "20px",
    //             height: "20px",
    //             marginRight: "10px",
    //             backgroundColor: "red",
    //         },

    //         "&:nth-child(2)::before": {
    //             backgroundColor: "orange",
    //         },
    //         "&:nth-child(3)::before": {
    //             backgroundColor: "yellow",
    //         },
    //     },
    // },
});

export default styles;
