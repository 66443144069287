import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useState } from "react";
import { useEffect } from "react";
import { getMerchandiserName } from "../../services/firestore/Merchandiser";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: "12px",
        top: "12px",
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
// let tableHeads = [
//     { id: "product", label: "Product" },
//     { id: "contract_id", label: "Contract No." },
//     { id: "description", label: "Description" },
//     { id: "type", label: "Type" },
//     { id: "start", label: "Start Date" },
//     { id: "end", label: "End Date" },
//     { id: "actions", label: "" },
// ];
let UpdateExclusiveMerchandiserDialog = (props) => {
    // const classes = useStyles(props);
    const { merchandiser, onSubmit, branches, open } = props;
    // const [name, setName] = useState("");
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [active, setActive] = useState(0);

    const handleSubmit = () => {
        onSubmit(merchandiser, selectedBranch);
    };

    useEffect(() => {
        if (open) {
            setActive(merchandiser?.status || 0);
            setSelectedBranch(branches.find((br) => br.branch_id === merchandiser?.supplier_branch_id));
        } else {
            setSelectedBranch(null);
            setActive(0);
        }
    }, [open, merchandiser?.status]);

    return (
        <Dialog onClose={props.onClose} open={open} fullWidth={true} maxWidth="xl">
            <DialogTitle
                id="customized-dialog-title"
                onClose={props.onClose}
                style={{ padding: "20px 56px", textAlign: "center" }}
            >
                {props.title}
            </DialogTitle>
            <DialogContent dividers style={{ padding: "16px 24px" }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Current Merchandiser"
                            value={merchandiser ? getMerchandiserName(merchandiser.data) : "-"}
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            id="branches"
                            // className={classes.autoCompleteList}
                            options={branches}
                            value={selectedBranch}
                            getOptionLabel={(option) => option.En_name}
                            renderInput={(params) => <TextField {...params} label="Branch" variant="outlined" />}
                            onChange={(event, newBranch) => setSelectedBranch(newBranch)}
                            disableClearable
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Active"
                            value={active === 1 ? "Yes" : "No"}
                            disabled
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "8px 24px" }}>
                <Button onClick={handleSubmit} color="primary" style={{ padding: "6px 0px" }}>
                    {props.submitText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateExclusiveMerchandiserDialog;
