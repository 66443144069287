import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import GridItem from "../components-lagacy/Grid/GridItem";
import PropTypes from "prop-types";
import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
import withStyles from "@material-ui/core/styles/withStyles";

import Paper from "@material-ui/core/Paper";
// nodejs library that concatenates classes
import classNames from "classnames";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Redirect } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { COLORS } from "../constants/constants-lagacy";

import { firestore as db } from "../services/firebase";
import PATHS from "../constants/paths";
import { PLATFORM } from "../constants/global";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 250,
        height: 280,
        backgroundColor: theme.palette.background.paper,
        overflow: "auto",
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

function TransferList(props) {
    const classes = useStyles();
    const { handleAddOutletsMethod, goToSupplierDashboardPageMethod } = props;
    // console.log(outlets);
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(props.left);
    const [right, setRight] = React.useState(props.right);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAddOutlets = () => {
        handleAddOutletsMethod(right);
    };

    const handleSupplierDashboardPageMethod = () => {
        goToSupplierDashboardPageMethod(PATHS.DASHBOARD);
    };

    const customList = (title, items) => (
        <Card>
            <CardHeader
                style={{
                    background: COLORS.primary,
                    color: COLORS.font,
                    //"rgba(145, 4, 28,0.83)", "#91041C"
                }}
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        style={{
                            color: COLORS.font,
                        }}
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ "aria-label": "all items selected" }}
                    />
                }
                title={title}
                subheader={
                    <p style={{ color: COLORS.font, fontSize: "13px" }}>{`${numberOfChecked(items)}/${
                        items.length
                    } selected`}</p>
                }
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-all-item-${value.En_short_name}-label`;

                    return (
                        <ListItem
                            style={{ padding: "0", margin: "0" }}
                            key={value.outlet_id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    style={{
                                        color: COLORS.primary,
                                    }}
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={`${value.En_short_name}`} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <Grid container direction="column">
            <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
                <Grid item>{left === undefined ? customList("Outlet Co.", []) : customList("Outlet Co.", left)}</Grid>

                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>

                <Grid item>
                    {right === undefined
                        ? customList("Selected Outlet Co.", [])
                        : customList("Selected Outlet Co.", right)}
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
                <Grid item>
                    <Grid container direction="row" alignItems="center">
                        <Button
                            style={{
                                float: "right",
                                textTransform: "none",
                                // backgroundColor: "#91041C",
                                backgroundColor: COLORS.accent,
                                color: COLORS.font,
                                // color: "#FFFFFF",
                                marginTop: 14,
                                padding: 10,
                                paddingLeft: 20,
                                paddingRight: 20,
                                marginRight: 20,
                                // borderColor: "#91041C",
                                border: "2",
                                fontWeight: "400",
                            }}
                            type="submit"
                            onClick={handleSupplierDashboardPageMethod}
                        >
                            {" "}
                            Cancel{" "}
                        </Button>

                        <Button
                            style={{
                                float: "right",
                                textTransform: "none",
                                backgroundColor: COLORS.submit,
                                color: COLORS.font,
                                marginTop: 14,
                                padding: 10,
                                paddingLeft: 20,
                                paddingRight: 20,
                                // borderColor: "#4BB543",
                                border: "2",
                                fontWeight: "400",
                            }}
                            type="submit"
                            onClick={handleAddOutlets}
                        >
                            {" "}
                            Save{" "}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

//==================================================================================
//==========================OutletSupplier======================
//==================================================================================
class OutletSupplier extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            city: "",
            company_id: "",
            supplier_name: "",

            right: [],
            left: [],
            DeleteOutletsOld: [],
            checkRight: false,
            checkLeft: false,
            //allOutlets:[],
            // supplierOutlets:[],

            //right: [{En_short_name:'L', outlet_id:'1'}],
            // left: [{En_short_name:'R', outlet_id:'2'},{En_short_name:'L', outlet_id:'1'}],

            pathBack: "",

            loading: false,
            dialogOpen: false,
            dialogHeader: "",
            dialogMsg: "",
            dialogCloseHandler: null,
        };
    }
    //==================================================================================
    showDialogMsg = (title, msg, closeHandler) => {
        this.setState({
            loading: false,
            dialogHeader: title,
            dialogMsg: msg,
            dialogOpen: true,
            dialogCloseHandler: closeHandler,
        });
    };

    hideDialogMsg = () => {
        this.setState({ dialogMsg: "", dialogOpen: false });
    };

    componentDidMount() {
        this.setState({ loading: true });

        let datat2 = [];
        //firebase.firestore();
        var supplieresRef = db.collection("Supplier").where("company_id", "==", this.props.id);
        supplieresRef
            .get()
            .then((snapshot) => {
                this.setState({ loading: false });

                snapshot.forEach((doc) => {
                    datat2 = doc.data();

                    this.setState(() => ({
                        city: datat2.city_id,
                        company_id: datat2.company_id,
                        supplier_name: datat2.En_short_name,
                        allOutlets: [],
                        supplierOutlets: [],
                    }));
                });
            })
            .then(() => {
                this.setState({ loading: true });

                let datat3 = [];
                var outletsRef = db.collection("Outlet");
                outletsRef
                    .get()
                    .then((snapshot) => {
                        snapshot.forEach((doc) => {
                            if (doc.data().status === "1") {
                                let datat = doc.data();
                                datat3.push(datat);
                            }
                        });
                        this.setState({ loading: false, allOutlets: datat3 });
                    })
                    .then(() => {
                        this.setState({ loading: true });

                        let datat4 = [];
                        var outletsRef = db
                            .collection("Supplier_Outlet_Product")
                            .doc(this.state.company_id)
                            .collection("Outlet_Product");
                        outletsRef
                            .get()
                            .then((snapshot) => {
                                snapshot.forEach((doc) => {
                                    let datat = doc.data();
                                    datat4.push(datat);
                                });
                                this.setState({ loading: false, supplierOutlets: datat4 });
                            })
                            .then(() => {
                                var right = [];
                                var DeleteOutletsOld = [];
                                for (let i = 0; i < this.state.supplierOutlets.length; i++) {
                                    let index = this.state.allOutlets.findIndex((post) => {
                                        return post.outlet_id === this.state.supplierOutlets[i].outlet_id;
                                    });

                                    if (index !== -1) {
                                        let name = this.state.allOutlets[index].En_short_name;
                                        let id = this.state.supplierOutlets[i].outlet_id;
                                        right.push({ En_short_name: name, outlet_id: id });
                                    } else {
                                        let name = this.state.supplierOutlets[i].En_short_name;
                                        let id = this.state.supplierOutlets[i].outlet_id;
                                        DeleteOutletsOld.push({ En_short_name: name, outlet_id: id });
                                    }
                                }
                                this.setState({
                                    right: [...right],
                                    DeleteOutletsOld: [...DeleteOutletsOld],
                                    checkRight: true,
                                });
                            })
                            .then(() => {
                                // console.log(this.not(this.state.supplierOutlets, this.state.allOutlets));
                                // console.log(this.state.supplierOutlets);
                                // console.log(this.state.allOutlets);
                                var left = [];
                                for (let i = 0; i < this.state.allOutlets.length; i++) {
                                    let name = this.state.allOutlets[i].En_short_name;
                                    let id = this.state.allOutlets[i].outlet_id;
                                    left.push({ En_short_name: name, outlet_id: id });
                                }
                                // console.log(left);
                                this.setState({ left: [...left], checkLeft: true });
                            });
                    });
            })
            .catch((err) => {
                console.log("Error getting documents", err);
                this.showDialogMsg("Alert", "Something went wrong, please refresh and try again", this.hideDialogMsg);
            });
    }

    //==================================================================================

    handleChange = (event, id) => {
        this.setState({ [id]: event.target.value });
    };
    handleChangeFormNumber = (num) => {
        this.setState({ form_number: num });
    };
    handleChangeExpiryDate = (date) => {
        this.setState({ expiry_date: date });
    };

    handleAddOutlets = async (outletsSelected) => {
        this.setState({ loading: true });

        if (!(this.state.supplierOutlets === undefined) && navigator.onLine) {
            if (this.state.right.length > 0) {
                let AddOutlets = this.not(outletsSelected, this.state.right);
                let DeleteOutlets = this.not(this.state.right, outletsSelected);
                //delete outlets that deleted from outlet collection
                for (let j = 0; j < this.state.DeleteOutletsOld.length; j++) {
                    DeleteOutlets.push(this.state.DeleteOutletsOld[j]);
                }

                //firebase.firestore();
                //add outlet info. to Outlet_Product collection
                let supplierOutletProductRef = db
                    .collection("Supplier_Outlet_Product")
                    .doc(this.state.company_id)
                    .collection("Outlet_Product");

                try {
                    await Promise.all(
                        AddOutlets.map((outlet) => {
                            return supplierOutletProductRef.doc(outlet.outlet_id).set({
                                platform: PLATFORM,
                                supplier_id: this.state.company_id,
                                outlet_id: outlet.outlet_id,
                                En_short_name: outlet.En_short_name,
                            });
                        })
                    );

                    await Promise.all(
                        DeleteOutlets.map((outlet) => {
                            return supplierOutletProductRef.doc(outlet.outlet_id).delete();
                        })
                    );
                } catch (error) {
                    console.error(error);
                    this.showDialogMsg("Alert", "Something Went wrong, try to refresh again.", this.hideDialogMsg);
                }

                if (AddOutlets.length === 0 && DeleteOutlets.length === 0) {
                    // this.goToSupplierDashboardPage(PATHS.DASHBOARD);
                }

                this.setState({
                    supplierOutlets: [],
                    allOutlets: [],
                });
                this.showDialogMsg("Message", "Saved successfully", () => {
                    this.hideDialogMsg();
                    // this.goToSupplierDashboardPage(PATHS.DASHBOARD);
                });
            } else {
                let company_id = this.state.company_id === undefined ? null : this.state.company_id;
                let supplier_name = this.state.supplier_name === undefined ? null : this.state.supplier_name;
                let city = this.state.city === undefined ? null : this.state.city;
                let AddOutlets = this.not(outletsSelected, this.state.right);
                let DeleteOutlets = this.not(this.state.right, outletsSelected);
                //delete outlets that deleted from outlet collection
                for (let j = 0; j < this.state.DeleteOutletsOld.length; j++) {
                    DeleteOutlets.push(this.state.DeleteOutletsOld[j]);
                }

                //firebase.firestore();
                //add Supplier info. to Supplier_Outlet_Product collection
                let supplierOutletRef = db.collection("Supplier_Outlet_Product");
                supplierOutletRef
                    .doc(this.state.company_id)
                    .set({
                        platform: PLATFORM,
                        supplier_id: this.props.id,
                        company_id: company_id,
                        En_name: supplier_name,
                        city_id: city,
                    })
                    .then(() => {
                        this.setState({
                            supplierOutlets: [],
                            allOutlets: [],
                        });
                    })
                    .then(() => {
                        //add outlet info. to Outlet_Product collection
                        let supplierOutletProductRef = db
                            .collection("Supplier_Outlet_Product")
                            .doc(this.state.company_id)
                            .collection("Outlet_Product");
                        for (let i = 0; i < AddOutlets.length; i++) {
                            supplierOutletProductRef
                                .doc(AddOutlets[i].outlet_id)
                                .set({
                                    platform: PLATFORM,
                                    supplier_id: this.props.id,
                                    outlet_id: AddOutlets[i].outlet_id,
                                    En_short_name: AddOutlets[i].En_short_name,
                                })
                                .then(() => {
                                    this.setState({
                                        supplierOutlets: [],
                                        allOutlets: [],
                                    });
                                });
                        }
                        for (let i = 0; i < DeleteOutlets.length; i++) {
                            //add outlet info. to Outlet_Product collection
                            supplierOutletProductRef
                                .doc(DeleteOutlets[i].outlet_id)
                                .delete()
                                .catch((error) => {
                                    console.error(error);
                                    this.showDialogMsg(
                                        "Alert",
                                        "Something Went wrong, try to refresh again.",
                                        this.hideDialogMsg
                                    );
                                });
                        }
                    })
                    .then(() => {
                        this.showDialogMsg("Message", "Added successfully", () => {
                            this.hideDialogMsg();
                            this.goToSupplierDashboardPage(PATHS.DASHBOARD);
                        });
                    })

                    .catch((error) => {
                        console.error(error);
                        this.showDialogMsg("Alert", "Something Went wrong, try to refresh again.", this.hideDialogMsg);
                    });
            }
        }
    };
    //==================================================================================

    not = (a, b) => {
        return a.filter((value) => b.indexOf(value) === -1);
    };

    intersection = (a, b) => {
        return a.filter((value) => b.indexOf(value) !== -1);
    };

    union = (a, b) => {
        return [...a, ...not(b, a)];
    };

    goToSupplierDashboardPage = (path) => {
        this.setState({ pathBack: path });
    };
    //==================================================================================

    render() {
        const { classes } = this.props;
        if (this.state.pathBack.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathBack,
                    }}
                />
            );
        } else {
            const left = [];

            for (let i = 0; i < this.state.left.length; i++) {
                const index = this.state.right.findIndex((post) => {
                    return post.outlet_id === this.state.left[i].outlet_id;
                });

                if (index === -1) {
                    left.push(this.state.left[i]);
                }
            }

            const right = this.state.right;

            if (this.state.checkLeft && this.state.checkRight) {
                return (
                    <div
                        /*style={{backgroundColor:"#262626", margin:"0px", padding:"0px", height:'100vh'}}*/ className={
                            classes.pageHeader
                        }
                    >
                        <div>
                            <div className={classNames(classes.container)}>
                                <div style={{ width: "100%", position: "relative" }}>
                                    <div
                                        className={`${classes.backdrop} ${classes.absolutePosition} ${
                                            this.state.loading ? null : classes.loadingHidden
                                        }`}
                                    ></div>
                                    <div
                                        className={`${classes.loaderContainer} ${classes.absolutePosition} ${
                                            this.state.loading ? null : classes.loadingHidden
                                        }`}
                                    >
                                        <CircularProgress />
                                    </div>
                                    <Box pt={4} pb={8}>
                                        <Grid>
                                            <Card pt={20} color="primary">
                                                <div
                                                    style={{
                                                        width: "95%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        marginLeft: "25px",
                                                    }}
                                                >
                                                    <div className={classes.title} style={{ width: "3%" }}>
                                                        <div
                                                            style={{
                                                                fontWeight: "700",
                                                                // backgroundColor: "#91041C",
                                                                color: "#EEEEEE",
                                                                height: "30px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "2px solid #575757",
                                                                // borderRightColor: "#91041C",
                                                                // borderBottomColor: "#91041C",
                                                                backgroundColor: COLORS.primary,
                                                                borderRightColor: COLORS.primary,
                                                                borderBottomColor: COLORS.primary,
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div
                                                        className={classes.title}
                                                        style={{ width: "96.7%", marginLeft: "2px" }}
                                                    >
                                                        <h4
                                                            style={{
                                                                justifyContent: "flex-start",
                                                                fontWeight: "700",
                                                                // backgroundColor: "#EEEEEE",
                                                                // color: "#91041C",
                                                                backgroundColor: COLORS.background,
                                                                color: COLORS.primary,
                                                                height: "30px",
                                                                paddingLeft: "10px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "1px solid #000000",
                                                            }}
                                                        >
                                                            {" "}
                                                            Select outlet companies that you want to deal with them.
                                                        </h4>
                                                    </div>
                                                </div>
                                                <Grid container justifyContent="center">
                                                    <GridItem xs={12} sm={12} md={10}>
                                                        <TransferList
                                                            left={left}
                                                            right={right}
                                                            handleAddOutletsMethod={this.handleAddOutlets}
                                                            goToSupplierDashboardPageMethod={
                                                                this.goToSupplierDashboardPage
                                                            }
                                                        />
                                                    </GridItem>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                        </div>

                        <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                            <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.state.dialogCloseHandler}
                                    style={{ backgroundColor: COLORS.accent }}
                                >
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                );
            } else {
                return (
                    <div
                        /*style={{backgroundColor:"#262626", margin:"0px", padding:"0px", height:'100vh'}}*/ className={
                            classes.pageHeader
                        }
                    >
                        <div>
                            <div className={classNames(classes.container)}>
                                <div style={{ width: "100%", position: "relative", marginTop: "40px" }}>
                                    <div
                                        className={`${classes.backdrop} ${classes.absolutePosition} ${
                                            this.state.loading ? null : classes.loadingHidden
                                        }`}
                                    ></div>
                                    <div
                                        className={`${classes.loaderContainer} ${classes.absolutePosition} ${
                                            this.state.loading ? null : classes.loadingHidden
                                        }`}
                                    >
                                        <CircularProgress />
                                    </div>
                                    {/* <Grid item xs={12}>
                                            <p></p>
                                            <Paper className={classes.paper}> </Paper>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <p></p>
                                            <Paper className={classes.paper}> </Paper>
                                        </Grid> */}
                                    {/* <div align="center" justify="center">
                                            <CircularProgress style={{ marginTop: "20px" }} className={classes.progress} color="#91041C" />
                                        </div> */}
                                </div>
                            </div>
                        </div>
                        <div>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                        </div>

                        <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                            <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                            <DialogContent>
                                <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.state.dialogCloseHandler}
                                    style={{ backgroundColor: COLORS.accent }}
                                >
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                );
            }
        }
    }
}
OutletSupplier.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(componentsStyle)(OutletSupplier);

/*
<Button
            style={{marginTop:'15px', marginLeft:'10px'}}
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAddOutletsMethod(right)}
          >
            Submit
          </Button>
*/
/*
var outletsRef = db.collection('Supplier_Outlet_Product').doc(this.props.id).collection('Outlet_Product');
    outletsRef.get()
    .then(snapshot => {
        snapshot.forEach(doc => {
            var outletsRef = db.collection('Outlet').where('outlet_id','==',doc.data().outlet_id);
            outletsRef.get()
            .then(snapshot2 => {
              snapshot2.forEach(doc2 => {
                datat4.push({outlet_id:doc.data().outlet_id, En_short_name:  doc2.data().En_short_name});

              })
            })


        });
        this.setState({ supplierOutlets: datat4 });

    })
*/
