import { PROJECT_TYPES, WEEKDAYS_IDS } from "../constants/global";
import { dateRangeOverlaps } from "./date-utils";

export let calculateWeeklyVisits = (merchDays) => {
    let totalVisits = 0;
    for (const day in merchDays) {
        if (Object.hasOwnProperty.call(merchDays, day)) {
            totalVisits += merchDays[day].selected ? 1 : 0;
        }
    }

    return totalVisits;
};

export const stringifyProductPacking = (product) => {
    return `${product.outer_unit <= 1 ? "" : product.outer_unit + "x"}${
        product.packing.no_units <= 1 ? "" : product.packing.no_units + "x"
    }${product.packing.size}${product.packing.size_unit}`;
};

// //filter seasons that fits inside updated project's date range
// export const getActiveSeasons = (seasons, startDate, endDate) => {
//     return seasons.filter((s) => dateRangeOverlaps(s.start_date, s.end_date, startDate, endDate));
// };

// // filter schedule's normal prices by selected merchandising days and active seasons during project's period
// export const getActiveScheduleNormalPrices = (schedule, activeSeasons) => {
//     const selectedWeekDaysFullNames = getWorkingDaysIDs(schedule.merchandising_days);
//     // const project_type = schedule.project_type;
//     const project_type = PROJECT_TYPES.SUPPLIER_OUTSOURCE;
//     const class_id = schedule.class_id;

//     //* update price list of of the outlet branch
//     //seasonal
//     let seasonalPrices = {};
//     for (const season of activeSeasons) {
//         const priceID = `${project_type}-${class_id}-${season.id}`;
//         let seasonalPrice = schedule.normal_prices.seasonal[priceID] || null;
//         if (seasonalPrice) seasonalPrices[priceID] = seasonalPrice;
//     }

//     //weekend
//     let weekendPrices = {};
//     for (const dayName of selectedWeekDaysFullNames) {
//         const priceID = `${project_type}-${class_id}-${dayName}`;
//         let weekendPrice = schedule.normal_prices.weekend[priceID] || null;
//         if (weekendPrice) weekendPrices[priceID] = weekendPrice;
//     }

//     //regular
//     let regularPrice = schedule.normal_prices.regular;

//     return {
//         regular: { ...regularPrice },
//         seasonal: seasonalPrices,
//         weekend: weekendPrices,
//     };
// };

// //filter schedule.extra_display_prices by active seasons and active contracts
// export const getActiveScheduleExtraDisplayPrices = (schedule, activeSeasons, activeContracts) => {
//     const workingDaysIDs = getWorkingDaysIDs(schedule.merchandising_days);

//     // init extra display contract prices
//     // item[type_id] = {regular: null, seasonal: {}, weekend: {}}
//     let extraDisplayPrices = {};
//     for (const contract of activeContracts) {
//         //find prices for each type
//         // for (const type of contrPrice.types) {
//         for (let index = 0; index < contrPrice.types.length; index++) {
//             let type = contrPrice.types[index];
//             //seasonal
//             let seasonalEDPrices = [];
//             for (const season of activeSeasons) {
//                 const priceID = `${type.id}-${season.id}`;
//                 let seasonalEDPrice = extraDisplayPrices.seasonalEDPrices.find((sp) => sp.id === priceID);
//                 if (seasonalEDPrice) seasonalEDPrices.push(seasonalEDPrice);
//             }
//             //weekend
//             let weekendEDPrices = [];
//             for (const dayID of workingDaysIDs) {
//                 const priceID = `${type.id}-${dayID}`;
//                 let weekendEDPrice = extraDisplayPrices.weekendEDPrices.find((wp) => wp.id === priceID);
//                 if (weekendEDPrice) weekendEDPrices.push(weekendEDPrice);
//             }

//             //regular
//             let regularEDPrice = extraDisplayPrices.regularEDPrices.find(
//                 (price) => price.extra_display_price_id === type.id
//             );

//             contrPrice.types[index] = {
//                 ...type,
//                 regular: { ...regularEDPrice },
//                 seasonal: seasonalEDPrices,
//                 weekend: weekendEDPrices,
//             };
//         }
//         extraDisplayContractPrices.push(contrPrice);
//     }

//     return extraDisplayContractPrices;
// };

// export let getWorkingDaysIDs = (merchandising_days) => {
//     const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
//     let workingDaysIDs = [];
//     if (merchandising_days.sun.selected) {
//         workingDaysIDs.push(WEEKDAYS_IDS[weekday[0]]);
//     }
//     if (merchandising_days.mon.selected) {
//         workingDaysIDs.push(WEEKDAYS_IDS[weekday[1]]);
//     }
//     if (merchandising_days.tue.selected) {
//         workingDaysIDs.push(WEEKDAYS_IDS[weekday[2]]);
//     }
//     if (merchandising_days.wed.selected) {
//         workingDaysIDs.push(WEEKDAYS_IDS[weekday[3]]);
//     }
//     if (merchandising_days.thu.selected) {
//         workingDaysIDs.push(WEEKDAYS_IDS[weekday[4]]);
//     }
//     if (merchandising_days.fri.selected) {
//         workingDaysIDs.push(WEEKDAYS_IDS[weekday[5]]);
//     }
//     if (merchandising_days.sat.selected) {
//         workingDaysIDs.push(WEEKDAYS_IDS[weekday[6]]);
//     }

//     return workingDaysIDs;
// };
