import { flatten } from "lodash/fp";
import { PLATFORM } from "../../constants/global";
import { arrayChunks } from "../../helpers/array-helpers";
import { firestore as db } from "../firebase";
const MEC = "Merchandiser_Exclusivity_Contract";

export const queryMerchandiserExclusivityContracts = async (options) => {
    try {
        let ref = db.collection(MEC);
        if (options.docID) {
            return await ref.doc(options.docID).get();
        }

        //check for in operator
        let inQuery = options.filter((op) => op?.operator === "in");
        if (inQuery.length > 1) throw new Error("There are more than 1 in operator");

        for (const option of options) {
            const { key, value = null, operator = "==" } = option;
            if (operator === "in") continue;

            const firestoreKey = firestoreKeyMap(key);
            if (firestoreKey) ref = ref.where(firestoreKey, operator, value);
        }

        if (inQuery.length === 1) {
            const { key, value = null, operator = "==" } = inQuery[0];

            const firestoreKey = firestoreKeyMap(key);
            if (!firestoreKey) return (await ref.get()).docs;

            //inQuery value should an array
            const chunks = arrayChunks(value, 10);
            const res = await Promise.all(
                chunks.map(async (chunk) => {
                    return (await ref.where(firestoreKey, operator, chunk).get()).docs;
                })
            );

            //flatten
            return flatten(res);
        } else {
            return (await ref.get()).docs;
        }
    } catch (error) {
        throw error;
    }
};

export const addMerchandiserExclusivityContract = async (data) => {
    try {
        const ref = db.collection(MEC).doc();
        await ref.set({ platform: PLATFORM, ...data, id: ref.id });
        return ref;
    } catch (error) {
        throw error;
    }
};

export const setMerchandiserExclusivityContract = async (docID, data) => {
    try {
        let ref = db.collection(MEC).doc(docID);
        return await ref.set({ platform: PLATFORM, ...data });
    } catch (error) {
        throw error;
    }
};

export const updateMerchandiserExclusivityContract = async (docID, data) => {
    try {
        let ref = db.collection(MEC).doc(docID);
        return await ref.update(data);
    } catch (error) {
        throw error;
    }
};

const firestoreKeyMap = (key) => {
    switch (key) {
        case "id":
            return "id";
        case "name":
            return "name";
        case "number":
            return "number";
        case "start_date":
            return "start_date";
        case "end_date":
            return "end_date";
        case "supplier_id":
            return "supplier_id";
        case "mp_id":
            return "mp_id";

        default:
            return null;
    }
};
