import { COLLECTIONS } from "../constants";
import { query } from "../helpers";
import { queryProjectOutletBranch } from "./Project_Outlet_Branch";

export const queryProjectSchedule = async (projectID, supplierBranchID, outletBranchID, options) => {
    try {
        const projectOutletBranch = await queryProjectOutletBranch(projectID, supplierBranchID, {
            docID: outletBranchID,
        });

        return query(COLLECTIONS.PROJECT_SCHEDULE, options, projectOutletBranch.ref);
    } catch (error) {
        throw error;
    }
};
