import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

export const queryCompetitorBrands = async (q) => {
    try {
        return await query(COLLECTIONS.COMPETITOR_BRANDS, q);
    } catch (error) {
        throw error;
    }
};

export const setCompetitorBrand = async (data) => {
    try {
        const doc = db.collection(COLLECTIONS.COMPETITOR_BRANDS).doc();
        await doc.set({
            ...data,
            id: doc.id,
            status: "1",
        });
    } catch (error) {
        throw error;
    }
};

export const updateCompetitorBrand = async (id, data) => {
    try {
        const doc = db.collection(COLLECTIONS.COMPETITOR_BRANDS).doc(id);
        await doc.update({
            ...data,
        });
    } catch (error) {
        throw error;
    }
};
