import React, { useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { useCallback } from "react";

import EnhancedTable from "../../../components-lagacy/Table/EnhancedTable";
import { DashboardCard } from "../../../core-ui/custom";
import { filterDuplicates } from "../../../helpers/array-helpers";
import { formatDate } from "../../../helpers/date-utils";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import { memo } from "react";
import PATHS from "../../../constants/paths";

const TABLE_HEADERS = [
    { id: "id", label: "No.", hidden: true },
    { id: "outletBranchName", label: "Outlet Branch", textEllepsis: true },
    { id: "productName", label: "Product", textEllepsis: true },
    { id: "date", label: "Date" },
];

const OutOfStockTable = ({ tasks, taskDetails, outletBranches, loading = false, filterValues = {} }) => {
    const classes = styles();
    const [rowsData, setRowsData] = React.useState([]);

    const init = useCallback(async () => {
        try {
            //reset
            setRowsData([]);
            setup(tasks, taskDetails, outletBranches);
        } catch (error) {
            console.log(error);
        }
    }, [tasks, taskDetails, outletBranches]);

    const setup = (tasks, taskDetails, outletBranches) => {
        //compute all these products to get chart data
        let chartData = taskDetails
            .map((td) => {
                //get task info
                // const task = tasks.find((task) => `${task.task_id}` === `${td.task_id}`);
                const task = td.task;
                //get outlet branch name
                // const outletBranchName = outletBranches.find(
                //     (branch) => `${task.outlet_branch_id}` === `${branch.branch_id}`
                // ).En_short_name;
                const outletBranchName = task.outlet_branch.En_short_name;

                //determine if the product is out of stock
                const isOutOfStock = td.quantity_on + td.quantity_added === 0;

                return {
                    id: td.id,
                    productID: td.product_id,
                    outletBranchID: task.outlet_branch_id,
                    productName: td.product_name?.en,
                    outletBranchName,
                    isOutOfStock,
                    date: formatDate(task.date_time_from.toDate()),
                };
            })
            .filter((td) => td.isOutOfStock);

        //filter out duplicates by product id and outlet branch id
        chartData = filterDuplicates(chartData, ["productID", "outletBranchID"]);

        setRowsData(chartData);
    };

    const generateQueryParams = (values) => {
        const { date, supplierBranch, project, outlet, outletBranch, city } = values;
        const urlParams = new URLSearchParams({});

        if (date) {
            urlParams.append("from", formatDate(date.startDate, "-", true));
            urlParams.append("to", formatDate(date.endDate, "-", true));
        }
        if (supplierBranch) urlParams.append("supplier_branch", supplierBranch.value);
        if (project) urlParams.append("project", project.value);
        if (outlet) urlParams.append("outlet", outlet.value);
        if (outletBranch) urlParams.append("outlet_branch", outletBranch.value);
        if (city) urlParams.append("city", city.value);

        return urlParams;
    };

    useEffect(() => {
        init();
    }, [init]);

    return (
        <DashboardCard
            title={`Out Of Stock (${rowsData.length})`}
            type="table"
            classes={{
                cardContainer: classes.cardContainer,
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
                cardActionArea: classes.cardActionArea,
            }}
            footerComponent={
                <Link
                    className={classes.link}
                    to={{
                        pathname: PATHS.OUT_OF_STOCK_REPORT,
                        search: generateQueryParams(filterValues).toString(),
                    }}
                >
                    {`View Details >`}
                </Link>
            }
        >
            <div className={classes.tableWrapper}>
                <EnhancedTable
                    columns={TABLE_HEADERS}
                    rows={rowsData}
                    actions={[]}
                    updateSelected={() => {}}
                    loading={loading}
                    selectionDisabled
                    toolbarDisabled
                    paginationDisabled
                    emptyMessage="No Out of Stock Products"
                    stickyHeader={true}
                />
            </div>
        </DashboardCard>
    );
};

OutOfStockTable.propTypes = {
    classes: PropTypes.object,
};

export default memo(OutOfStockTable, isEqual);
