import React, { useEffect, useRef, useState } from "react";
import styles from "./styles";
import NAV_MENU from "../../../constants/navigation-menu";
import NavigationMenuItem from "../navigation-menu-item";
import { useAuth } from "../../../contexts/auth-context";

const NavigationMenu = (props) => {
    const classes = styles();
    const { userData } = useAuth();
    const { onItemClick } = props;
    const [activeMenuPath, setActiveMenuPath] = useState([]);
    const navRef = useRef();

    const handleNavItemClick = (level, index) => {
        if (activeMenuPath[level] === index) {
            setActiveMenuPath((prevState) => {
                let newPath = [...prevState];
                const num = newPath.length - level;
                newPath.splice(level, num);
                return newPath;
            });
        } else if (activeMenuPath.length > level) {
            setActiveMenuPath((prevState) => {
                let newPath = [...prevState];
                const num = newPath.length - level;
                newPath.splice(level, num);
                newPath.push(index);
                return newPath;
            });
        } else if (activeMenuPath.length !== level) {
            setActiveMenuPath([]);
        } else {
            setActiveMenuPath((prevState) => {
                let newPath = [...prevState];
                newPath.push(index);
                return newPath;
            });
        }
    };

    const handleOutsideNavClick = (e) => {
        if (navRef && !navRef.current.contains(e.target)) {
            setActiveMenuPath([]);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideNavClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideNavClick);
        };
    }, []);

    useEffect(() => {
        if (activeMenuPath.length > 0) onItemClick(true);
        else onItemClick(false);
    }, [activeMenuPath, onItemClick]);

    return (
        <nav className={classes.headerMenu} ref={navRef}>
            <ul className={classes.mainMenu}>
                {NAV_MENU.map((navItem, index) => {
                    if (!navItem.access[userData.user_type]) return null;
                    return (
                        <NavigationMenuItem
                            key={`main-nav-item:${navItem.label}`}
                            label={navItem.label}
                            menu={navItem.menu}
                            link={navItem.link}
                            level={0}
                            activeMenuPath={activeMenuPath}
                            index={index}
                            onClick={handleNavItemClick}
                        />
                    );
                })}
            </ul>
        </nav>
    );
};

export default NavigationMenu;
