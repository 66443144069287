import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./styles";
import LogoHeader from "../../core-ui/custom/logo-header";
import Title from "../../core-ui/custom/title";

import { LoadingOverlay } from "../../core-ui/custom";
import { useAuth } from "../../contexts/auth-context";
import PATHS from "../../constants/paths";

const VerifyEmail = () => {
    const classes = styles();
    const history = useHistory();
    const [codeAuthenticating, setCodeAuthenticating] = useState(true);
    const [isVerified, setIsVerified] = useState(false);
    const { verifyActionCode, currentUser } = useAuth();

    const verifyCode = useCallback(
        async (code) => {
            try {
                await verifyActionCode(code, "verify-email");
                if (currentUser) await currentUser.reload();
                setCodeAuthenticating(false);
                setIsVerified(true);
            } catch (error) {
                console.log(error);
                setIsVerified(false);
                setCodeAuthenticating(false);
            }
        },
        [verifyActionCode, history]
    );

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        verifyCode(searchParams.get("oobCode"));
    }, []);

    if (codeAuthenticating) {
        return <LoadingOverlay />;
    }

    return (
        <section className={classes.signinPage}>
            <LogoHeader />

            <section className={classes.container}>
                <div className={classes.formSection}>
                    <Title>Account Verification</Title>
                    {isVerified && (
                        <p>
                            Your Account is Verified. <Link to={PATHS.SIGN_IN}>Continue</Link>
                        </p>
                    )}
                    {!isVerified && <p>Verification is expired or invalid. Please Verify your email address again.</p>}
                </div>
            </section>
        </section>
    );
};

export default VerifyEmail;
