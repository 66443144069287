import React from "react";

//related to meterial ui package
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { COLORS } from "../../constants/constants-lagacy";

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        "aria-controls": `scrollable-force-tabpanel-${index}`,
    };
}
const useStyles = makeStyles({
    root: {
        margin: "15px 0",
    },

    appBar: {
        backgroundColor: "#fff",
    },

    tabIndicator: {
        backgroundColor: COLORS.primary,
    },
    tabRoot: {
        color: "#151515",
        flex: 1,
    },
    tabSelected: {
        color: COLORS.primary,
    },
});

let ProjectTabs = (props) => {
    const classes = useStyles(props);
    const { onTabSelect, tabIndex, tabs } = props;
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Tabs
                    TabIndicatorProps={{
                        className: classes.tabIndicator,
                    }}
                    value={tabIndex}
                    onChange={onTabSelect}
                >
                    {tabs.map((tab, index) => {
                        return (
                            <Tab
                                key={`${tab}-${index}-project-tab`}
                                label={tab}
                                {...a11yProps(0)}
                                classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            />
                        );
                    })}
                </Tabs>
            </AppBar>
        </div>
    );
};

export default ProjectTabs;
