import { flatten } from "lodash";
import React, { Fragment, useEffect, useReducer, useState } from "react";
import { Filters } from "../../components";
import { useAuth } from "../../contexts/auth-context";
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import { CircularLoader, Header } from "../../core-ui/custom";
import { formatDate, getToday } from "../../helpers/date-utils";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { getLinkedOutletBranchesBySupplierID } from "../../services/firestore/Branch_Outlet_Branch";
import { getAllCities } from "../../services/firestore/City";
import { getSupplierMerchandisers } from "../../services/firestore/Merchandiser";
import {
    getOutletBranches,
    getOutletBranchesByCompany,
    getOutletBranchesFromTasks,
} from "../../services/firestore/Outlet_Branch";
import { getProducts, getSupplierProducts } from "../../services/firestore/Product";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";
import { queryTasks_V2 } from "../../services/firestore/Task";
import { getLinkedOutlets } from "../../services/firestore/Supplier_Outlet_Product";
import { queryTaskDetails } from "../../services/firestore/Task_Details";
import { getMerchandiserName } from "../../helpers/merchandiser-utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Button, Tooltip } from "@material-ui/core";
import { TASKS_STATUS } from "../../constants/constants-lagacy";
import DamageAndExpiryReportPdf from "../../components-lagacy/PDF/DamageAndExpiryReport";
import { generatePDFinNewTab } from "../../helpers/pdf-utils";

//related to meterial ui package
import styles from "./styles";
import { getLinkedSuppliers } from "../../services/firestore/MP_Supplier_Link";
import { getAllOutlets } from "../../services/firestore/Outlet";
import { stringifyProductPacking } from "../../helpers/misc";
import EnhancedTable from "../../components-lagacy/Table/EnhancedTable";
import { getEDRegularPrices } from "../../services/firestore/Extra_Display_Price";

const INITIAL_FILTERS = {
    startDate: {
        label: "Start Date",
        component: "date",
        initialValue: getToday(),
    },
    endDate: {
        label: "End Date",
        component: "date",
        initialValue: getToday(),
    },
    cities: {
        label: "City",
        options: [],
        initialValue: null,
    },
    products: {
        label: "Products",
        options: [],
        initialValue: null,
    },

    outlets: {
        label: "Outlet",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    outletBranches: {
        label: "Outlet Branch",
        options: [],
        initialValue: null,
    },
};

const dataSourcesReducer = (state, { type, payload }) => {
    switch (type) {
        case "MULTI_SET":
            return {
                ...state,
                ...payload,
            };
        case "SET":
            return {
                ...state,
                [payload.key]: payload.value,
            };
        case "RESET":
            return {
                ...state,
                [payload.key]: payload.initialValue,
            };
        default:
            return state;
    }
};

const initialState = {
    cities: [],
    // merchandisers: [],
    outlets: [],
    supplierBranches: [],
    products: [],
    outletBranches: [],
};

const TABLE_HEADERS = [
    { id: "id", label: "No.", hidden: true },
    { id: "outlet", label: "Outlet", textEllepsis: true },
    { id: "outletBranch", label: "Outlet Branch", textEllepsis: true },
    { id: "city", label: "City" },
    { id: "product", label: "Product", textEllepsis: true },
    { id: "date", label: "Date", disablePadding: true, style: { maxWidth: 30 } },
    { id: "shelf", label: "Shelf", disablePadding: true, style: { maxWidth: 30, textAlign: "center" } },
    {
        id: "extraDisplays",
        label: "Extra Displays",
        disablePadding: true,
        style: { maxWidth: 50, textAlign: "center" },
    },
    { id: "warehouse", label: "Warehouse", disablePadding: true, style: { maxWidth: 41, textAlign: "center" } },
];

const OutOfStockReport = (props) => {
    const classes = styles();
    const { companyData } = useAuth();
    // states
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dataSources, dispatchDataSources] = useReducer(dataSourcesReducer, initialState);
    const [tasks, setMainTasks] = useState([]);
    const [extraDisplayTypes, setExtraDisplayTypes] = useState([]);
    const [startDate, setStartDate] = useState(getToday());
    const [endDate, setEndDate] = useState(getToday());
    const [isClicked, setIsClicked] = useState(false);
    const [pdfRows, setPdfRows] = useState([]);
    let counter = 0;

    const init = async () => {
        try {
            const { company_id } = companyData;
            // load filters options
            const [products, cities, outlets, extraDisplayTypes] = (
                await Promise.all([
                    getSupplierProducts(company_id),
                    getAllCities(),
                    getAllOutlets(company_id),
                    getEDRegularPrices(),
                ])
            ).map((docs) => docs.map((doc) => doc.data()));

            setExtraDisplayTypes(extraDisplayTypes);

            dispatchDataSources({
                type: "MULTI_SET",
                payload: {
                    products: products.map((p) => ({ value: `${p.product_id}`, label: p.En_name, data: p })),
                    cities: cities.map((c) => ({ value: `${c.city_id}`, label: c.En_name, data: c })),
                    outlets: outlets.map((o) => ({ value: o.outlet_id, label: o.En_short_name, data: o })),
                },
            });
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    };

    const initFilters = (context) => {
        INITIAL_FILTERS.products.options = dataSources.products;
        INITIAL_FILTERS.cities.options = dataSources.cities;
        INITIAL_FILTERS.outlets.options = dataSources.outlets;

        INITIAL_FILTERS.outlets.onChange = handleOutletsChange;

        context.setFilters(INITIAL_FILTERS);
    };

    const handleOutletsChange = async (option, context) => {
        try {
            if (!option) {
                dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: [] } });
                context.dispatchFilters({
                    type: "CLEAR_FILTER",
                    payload: { id: "outletBranches" },
                });
            }

            let outletBranches = (await getOutletBranchesByCompany(option.value)).map((doc) => doc.data());

            outletBranches = outletBranches.map((ob) => ({ value: ob.branch_id, label: ob.En_short_name }));

            dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: outletBranches } });
            context.dispatchFilters({
                type: "SET_FILTER_OPTIONS",
                payload: { id: "outletBranches", options: outletBranches },
            });
        } catch (error) {
            throw error;
        }
    };

    const handleDamageAndExpiryReportPdf = () => {
        generatePDFinNewTab(
            <DamageAndExpiryReportPdf
                info={{
                    companyData: companyData,
                    pdfRows: pdfRows,
                    startDate: startDate,
                    endDate: endDate,
                }}
            />,
            `Damage and Expiry Report`
        );
    };
    const handleDamageAndExpiryReportExcel = () => {};

    const search = async (values) => {
        setPdfRows([]);
        try {
            setLoading(true);
            const { startDate, endDate, cities, products, outlets, outletBranches } = values;
            const startofDate = new Date(startDate);
            const endofDate = new Date(endDate);
            startofDate.setHours(0, 0, 0, 0);
            endofDate.setHours(23, 59, 59, 999);

            const taskQuery = [
                { key: "date_time_from", operator: ">=", value: startofDate },
                { key: "date_time_from", operator: "<=", value: endofDate },
                { key: "supplier_id", operator: "==", value: Number(companyData.company_id) },
                { key: "state", operator: "in", value: [TASKS_STATUS.UNDER_PROCESS, TASKS_STATUS.FINISHED] },
            ];

            if (cities?.value) taskQuery.push({ key: "city_id", operator: "==", value: cities.value });

            if (outlets?.value) taskQuery.push({ key: "outlet_id", operator: "==", value: Number(outlets.value) });
            if (outletBranches?.value)
                taskQuery.push({ key: "outlet_branch_id", operator: "==", value: outletBranches.value });

            let tasks = (await queryTasks_V2(taskQuery)).map((doc) => doc.data());

            //get task details for each task
            tasks = await Promise.all(
                tasks.map(async (task) => {
                    const query = [
                        {
                            key: "task_id",
                            operator: "==",
                            value: task.task_id,
                        },
                    ];

                    let taskDetails = (await queryTaskDetails(query)).map((doc) => doc.data());
                    task.taskDetails = taskDetails;
                    return task;
                })
            );

            //unique outlet branch ids
            let outletBranchIds = tasks.map((task) => `${task.outlet_branch_id}`);
            outletBranchIds = [...new Set(outletBranchIds)];
            const outletBranchesList = (await getOutletBranches(outletBranchIds)).map((doc) => doc.data());

            outletBranchesList.forEach((ob) => {
                ob.outlet = dataSources.outlets.find((o) => `${o.data.outlet_id}` === `${ob.outlet_id}`)?.data;
                ob.city = dataSources.cities.find((c) => `${c.data.city_id}` === `${ob.city_id}`)?.data;
            });

            //unique product ids
            let allTaskDetails = tasks.reduce((prev, curr) => {
                prev = [...prev, ...curr.taskDetails];
                return prev;
            }, []);
            let productIds = allTaskDetails.map((taskDetail) => taskDetail.product_id);
            productIds = [...new Set(productIds)];
            //filter products
            if (products?.value) productIds = productIds.filter((id) => id === products.value);
            const tasksProducts = (await getProducts(productIds)).map((doc) => doc.data());

            //assign product info and outlet branch info into their appropriate place
            tasks.forEach((task) => {
                //outlet branch
                const outletBranch = outletBranchesList.find((ob) => ob.branch_id === `${task.outlet_branch_id}`);
                task.outletBranch = outletBranch;

                //products
                task.taskDetails.forEach((td) => {
                    const product = tasksProducts.find((prod) => prod.product_id === td.product_id);
                    td.product = product;
                });
            });
            let supplierProducts = dataSources.products.map((p) => p.data);

            // each row is a 1 product, 1 outlet branch, multiple tasks and displays
            let rows = [];
            // tasks.forEach((task) => {
            //     const outletBranchName = task.outletBranch.En_short_name;

            //     let outOfStock = task.taskDetails.filter((td) => {
            //         //determine if the product is out of stock
            //         return td.quantity_on + td.quantity_added === 0;
            //     });

            //     //map taskDetails array to rows data
            //     outOfStock = outOfStock.map((td) => {
            //         return {
            //             id: td.id,
            //             data: td,
            //             productID: td.product_id,
            //             outletBranchID: task.outlet_branch_id,
            //             productName: td.product_name?.en,
            //             outletBranchName,
            //             date: formatDate(task.date_time_from.toDate()),
            //         };
            //     });
            // });

            // rows = rows.filter((r) => r.damageQty != 0 || r.expiredQty != 0 || r.nearExpiryQty != 0);

            const productStats = supplierProducts.reduce((prev, product) => {
                if (!prev[product.product_id]) {
                    prev[product.product_id] = {
                        product,
                        outletBranches: {},

                        outOfStockShelf: {
                            outletIDs: [],
                            outletBranchIDs: [],
                            numberOfDisplays: 0,
                        },
                        outOfStockExtraDisplay: {
                            outletIDs: [],
                            outletBranchIDs: [],
                            numberOfDisplays: 0,
                        },
                        outOfStockWarehouse: {
                            outletIDs: [],
                            outletBranchIDs: [],
                        },
                    };
                }

                return prev;
            }, {});

            //! HEAVY CALCULATIONS
            //* assign product info to task details and perform calculations
            for (const task of tasks) {
                task.taskDetails.forEach((taskDetail) => {
                    const product = taskDetail.product;
                    taskDetail.product_name = {
                        en: product.En_name,
                        ar: product.Ar_name,
                    };

                    if (taskDetail.extra_display) {
                        taskDetail.extraDisplay = extraDisplayTypes.find(
                            (ex) => ex.extra_display_price_id === taskDetail.extra_display_type
                        );
                    }

                    //set task
                    taskDetail.task = task;
                    const dateStr = formatDate(taskDetail.task.date_time_from.toDate(), "-");

                    //set product stats
                    const stats = productStats[taskDetail.product_id];
                    let outletBranchStats = stats.outletBranches[`${taskDetail.task.outlet_branch_id}`];

                    if (!outletBranchStats) {
                        outletBranchStats = {
                            outletBranch: taskDetail.task.outletBranch,
                            displays: [taskDetail],
                            outOfStockShelf: {},
                            outOfStockExtraDisplay: {},
                            // by default is true unless ANY display has quantity added, it becomes false
                            outOfStockWarehouse: { [dateStr]: true },
                            dates: [dateStr],
                        };
                    } else {
                        outletBranchStats.displays.push(taskDetail);

                        //if it is a new date, add it as new entry as true by default
                        if (!outletBranchStats.dates.find((d) => d === dateStr)) {
                            outletBranchStats.outOfStockWarehouse[dateStr] = true;
                            outletBranchStats.dates.push(dateStr);
                        }
                    }

                    //if quantity added !== 0, that means there is a stock in the warehouse
                    //! BUT it does not indicate this could be the last quantity pulled from the warehouse
                    if (taskDetail.warehouse_draw === taskDetail.warehouse_actual_draw) {
                        outletBranchStats.outOfStockWarehouse[dateStr] = false;
                    }
                    //is out of stock
                    if (taskDetail.quantity_on + taskDetail.quantity_added === 0 && taskDetail.isEnded) {
                        //calculate stats
                        const displayType = taskDetail.extra_display ? "outOfStockExtraDisplay" : "outOfStockShelf";
                        if (taskDetail.extra_display) {
                            if (!outletBranchStats.outOfStockExtraDisplay[dateStr]) {
                                outletBranchStats.outOfStockExtraDisplay[dateStr] = [];
                            }
                            outletBranchStats.outOfStockExtraDisplay[dateStr].push(taskDetail);
                        } else {
                            if (!outletBranchStats.outOfStockShelf[dateStr]) {
                                outletBranchStats.outOfStockShelf[dateStr] = [];
                            }
                            outletBranchStats.outOfStockShelf[dateStr].push(taskDetail);
                        }

                        let outletIDs = productStats[taskDetail.product_id][displayType].outletIDs;
                        let outletBranchIDs = productStats[taskDetail.product_id][displayType].outletBranchIDs;
                        outletIDs.push(`${taskDetail.task.outlet_id}`);
                        outletBranchIDs.push(`${taskDetail.task.outlet_branch_id}`);

                        productStats[taskDetail.product_id][displayType].outletIDs = [...new Set(outletIDs)];
                        productStats[taskDetail.product_id][displayType].outletBranchIDs = [
                            ...new Set(outletBranchIDs),
                        ];
                        productStats[taskDetail.product_id][displayType].numberOfDisplays++;
                    }

                    productStats[taskDetail.product_id].outletBranches[`${taskDetail.task.outlet_branch_id}`] =
                        outletBranchStats;
                });
            }

            let outOfStockTableData = Object.values(productStats).reduce((prev, curr) => {
                let productRows = [];
                let outletBranches = Object.values(curr.outletBranches);
                for (const ob of outletBranches) {
                    for (const dateStr of ob.dates) {
                        const shelves = ob.outOfStockShelf[dateStr] ?? [];
                        const extraDisplays = ob.outOfStockExtraDisplay[dateStr] ?? [];
                        const extraDisplaysStr = extraDisplays.reduce((str, ex) => {
                            return `${str} - ${ex.extraDisplay.extra_display_type}`;
                        }, "");
                        const warehouse = ob.outOfStockWarehouse[dateStr];

                        // const totalShelves = ob.displays.filter(dis => formatDate(dis.task.date_time_from.toDate(), "-") === dateStr && !dis.extra_display);
                        const totalExtraDisplays = ob.displays.filter(
                            (dis) => formatDate(dis.task.date_time_from.toDate(), "-") === dateStr && dis.extra_display
                        ).length;

                        if (shelves.length <= 0 && extraDisplays.length <= 0 && !warehouse) {
                            continue;
                        }
                        //find displays on the same date
                        productRows.push({
                            id: `${curr.product.product_id}-${ob.outletBranch.branch_id}-${dateStr}`,
                            product: curr.product.En_name,
                            outlet: ob.outletBranch.outlet.En_short_name,
                            outletBranch: ob.outletBranch.En_short_name,
                            city: ob.outletBranch.city.En_name,
                            date: dateStr,
                            shelf: (
                                <div
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: shelves.length > 0 ? "red" : "transparent",
                                    }}
                                ></div>
                            ),
                            extraDisplays: (
                                <Tooltip title={`${extraDisplaysStr}`}>
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            color: "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor:
                                                extraDisplays.length === 0 || totalExtraDisplays === 0
                                                    ? "transparent"
                                                    : extraDisplays.length < totalExtraDisplays
                                                    ? "darkorange"
                                                    : "red",
                                        }}
                                    >
                                        {extraDisplays.length} / {totalExtraDisplays}
                                    </div>
                                </Tooltip>
                            ),
                            warehouse: (
                                <div
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: warehouse ? "red" : "transparent",
                                    }}
                                ></div>
                            ),
                        });
                    }
                }

                return [...prev, ...productRows];
            }, []);

            setPdfRows(outOfStockTableData);
            setMainTasks(tasks);
            setStartDate(startDate);
            setEndDate(endDate);

            setIsClicked(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <section className={classes.sectionContainer}>
            <Header className={classes.reportHeader}>
                <span className={classes.headerColor}>Out Of Stock Report</span>
            </Header>
            {!initializing && <Filters init={initFilters} onSubmit={search} />}
            {!isClicked && (initializing || loading) ? <CircularLoader className={classes.alignCenter} /> : null}
            {isClicked ? (
                <div className={classes.container}>
                    <div className={classes.legends}>
                        <span>Out Of stock in all displays</span>
                        <span>Out Of stock in some displays</span>
                    </div>
                    <div className={classes.tableWrapper}>
                        <EnhancedTable
                            columns={TABLE_HEADERS}
                            rows={pdfRows}
                            actions={[]}
                            updateSelected={() => {}}
                            loading={loading}
                            selectionDisabled
                            toolbarDisabled
                            // paginationDisabled
                            emptyMessage="No Out of Stock Products"
                            stickyHeader={true}
                        />
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </section>
    );
};

export default OutOfStockReport;
