import { cloneDeep } from "lodash";
import { useState } from "react";
import Swal from "sweetalert2";
import COLORS from "../constants/colors";
import { useAuth } from "../contexts/auth-context";
import {
    FORM_SCHEMA,
    INITIAL_VALUES,
    PACKING_UNITS,
    PERIOD_UNITS,
    STATUS,
    SIZE_UNITS,
    DISPLAY_UNITS,
} from "../pages/add-product/schema";
import { addProduct } from "../services/firestore/Product";
import useProduct from "./use-product";

const useAddProduct = () => {
    const { companyData } = useAuth();

    const [initValues, setInitValues] = useState(INITIAL_VALUES);

    const onAfterInit = (categories, subcategories, subsubcategories, brands) => {
        try {
            //select unclassified by default
            let category = categories.find((c) => c.value === "110");
            let subcategory = subcategories.find((sc) => sc.data.category_id === category.value);
            let subsubcategory = subsubcategories.find((ssc) => ssc.data.sub_category_id === subcategory.value);

            setInitValues((prev) => ({
                ...prev,
                product_category: {
                    category_id: category,
                    sub_category_id: subcategory,
                    sub_sub_category_id: subsubcategory,
                },
            }));
        } catch (error) {
            throw error;
        }
    };

    const {
        initializing,
        saving,
        setSaving,
        categoriesList,
        subcategoriesList,
        subsubcategoriesList,
        brandsList,
        UNCALSSIFIED_CATEGORY,
        UNCALSSIFIED_SUBCATEGORY,
        UNCALSSIFIED_SUB_SUBCATEGORY,
    } = useProduct({ onAfterInit });

    const castValues = (values) => {
        //get values from auto complete
        const newProduct = cloneDeep(FORM_SCHEMA.cast(values));
        // trim values
        newProduct.En_name = newProduct.En_name.trim();
        newProduct.Ar_name = newProduct.Ar_name.trim();
        newProduct.product_number = newProduct.product_number.trim();
        newProduct.barcode = newProduct.barcode.trim();
        newProduct.carton_barcode = newProduct.carton_barcode.trim();
        newProduct.product_description = newProduct.product_description.trim();

        newProduct.packing.packing = newProduct.packing.packing.value ?? PACKING_UNITS[0].value;
        newProduct.packing.size_unit = newProduct.packing.size_unit.value ?? SIZE_UNITS[0].value;
        newProduct.display_unit = newProduct.display_unit.value ?? DISPLAY_UNITS[0].value;
        newProduct.status = newProduct.status.value ?? STATUS[0].value;
        newProduct.brand_id = newProduct.brand_id?.value ?? null;
        newProduct.product_category.category_id =
            newProduct.product_category.category_id.value ??
            categoriesList.find((c) => c.value === UNCALSSIFIED_CATEGORY).value;
        newProduct.product_category.sub_category_id =
            newProduct.product_category.sub_category_id.value ??
            subcategoriesList.find((c) => c.value === UNCALSSIFIED_SUBCATEGORY).value;
        newProduct.product_category.sub_sub_category_id =
            newProduct.product_category.sub_sub_category_id.value ??
            subsubcategoriesList.find((c) => c.value === UNCALSSIFIED_SUB_SUBCATEGORY).value;

        //fmcg
        newProduct.expiration_period_unit = newProduct.expiration_period_unit.value ?? PERIOD_UNITS[0].value;
        newProduct.product_recall_period_unit = newProduct.product_recall_period_unit.value ?? PERIOD_UNITS[0].value;
        newProduct.shelf_life_unit = newProduct.shelf_life_unit.value ?? PERIOD_UNITS[0].value;

        //number to string
        newProduct.packing.size = `${newProduct.packing.size ?? 1}`;
        newProduct.weight = `${newProduct.weight ?? 1}`;
        newProduct.expiration_period = `${newProduct.expiration_period ?? 1}`;
        newProduct.product_recall_period = `${newProduct.product_recall_period ?? 1}`;
        newProduct.shelf_life = `${newProduct.shelf_life ?? 1}`;

        return newProduct;
    };

    const handleSubmit = async (values, helpers) => {
        try {
            setSaving(true);

            const newValues = castValues(values);

            const data = { ...newValues, supplier_id: companyData.company_id };
            const newPRoduct = await addProduct(data);

            const result = await Swal.fire({
                title: `A new product has been added!`,
                text: `Product ${newPRoduct.En_name} has been successfully added.`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
            });

            if (!result.value) {
                return false;
            } else {
                helpers.resetForm();
            }
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: error.message,
                icon: "error",
                confirmButtonText: "Ok",
            });
            console.log(error);
        }
        setSaving(false);
    };

    return {
        initValues,
        setInitValues,
        handleSubmit,
        initializing,
        saving,

        categoriesList,
        subcategoriesList,
        subsubcategoriesList,
        brandsList,
    };
};

export default useAddProduct;
