import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import "../../../core-ui/core-styles/pdf/fonts";
import { REPORT_IDS } from "../../../constants/constants-lagacy";
import styles from "./styles";
let rowsPerPage = 26;
let counter = 0;

let createRow = (merchandiser, style) => {
    if (!merchandiser)
        return (
            <View style={style} key={`PDF Row ${Math.random()}-${merchandiser.id}`}>
                <Text style={styles.lastCell}>Error getting the merchandiser's data</Text>
            </View>
        );
    counter++;

    return (
        <View style={style} key={`PDF Row ${Math.random()}-${merchandiser.id}`}>
            <Text style={styles.indexCell}>{counter}</Text>
            <Text style={styles.nameCell}>&nbsp;{merchandiser.en_name}</Text>
            <Text style={styles.nameCell}>&nbsp;{merchandiser.ar_name}</Text>
            <Text style={styles.cell}>{merchandiser.birth}</Text>
            <Text style={styles.cell}>{merchandiser.nationality}</Text>
            <Text style={styles.cell}>{merchandiser.gender}</Text>
            <Text style={styles.cell}>{merchandiser.phone}</Text>
            <Text style={styles.lastCell}>{merchandiser.city}</Text>
        </View>
    );
};

let createPage = (merchandisers, info, pageNum) => {
    //const rows = [...outlet.products];
    let rowsToDisplay = merchandisers.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;

    return (
        <Page size="A4" orientation="portrait" style={styles.page} key={`Branches List ${Math.random()}-${pageNum}`}>
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View>
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>Merchandisers List</Text>

            <View style={styles.content}>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        <View style={[styles.row, styles.headerRow]}>
                            {/* <View style={styles.rowGroupRow}>lastRowGroupRow */}
                            {/* <View style={styles.columnGroup}> */}
                            <View style={styles.indexHeader}>
                                <Text style={styles.headerCellText}></Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>En. Name</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Ar. Name</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Birth Date</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Nationality</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Gender</Text>
                            </View>
                            {/* <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>Status</Text>
                            </View> */}
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Phone</Text>
                            </View>
                            <View style={styles.lastHeaderCell}>
                                <Text style={styles.headerCellText}>City</Text>
                            </View>
                            {/* </View> */}
                            {/* </View> */}
                        </View>
                    </View>

                    {/* Table Body */}

                    <View style={styles.tableBody}>
                        {rowsToDisplay.map((merchandiser, index) => {
                            let style = styles.row;
                            if (index % 2 !== 0 && index === rowsToDisplay.length - 1) style = styles.lastRow;
                            else if (index % 2 === 0 && index !== rowsToDisplay.length - 1) style = styles.coloredRow;
                            else if (index % 2 === 0 && index === rowsToDisplay.length - 1)
                                style = styles.lastColoredRow;
                            return createRow(merchandiser, style);
                        })}
                    </View>
                </View>
            </View>
            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.idContainer}>
                <Text>{REPORT_IDS.MERCHANDISERS_LIST}</Text>
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <View style={styles.logoFooterFlex}>
                    <Text style={styles.logoFooterText}>Powered By:</Text>

                </View>
                <View style={styles.logoFooterFlex}>
                    <Image style={styles.logo} src={logo} />
                </View>


            </View>
        </Page>
    );
};

let createDoc = (info) => {
    const { merchandisers } = info;

    let pages = [];
    let merchandisersPerPage = Math.ceil(merchandisers.length / rowsPerPage);

    for (let pageNum = 0; pageNum < merchandisersPerPage; pageNum++) {
        pages[pageNum] = createPage(merchandisers, info, pageNum);
    }

    return pages;
};

// Create Document Component
const MerchandisersPdf = (props) => {
    let document = createDoc(props.info);
    return <Document>{document}</Document>;
};

export default MerchandisersPdf;
