import { PLATFORM } from "../../constants/global";
import { generateNewDocID } from "../../helpers/firebase-helpers";
import { firestore as db } from "../firebase";
import { uploadImage } from "../storage";
import { getMPLinks } from "./MP_Supplier_Link";

const MERCH_PROVIDER_COLLECTION = "MP";
const ACCOUNT_STATUS = {
    INACTIVE: "0",
    ACTIVE: "1",
};

export const getMP = async (id) => {
    try {
        return await db.collection(MERCH_PROVIDER_COLLECTION).doc(id).get();
    } catch (error) {
        throw error;
    }
};

export const addMerchProvider = async (data) => {
    try {
        const newID = await generateNewDocID(MERCH_PROVIDER_COLLECTION, "id");

        data.id = newID;
        data.company_id = newID;
        data.status = ACCOUNT_STATUS.ACTIVE;
        data.platform = PLATFORM;

        //upload files
        const logo = data.logo;
        const tax = data.commercial_register.tax_image;
        const cr = data.commercial_register.commercial_register_image;

        if (logo) {
            const filename = `logo.${logo.name.split(".").pop()}`;
            //upload and return a url
            data.logo = await uploadImage(`Merchandising_Provider/${newID}/`, logo, filename);
        }

        if (tax) {
            const filename = `vat.${tax.name.split(".").pop()}`;
            data.commercial_register.tax = await uploadImage(`Merchandising_Provider/${newID}/`, tax, filename);
        }

        if (cr) {
            const filename = `commercial-registry.${cr.name.split(".").pop()}`;
            data.commercial_register.cr = await uploadImage(`Merchandising_Provider/${newID}/`, cr, filename);
        }

        await db.collection(MERCH_PROVIDER_COLLECTION).doc(newID).set(data);

        return data;
    } catch (error) {
        throw error;
    }
};

export const getMerchProvider = async (id) => {
    try {
        return await db.collection(MERCH_PROVIDER_COLLECTION).doc(id).get();
    } catch (error) {
        throw error;
    }
};

export const updateMerchandisingProvider = async (id, data) => {
    try {
        //upload files
        const logo = data.logo;
        const tax = data["commercial_register.tax_image"];
        const cr = data["commercial_register.commercial_register_image"];

        if (logo) {
            const filename = `logo.${logo.name.split(".").pop()}`;
            //upload and return a url
            data.logo = await uploadImage(`Merchandising_Provider/${id}/`, logo, filename);
        }

        if (tax) {
            const filename = `vat.${tax.name.split(".").pop()}`;
            data.commercial_register.tax = await uploadImage(`Merchandising_Provider/${id}/`, tax, filename);
        }

        if (cr) {
            const filename = `commercial-registry.${cr.name.split(".").pop()}`;
            data.commercial_register.cr = await uploadImage(`Merchandising_Provider/${id}/`, cr, filename);
        }

        return await db.collection(MERCH_PROVIDER_COLLECTION).doc(id).update(data);
    } catch (error) {
        throw error;
    }
};

export const getLinkedMPWithSupplier = async (supplierID) => {
    try {
        const links = (await getMPLinks(supplierID)).map((link) => link.data());
        const MPs = await Promise.all(links.map((link) => getMP(link.mp_id)));

        return MPs;
    } catch (error) {
        throw error;
    }
};
