import * as Yup from "yup";
import { getToday, getTomorrow } from "../../helpers/date-utils";

Yup.addMethod(Yup.array, "noDuplicates", function (key = null, msg = "No duplicate items allowed") {
    let keys = [];
    if (key) keys = key.split(".");
    return this.test("no-duplicates", msg, (array) => {
        let seen = new Set();
        const hasDuplicates = array.some(function (item) {
            let value = { ...item };

            if (keys.length > 0) {
                value = keys.reduce((result, key) => (result ? result[key] : result), item);
            }
            return seen.size === seen.add(value).size;
        });

        return !hasDuplicates;
    });
});

export const FORM_SCHEMA = Yup.object().shape({
    name: Yup.string().max(255, "Max Character: 255").trim().required("Required"),
    number: Yup.string().max(255, "Max Character: 255").trim().required("Required"),
    start_date: Yup.date("Invalid date")
        .max(Yup.ref("end_date"), "Start date cannot be after end date.")
        .required("Required"),
    end_date: Yup.date("Invalid date")
        .min(Yup.ref("start_date"), "End date cannot be after start date.")
        .required("Required"),
    products: Yup.array(
        Yup.object().shape({
            product_id: Yup.object().typeError("You must select a product").shape({
                label: Yup.string(),
                value: Yup.string(),
            }),
            price: Yup.number("Invalid price").positive("Invalid price").required("required"),
        })
    )
        .min(1, "You must at least insert 1 product")
        .noDuplicates("product_id.value", "No Duplicate Products is allowed"),
});

export const STATUS = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
];

export const INITIAL_VALUES = {
    id: null,
    supplier_id: null,
    name: "",
    number: "",

    start_date: getToday(),
    end_date: getTomorrow(),

    status: STATUS[0],

    products: [],
};
