export let get = function (obj, key) {
    return key.split(".").reduce(function (o, x) {
        return typeof o == "undefined" || o === null ? o : o[x];
    }, obj);
};

export const isObject = (variable) => {
    return (
        typeof variable === "object" &&
        variable !== null &&
        !Array.isArray(variable) &&
        !(variable instanceof Date) &&
        !(variable instanceof RegExp) &&
        !(variable instanceof Function) &&
        !(variable instanceof Error) &&
        !(variable instanceof Number) &&
        !(variable instanceof Boolean) &&
        !(variable instanceof String) &&
        !(variable instanceof Array) &&
        !(variable instanceof Map) &&
        !(variable instanceof Set) &&
        !(variable instanceof WeakMap) &&
        !(variable instanceof WeakSet)
    );
};
