import * as Yup from "yup";

const arabicRegex = /^[.,[/\\\](){}\u0600-\u06FF_-،\d\sxX]+$/;
const englishRegex = /^[.,[/\\\](){}A-Za-z_-\d\s]+$/;

// const MAX_IMAGE_SIZE = 1_048_576; //1MB
const MAX_IMAGE_SIZE = 2_097_152; //2MB
const SUPPORTED_IMAGE_FORMATS = ["image/jpeg", "image/png", "image/gif"];

Yup.addMethod(Yup.mixed, "imageFormat", function () {
    return this.test(
        "image-format",
        "Only allowed .jpg, .png, .gif formats",
        (value) => !value || SUPPORTED_IMAGE_FORMATS.includes(value?.type)
    );
});

Yup.addMethod(Yup.mixed, "imageSize", function () {
    return this.test("image-size", "Max size: 2 MB", (value) => !value || value?.size <= MAX_IMAGE_SIZE);
});

export const FORM_SCHEMA = Yup.object().shape({
    Ar_name: Yup.string()
        .trim()
        .matches(arabicRegex, "Must be in Arabic letters")
        .max(255, "You've the maximum of characters (255 max)")
        .required("Required"),

    En_name: Yup.string()
        .trim()
        .matches(englishRegex, "Must be in English letters")
        .max(255, "You've the maximum of characters (255 max)")
        .required("Required"),

    barcode: Yup.string().trim().max(48, "Max length: 48").required("Required"),

    carton_barcode: Yup.string().trim().max(48, "Max length: 48").required("Required"),

    product_number: Yup.string().trim().max(50, "Max length: 50").required("Required"),

    display_unit: Yup.object().typeError("Required").shape({
        label: Yup.string(),
        value: Yup.string(),
    }),
    outer_unit: Yup.number().positive("must be positive").required("Required"),

    packing: Yup.object().shape({
        no_units: Yup.number().positive("must be positive").required("Required"),
        packing_unit: Yup.number().positive("must be positive").required("Required"),
        packing: Yup.object().typeError("Required").shape({
            label: Yup.string(),
            value: Yup.string(),
        }),
        size: Yup.number().positive("must be positive").required("Required"),
        size_unit: Yup.object().typeError("Required").shape({
            label: Yup.string(),
            value: Yup.string(),
        }),
    }),
    weight: Yup.number().positive("must be positive").required("Required"),

    product_description: Yup.string().trim(),
    product_image: Yup.object().shape({
        product_image1: Yup.lazy((value) => {
            if (typeof value === "object") {
                return Yup.mixed().imageFormat().imageSize();
            } else {
                return Yup.string();
            }
        }),
        product_image2: Yup.lazy((value) => {
            if (typeof value === "object") {
                return Yup.mixed().imageFormat().imageSize();
            } else {
                return Yup.string();
            }
        }),
        product_image3: Yup.lazy((value) => {
            if (typeof value === "object") {
                return Yup.mixed().imageFormat().imageSize();
            } else {
                return Yup.string();
            }
        }),
    }),

    product_category: Yup.object().shape({
        sub_category_id: Yup.object().typeError("Required").shape({
            label: Yup.string(),
            value: Yup.string(),
        }),
        category_id: Yup.object().typeError("Required").shape({
            label: Yup.string(),
            value: Yup.string(),
        }),
        sub_sub_category_id: Yup.object().typeError("Required").shape({
            label: Yup.string(),
            value: Yup.string(),
        }),
    }),

    brand_id: Yup.object().nullable().shape({
        label: Yup.string(),
        value: Yup.string(),
    }),
    //fmcg
    expiration_period: Yup.number().when("product_category.category_id", {
        is: (option) => option.value !== ELECTRONICS_ID,
        then: Yup.number().positive("must be positive").required("required"),
        otherwise: Yup.number()
            .positive("must be positive")
            .notRequired()
            .transform((val, originalVal) => {
                if (!val) return 1;
                return originalVal;
            }),
    }),
    expiration_period_unit: Yup.object().typeError("Required").shape({
        label: Yup.string(),
        value: Yup.string(),
    }),

    shelf_life: Yup.number().when("product_category.category_id", {
        is: (option) => option.value !== ELECTRONICS_ID,
        then: Yup.number().positive("must be positive").required("required"),
        otherwise: Yup.number()
            .positive("must be positive")
            .notRequired()
            .transform((val, originalVal) => {
                if (!val) return 1;
                return originalVal;
            }),
    }),
    product_recall_period: Yup.number().when("product_category.category_id", {
        is: (option) => option.value !== ELECTRONICS_ID,
        then: Yup.number().positive("must be positive").required("required"),
        otherwise: Yup.number()
            .positive("must be positive")
            .notRequired()
            .transform((val, originalVal) => {
                if (!val) return 1;
                return originalVal;
            }),
    }),
    product_recall_period_unit: Yup.object().when("product_category.category_id", {
        is: (option) => option.value !== ELECTRONICS_ID,
        then: Yup.object().typeError("Required").shape({
            label: Yup.string(),
            value: Yup.string(),
        }),
        otherwise: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .transform((val, originalVal) => {
                if (!val) return PERIOD_UNITS[0];
                return originalVal;
            }),
    }),
    shelf_life_unit: Yup.object().when("product_category.category_id", {
        is: (option) => option.value !== ELECTRONICS_ID,
        then: Yup.object().typeError("Required").shape({
            label: Yup.string(),
            value: Yup.string(),
        }),
        otherwise: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string(),
            })
            .transform((val, originalVal) => {
                if (!val) return PERIOD_UNITS[0];
                return originalVal;
            }),
    }),

    //electronics
    manufacture_date: Yup.date().when("product_category.category_id", {
        is: (option) => option.value === ELECTRONICS_ID,
        then: Yup.date().required("required"),
        otherwise: Yup.date().notRequired(),
    }),
    manufacture_number: Yup.string().when("product_category.category_id", {
        is: (option) => option.value === ELECTRONICS_ID,
        then: Yup.string().trim().required("required"),
        otherwise: Yup.string().trim().notRequired(),
    }),
    model_number: Yup.string().when("product_category.category_id", {
        is: (option) => option.value === ELECTRONICS_ID,
        then: Yup.string().trim().required("required"),
        otherwise: Yup.string().trim().notRequired(),
    }),

    //sales related props
    shelf_price: Yup.number().min(0, "must be 0 or greater").required("required"),
    minimum_quantity_alert: Yup.number().min(0, "must be 0 or greater").required("required"),
    min_display_moving_percentage: Yup.number().min(0, "must be 0 or greater").required("required"),
    product_recommendations: Yup.string().trim(),
    priority: Yup.number().positive("must be positive").integer("must be integer").required("required"),
    percentage: Yup.number().min(0, "must be 0 or greater").required("required"),
});

export const PERIOD_UNITS = [
    { label: "Year", value: "year" },
    { label: "Month", value: "month" },
    { label: "Week", value: "week" },
    { label: "Day", value: "day" },
];

export const SIZE_UNITS = [
    { label: "l - Liter", value: "L" },
    { label: "ml - Milliliter", value: "ml" },
    { label: "kg - Kilogram", value: "kg" },
    { label: "g - Gram", value: "g" },
    { label: "lb - Pound", value: "lb" },
    { label: "oz - Ounce", value: "oz" },
];

export const PACKING_UNITS = [
    { label: "Carton", value: "Carton" },
    { label: "Shrink", value: "Shrink" },
    { label: "Paket", value: "Paket" },
];

export const DISPLAY_UNITS = [
    { label: "Both", value: "both" },
    { label: "Outer Unit", value: "outer_unit" },
    { label: "Pieces", value: "pieces" },
];

export const STATUS = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
];

export const PRODUCT_TYPES = [
    { label: "FMCG", value: "1" },
    { label: "Electronics", value: "2" },
];

export const ELECTRONICS_ID = "108";

export const INITIAL_VALUES = {
    supplier_id: null,
    product_id: null,
    En_name: "",
    Ar_name: "",
    barcode: "",
    carton_barcode: "",
    product_number: "",
    //product number - supplier id
    murtab_code: "",

    packing: {
        packing_unit: 1,
        packing: PACKING_UNITS[0],
        no_units: 1,
        size: 1, //string
        size_unit: SIZE_UNITS[0],
    },
    display_unit: DISPLAY_UNITS[0],
    outer_unit: 1,
    product_description: "",
    weight: 1, //string
    weight_unit: "Kg",
    product_id: "",

    product_category: {
        // category_id: "110",
        // sub_category_id: "140",
        // sub_sub_category_id: "158",
        category_id: null,
        sub_category_id: null,
        sub_sub_category_id: null,
    },

    product_image: {
        product_image3: null,
        product_image2: null,
        product_image1: null,
    },

    brand_id: null,
    status: STATUS[0],
    //fmcg
    expiration_period: 1, //string
    expiration_period_unit: PERIOD_UNITS[1],
    product_recall_period: 1, //string
    product_recall_period_unit: PERIOD_UNITS[1],
    shelf_life: 1, //string
    shelf_life_unit: PERIOD_UNITS[1],

    //electronics
    manufacture_date: new Date(),
    manufacture_number: "",
    model_number: "",

    //sales related props
    shelf_price: 1,
    minimum_quantity_alert: 20,
    min_display_moving_percentage: 0.3,
    priority: 1,
    product_recommendations: "",
    percentage: 0.75,
};
