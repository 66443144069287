import { firestore as db } from "../firebase";
import { getSupplier } from "./Supplier";
const MPS_LINK = "MP_Supplier_Link";

export const getLinkedSuppliers = async (mpID) => {
    try {
        const supplierLinks = (await db.collection(MPS_LINK).where("mp_id", "==", mpID).get()).docs.map((doc) =>
            doc.data()
        );

        let supplierIDs = supplierLinks.map((link) => link.supplier_id);

        const suppliers = await Promise.all(supplierIDs.map((id) => getSupplier(id)));
        return suppliers;
    } catch (error) {
        throw error;
    }
};

export const getMPLinks = async (supplierID) => {
    try {
        return (await db.collection(MPS_LINK).where("supplier_id", "==", supplierID).get()).docs;
    } catch (error) {
        throw error;
    }
};
