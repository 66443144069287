import { firestore as db, firestoreWorker, storageWorker } from "../firebase";
import { generateNewDocID } from "../../helpers/firebase-helpers";
import { uploadImage } from "../storage";
import { PLATFORM } from "../../constants/global";

const SUPPLIER = "Supplier";
const ACCOUNT_STATUS = {
    INACTIVE: "0",
    ACTIVE: "1",
};

export let getSupplier = async (id) => {
    try {
        return await db.collection(SUPPLIER).doc(id).get();
    } catch (error) {
        throw error;
    }
};

export const addSupplier = async (data) => {
    try {
        const newID = await generateNewDocID(SUPPLIER, "company_id", firestoreWorker);

        data.id = newID;
        data.company_id = newID;
        data.status = ACCOUNT_STATUS.INACTIVE;
        data.platform = PLATFORM;

        //upload files
        const logo = data.logo;
        const tax = data.commercial_register.tax_image;
        const cr = data.commercial_register.commercial_register_image;

        if (logo) {
            const filename = `logo.${logo.name.split(".").pop()}`;
            //upload and return a url
            data.logo = await uploadImage(`Supplier/${newID}/`, logo, filename, storageWorker);
        }

        if (tax) {
            const filename = `vat.${tax.name.split(".").pop()}`;
            data.commercial_register.tax = await uploadImage(`Supplier/${newID}/`, tax, filename, storageWorker);
        }

        if (cr) {
            const filename = `commercial-registry.${cr.name.split(".").pop()}`;
            data.commercial_register.cr = await uploadImage(`Supplier/${newID}/`, cr, filename, storageWorker);
        }

        await firestoreWorker.collection(SUPPLIER).doc(newID).set(data);

        return data;
    } catch (error) {
        throw error;
    }
};

export const updateSupplier = async (id, data) => {
    try {
        //upload files
        const logo = data.logo;
        const tax = data["commercial_register.tax_image"];
        const cr = data["commercial_register.commercial_register_image"];

        if (logo) {
            const filename = `logo.${logo.name.split(".").pop()}`;
            //upload and return a url
            data.logo = await uploadImage(`Supplier/${id}/`, logo, filename);
        }

        if (tax) {
            const filename = `vat.${tax.name.split(".").pop()}`;
            data.commercial_register.tax = await uploadImage(`Supplier/${id}/`, tax, filename);
        }

        if (cr) {
            const filename = `commercial-registry.${cr.name.split(".").pop()}`;
            data.commercial_register.cr = await uploadImage(`Supplier/${id}/`, cr, filename);
        }

        return await db.collection(SUPPLIER).doc(id).update(data);
    } catch (error) {
        throw error;
    }
};
