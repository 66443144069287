import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    mapContainer: {
        width: "100%",
        height: "550px",
    },
});

export default styles;
