import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "../components-lagacy/Grid/GridContainer";
import GridItem from "../components-lagacy/Grid/GridItem";

import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
//import withStyles from "@material-ui/core/styles/withStyles";

import Card from "../core-ui/material-kit-ui/cards/Card";
import CardBody from "../core-ui/material-kit-ui/cards/CardBody";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "../core-ui/material-kit-ui/buttons/Button";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";

import bg11 from "../assets/images/placeholder.jpg";
import { Redirect } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";

import { withStyles } from "@material-ui/core/styles";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Swal from "sweetalert2";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { COLORS } from "../constants/constants-lagacy";
import PATHS from "../constants/paths";

import { firestore as db, storage } from "../services/firebase";

class UpdateProductInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supplier_id: "",
            Ar_name: "",
            En_name: "",
            product_number: "",
            barcode: "",
            carton_barcode: "",
            product_image1: "",
            product_image2: "",
            product_image3: "",
            weight: "",
            weight_unit: "",
            shelf_life: "",
            shelf_life_unit: "",
            product_recall_period: "",
            product_recall_period_unit: "",
            expiration_period: "",
            activity_id: "",
            category_id: "",
            sub_category_id: "",
            sub_sub_category_id: "",
            merchandiser_qualities: "",
            product_description: "",
            product_recommendations: "",
            packing: "",
            packing_unit: "1",
            size: "",
            size_unit: "",
            no_units: "1",
            outer_unit: "1",
            number_of_pieces: 1,
            shelf_price: "",
            packing3: "",
            packing4: "",
            status: "",
            form_number: 1,

            supplier_name: "",
            activities: [],
            categories: [],
            sub_categories: [],
            sub_subCategories: [],

            displayUnit: "both",
            percentage: 0.75,

            pathBack: "",

            loading: false,
            dialogOpen: false,
            dialogHeader: "",
            dialogMsg: "",
            dialogCloseHandler: null,
        };
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    //==================================================================================
    showDialogMsg = (title, msg, closeHandler) => {
        this.setState({
            loading: false,
            dialogHeader: title,
            dialogMsg: msg,
            dialogOpen: true,
            dialogCloseHandler: closeHandler,
        });
    };

    hideDialogMsg = () => {
        this.setState({ dialogMsg: "", dialogOpen: false });
    };

    componentDidMount() {
        /*if (this.captchaDemo) {
        console.log("started, just a second...")
        this.captchaDemo.reset();
        this.captchaDemo.execute();
    }*/
        this.setState({ loading: true });
        let datat2 = [];
        //firebase.firestore();
        var citiesRef = db.collection("Product").where("product_id", "==", this.props.productID);
        citiesRef
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    datat2 = doc.data();
                    this.setState(
                        () => ({
                            supplier_id: datat2.supplier_id,
                            Ar_name: datat2.Ar_name,
                            En_name: datat2.En_name,
                            product_number: datat2.product_number,
                            barcode: datat2.barcode,
                            carton_barcode: datat2.carton_barcode,

                            product_image1: datat2.product_image.product_image1,
                            product_image2: datat2.product_image.product_image2,
                            product_image3: datat2.product_image.product_image3,

                            weight: datat2.weight.toString(),

                            shelf_life: datat2.shelf_life.toString(),
                            product_recall_period: datat2.product_recall_period.toString(),
                            expiration_period: datat2.expiration_period.toString(),

                            shelf_life_unit: datat2.shelf_life_unit,
                            product_recall_period_unit: datat2.product_recall_period_unit,
                            expiration_period_unit: datat2.expiration_period_unit,

                            activity_id: datat2.product_category.activity_id,
                            category_id: datat2.product_category.category_id,
                            sub_category_id: datat2.product_category.sub_category_id,
                            sub_sub_category_id: datat2.product_category.sub_sub_category_id,

                            product_description: datat2.product_description,
                            product_recommendations: datat2.product_recommendations,

                            packing: datat2.packing.packing,
                            packing_unit: datat2.packing.packing_unit.toString(),
                            size: datat2.packing.size,
                            size_unit: datat2.packing.size_unit,
                            no_units: datat2.packing.no_units.toString(),

                            outer_unit: datat2.outer_unit.toString(),
                            shelf_price: datat2.shelf_price.toString(),

                            displayUnit: datat2.display_unit,
                            number_of_pieces:
                                datat2.packing.packing_unit * datat2.outer_unit * datat2.packing.no_units || 1,

                            status: datat2.status,
                        }),
                        () => console.log(typeof this.state.shelf_life)
                    );
                });

                this.setState({ loading: true });
                let datat3 = [];
                var citiesRef = db.collection("Category").where("status", "==", "1").orderBy("En_name");
                citiesRef.get().then((snapshot) => {
                    snapshot.forEach((doc) => {
                        let datat = doc.data();
                        datat3.push(datat);
                    });
                    this.setState({ categories: datat3, loading: false });
                });

                this.setState({ loading: true });
                let datat4 = [];
                citiesRef = db.collection("SubCategory").where("status", "==", "1").orderBy("En_name");
                citiesRef.get().then((snapshot) => {
                    snapshot.forEach((doc) => {
                        let datat = doc.data();
                        datat4.push(datat);
                    });
                    this.setState({ sub_categories: datat4, loading: false });
                });

                this.setState({ loading: true });
                let datat5 = [];
                citiesRef = db.collection("Sub_SubCategory").where("status", "==", "1").orderBy("En_name");
                citiesRef.get().then((snapshot) => {
                    snapshot.forEach((doc) => {
                        let datat = doc.data();
                        datat5.push(datat);
                    });
                    this.setState({ sub_subCategories: datat5, loading: false });
                });
            }) /*.then(()=>{
                    let datat6 = [];
                    var activitiesRef = db.collection('Activity');
                    activitiesRef.get()
                    .then(snapshot => {
                      snapshot.forEach(doc => {
                        let datat = doc.data();
                        datat6.push(datat);
                    
                      });
                      this.setState({ activities: datat6 });
                    
                    })

                })*/
            .catch((err) => {
                console.log("Error getting documents", err);
                this.setState({ loading: false });
            });
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
            this.captchaDemo.execute();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!
        console.log(recaptchaToken, "<= your recaptcha token");
    }

    validateFirstForm() {
        let validate =
            this.state.Ar_name.length > 0 && this.state.En_name.length > 0 && this.state.product_number.length > 0;
        /* && this.state.barcode.length >0*/

        return validate;
    }
    validateSecondForm() {
        let validate =
            this.state.Ar_name.length > 0 &&
            this.state.En_name.length > 0 &&
            this.state.product_number.length > 0 &&
            /* && this.state.barcode.length >0*/

            this.state.packing.length > 0 &&
            this.state.packing_unit.length > 0;

        return validate;
    }
    validateThirdForm() {
        let validate =
            this.state.Ar_name.length > 0 &&
            this.state.En_name.length > 0 &&
            this.state.product_number.length > 0 &&
            /*&& this.state.barcode.length >0*/

            this.state.packing.length > 0 &&
            this.state.packing_unit.length > 0;

        return validate;
    }
    //==================================================================================
    handleChange = (event, id) => {
        if (
            id === "shelf_life" ||
            id === "product_recall_period" ||
            id === "expiration_period" ||
            id === "size" ||
            id === "no_units" ||
            id === "weight" ||
            id === "outer_unit" ||
            id === "packing_unit" ||
            id === "shelf_price" ||
            id === "displayUnit"
        ) {
            if (id === "displayUnit") {
                let displayUnit = event.target.value;
                let packing_unit = Number(this.state.packing_unit);
                let outer_unit = Number(this.state.outer_unit);
                let pieces = displayUnit === "outer_unit" ? 1 : Number(this.state.no_units);
                let number_of_pieces = packing_unit * outer_unit * pieces || 1;
                this.setState({
                    [id]: displayUnit,
                    outer_unit: `${outer_unit}`,
                    no_units: `${pieces}`,
                    number_of_pieces: number_of_pieces,
                });
            } else if (id === "no_units" || id === "outer_unit" || id === "packing_unit") {
                let number_of_pieces =
                    Number(
                        id === "packing_unit" ? event.target.value.replace(/[^0-9.]/, "") : this.state.packing_unit
                    ) *
                    Number(id === "no_units" ? event.target.value.replace(/[^0-9.]/, "") : this.state.no_units) *
                    Number(id === "outer_unit" ? event.target.value.replace(/[^0-9.]/, "") : this.state.outer_unit);
                number_of_pieces = number_of_pieces === 0 ? 1 : number_of_pieces;
                this.setState({ [id]: event.target.value.replace(/[^0-9.]/, ""), number_of_pieces: number_of_pieces });
            } else {
                this.setState({ [id]: event.target.value.replace(/[^0-9.]/, "") });
            }
        } else {
            if (id === "Ar_name") {
                this.setState({ [id]: event.target.value.replace(/[^\u0600-\u06FF-9._!@#$%^&*)(-=+/ ]/, "") });
            } else if (id === "En_name") {
                this.setState({ [id]: event.target.value.replace(/[^a-z-A-Z-9._!@#$%^&*)(-=+/ ]/, "") });
            } else if (id === "category_id" && event.target.value !== this.state.category_id) {
                this.setState({ [id]: event.target.value, sub_category_id: "", sub_sub_category_id: "" });
            } else if (id === "sub_category_id" && event.target.value !== this.state.sub_category_id) {
                this.setState({ [id]: event.target.value, sub_sub_category_id: "" });
            } else {
                this.setState({ [id]: event.target.value });
            }
        }
    };
    handleChangeFormNumber = (num) => {
        this.setState({ form_number: num });
    };
    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    handleClickShowConfPassword = () => {
        this.setState({ showConfPassword: !this.state.showConfPassword });
    };
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //==================================================================================
    fileChangedHandler = (event) => {
        this.setState({ loading: true });

        try {
            const date = new Date();
            const img = event.target.files[0];
            const inputName = event.target.name;
            const ref = storage.ref();
            const name = img.name;
            const metadata = {
                contentType: img.type,
            };
            ref.child("Product_Images/" + name + "_" + date.toString())
                .put(img, metadata)

                .then(() => {
                    storage
                        .ref("Product_Images")
                        .child(name + "_" + date.toString())
                        .getDownloadURL()
                        .then((url) => {
                            this.setState({ [inputName]: url });
                            this.showDialogMsg("Message", inputName + " added Successfully!", this.hideDialogMsg);
                        });
                })
                .catch((err) => {
                    console.log(err);
                    this.showDialogMsg("Alert", "Something Went wrong!", this.hideDialogMsg);
                });
        } catch (error) {
            console.log(error);
            this.showDialogMsg("Alert", "Something Went wrong!", this.hideDialogMsg);
        }
    };

    //==================================================================================
    /**
     * To write user data into firestore
     */
    writeProductData = async (e) => {
        const result = await Swal.fire({
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "",
            cancelButtonColor: "",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            title: "Are You Sure?",
            text: `These changes will be used and referred to in all of your projects and reports.`,
        });

        if (!result.value) {
            return;
        }

        this.setState({ loading: true });

        if (navigator.onLine) {
            //firebase.firestore();
            let En_name_check =
                this.state.En_name === undefined
                    ? null
                    : this.state.En_name.charAt(0).toUpperCase() + this.state.En_name.slice(1);
            var productRef = db
                .collection("Product")
                .where("supplier_id", "==", this.props.id)
                .where("En_name", "==", En_name_check)
                .where("Ar_name", "==", this.state.Ar_name);
            productRef
                .get()
                .then((snapshotProduct) => {
                    if (
                        snapshotProduct.size > 0 &&
                        snapshotProduct.docs[0].data().product_id !== this.props.productID
                    ) {
                        this.showDialogMsg(
                            "Alert",
                            "The name of product you have entered is already registered!",
                            this.hideDialogMsg
                        );
                    } else {
                        let supplier_id = this.props.id;
                        let Ar_name = this.state.Ar_name === undefined ? null : this.state.Ar_name;
                        let En_name = this.state.En_name === undefined ? null : this.state.En_name;
                        let product_number = this.state.product_number === undefined ? null : this.state.product_number;
                        let barcode = this.state.barcode === undefined ? null : this.state.barcode;
                        let carton_barcode = this.state.carton_barcode === undefined ? null : this.state.carton_barcode;
                        let product_image1 = this.state.product_image1 === undefined ? null : this.state.product_image1;
                        let product_image2 = this.state.product_image2 === undefined ? null : this.state.product_image2;
                        let product_image3 = this.state.product_image3 === undefined ? null : this.state.product_image3;
                        let weight = this.state.weight === undefined ? null : this.state.weight;
                        // let weight_unit = this.state.weight_unit === undefined ? null : this.state.weight_unit;
                        let shelf_life = this.state.shelf_life === undefined ? null : this.state.shelf_life;
                        let shelf_life_unit =
                            this.state.shelf_life_unit === undefined ? "day" : this.state.shelf_life_unit;
                        let product_recall_period =
                            this.state.product_recall_period === undefined ? null : this.state.product_recall_period;
                        let product_recall_period_unit =
                            this.state.product_recall_period_unit === undefined
                                ? "day"
                                : this.state.product_recall_period_unit;
                        let expiration_period =
                            this.state.expiration_period === undefined ? null : this.state.expiration_period;
                        let expiration_period_unit =
                            this.state.expiration_period_unit === undefined ? "day" : this.state.expiration_period_unit;
                        //let activity_id = this.state.activity_id === undefined ? null : this.state.activity_id;
                        let category_id = this.state.category_id === undefined ? null : this.state.category_id;
                        let sub_category_id =
                            this.state.sub_category_id === undefined ? null : this.state.sub_category_id;
                        let sub_sub_category_id =
                            this.state.sub_sub_category_id === undefined ? null : this.state.sub_sub_category_id;
                        let product_description =
                            this.state.product_description === undefined ? null : this.state.product_description;
                        let product_recommendations =
                            this.state.product_recommendations === undefined
                                ? null
                                : this.state.product_recommendations;
                        let packing = this.state.packing === undefined ? null : this.state.packing;
                        let packing_unit = this.state.packing_unit === undefined ? 1 : Number(this.state.packing_unit);
                        let size = this.state.size === undefined ? null : this.state.size;
                        let size_unit = this.state.size_unit === undefined ? null : this.state.size_unit;
                        let no_units = this.state.no_units === undefined ? 1 : Number(this.state.no_units);
                        let status = this.state.status === undefined ? null : this.state.status;
                        let outer_unit = this.state.outer_unit === undefined ? 1 : Number(this.state.outer_unit);
                        let shelf_price = this.state.shelf_price === undefined ? null : Number(this.state.shelf_price);
                        let display_unit = this.state.displayUnit ? this.state.displayUnit : "both";
                        let percentage = this.state.percentage === undefined ? 0.75 : this.state.percentage;

                        // if (shelf_life_unit === "year") {
                        //     shelf_life = shelf_life * 360;
                        // } else if (shelf_life_unit === "month") {
                        //     shelf_life = shelf_life * 30;
                        // } else if (shelf_life_unit === "week") {
                        //     shelf_life = shelf_life * 7;
                        // } else {
                        //     shelf_life = shelf_life * 1;
                        // }

                        // if (product_recall_period_unit === "year") {
                        //     product_recall_period = product_recall_period * 360;
                        // } else if (product_recall_period_unit === "month") {
                        //     product_recall_period = product_recall_period * 30;
                        // } else if (product_recall_period_unit === "week") {
                        //     product_recall_period = product_recall_period * 7;
                        // } else {
                        //     product_recall_period = product_recall_period * 1;
                        // }

                        // if (expiration_period_unit === "year") {
                        //     expiration_period = expiration_period * 360;
                        // } else if (expiration_period_unit === "month") {
                        //     expiration_period = expiration_period * 30;
                        // } else if (expiration_period_unit === "week") {
                        //     expiration_period = expiration_period * 7;
                        // } else {
                        //     expiration_period = expiration_period * 1;
                        // }
                        if (navigator.onLine) {
                            //update product info. into Product collection
                            let productRef = db.collection("Product");
                            productRef
                                .doc(this.props.productID)
                                .update({
                                    Ar_name: Ar_name,
                                    En_name: En_name.charAt(0).toUpperCase() + En_name.slice(1),
                                    product_number: product_number,
                                    murtab_code: product_number + "-" + supplier_id,
                                    barcode: barcode,
                                    carton_barcode: carton_barcode,
                                    product_image: {
                                        product_image1: product_image1,
                                        product_image2: product_image2,
                                        product_image3: product_image3,
                                    },

                                    weight: weight,
                                    weight_unit: "Kg",
                                    shelf_life: shelf_life,
                                    shelf_life_unit: shelf_life_unit,
                                    product_recall_period: product_recall_period,
                                    product_recall_period_unit: product_recall_period_unit,
                                    expiration_period: expiration_period,
                                    expiration_period_unit: expiration_period_unit,

                                    product_category: {
                                        //activity_id: activity_id,
                                        category_id: category_id,
                                        sub_category_id: sub_category_id,
                                        sub_sub_category_id: sub_sub_category_id,
                                    },

                                    product_description: product_description,
                                    product_recommendations: product_recommendations,
                                    packing: {
                                        packing: packing,
                                        packing_unit: packing_unit,
                                        size: size,
                                        size_unit: size_unit,
                                        no_units: no_units,
                                    },

                                    outer_unit: outer_unit,
                                    shelf_price: shelf_price,

                                    status: status,
                                    display_unit: display_unit,

                                    percentage: percentage,
                                })
                                .then(() => {
                                    this.showDialogMsg("Alert", "Product's Information Edited Successfully!", () => {
                                        this.hideDialogMsg();
                                        this.goToSupplierProductsListPage(1);
                                    });
                                })
                                .catch((error) => {
                                    console.error(error);
                                    this.showDialogMsg("Alert", "There is something wrong!", this.hideDialogMsg);
                                });
                        } else {
                            this.showDialogMsg("Alert", "you're offline!", this.hideDialogMsg);
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    this.showDialogMsg("Alert", "There is something wrong!", this.hideDialogMsg);
                });
        } else {
            this.showDialogMsg("Alert", "you're offline!", this.hideDialogMsg);
        }
    };

    goToSupplierProductsListPage = (number) => {
        //1 for submit and 2 for cancel
        if (number === 1) {
            // if (this.props.branch) {
            //     this.setState({ pathBack: "/ProductsListForBranch" });
            // } else {
            //     this.setState({ pathBack: "/SupplierProductsList" });
            // }
            this.setState({ pathBack: PATHS.PRODUCTS });
        } else {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Discard All Changes!",
                text: "Are you sure you want to discard the changes?",
            }).then((result) => {
                if (!result.value) {
                    this.setState({ pathBack: PATHS.PRODUCTS });
                    // if (this.props.branch) {
                    //     this.setState({ pathBack: "/ProductsListForBranch" });
                    // } else {
                    //     this.setState({ pathBack: "/SupplierProductsList" });
                    // }
                }
            });
        }
    };
    //==================================================================================
    render() {
        const { classes } = this.props;
        if (this.state.pathBack.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathBack,
                    }}
                />
            );
        } else {
            //activity_list
            /*const activity_list =[];
        for(let i=0; i<this.state.activities.length;i++){
            let label = this.state.activities[i].En_name ;
            activity_list.push({value: this.state.activities[i].activity_id, label: label,});
        }*/

            //category_list
            const category_list = [];
            /*if(this.state.activity_id.length >0){
          for(let i=0; i<this.state.categories.length;i++){
            if(this.state.activity_id === this.state.categories[i].activity_id){
              let label = this.state.categories[i].En_name ;
              category_list.push({value: this.state.categories[i].category_id, label: label,});
            }
          }
        }else{*/
            for (let i = 0; i < this.state.categories.length; i++) {
                let label = this.state.categories[i].En_name;
                category_list.push({ value: this.state.categories[i].category_id, label: label });
            }

            //}

            //sub_category_list
            const sub_category_list = [];
            if (this.state.category_id.length > 0) {
                for (let i = 0; i < this.state.sub_categories.length; i++) {
                    if (this.state.category_id === this.state.sub_categories[i].category_id) {
                        let label = this.state.sub_categories[i].En_name;
                        sub_category_list.push({ value: this.state.sub_categories[i].sub_category_id, label: label });
                    }
                }
            } else {
                for (let i = 0; i < this.state.sub_categories.length; i++) {
                    let label = this.state.sub_categories[i].En_name;
                    sub_category_list.push({ value: this.state.sub_categories[i].sub_category_id, label: label });
                }
            }

            //sub_subCategory_list
            const sub_subCategory_list = [];
            if (this.state.sub_category_id.length > 0) {
                for (let i = 0; i < this.state.sub_subCategories.length; i++) {
                    if (this.state.sub_category_id === this.state.sub_subCategories[i].sub_category_id) {
                        let label = this.state.sub_subCategories[i].En_name;
                        sub_subCategory_list.push({
                            value: this.state.sub_subCategories[i].sub_sub_category_id,
                            label: label,
                        });
                    }
                }
            } else {
                for (let i = 0; i < this.state.sub_subCategories.length; i++) {
                    let label = this.state.sub_subCategories[i].En_name;
                    sub_subCategory_list.push({
                        value: this.state.sub_subCategories[i].sub_sub_category_id,
                        label: label,
                    });
                }
            }

            const packing_list = [
                {
                    value: "Carton",
                    label: "Carton",
                },
                {
                    value: "Shrink",
                    label: "Shrink",
                },
                {
                    value: "Paket",
                    label: "Paket",
                },
            ];

            const display_unit_list = [
                {
                    value: "both",
                    label: "Both",
                },
                {
                    value: "outer_unit",
                    label: "Outer Unit",
                },
                {
                    value: "pieces",
                    label: "Pieces",
                },
            ];
            const shelf_life_unit = [
                {
                    value: "year",
                    label: "Year",
                },
                {
                    value: "month",
                    label: "Month",
                },
                {
                    value: "week",
                    label: "Week",
                },
                {
                    value: "day",
                    label: "Day",
                },
            ];

            const product_recall_period_unit = [
                {
                    value: "day",
                    label: "Day",
                },
                {
                    value: "week",
                    label: "Week",
                },
                {
                    value: "month",
                    label: "Month",
                },
                {
                    value: "year",
                    label: "Year",
                },
            ];
            const expiration_period_unit = [
                {
                    value: "day",
                    label: "Day",
                },
                {
                    value: "week",
                    label: "Week",
                },
                {
                    value: "month",
                    label: "Month",
                },
                {
                    value: "year",
                    label: "Year",
                },
            ];

            const size_list = [
                {
                    value: "L",
                    label: "L",
                },
                {
                    value: "mL",
                    label: "mL",
                },
                {
                    value: "Kg",
                    label: "Kg",
                },
                {
                    value: "g",
                    label: "g",
                },
                {
                    value: "lb",
                    label: "lb",
                },
                {
                    value: "oz",
                    label: "oz",
                },
            ];

            const state_list = [
                {
                    value: "1",
                    label: "Active",
                },
                {
                    value: "0",
                    label: "Not Active",
                },
            ];

            var fieldsArray = [];
            if (this.state.form_number === 1) {
                fieldsArray.push(
                    <GridContainer xs={12} sm={12} md={12}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                            <div className={classes.title} style={{ width: "3%" }}>
                                <div
                                    style={{
                                        fontWeight: "700",
                                        backgroundColor: COLORS.primary,
                                        color: "#EEEEEE",
                                        height: "30px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "2px solid #575757",
                                        // borderRightColor: "#91041C",
                                        // borderBottomColor: "#91041C",
                                        borderRightColor: COLORS.primary,
                                        borderBottomColor: COLORS.primary,
                                    }}
                                ></div>
                            </div>
                            <div className={classes.title} style={{ width: "96.7%", marginLeft: "2px" }}>
                                <h4
                                    style={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        backgroundColor: COLORS.background,
                                        color: COLORS.primary,
                                        height: "30px",
                                        paddingLeft: "20px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "1px solid #000000",
                                    }}
                                >
                                    Update Product Info. (Basic Information)
                                </h4>
                            </div>
                        </div>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.En_name}
                                variant="filled"
                                fullWidth
                                id="En_name"
                                label="English Name*"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "En_name")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.Ar_name}
                                variant="filled"
                                fullWidth
                                id="Ar_name"
                                label="Arabic Name*"
                                dir="rtl"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "Ar_name")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.product_number}
                                variant="filled"
                                fullWidth
                                id="product_number"
                                label="Product Number*"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "product_number")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.barcode}
                                variant="filled"
                                fullWidth
                                id="barcode"
                                label="Barcode*"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "barcode")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.carton_barcode}
                                variant="filled"
                                fullWidth
                                id="carton_barcode"
                                label="Carton Barcode*"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "carton_barcode")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                value={this.state.status}
                                variant="filled"
                                fullWidth
                                id="status"
                                label="Status*"
                                disabled={this.state.owner}
                                style={{ textAlign: "left" }}
                                multiline
                                onChange={(event) => this.handleChange(event, "status")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                            >
                                {state_list.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                value={this.state.category_id}
                                variant="filled"
                                fullWidth
                                id="category_id"
                                label="Category"
                                placeholder=""
                                multiline
                                style={{ textAlign: "left" }}
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "category_id")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                            >
                                {category_list.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                value={this.state.sub_category_id}
                                variant="filled"
                                fullWidth
                                id="sub_category_id"
                                label="Sub_Category"
                                placeholder=""
                                multiline
                                style={{ textAlign: "left" }}
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "sub_category_id")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                                disabled={!this.state.category_id || sub_category_list.length === 0}
                            >
                                {sub_category_list.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                value={this.state.sub_sub_category_id}
                                variant="filled"
                                fullWidth
                                id="sub_sub_category_id"
                                label="Sub_SubCategory"
                                placeholder=""
                                multiline
                                style={{ textAlign: "left" }}
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "sub_sub_category_id")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                                disabled={
                                    !this.state.category_id ||
                                    !this.state.sub_category_id ||
                                    sub_subCategory_list.length === 0
                                }
                            >
                                {sub_subCategory_list.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <Card style={{ display: "absolute", backgroundColor: "transparent", boxShadow: "none" }}>
                                <CardMedia
                                    style={{ /*width: 151, height: 90*/ height: 70, width: 70 }}
                                    image={this.state.product_image1 ? this.state.product_image1 : bg11}
                                    title="Product Image 1"
                                />
                            </Card>
                            <Input
                                style={{ textAlign: "left", float: "left" }}
                                label="Upload Product Image 1"
                                type="file"
                                name="product_image1"
                                id=""
                                inline="true"
                                onChange={this.fileChangedHandler}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card style={{ display: "absolute", backgroundColor: "transparent", boxShadow: "none" }}>
                                <CardMedia
                                    style={{ /*width: 151, height: 90*/ height: 70, width: 70 }}
                                    image={this.state.product_image2 ? this.state.product_image2 : bg11}
                                    title="Product Image 2"
                                />
                            </Card>
                            <Input
                                style={{ textAlign: "left", float: "left" }}
                                label="Upload Product Image 2"
                                type="file"
                                name="product_image2"
                                id=""
                                inline="true"
                                onChange={this.fileChangedHandler}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <Card style={{ display: "absolute", backgroundColor: "transparent", boxShadow: "none" }}>
                                <CardMedia
                                    style={{ /*width: 151, height: 90*/ height: 70, width: 70 }}
                                    image={this.state.product_image3 ? this.state.product_image3 : bg11}
                                    title="Product Image 3"
                                />
                            </Card>
                            <Input
                                style={{ textAlign: "left", float: "left" }}
                                label="Upload Product Image 3"
                                type="file"
                                name="product_image3"
                                id=""
                                inline="true"
                                onChange={this.fileChangedHandler}
                            />
                        </GridItem>

                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                simple
                                size="lg"
                                disabled={!this.validateFirstForm()}
                                onClick={() => this.handleChangeFormNumber(2)}
                                style={{
                                    float: "right",
                                    textTransform: "none",
                                    backgroundColor: COLORS.submit,
                                    color: COLORS.font,
                                    marginTop: 14,
                                    padding: 10,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    // borderColor: "#4BB543",
                                    border: "2",
                                    fontWeight: "400",
                                }}
                                type="submit"
                            >
                                {" "}
                                Next <ChevronRight />{" "}
                            </Button>
                            <Button
                                simple
                                size="lg"
                                style={{
                                    float: "right",
                                    textTransform: "none",
                                    backgroundColor: COLORS.accent,
                                    color: COLORS.font,
                                    marginTop: 14,
                                    marginRight: 40,
                                    padding: 10,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    // borderColor: "#4BB543",
                                    border: "2",
                                    fontWeight: "400",
                                }}
                                type="submit"
                                className={classes.navLink}
                                onClick={() => this.goToSupplierProductsListPage(2)}
                            >
                                {" "}
                                Cancel{" "}
                            </Button>
                        </GridItem>
                        <Divider orientation="vertical" xs={3} />
                    </GridContainer>
                );
            } else if (this.state.form_number === 2) {
                fieldsArray.push(
                    <GridContainer xs={12} sm={12} md={12}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                            <div className={classes.title} style={{ width: "3%" }}>
                                <div
                                    style={{
                                        fontWeight: "700",
                                        // backgroundColor: "#91041C",
                                        color: "#EEEEEE",
                                        height: "30px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "2px solid #575757",
                                        backgroundColor: COLORS.primary,
                                        borderRightColor: COLORS.primary,
                                        borderBottomColor: COLORS.primary,
                                    }}
                                ></div>
                            </div>
                            <div className={classes.title} style={{ width: "96.7%", marginLeft: "2px" }}>
                                <h4
                                    style={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        backgroundColor: COLORS.background,
                                        color: COLORS.primary,
                                        height: "30px",
                                        paddingLeft: "20px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "1px solid #000000",
                                    }}
                                >
                                    Update Product Info. (Additional Information)
                                </h4>
                            </div>
                        </div>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                value={this.state.packing}
                                variant="filled"
                                fullWidth
                                id="packing"
                                label="Packing"
                                style={{ textAlign: "left" }}
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "packing")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                    shrink: true,
                                }}
                                // InputProps={{
                                //     startAdornment: (
                                //         <TextField
                                //             position="start"
                                //             value={this.state.packing_unit.replace(/[^0-9.]/, "")}
                                //             fullWidth
                                //             style={{ width: "25%" }}
                                //             onChange={(event) => this.handleChange(event, "packing_unit")}
                                //             rowsMax={1}
                                //         >
                                //             {" "}
                                //         </TextField>
                                //     ),
                                // }}
                            >
                                {packing_list.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                value={this.state.displayUnit}
                                variant="filled"
                                fullWidth
                                id="displayUnit"
                                label="Display Unit"
                                style={{ textAlign: "left" }}
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "displayUnit")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                    shrink: true,
                                }}
                            >
                                {display_unit_list.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.outer_unit}
                                variant="filled"
                                fullWidth
                                id="outer_unit"
                                label="Outer Unit"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "outer_unit")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                                // disabled={this.state.displayUnit === "pieces"}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.no_units.replace(/[^0-9.]/, "")}
                                variant="filled"
                                fullWidth
                                id="no_units"
                                label="Number of Units"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "no_units")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                                disabled={this.state.displayUnit === "outer_unit"}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.number_of_pieces}
                                variant="filled"
                                fullWidth
                                id="number_of_pieces"
                                label="Number Of Pieces"
                                placeholder=""
                                multiline
                                disabled={true}
                                //onChange={(event)=>this.handleChange(event,"number_of_pieces")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.shelf_price}
                                variant="filled"
                                fullWidth
                                id="shelf_price"
                                label="Shelf Price"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "shelf_price")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                className={classNames(classes.textField)}
                                variant="filled"
                                value={this.state.size_unit}
                                //variant="filled"
                                id="size"
                                label="Size/Weight/Volume"
                                // style={{ width: "100%" }}
                                placeholder=""
                                multiline
                                style={{ textAlign: "left" }}
                                fullWidth
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "size_unit")}
                                //className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                    startAdornment: (
                                        <TextField
                                            position="start"
                                            value={this.state.size.replace(/[^0-9.]/, "")}
                                            fullWidth
                                            style={{ width: "25%" }}
                                            onChange={(event) => this.handleChange(event, "size")}
                                        >
                                            {" "}
                                        </TextField>
                                    ),
                                }}
                            >
                                {size_list.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                className={classNames(classes.textField)}
                                variant="filled"
                                value={this.state.shelf_life_unit}
                                //variant="filled"
                                id="shelf_life"
                                label="Shelf Life"
                                // style={{ width: "100%" }}
                                placeholder=""
                                multiline
                                style={{ textAlign: "left" }}
                                fullWidth
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "shelf_life_unit")}
                                //className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                    startAdornment: (
                                        <TextField
                                            position="start"
                                            value={this.state.shelf_life.replace(/[^0-9.]/, "")}
                                            fullWidth
                                            style={{ width: "25%" }}
                                            onChange={(event) => this.handleChange(event, "shelf_life")}
                                        >
                                            {" "}
                                        </TextField>
                                    ),
                                }}
                            >
                                {shelf_life_unit.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                className={classNames(classes.textField)}
                                variant="filled"
                                value={this.state.product_recall_period_unit}
                                //variant="filled"
                                id="product_recall_period_unit"
                                label="Product Recall Period (Near Expiration)"
                                helperText="Maximum Period to Recall the Produc from Stores"
                                // style={{ width: "100%" }}
                                placeholder=""
                                multiline
                                style={{ textAlign: "left" }}
                                fullWidth
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "product_recall_period_unit")}
                                //className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                    startAdornment: (
                                        <TextField
                                            position="start"
                                            //variant="filled"
                                            value={this.state.product_recall_period.replace(/[^0-9.]/, "")}
                                            fullWidth
                                            style={{ width: "25%" }}
                                            onChange={(event) => this.handleChange(event, "product_recall_period")}
                                        >
                                            {" "}
                                        </TextField>
                                    ),
                                }}
                            >
                                {product_recall_period_unit.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                select
                                className={classNames(classes.textField)}
                                variant="filled"
                                value={this.state.expiration_period_unit}
                                //variant="filled"
                                id="expiration_period"
                                label="Expiration Pull Out"
                                // style={{ width: "100%" }}
                                placeholder=""
                                multiline
                                style={{ textAlign: "left" }}
                                fullWidth
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "expiration_period_unit")}
                                //className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                    startAdornment: (
                                        <TextField
                                            position="start"
                                            value={this.state.expiration_period.replace(/[^0-9.]/, "")}
                                            fullWidth
                                            style={{ width: "25%" }}
                                            onChange={(event) => this.handleChange(event, "expiration_period")}
                                        >
                                            {" "}
                                        </TextField>
                                    ),
                                }}
                            >
                                {expiration_period_unit.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.product_description}
                                variant="filled"
                                fullWidth
                                id="product_description"
                                label="Product Description"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "product_description")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                value={this.state.product_recommendations}
                                variant="filled"
                                fullWidth
                                id="product_recommendations"
                                label="Product Recommendations"
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "product_recommendations")}
                                className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={6}>
                            <TextField
                                InputLabelProps={{
                                    classes: {
                                        root: classes.textFieldLabel,
                                        disabled: classes.disabled,
                                    },
                                }}
                                className={classNames(classes.textField)}
                                variant="filled"
                                label="Physical Weight"
                                value={this.state.weight.replace(/[^0-9.]/, "")}
                                //variant="filled"
                                id="weight"
                                //label=""
                                style={{ width: "100%" }}
                                placeholder=""
                                multiline
                                //value={"" + this.props.input}
                                onChange={(event) => this.handleChange(event, "weight")}
                                //className={classes.textField}
                                margin="dense"
                                rowsMax={1}
                                InputProps={{
                                    classes: {
                                        root: classes.textFieldRoot,
                                        disabled: classes.disabled,
                                    },
                                    endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                                }}
                            ></TextField>
                        </GridItem>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>

                        <GridItem xs={12} sm={12} md={12}>
                            <Button
                                size="lg"
                                simple
                                disabled={!this.validateSecondForm()}
                                onClick={this.writeProductData}
                                style={{
                                    float: "right",
                                    textTransform: "none",
                                    backgroundColor: COLORS.submit,
                                    color: COLORS.font,
                                    marginTop: 14,
                                    padding: 10,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    // borderColor: "#4BB543",
                                    border: "2",
                                    fontWeight: "400",
                                }}
                                type="submit"
                            >
                                {" "}
                                Save{" "}
                            </Button>
                            <Button
                                simple
                                size="lg"
                                style={{
                                    float: "right",
                                    textTransform: "none",
                                    backgroundColor: COLORS.accent,
                                    color: COLORS.font,
                                    marginTop: 14,
                                    marginRight: 40,
                                    padding: 10,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                    // borderColor: "#4BB543",
                                    border: "2",
                                    fontWeight: "400",
                                }}
                                type="submit"
                                className={classes.navLink}
                                onClick={() => this.goToSupplierProductsListPage(2)}
                            >
                                {" "}
                                Cancel{" "}
                            </Button>
                        </GridItem>
                        <Divider orientation="vertical" xs={3} />
                    </GridContainer>
                );
            } /*else{
            fieldsArray.push(
              <GridContainer xs={12} sm={12} md={12}>
            <div  style={{width: '100%', display:"flex", flexDirection:"row"}}>
                <div className={classes.title} style={{width: "3%"}}>
                    <h4 style={{ fontWeight: "700", backgroundColor: '#91041C', color:"#EEEEEE",height:"30px", 
                    paddingTop:"3px",paddingBottom:"3px",border: "2px solid #575757", borderRightColor:  '#91041C', borderBottomColor: '#91041C'}}></h4>
              
                </div>
                <div className={classes.title} style={{width: "96.7%",marginLeft:"2px"}}>
                    <h4 style={{textAlign:'left', fontWeight: "700", backgroundColor: '#EEEEEE', color:"#91041C",height:"30px", 
                    paddingLeft:"20px", paddingTop:"3px",paddingBottom:"3px",border: "1px solid #000000"}}>Update Product Info. (Product Category)</h4>
                  
                </div>
            </div>

            <GridItem xs={12} sm={12} md={6}>
            <TextField
                    select
                    value={this.state.activity_id}
                    variant="filled"
                    fullWidth
                    id="activity_id"
                    label="Activity"
                    
                    placeholder=""
                    multiline
                    style={{textAlign:'left'}}
                    //value={"" + this.props.input}
                    onChange={(event)=>this.handleChange(event,"activity_id")}
                    className={classes.textField}
                    margin="dense"
                    rowsMax={1}
                    >
                    {activity_list.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                  </TextField> 
            
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
            <TextField
                    select
                    value={this.state.category_id}
                    variant="filled"
                    fullWidth
                    id="category_id"
                    label="Category"
                    
                    placeholder=""
                    multiline
                    style={{textAlign:'left'}}
                    //value={"" + this.props.input}
                    onChange={(event)=>this.handleChange(event,"category_id")}
                    className={classes.textField}
                    margin="dense"
                    rowsMax={1}
                    >
                    {category_list.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                  </TextField> 
            
            </GridItem>
            
            <GridItem xs={12} sm={12} md={6}>
            <TextField
                    select
                    value={this.state.sub_category_id}
                    variant="filled"
                    fullWidth
                    id="sub_category_id"
                    label="Sub_Category"
                    
                    placeholder=""
                    multiline
                    style={{textAlign:'left'}}
                  
                    //value={"" + this.props.input}
                    onChange={(event)=>this.handleChange(event,"sub_category_id")}
                    className={classes.textField}
                    margin="dense"
                    rowsMax={1}
                    >
                    {sub_category_list.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                  </TextField> 
            
            </GridItem>
            
            <GridItem xs={12} sm={12} md={6}>
            <TextField
                    select
                    value={this.state.sub_sub_category_id}
                    variant="filled"
                    fullWidth
                    id="sub_sub_category_id"
                    label="Sub_SubCategory"
                    
                    placeholder=""
                    multiline
                    style={{textAlign:'left'}}
                    
                    //value={"" + this.props.input}
                    onChange={(event)=>this.handleChange(event,"sub_sub_category_id")}
                    className={classes.textField}
                    margin="dense"
                    rowsMax={1}
                    >
                    {sub_subCategory_list.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                  </TextField> 
            
            </GridItem>
            
            <Grid item xs={12}>
                <p></p>
                <Paper className={classes.paper}> </Paper>
            </Grid>
            <Grid item xs={12}>
                <p></p>
                <Paper className={classes.paper}> </Paper>
            </Grid>
    
            <GridItem xs={12} sm={12} md={10}    >
              
              <Button
                simple
                size="lg"
                style={{ float: "right", textTransform: 'none', backgroundColor: '#91041C', color:"#FFFFFF", marginTop:14 , padding:10, paddingLeft:20, paddingRight:20, borderColor:'#4BB543', border:'2', fontWeight: "400"}}
                type="submit"
                className={classes.navLink}
                onClick={()=>this.goToSupplierProductsListPage(2)}
              > Cancel </Button>

            </GridItem>
            <GridItem xs={12} sm={12} md={2}    >
            <Button
              simple
              size="lg"
              disabled={!this.validateThirdForm()}
              onClick={this.writeProductData}
              
              style={{float: "right", textTransform: 'none', backgroundColor: '#4BB543', color:"#FFFFFF", marginTop:14 , padding:10, paddingLeft:20, paddingRight:20, borderColor:'#4BB543', border:'2', fontWeight: "400"}}
              type="submit"
            > Submit </Button>
    
            </GridItem> 
            <Divider orientation="vertical" xs={3} />
            </GridContainer>
              
              
            )
          }
*/
            return (
                <div
                    /*style={{backgroundColor:"#262626", margin:"0px", padding:"0px", height:'100vh'}}*/ className={
                        classes.pageHeader
                    }
                >
                    <div className={`${classes.backdrop} ${this.state.loading ? null : classes.loadingHidden}`}></div>
                    <div className={`${classes.loaderContainer} ${this.state.loading ? null : classes.loadingHidden}`}>
                        <CircularProgress />
                    </div>

                    <div>
                        <div
                            style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}
                            className={classNames(classes.textCenter, classes.container)}
                        >
                            <div style={{ width: "19%" }}>
                                <Grid style={{}} xs={12} sm={12} md={12}>
                                    <Card
                                        style={{
                                            backgroundColor: "#fff",
                                            boxShadow: "#96a1a9 0 4px 5px 0px",
                                        }}
                                    >
                                        <CardBody style={{ padding: "5px" }}>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <Button
                                                    simple
                                                    size="lg"
                                                    // disabled={!this.validateFirstForm()}
                                                    onClick={() => this.handleChangeFormNumber(1)}
                                                    style={
                                                        this.state.form_number === 1
                                                            ? {
                                                                  paddingLeft: "10px",
                                                                  paddingRight: "0",
                                                                  paddingTop: "10px",
                                                                  paddingBottom: "10px",
                                                                  textTransform: "none",
                                                                  fontWeight: "700",
                                                                  backgroundColor: COLORS.primary,
                                                                  color: COLORS.font,
                                                                  width: "100%",
                                                                  height: "100%",
                                                                  justifyContent: "left",
                                                                  borderRadius: "0",
                                                              }
                                                            : {
                                                                  paddingLeft: "10px",
                                                                  paddingRight: "0",
                                                                  paddingTop: "5px",
                                                                  paddingBottom: "5px",
                                                                  textTransform: "none",
                                                                  fontWeight: "700",
                                                                  backgroundColor: "transparent",
                                                                  color: "#000000",
                                                                  width: "100%",
                                                                  height: "100%",
                                                                  justifyContent: "left",
                                                                  borderRadius: "0",
                                                              }
                                                    }
                                                >
                                                    {" "}
                                                    Basic Information{" "}
                                                </Button>
                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={12}>
                                                <Button
                                                    simple
                                                    size="lg"
                                                    // disabled={!this.validateFirstForm()}
                                                    disabled={!this.validateFirstForm()}
                                                    onClick={() => this.handleChangeFormNumber(2)}
                                                    style={
                                                        this.state.form_number === 2
                                                            ? {
                                                                  paddingLeft: "10px",
                                                                  paddingRight: "0",
                                                                  paddingTop: "10px",
                                                                  paddingBottom: "10px",
                                                                  textTransform: "none",
                                                                  fontWeight: "700",
                                                                  backgroundColor: COLORS.primary,
                                                                  color: COLORS.font,
                                                                  width: "100%",
                                                                  height: "100%",
                                                                  justifyContent: "left",
                                                                  borderRadius: "0",
                                                              }
                                                            : {
                                                                  paddingLeft: "10px",
                                                                  paddingRight: "0",
                                                                  paddingTop: "5px",
                                                                  paddingBottom: "5px",
                                                                  textTransform: "none",
                                                                  fontWeight: "700",
                                                                  backgroundColor: "transparent",
                                                                  color: "#000000",
                                                                  width: "100%",
                                                                  height: "100%",
                                                                  justifyContent: "left",
                                                                  borderRadius: "0",
                                                              }
                                                    }
                                                >
                                                    {" "}
                                                    Additional Information{" "}
                                                </Button>
                                            </GridItem>
                                        </CardBody>
                                    </Card>
                                </Grid>
                            </div>
                            <div
                                container
                                spacing={0}
                                align="center"
                                justify="center"
                                direction="column"
                                className={classes.container}
                                pl={5}
                                style={{ width: "80%" }}
                            >
                                <Grid xs={12} sm={12} md={12}>
                                    <Card color="primary">
                                        <CardBody>{fieldsArray}</CardBody>
                                    </Card>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                    </div>

                    <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                        <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.state.dialogCloseHandler} style={{ backgroundColor: COLORS.accent }}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }
}
UpdateProductInfo.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(componentsStyle)(UpdateProductInfo);
