import React from "react";

//related to meterial ui package
import styles from "./styles";
import { Button, CircularLoader, Header } from "../../core-ui/custom";
import { FORM_SCHEMA } from "./schema";
import { FieldArray, Form, Formik } from "formik";
import { Card, Grid, Typography } from "@material-ui/core";
import { Autocomplete, DateTimePicker, SubmitButton, TextField } from "../../core-ui/forms-ui";
import { Asterisk } from "../../components";

import useAddPromotionPrices from "../../hooks/use-add-promotion-prices";
import DeleteIcon from "@material-ui/icons/Delete";

const AddCompetitorProduct = () => {
    const classes = styles();
    const { initValues, handleSubmit, initializing, saving, products } = useAddPromotionPrices();

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.pageHeader}>Add Promotion Prices</Header>
            {initializing && <CircularLoader className={classes.circularProgress} />}
            <Formik
                initialValues={initValues}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={FORM_SCHEMA}
            >
                {(formik) => {
                    if (initializing) return null;

                    const filteredProducts = products.filter((p) => {
                        const index = formik.values.products.findIndex(
                            (selected) => selected.product_id.value === p.value
                        );
                        if (index === -1) return true;
                        else return false;
                    });

                    return (
                        <Form>
                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Promotion Information
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="name"
                                                label={<Asterisk>Name</Asterisk>}
                                                className={classes.input}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField name="number" label={"Number"} className={classes.input} />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <DateTimePicker
                                                name="start_date"
                                                label={<Asterisk>Start Date</Asterisk>}
                                                className={classes.input}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <DateTimePicker
                                                name="end_date"
                                                label={<Asterisk>End Date</Asterisk>}
                                                className={classes.input}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>

                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Products
                                </Typography>
                                <Card className={classes.formCard}>
                                    <FieldArray name="products">
                                        {({ push, remove }) => {
                                            return (
                                                <Grid container spacing={3}>
                                                    {formik.values.products.map((_, index) => {
                                                        const productsList = [...filteredProducts];
                                                        if (formik.values.products[index]?.product_id) {
                                                            productsList.unshift(
                                                                formik.values.products[index]?.product_id
                                                            );
                                                        }
                                                        return (
                                                            <Grid
                                                                key={`prices-${index}`}
                                                                item
                                                                container
                                                                spacing={2}
                                                                xs={12}
                                                            >
                                                                <Grid item xs={8} sm={9}>
                                                                    <Autocomplete
                                                                        name={`products[${index}].product_id`}
                                                                        options={productsList}
                                                                        className={classes.input}
                                                                        classes={{
                                                                            option: classes.option,
                                                                        }}
                                                                        loading={initializing}
                                                                        disableClearable
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={3} sm={2}>
                                                                    <TextField
                                                                        name={`products[${index}].price`}
                                                                        label={"Price"}
                                                                        className={classes.input}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <span className={classes.endAdornment}>
                                                                                    SR
                                                                                </span>
                                                                            ),
                                                                        }}
                                                                        type="number"
                                                                        blockInvalidNumberCharacters
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={1} sm={1}>
                                                                    <Button
                                                                        className={classes.deleteBtn}
                                                                        onClick={() => remove(index)}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}

                                                    {typeof formik.errors.products === "string" && (
                                                        <p className={classes.errorMsg}>{formik.errors?.products}</p>
                                                    )}

                                                    <Grid item xs={12}>
                                                        <Button
                                                            className={classes.addProductBtn}
                                                            disabled={saving}
                                                            onClick={() =>
                                                                push({
                                                                    product_id: filteredProducts[0] ?? null,
                                                                    price: 0,
                                                                })
                                                            }
                                                        >
                                                            Add Product
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            );
                                        }}
                                    </FieldArray>
                                </Card>
                            </div>

                            <div className={classes.cardContainer}>
                                <SubmitButton
                                    type="submit"
                                    className={classes.submitBtn}
                                    loadingClassName={classes.circularProgress}
                                    submitting={saving}
                                >
                                    Create
                                </SubmitButton>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </section>
    );
};

export default AddCompetitorProduct;
