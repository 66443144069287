import { cloneDeep } from "lodash";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import COLORS from "../constants/colors";
import PATHS from "../constants/paths";
import { useAuth } from "../contexts/auth-context";
import { INITIAL_VALUES, FORM_SCHEMA, STATUS } from "../pages/update-competitor-product/schema";
import { getCompetitorProduct, setCompetitorProduct } from "../services/firestore/Competitor_Products";
import useCompetitorProduct from "./use-competitor-product";

const useUpdateCompetitorProduct = () => {
    const { companyData } = useAuth();
    const history = useHistory();
    const { id: product_id } = useParams();
    const [authorized, setAuthorized] = useState(true);
    const [loading, setLoading] = useState(false);
    const [initValues, setInitValues] = useState(INITIAL_VALUES);

    const onAfterInit = async (categories, subcategories, subsubcategories, brands) => {
        try {
            setLoading(true);
            const product = (await getCompetitorProduct(product_id)).data();
            if (!product || product.supplier_id !== companyData.company_id) {
                setAuthorized(false);
                setLoading(false);
                return;
            }
            let category = categories.find((c) => c.value === product.product_category.category_id);
            //select unclassified by default
            if (!category) category = categories.find((c) => c.value === "110");

            let subcategory = subcategories.find((sc) => sc.value === product.product_category.sub_category_id);
            //select first matched sub category by default
            if (!subcategory) subcategory = subcategories.find((sc) => sc.data.category_id === category.value);

            let subsubcategory = subsubcategories.find(
                (ssc) => ssc.value === product.product_category.sub_sub_category_id
            );
            if (!subsubcategories)
                subsubcategory = subsubcategories.find((ssc) => ssc.data.sub_category_id === subcategory.value);

            //select options for autocomplete inputs
            let brand = brands.find((b) => b.value === product.brand_id) ?? null;

            setInitValues((prev) => ({
                ...prev,
                ...product,
                brand_id: brand,

                product_category: {
                    category_id: category,
                    sub_category_id: subcategory,
                    sub_sub_category_id: subsubcategory,
                },
            }));

            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const {
        initializing,
        saving,
        setSaving,
        categoriesList,
        subcategoriesList,
        subsubcategoriesList,
        brandsList,
        UNCALSSIFIED_CATEGORY,
        UNCALSSIFIED_SUBCATEGORY,
        UNCALSSIFIED_SUB_SUBCATEGORY,
    } = useCompetitorProduct({ onAfterInit });

    const castValues = (values) => {
        //get values from auto complete
        const newProduct = cloneDeep(FORM_SCHEMA.cast(values));
        newProduct.status = newProduct.status.value ?? STATUS[0].value;
        newProduct.brand_id = newProduct.brand_id?.value ?? null;
        newProduct.product_category.category_id =
            newProduct.product_category.category_id.value ??
            categoriesList.find((c) => c.value === UNCALSSIFIED_CATEGORY).value;
        newProduct.product_category.sub_category_id =
            newProduct.product_category.sub_category_id.value ??
            subcategoriesList.find((c) => c.value === UNCALSSIFIED_SUBCATEGORY).value;
        newProduct.product_category.sub_sub_category_id =
            newProduct.product_category.sub_sub_category_id.value ??
            subsubcategoriesList.find((c) => c.value === UNCALSSIFIED_SUB_SUBCATEGORY).value;

        return newProduct;
    };

    const handleSubmit = async (values, helpers) => {
        try {
            setSaving(true);

            const newValues = castValues(values);

            const data = { ...newValues };
            const updatedProduct = await setCompetitorProduct(data);

            const result = await Swal.fire({
                title: `Product has been Updated!`,
                text: `Product ${updatedProduct.En_name} has been successfully updated.`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
            });

            if (!result.value) {
                return false;
            } else {
                // history.push({
                //     pathname: PATHS.PRODUCTS,
                //     state: {
                //         selfService: true,
                //         projectType: project.project_type,
                //         id: project.supplier_id,
                //         // mpId: this.props.id,
                //         projectId: project.project_id,
                //     },
                // });
                setSaving(false);
                history.push({ pathname: PATHS.COMPETITOR_PRODUCTS });
            }
        } catch (error) {
            console.log(error);
            setSaving(false);
        }
    };

    return {
        initValues,
        setInitValues,
        handleSubmit,
        initializing,
        saving,
        loading,

        authorized,

        categoriesList,
        subcategoriesList,
        subsubcategoriesList,
        brandsList,
    };
};

export default useUpdateCompetitorProduct;
