import { lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

const styles = makeStyles({
    scheduleHeaderCell: {
        borderBottom: `2px solid ${COLORS.ACCENT}`,
        backgroundColor: `${COLORS.ACCENT}21`,
        textAlign: "center",
    },

    addOutletBranchCell: {
        padding: 5,
    },

    addOutletBranchBtn: {
        margin: 0,
        width: "100%",
        backgroundColor: "white",
        color: "black",
        border: "1px dashed green",
        "&:hover": {
            backgroundColor: "lightgrey",
        },
    },

    outletBranchCell: {
        maxWidth: "250px",
        whiteSpace: "break-spaces",
    },

    option: {
        padding: "2px 8px",
        fontSize: "0.8rem",
    },

    input: {
        "& .MuiInputBase-root": {
            fontSize: "0.8rem",
        },

        "& .MuiInputLabel-outlined": {
            fontSize: "0.7rem",
            transform: "translate(14px, 12px) scale(1)",
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
            fontSize: "0.7rem",
            transform: "translate(10px, 12px) scale(1)",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            padding: "6px 4px",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "4px 4px",
        },

        "& .MuiOutlinedInput-notchedOutline legend": {
            fontSize: "0.5rem",
        },

        "& .MuiFormHelperText-root.Mui-error": {
            position: "absolute",
            top: "35px",
            left: 0,
            fontSize: "0.6rem",
            margin: 0,
        },

        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },

    deleteBtn: {
        border: "none",
        color: COLORS.ACCENT,
        transition: "color 0.5s",

        "&:hover, &:focus": {
            border: "none",
            backgroundColor: "transparent",
            color: lighten(COLORS.ACCENT, 0.3),
        },
    },
});

export default styles;
