import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import componentsStyle from "../../core-ui/core-styles/jss/general-component-style";
import ProjectController from "./Content/ProjectController";
import PATHS from "../../constants/paths";
import { withRouter } from "react-router";

class ManageProject extends React.Component {
    state = {
        loading: false,
        dialogOpen: false,
        dialogHeader: "",
        dialogMsg: "",
        dialogCloseHandler: null,
        uid: this.props.uid,
        projectID: this.props.projectId,
        supplierID: this.props.id,
    };

    onBackClicked = () => {
        // const { projectType } = this.props;
        let path = PATHS.OUTSOURCE_PROJECTS;

        this.props.history.push({
            pathname: path,
        });
    };
    render() {
        const { projectId, projectType, classes, history } = this.props;
        if (!projectId || !projectType) {
            history.push(PATHS.OUTSOURCE_PROJECTS);
            return null;
        }
        return (
            <div className={classes.pageHeader}>
                <ProjectController
                    supplierID={this.state.supplierID}
                    uid={this.state.uid}
                    projectID={this.state.projectID}
                    projectType={projectType}
                    onBackClicked={this.onBackClicked}
                />

                <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                    <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.state.dialogCloseHandler} style={{ backgroundColor: "#91041c" }}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ManageProject.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(componentsStyle)(withRouter(ManageProject));
