import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Tooltip } from "@material-ui/core";
import styles from "./styles";

import { formatDate } from "../../helpers/date-utils";
import { VideoDetailsDialog } from "..";
import ReactPlayer from "react-player/vimeo";

const VideoCard = ({ video }) => {
    const classes = styles();
    const [isDialogOpened, setIsDialogOpened] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [duration, setDuration] = useState("00:00");

    const onDuration = (seconds) => {
        let newDuration =
            seconds < 3600
                ? new Date(seconds * 1000).toISOString().slice(14, 19)
                : new Date(seconds * 1000).toISOString().slice(11, 19);

        setDuration(newDuration);
    };
    return (
        <Card className={classes.root}>
            {/* <video src={video.url} muted className={classes.videoThumbnail} /> */}
            <CardContent className={classes.cardContent}>
                <ReactPlayer
                    url={video.url}
                    width={"100%"}
                    height="100%"
                    style={{ background: "black" }}
                    onDuration={onDuration}
                    onReady={() => setIsLoaded(true)}
                />
                {isLoaded && <div className={classes.videoDuration}>{duration}</div>}
            </CardContent>

            <CardActionArea onClick={() => setIsDialogOpened(true)}>
                <CardContent>
                    <Tooltip title={video.title}>
                        <Typography className={classes.cardHeader} gutterBottom variant="h5" component="h2">
                            {video.title}
                        </Typography>
                    </Tooltip>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {video.description}
                    </Typography>
                    <Typography className={classes.dateTime} variant="body2" color="textSecondary" component="p">
                        {formatDate(video.timestamp.toDate())}
                    </Typography>
                </CardContent>
            </CardActionArea>

            {<VideoDetailsDialog open={isDialogOpened} onClose={() => setIsDialogOpened(false)} video={video} />}
        </Card>
    );
};

export default VideoCard;
