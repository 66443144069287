import { firestore as db } from "../firebase";

export const getVAT = async () => {
    let vat = null;
    try {
        const today = new Date();

        vat = (await db.collection("VAT").where("start_date", "<=", today).orderBy("start_date", "desc").limit(1).get())
            .docs;

        if (vat.length > 0) {
            vat = vat[0];
        } else {
            vat = null;
        }
    } catch (error) {
        console.log(error);
    }

    return vat;
};
