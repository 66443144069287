import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// core components
import GridContainer from "../components-lagacy/Grid/GridContainer";
import GridItem from "../components-lagacy/Grid/GridItem";

import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "../core-ui/material-kit-ui/cards/Card";
import CardBody from "../core-ui/material-kit-ui/cards/CardBody";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "../core-ui/material-kit-ui/buttons/Button";
//import { ReCaptcha } from "react-recaptcha-google";
import Paper from "@material-ui/core/Paper";
import { Redirect } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
//const callback = function () {};
//const expiredCallback = function () {};
import Swal from "sweetalert2";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CardHeader from "@material-ui/core/CardHeader";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { COLORS } from "../constants/constants-lagacy";
import PATHS from "../constants/paths";

import { firestore as db } from "../services/firebase";

class EditContractExtraDisplayInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contract_no: "",
            date_from: new Date(),
            date_to: new Date(),
            date_of_contract: new Date(),
            extra_display_type: [],
            check_extra_display_type: false,
            outlets: [],
            outlet_id: "",
            description: "",
            status: "0",
            used: false,
            pathBack: "",

            loading: false,
            dialogOpen: false,
            dialogHeader: "",
            dialogMsg: "",
            dialogCloseHandler: null,
        };
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    //==================================================================================
    showDialogMsg = (title, msg, closeHandler) => {
        this.setState({
            loading: false,
            dialogHeader: title,
            dialogMsg: msg,
            dialogOpen: true,
            dialogCloseHandler: closeHandler,
        });
    };

    hideDialogMsg = () => {
        this.setState({ dialogMsg: "", dialogOpen: false });
    };

    componentDidMount() {
        let extra_display_type = [];
        var extraDisplayTypesRef = db.collection("Extra_Display_Price").where("status", "==", "1");
        extraDisplayTypesRef
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    let data = {
                        extra_display_type: doc.data().extra_display_type,
                        extra_display_price_id: doc.data().extra_display_price_id,
                        selected: false,
                    };
                    extra_display_type.push(data);
                });
                //this.setState({extra_display_type: extra_display_type});
                // let datat2 = [];
                let check_extra_display_type = false;
                db.collection("Contract_Extra_Display")
                    .doc(this.props.contractID)
                    .get()
                    .then((doc) => {
                        let datat2 = doc.data();
                        for (let i = 0; i < datat2.extra_display_type.length; i++) {
                            let index = extra_display_type.findIndex((post) => {
                                return post.extra_display_price_id === datat2.extra_display_type[i];
                            });

                            if (index !== -1) {
                                extra_display_type[index].selected = true;
                                check_extra_display_type = true;
                            }
                        }

                        this.setState(() => ({
                            contract_id: datat2.contract_id,
                            contract_no: datat2.contract_no,
                            date_from: datat2.date_from.toDate(),
                            date_to: datat2.date_to.toDate(),
                            date_of_contract: datat2.date_of_contract.toDate(),
                            outlet_id: datat2.outlet_id,
                            status: datat2.status,
                            extra_display_type: extra_display_type,
                            description: datat2.description || "",
                            used: datat2.used || false,

                            check_extra_display_type: check_extra_display_type,
                        }));
                    })
                    .catch((err) => {
                        console.log("Error getting documents", err);
                    });
            })
            .catch((err) => {
                console.log("Error getting documents", err);
            });

        let outletsWithSupplier = [];
        let outlets = [];
        var outletsWithSupplierRef = db.collection("Supplier_Outlet_Product").where("company_id", "==", this.props.id);
        outletsWithSupplierRef
            .get()
            .then((snapshot) => {
                if (snapshot.size > 0) {
                    db.collection("Supplier_Outlet_Product")
                        .doc(this.props.id)
                        .collection("Outlet_Product")
                        .get()
                        .then((snapshot2) => {
                            snapshot2.forEach((doc) => {
                                outletsWithSupplier.push({
                                    outlet_id: doc.data().outlet_id,
                                });
                            });
                        })
                        .then(() => {
                            for (let i = 0; i < outletsWithSupplier.length; i++) {
                                var outletsRef = db
                                    .collection("Outlet_Branch")
                                    .where("outlet_id", "==", outletsWithSupplier[i].outlet_id);
                                outletsRef
                                    .get()
                                    .then((snapshot2) => {
                                        snapshot2.forEach((doc) => {
                                            if (doc.data().status === "1") {
                                                outlets.push({
                                                    value: doc.data().branch_id,
                                                    label: doc.data().En_short_name,
                                                });
                                            }
                                        });
                                        this.setState({ outlets: outlets });
                                    })
                                    .catch((err) => {
                                        console.log("Error getting documents", err);
                                    });
                            }
                        })
                        .catch((err) => {
                            console.log("Error getting documents", err);
                        });
                } else {
                    var outletsRef = db.collection("Outlet_Branch").where("status", "==", "1");
                    outletsRef
                        .get()
                        .then((snapshot2) => {
                            snapshot2.forEach((doc) => {
                                outlets.push({
                                    value: doc.data().branch_id,
                                    label: doc.data().En_short_name,
                                });
                            });
                            this.setState({ outlets: outlets });
                        })
                        .catch((err) => {
                            console.log("Error getting documents", err);
                        });
                }
            })
            .catch((err) => {
                console.log("Error getting documents", err);
            });
    }

    onLoadRecaptcha() {
        if (this.captchaDemo) {
            this.captchaDemo.reset();
            this.captchaDemo.execute();
        }
    }

    verifyCallback(recaptchaToken) {
        // Here you will get the final recaptchaToken!!!
    }

    validateForm() {
        let validate =
            this.state.contract_no.length > 0 && this.state.outlet_id.length > 0 && this.state.check_extra_display_type;

        return validate;
    }

    handleChange = (event, id) => {
        this.setState({ [id]: event.target.value });
    };

    handleChangeExteraDisplayTypeSelected = (id) => {
        if (this.state.used) return;
        let index = this.state.extra_display_type.findIndex((post) => {
            return post.extra_display_price_id === id;
        });

        let extra_display_type = [...this.state.extra_display_type];
        extra_display_type[index].selected = !extra_display_type[index].selected;

        let index2 = this.state.extra_display_type.findIndex((post) => {
            return post.selected === true;
        });

        this.setState({
            extra_display_type: extra_display_type,
            check_extra_display_type: index2 !== -1 ? true : false,
        });
    };

    handleChangeDateFrom = (date) => {
        this.setState({ date_from: date });
    };
    handleChangeDateTo = (date) => {
        this.setState({ date_to: date });
    };
    handleChangeDateOfContract = (date) => {
        this.setState({ date_of_contract: date });
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    handleClickShowConfPassword = () => {
        this.setState({ showConfPassword: !this.state.showConfPassword });
    };
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    writeContractExtraDisplayData = async (e) => {
        let contract_no = this.state.contract_no === undefined ? null : this.state.contract_no;
        let outlet_id = this.state.outlet_id === undefined ? null : this.state.outlet_id;
        let extra_display_type = this.state.extra_display_type.filter((exType) => exType.selected);
        this.setState({ loading: true });
        try {
            if (this.state.used) {
                await this.addContract();
                return;
            }
            let extraDisplayContracts = (
                await db
                    .collection("Contract_Extra_Display")
                    .where("contract_no", "==", contract_no)
                    .where("outlet_id", "==", outlet_id)
                    .where("supplier_id", "==", this.props.id)
                    .get()
            ).docs;

            if (extraDisplayContracts.length === 0) {
                await this.addContract();
                this.setState({ loading: false });
                return;
            }

            //find conflicts
            extraDisplayContracts = extraDisplayContracts.map((doc) => doc.data());
            let exIntersections = [];
            for (const contract of extraDisplayContracts) {
                let contractIntersections = extra_display_type.filter(
                    (type) => contract.extra_display_type.indexOf(type.extra_display_price_id) !== -1
                );
                exIntersections = [...new Set([...exIntersections, ...contractIntersections])];
            }

            if (exIntersections.length === 0) {
                await this.addContract();
                this.setState({ loading: false });
                return;
            }

            exIntersections = exIntersections.map((ex) => ex.extra_display_type);
            const result = await Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                title: "Warning",
                text: `Similar Extra Display types with the same contract No. and outlet Branch have been detected: ${exIntersections.join(
                    ", "
                )}.
                        Are sure to add them anyway?`,
            });

            if (result.value) await this.addContract();
        } catch (error) {
            console.log(error);
            this.showDialogMsg("Alert", "There is something wrong!", this.hideDialogMsg);
        }
        this.setState({ loading: false });
    };

    addContract = async () => {
        const contractID = this.props.contractID;
        let contract_no = this.state.contract_no === undefined ? null : this.state.contract_no;
        let outlet_id = this.state.outlet_id === undefined ? null : this.state.outlet_id;
        let date_from = this.state.date_from === undefined ? null : this.state.date_from;
        let date_to = this.state.date_to === undefined ? null : this.state.date_to;
        let date_of_contract = this.state.date_of_contract === undefined ? null : this.state.date_of_contract;
        const description = this.state.description || "";
        let extra_display_type = this.state.extra_display_type
            .filter((exType) => exType.selected)
            .map((exType) => exType.extra_display_price_id);

        try {
            let updated = {
                date_from: date_from,
                date_to: date_to,
                date_of_contract: date_of_contract,
                status: "1",
                description: description,
            };

            if (!this.state.used) {
                updated = {
                    ...updated,
                    contract_no: contract_no,
                    extra_display_type: extra_display_type,
                    outlet_id: outlet_id,
                };
            }

            await db.collection("Contract_Extra_Display").doc(contractID).update(updated);

            this.showDialogMsg("Contract Updated", "The Contract has been Updated Successfully.", () => {
                this.hideDialogMsg();
                this.setState({ pathBack: PATHS.EXTRA_DISPLAY_CONTRACTS });
            });
        } catch (error) {
            console.log(error);
            this.showDialogMsg("Alert", "There is something wrong!", this.hideDialogMsg);
        }
    };

    goToContractExtraDisplayListPage = (number) => {
        //1 for submit and 2 for cancel
        if (number === 1) {
            this.setState({ pathBack: PATHS.EXTRA_DISPLAY_CONTRACTS });
        } else {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Discard All Changes!",
                text: "Are you sure you want to discard the changes?",
            }).then((result) => {
                if (!result.value) {
                    this.setState({ pathBack: PATHS.EXTRA_DISPLAY_CONTRACTS });
                }
            });
        }
    };
    //==================================================================================
    render() {
        const { classes } = this.props;
        if (this.state.pathBack.length > 0) {
            return <Redirect push={true} to={{ pathname: this.state.pathBack }} />;
        } else {
            let extra_display_type = [];

            extra_display_type.push(
                <GridItem key={"ex-types"} align="center" xs={12} sm={12} md={12}>
                    <Card style={{ width: "70%", align: "center" }}>
                        <CardHeader
                            style={{
                                background: COLORS.background,
                                color: COLORS.primary,
                                paddingLeft: "0px",
                                margin: "0px",
                                paddingTop: "5px",
                                paddingBottom: "0px",
                                //"rgba(145, 4, 28,0.83)", "#91041C"
                            }}
                            className={classes.cardHeader}
                            title={
                                <p
                                    style={{
                                        color: COLORS.primary,
                                        fontSize: "17px",
                                        paddingBottom: "0px",
                                        margin: "0px",
                                    }}
                                >
                                    {"Extra Display Types"}
                                </p>
                            }
                            subheader={
                                <p style={{ color: COLORS.primary, fontSize: "13px", paddingTop: "0px" }}>{`select`}</p>
                            }
                        />
                        <Divider />
                        <List className={classes.list} dense component="div" role="list">
                            {this.state.extra_display_type.map((row) => (
                                <ListItem
                                    style={{ padding: "0", margin: "0" }}
                                    key={row.extra_display_price_id}
                                    role="listitem"
                                    button
                                    onClick={() =>
                                        this.handleChangeExteraDisplayTypeSelected(row.extra_display_price_id)
                                    }
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            style={{
                                                color: COLORS.primary,
                                            }}
                                            checked={row.selected}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ "aria-labelledby": row.selected }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={row.extra_display_price_id}
                                        primary={`${row.extra_display_type}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Card>
                </GridItem>
            );

            const state_list = [
                {
                    value: "1",
                    label: "Active",
                },
                {
                    value: "0",
                    label: "Not Active",
                },
            ];

            return (
                <div className={classes.pageHeader}>
                    <div className={`${classes.backdrop} ${this.state.loading ? null : classes.loadingHidden}`}></div>
                    <div className={`${classes.loaderContainer} ${this.state.loading ? null : classes.loadingHidden}`}>
                        <CircularProgress />
                    </div>

                    <div>
                        <div
                            align="center"
                            justify="center"
                            style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}
                            className={classes.container}
                        >
                            <div pl={5} style={{ width: "100%", marginBottom: "20px" }}>
                                <Grid>
                                    <Card color="primary">
                                        <CardBody>
                                            <GridContainer>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className={classes.title} style={{ width: "3%" }}>
                                                        <div
                                                            style={{
                                                                fontWeight: "700",
                                                                backgroundColor: COLORS.primary,
                                                                color: "#EEEEEE",
                                                                height: "30px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "2px solid #575757",
                                                                borderRightColor: COLORS.primary,
                                                                borderBottomColor: COLORS.primary,
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div
                                                        className={classes.title}
                                                        style={{ width: "96.7%", marginLeft: "2px" }}
                                                    >
                                                        <h4
                                                            style={{
                                                                textAlign: "left",
                                                                fontWeight: "700",
                                                                backgroundColor: COLORS.background,
                                                                color: COLORS.primary,
                                                                height: "30px",
                                                                paddingLeft: "10px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "1px solid #000000",
                                                            }}
                                                        >
                                                            Update Extra Display Contract
                                                        </h4>
                                                    </div>
                                                </div>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        value={this.state.contract_no}
                                                        variant="filled"
                                                        disabled={this.state.used}
                                                        fullWidth
                                                        id="contract_no"
                                                        label="Contract No.*"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "contract_no")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <MuiPickersUtilsProvider
                                                        style={{ selectColor: "#91041C" }}
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.textFieldLabel,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            //disableToolbar
                                                            className={classes.textField}
                                                            fullWidth
                                                            multiline
                                                            inputVariant="filled"
                                                            format="dd/MM/yyyy"
                                                            // format="MM/dd/yyyy"
                                                            margin="dense"
                                                            id="date_of_contract"
                                                            label="Date of Contract*"
                                                            // selectColor="#91041C"
                                                            // InputProps={{  }}
                                                            style={{ selectColor: "#91041C" }}
                                                            // value={this.state.expiry_date==='' ? this.state.bufDate :this.state.expiry_date}
                                                            value={this.state.date_of_contract}
                                                            onChange={this.handleChangeDateOfContract}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        select
                                                        value={this.state.outlet_id}
                                                        variant="filled"
                                                        fullWidth
                                                        disabled={this.state.used}
                                                        id="outlet_id"
                                                        label="Outlet"
                                                        style={{ textAlign: "left" }}
                                                        multiline
                                                        onChange={(event) => this.handleChange(event, "outlet_id")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    >
                                                        {this.state.outlets.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <MuiPickersUtilsProvider
                                                        style={{ selectColor: "#91041C" }}
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.textFieldLabel,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            //disableToolbar
                                                            className={classes.textField}
                                                            fullWidth
                                                            multiline
                                                            inputVariant="filled"
                                                            format="dd/MM/yyyy"
                                                            // format="MM/dd/yyyy"
                                                            margin="dense"
                                                            id="date_from"
                                                            label="From*"
                                                            // selectColor="#91041C"
                                                            // InputProps={{  }}
                                                            style={{ selectColor: "#91041C" }}
                                                            // value={this.state.expiry_date==='' ? this.state.bufDate :this.state.expiry_date}
                                                            value={this.state.date_from}
                                                            onChange={this.handleChangeDateFrom}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <MuiPickersUtilsProvider
                                                        style={{ selectColor: "#91041C" }}
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.textFieldLabel,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            //disableToolbar
                                                            className={classes.textField}
                                                            fullWidth
                                                            multiline
                                                            inputVariant="filled"
                                                            format="dd/MM/yyyy"
                                                            // format="MM/dd/yyyy"
                                                            margin="dense"
                                                            id="date_to"
                                                            label="to*"
                                                            // selectColor="#91041C"
                                                            // InputProps={{  }}
                                                            style={{ selectColor: "#91041C" }}
                                                            // value={this.state.expiry_date==='' ? this.state.bufDate :this.state.expiry_date}
                                                            value={this.state.date_to}
                                                            onChange={this.handleChangeDateTo}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        select
                                                        value={this.state.status}
                                                        variant="filled"
                                                        fullWidth
                                                        id="status"
                                                        label="Status*"
                                                        disabled={this.state.owner}
                                                        style={{ textAlign: "left" }}
                                                        multiline
                                                        onChange={(event) => this.handleChange(event, "status")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    >
                                                        {state_list.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        value={this.state.description}
                                                        variant="filled"
                                                        fullWidth
                                                        id="description"
                                                        label="Description*"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "description")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>

                                                {extra_display_type}

                                                <Grid item xs={12}>
                                                    <p></p>
                                                    <Paper className={classes.paper}> </Paper>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <p></p>
                                                    <Paper className={classes.paper}> </Paper>
                                                </Grid>
                                                <GridItem xs={12} sm={12} md={10}>
                                                    <Button
                                                        simple
                                                        size="lg"
                                                        style={{
                                                            float: "right",
                                                            textTransform: "none",
                                                            backgroundColor: COLORS.accent,
                                                            color: COLORS.font,
                                                            marginTop: 14,
                                                            padding: 10,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            // borderColor: "#4BB543",
                                                            border: "2",
                                                            fontWeight: "400",
                                                        }}
                                                        type="submit"
                                                        className={classes.navLink}
                                                        onClick={() => this.goToContractExtraDisplayListPage(2)}
                                                    >
                                                        {" "}
                                                        Cancel{" "}
                                                    </Button>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <Button
                                                        simple
                                                        size="lg"
                                                        disabled={!this.validateForm()}
                                                        onClick={this.writeContractExtraDisplayData}
                                                        style={{
                                                            float: "left",
                                                            textTransform: "none",
                                                            backgroundColor: COLORS.submit,
                                                            color: COLORS.font,
                                                            marginTop: 14,
                                                            padding: 10,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            // borderColor: "#4BB543",
                                                            border: "2",
                                                            fontWeight: "400",
                                                        }}
                                                        type="submit"
                                                    >
                                                        {" "}
                                                        Save{" "}
                                                    </Button>
                                                </GridItem>
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </Grid>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                    </div>

                    <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                        <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.state.dialogCloseHandler} style={{ backgroundColor: COLORS.accent }}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }
}
EditContractExtraDisplayInfo.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(componentsStyle)(EditContractExtraDisplayInfo);
