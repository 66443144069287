// import { cloneDeep } from "lodash";
// import { generateNewDocID } from "../../helpers/firebase-helpers";
import { firestore as db } from "../firebase";
// import { uploadImage } from "../storage";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

export const queryPromotionPrices = async (q) => {
    try {
        return await query(COLLECTIONS.PROMOTION_PRICES, q);
    } catch (error) {
        throw error;
    }
};

export let getPromotionPrices = async (id) => {
    let promotionPrices = null;
    try {
        promotionPrices = await db.collection(COLLECTIONS.PROMOTION_PRICES).doc(id).get();
    } catch (error) {
        console.log(error);
    }

    return promotionPrices;
};

export let getPromotionsPrices = async (IDs) => {
    let promotionsPrices = [];

    try {
        promotionsPrices = await Promise.all(IDs.map(getPromotionPrices));
    } catch (error) {
        console.log(error);
    }
    return promotionsPrices;
};

export const addPromotionPrices = async (values) => {
    try {
        const ref = db.collection(COLLECTIONS.PROMOTION_PRICES).doc();
        values.id = ref.id;

        await ref.set(values);
        return values;
    } catch (error) {
        throw error;
    }
};
export const updatePromotionPrices = async (values) => {
    try {
        await db.collection(COLLECTIONS.PROMOTION_PRICES).doc(values.id).update(values);
        return values;
    } catch (error) {
        throw error;
    }
};
