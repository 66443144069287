import { PLATFORM } from "../../constants/global";
import { firestore as db, firestoreWorker } from "../firebase";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

const USER_COLLECTION = "User";

export const queryUser = async (q) => {
    try {
        return await query(COLLECTIONS.USER, q);
    } catch (error) {
        throw error;
    }
};

export const addUser = async (user) => {
    try {
        user.platform = PLATFORM;
        await firestoreWorker.collection(USER_COLLECTION).doc(user.uid).set(user);

        return user;
    } catch (error) {
        throw error;
    }
};

export const getUser = async (id) => {
    try {
        return await db.collection(USER_COLLECTION).doc(id).get();
    } catch (error) {
        throw error;
    }
};

export const verifyUser = async (id) => {
    try {
        await db.collection(USER_COLLECTION).doc(id).update({ is_verified: true });
    } catch (error) {
        throw error;
    }
};

export const updateUser = async (id, data) => {
    try {
        return db.collection(USER_COLLECTION).doc(id).update(data);
    } catch (error) {
        throw error;
    }
};
