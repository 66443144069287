import { darken, makeStyles } from "@material-ui/core";
import COLORS from "../../../constants/colors";

export default makeStyles({
    button: {
        width: "100%",
        height: "100%",
        maxHeight: "56px",
        backgroundColor: COLORS.SUBMIT,
        textTransform: "none",
        "&:hover": {
            backgroundColor: darken(COLORS.SUBMIT, 0.1),
        },
        "&$disabled": {
            backgroundColor: "#ccc",
            color: "#fff",
        },
    },
    circularProgress: {
        color: COLORS.WHITE,
        padding: 7,
    },

    disabled: {},
});
