import React, { useState, useCallback, useEffect } from "react";

//related to meterial ui package
import styles from "./styles";
import { Header } from "../../core-ui/custom";
import { useAuth } from "../../contexts/auth-context";
import { Filters } from "../../components";
import EnhancedTable from "../../components-lagacy/Table/EnhancedTable";
import PATHS from "../../constants/paths";
import { Link, useHistory, useLocation } from "react-router-dom";
import { queryProjects } from "../../services/firestore/Project";
import { PROJECT_TYPES } from "../../constants/global";
import { formatDate } from "../../helpers/date-utils";
import EditIcon from "@material-ui/icons/Edit";
import { cloneDeep } from "lodash";

const FILTERS = {
    search: {
        label: "Search Name or Number",
        component: "text",
        initialValue: "",
    },
    confirmation: {
        label: "Confirmation",
        options: [
            { value: true, label: "Confirmed" },
            { value: false, label: "Not Confirmed" },
        ],
        initialValue: null,
    },
};

const HEADROW = [
    { id: "id", label: "No." },
    { id: "name", label: "Name" },
    { id: "date", label: "Created At" },
    { id: "start", label: "Start Date" },
    { id: "end", label: "End Date" },
    { id: "confirmation", label: "Confirmed" },
    { id: "action", label: "" },
];

const InsourceProductMerchandisingProjects = (props) => {
    const classes = styles();
    const { companyData } = useAuth();
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);

    const history = useHistory();
    const { search: queryString } = useLocation();

    const [rows, setRows] = useState([]);

    const TABLE_TOOLBAR_ACTIONS = [
        {
            title: "Add New Project",
            action: () => history.push(PATHS.ADD_INSOURCE_PRODUCT_MERCHANDISING_PROJECT),
            icon: "add",
            enabled: () => true,
        },
    ];

    const init = useCallback(async () => {
        try {
            //migration
            setInitializing(true);

            setInitializing(false);
        } catch (error) {
            setInitializing(false);
            throw error;
        }
    }, []);

    useEffect(() => {
        init();
    }, [init]);

    const generateActionButton = useCallback(
        (project) => {
            return (
                // <Link to={`${PATHS.MANAGE_INSOURCE_PRODUCT_MERCHANDISING_PROJECT}/${id}`} className={classes.editLink}>
                <Link
                    to={{
                        pathname: `${PATHS.MANAGE_INSOURCE_PROJECT}`,
                        state: {
                            selfService: true,
                            projectType: project.project_type,
                            id: project.supplier_id,
                            projectId: project.project_id,
                        },
                    }}
                    className={classes.editLink}
                >
                    <EditIcon />
                </Link>
            );
        },
        [classes.editLink]
    );

    const search = useCallback(
        async ({ search, confirmation }) => {
            try {
                setLoading(true);
                const { company_id } = companyData;
                let query = [
                    { key: "supplier_id", operator: "==", value: company_id },
                    { key: "project_type", operator: "==", value: PROJECT_TYPES.SUPPLIER_INSOURCE },
                ];

                const urlParams = new URLSearchParams({});

                if (confirmation) {
                    query.push({ key: "isConfirmed", operator: "==", value: confirmation.value });
                    urlParams.append("confirmation", confirmation.value);
                }

                let projects = (await queryProjects(query)).map((doc) => doc.data());

                if (search) {
                    projects = projects.filter((project) => {
                        const project_id = project.project_id.toLowerCase();
                        const projName = project.projName.toLowerCase();

                        return project_id.includes(search.toLowerCase()) || projName.includes(search.toLowerCase());
                    });
                    urlParams.append("search", search);
                }

                //init rows
                const rows = projects.map((project) => {
                    return {
                        id: project.project_id,
                        name: project.projName,
                        date: formatDate(project.project_date?.toDate()),
                        start: formatDate(project.date_from?.toDate()),
                        end: formatDate(project.date_to?.toDate()),
                        confirmation: project.isConfirmed ? "Yes" : "No",
                        action: generateActionButton(project),
                    };
                });

                setRows(rows);
                history.replace({ search: urlParams.toString() });
                setLoading(false);
            } catch (error) {
                setLoading(false);
                throw error;
            }
        },
        [companyData, generateActionButton, history]
    );

    // after the filters component is mounted, load the data
    const initFilters = useCallback(
        (context) => {
            try {
                const params = new URLSearchParams(queryString);
                const filters = cloneDeep(FILTERS);

                //check if there is any search params in URL
                if (params.get("search")) filters.search.initialValue = params.get("search");

                if (params.get("confirmation")) {
                    const confirmation = params.get("confirmation").toLowerCase();
                    filters.confirmation.initialValue =
                        filters.confirmation.options.find((j) => j.value.toLowerCase() === confirmation) || null;
                }

                context.setFilters(filters);
                // load tasks and merchandisers with default filters
                search({
                    search: filters.search.initialValue,
                    confirmation: filters.confirmation.initialValue,
                });
            } catch (error) {
                console.log(error);
            }
        },
        [queryString, search]
    );

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.pageHeader}>Insource Product Merchandising Projects</Header>
            <div className={classes.content}>
                {!initializing && <Filters init={initFilters} onSubmit={search} submitText="Search" />}
                {/* {initializing && <CircularLoader />} */}
                <div className={classes.tableWrapper}>
                    <EnhancedTable
                        title={"Projects"}
                        columns={HEADROW}
                        rows={rows}
                        actions={TABLE_TOOLBAR_ACTIONS}
                        updateSelected={() => {}}
                        selectionDisabled
                        loading={initializing || loading}
                    />
                </div>
            </div>
        </section>
    );
};

export default InsourceProductMerchandisingProjects;
