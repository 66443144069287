import React, { Component } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import componentsStyle from "../../core-ui/core-styles/jss/general-component-style";

//body
// import SelfServiceContent from "./SelfServiceContent/SelfServiceContent";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import PATHS from "../../constants/paths";
import { COLORS } from "../../constants/constants-lagacy";
import Content from "./Content";
import { withRouter } from "react-router";

class OutsourceProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            dialogOpen: false,
            dialogHeader: "",
            dialogMsg: "",
            dialogCloseHandler: null,
        };
    }

    showDialogMsg = (title, msg, closeHandler) => {
        this.setState({
            loading: false,
            dialogHeader: title,
            dialogMsg: msg,
            dialogOpen: true,
            dialogCloseHandler: closeHandler,
        });
    };

    hideDialogMsg = () => {
        this.setState({ dialogMsg: "", dialogOpen: false });
    };

    goToAddProject = () => {
        this.props.history.push({
            pathname: PATHS.ADD_OUTSOURCE_PROJECT,
            state: {
                id: this.props.id,
            },
        });
    };

    gotToProjectDetails = (project) => {
        this.props.history.push({
            // pathname: "/SupplierProjectConfirmationStep5",
            pathname: PATHS.MANAGE_OUTSOURCE_PROJECT,
            state: {
                projectType: project.project_type,
                // id: project.supplier_id,
                // mpId: project.mp_id,
                projectId: project.project_id,
            },
        });
    };

    render() {
        const classes = this.props.classes;
        return (
            // <div style={{ backgroundColor: "#262626", margin: "0px", padding: "0px", height: "100vh" }} className={classes.pageHeader}>
            <div
                style={{ backgroundColor: COLORS.background, margin: "0px", padding: "0px", height: "100vh" }}
                className={classes.pageHeader}
            >
                <Content
                    uid={this.props.uid}
                    supplierId={this.props.id}
                    goToAddProject={this.goToAddProject}
                    gotToProjectDetails={this.gotToProjectDetails}
                    showDialogMsg={this.showDialogMsg}
                    hideDialogMsg={this.hideDialogMsg}
                />
                <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                    <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.state.dialogCloseHandler} style={{ backgroundColor: "#91041c" }}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

OutsourceProjects.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(componentsStyle)(withRouter(OutsourceProjects));
