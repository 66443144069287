import { makeStyles } from "@material-ui/styles";
import { COLORS } from "../../constants/constants-lagacy";
import { darken } from "@material-ui/core";

const styles = makeStyles({
    sectionContainer: {
        padding: 8,
        margin: "7px",
    },

    reportHeader: {
        marginBottom: 8,
    },

    contentCard: {
        padding: 8,
        margin: "0 auto",
        maxWidth: "100%",
    },

    galleryContent: {
        marginTop: 20,
    },

    checkboxGrid: {
        flexBasis: 0,
        whiteSpace: "nowrap",
    },
    container: {
        overflowX: "scroll",
        overflowY: "hidden",
        whiteSpace: "nowrap",
    },

    legends: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: " 0.8rem",
        margin: "10px 0",

        "& > span": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 7px",
        },

        "& > span:first-child:before": {
            content: "''",
            display: "inline-block",
            width: 20,
            height: 20,
            marginRight: 7,
            backgroundColor: "red",
        },

        "& > span:last-child:before": {
            content: "''",
            display: "inline-block",
            width: 20,
            height: 20,
            marginRight: 7,
            backgroundColor: "orange",
        },
    },

    tableWrapper: {
        height: "100%",
        // overflow: "auto",
        backgroundColor: "white",

        "& table": {
            whiteSpace: "normal",
            height: "100%",
        },

        "& table th": {
            fontSize: ".8rem",
            whiteSpace: "nowrap",
        },

        "& table td": {
            fontSize: ".7rem",
        },

        // "& table td, & table th": {
        //     padding: "0 0 0 15px",
        // },
    },

    taskCol: {
        backgroundColor: "pink",
        margin: "0",
        padding: "0",
        border: "solid 1px",
    },

    // table styles
    pdfButton: {
        marginTop: "12px",
        float: "right",
    },

    pdfIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "400",
        cursor: "pointer",
        color: "white",
        backgroundColor: COLORS.accent,
        borderRadius: "50%",
        width: "50px",
        height: "50px",
    },
    excelIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "400",
        cursor: "pointer",
        color: "white",
        backgroundColor: "#1A4D2E",
        borderRadius: "50%",
        width: "50px",
        height: "50px",
    },
    boldFont: {
        fontWeight: "bold",
        color: "#1F4690",
    },
    containerDiv: {
        marginTop: "4em",
    },
    alignCenter: {
        textAlign: "center",
    },

    tableContainer: {
        maxHeight: "700px",
        overflow: "auto",
        width: "90%",
        minWidth: "560px",
    },

    textLeft: {
        textAlign: "left",
    },
    headerRow: {
        backgroundColor: COLORS.primary,
    },
    header: {
        position: "sticky",
        top: "0px",
    },
    indexHeader: {
        width: "20px",
    },
    indexCell: {
        width: "20px",
    },
    outletHeader: {
        width: "60px",
    },
    outletCell: {
        width: "60px",
        textAlign: "left",
        paddingLeft: "5px",
    },
    productHeader: {
        width: "150px",
    },
    barcodeHeader: { width: "40px" },
    discHeader: { width: "60px" },
    packingHeader: { width: "50px" },
    stockHeader: {
        width: "60px",
    },
    headerColor: {
        color: "#A9333A",
    },
    qtyHeader: { width: "25px", padding: "3px", lineHeight: 1.3 },
    qtyCell: { textAlign: "center", padding: 0, margin: 0 },
    remarksHeader: {
        width: "500px",
    },
    secondHeader: {
        position: "sticky",
        top: "35px",
    },
    topContainer: {
        display: "block",
        marginTop: "5em",
    },
    dateContainer: {
        display: "inline-block",
        position: "relative",
        bottom: "-20px",
    },
    alignLeft: {
        textAlign: "left",
        paddingLeft: "5px",
    },
    alignRight: {
        textAlign: "right",
        display: "inline-block",
        float: "right",
    },
    pdfDiv: {
        display: "inline",
        textAlign: "right",
        position: "relative",
        bottom: "2.5em",
        right: "15.6em",
        marginLeft: "10px",
    },

    imageIcon: {
        color: "#606060",
        cursor: "pointer",
        position: "absolute",
        right: 0,
        "&:hover": {
            color: "#1a1a1a",
        },
    },
    blueCell: {},
    cellItem: {
        textAlign: "center",
        border: "solid 1px",
    },
    rowEven: {
        backgroundColor: "#E8F9FD",
    },
});

export default styles;
