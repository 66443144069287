import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    pageRoot: {
        padding: 20,
    },

    pageHeader: {
        color: COLORS.ACCENT,
    },
    content: {
        maxWidth: 920,
        margin: "15px auto 0 auto",
    },

    tableWrapper: {
        marginTop: 7,
    },

    editButton: {
        color: COLORS.ACCENT,
        backgroundColor: "transparent",
        padding: 0,
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
});

export default styles;
