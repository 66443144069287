import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "../components-lagacy/Grid/GridContainer.jsx";
import GridItem from "../components-lagacy/Grid/GridItem.jsx";

import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
//import withStyles from "@material-ui/core/styles/withStyles";

import Card from "../components-lagacy/Card/Card.jsx";
import CardBody from "../components-lagacy/Card/CardBody.jsx";
import Grid from "@material-ui/core/Grid";
import Button from "../components-lagacy/CustomButtons/Button.jsx";
import Paper from "@material-ui/core/Paper";

//import { withStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";

import { makeStyles, alpha, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import { COLORS } from "../constants/constants-lagacy";
import { firestore as db } from "../services/firebase";
import PATHS from "../constants/paths";

class AccountInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supplier: [
                {
                    contact_person: {
                        first_name: "_",
                        surname: "_",
                    },

                    company_name: "_",
                    city_id: "_",
                    logo: "",

                    contact_information: {
                        company_email: "_",
                        company_phone: "_",
                        fax: "_",
                    },

                    general_manager: {
                        general_first_name: "_",
                        general_surname: "_",
                    },

                    financial_manager: {
                        financial_first_name: "_",
                        financial_surname: "_",
                    },
                },
            ],

            city: [{ En_name: "_" }],

            pathname: "",
        };
    }
    //==================================================================================

    componentDidMount() {
        let datat2 = [];
        var citiesRef = db.collection("Supplier").where("company_id", "==", this.props.id);
        citiesRef
            .get()
            .then((snapshot) => {
                snapshot.docs.forEach((doc) => {
                    var citiesRef = db.collection("City").where("city_id", "==", doc.data().city_id);
                    citiesRef.get().then((snapshot2) => {
                        snapshot2.forEach((doc2) => {
                            datat2 = doc2.data();
                            this.setState({
                                supplier: doc.data(),
                                city: datat2,
                            });
                        });
                    });
                });
            })
            .catch((err) => {
                console.log("Error getting documents", err);
            });
    }

    goToDataEntryPage = (name) => {
        this.setState({ pathname: name });
    };
    //==================================================================================
    render() {
        const classes = makeStyles((theme) => ({
            root: {
                width: "100%",
                marginTop: theme.spacing(3),
                overflowX: "auto",
            },
            table: {
                minWidth: 650,
            },
        }));
        if (this.state.pathname.length > 0)
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathname,
                        state: { id: this.props.id },
                    }}
                />
            );

        const BootstrapInput = withStyles((theme) => ({
            root: {
                "label + &": {
                    marginTop: theme.spacing(3),
                },
            },
            input: {
                borderRadius: 4,
                position: "relative",
                border: "1px solid #ced4da",
                fontSize: 16,
                fontweight: "bold",
                width: "350px",
                backgroundColor: "transparent",
                padding: "10px 12px",
                transition: theme.transitions.create(["border-color", "box-shadow"]),
                // Use the system font instead of the default Roboto font.
                fontFamily: [
                    "-apple-system",
                    "BlinkMacSystemFont",
                    '"Segoe UI"',
                    "Roboto",
                    '"Helvetica Neue"',
                    "Arial",
                    "sans-serif",
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(","),
                "&:focus": {
                    boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                    borderColor: theme.palette.primary.main,
                },
            },
        }))(InputBase);

        //console.log(defaultGlobalState)
        return (
            <div
                /*style={{backgroundColor:"#262626", margin:"0px", padding:"0px", height:'100vh'}}*/ className={
                    classes.pageHeader
                }
            >
                <div style={{ marginBottom: "0px" }}>
                    <div
                        style={{
                            marginLeft: "10px",
                            paddingLeft: "10px",
                            marginRight: "10px",
                            paddingRight: "10px",
                            marginBottom: "0px",
                            paddingBottom: "0px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                        }}
                        className={classNames(classes.textCenter, classes.container)}
                    >
                        <div
                            spacing={0}
                            className={classes.container}
                            pl={5}
                            style={{ width: "78%", marginBottom: "0px", paddingBottom: "0px" }}
                        >
                            <Grid
                                style={{
                                    overflow: "hidden",
                                    marginBottom: "0px",
                                    paddingBottom: "0px",
                                    marginTop: "15px",
                                    boxShadow: "#96a1a9 0 4px 5px 0px",
                                }}
                            >
                                <Card
                                    style={{
                                        marginBottom: "0px",
                                        paddingBottom: "0px",
                                        paddingTop: "0px",
                                        marginTop: "0px",
                                    }}
                                    color="primary"
                                >
                                    <CardBody>
                                        <GridContainer>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginBottom: "0px",
                                                    paddingBottom: "0px",
                                                    paddingTop: "0px",
                                                    marginTop: "0px",
                                                }}
                                            >
                                                <div className={classes.title} style={{ width: "3%" }}>
                                                    <div
                                                        style={{
                                                            fontWeight: "700",
                                                            backgroundColor: COLORS.primary,
                                                            height: "30px",
                                                            marginTop: "0px",
                                                            paddingTop: "3px",
                                                            paddingBottom: "3px",
                                                            border: "2px solid #575757",
                                                            // borderRightColor: "#91041C",
                                                            // borderBottomColor: "#91041C",
                                                            borderRightColor: COLORS.primary,
                                                            borderBottomColor: COLORS.primary,
                                                        }}
                                                    ></div>
                                                </div>
                                                <div
                                                    className={classes.title}
                                                    style={{ width: "96.7%", marginLeft: "2px" }}
                                                >
                                                    <h4
                                                        style={{
                                                            textAlign: "left",
                                                            fontWeight: "700",
                                                            backgroundColor: COLORS.background,
                                                            color: COLORS.primary,
                                                            height: "30px",
                                                            marginTop: "0px",
                                                            paddingLeft: "10px",
                                                            paddingTop: "3px",
                                                            paddingBottom: "3px",
                                                            border: "1px solid #000000",
                                                        }}
                                                    >
                                                        Account Information
                                                    </h4>
                                                </div>
                                            </div>
                                            <Grid item xs={12}>
                                                <p></p>
                                                <Paper className={classes.paper}> </Paper>
                                            </Grid>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl className={classes.margin}>
                                                    <InputLabel
                                                        style={{ color: "#000000", fontWeight: "bold" }}
                                                        shrink
                                                        htmlFor="bootstrap-input"
                                                    >
                                                        Company Name
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        variant="filled"
                                                        multiline
                                                        disabled={true}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        fullWidth
                                                        value={this.state.supplier.company_name}
                                                        id="company_name"
                                                        style={{
                                                            marginTop: "15px",
                                                            color: "#000000",
                                                            fontWeight: "bold",
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl className={classes.margin}>
                                                    <InputLabel
                                                        style={{ color: "#000000", fontWeight: "bold" }}
                                                        shrink
                                                        htmlFor="bootstrap-input"
                                                    >
                                                        City
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        variant="filled"
                                                        multiline
                                                        disabled={true}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        fullWidth
                                                        value={this.state.city.En_name}
                                                        id="city_name"
                                                        style={{
                                                            marginTop: "15px",
                                                            color: "#000000",
                                                            fontWeight: "bold",
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl className={classes.margin}>
                                                    <InputLabel
                                                        style={{ color: "#000000", fontWeight: "bold" }}
                                                        shrink
                                                        htmlFor="bootstrap-input"
                                                    >
                                                        Contact Person
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        variant="filled"
                                                        multiline
                                                        disabled={true}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        fullWidth
                                                        value={
                                                            this.state.supplier.contact_person === undefined
                                                                ? "_"
                                                                : this.state.supplier.contact_person.first_name +
                                                                  " " +
                                                                  this.state.supplier.contact_person.surname
                                                        }
                                                        id="name"
                                                        style={{
                                                            marginTop: "15px",
                                                            color: "#000000",
                                                            fontWeight: "bold",
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl className={classes.margin}>
                                                    <InputLabel
                                                        style={{ color: "#000000", fontWeight: "bold" }}
                                                        shrink
                                                        htmlFor="bootstrap-input"
                                                    >
                                                        General Manager
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        variant="filled"
                                                        multiline
                                                        disabled={true}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        fullWidth
                                                        value={
                                                            this.state.supplier.general_manager === undefined
                                                                ? "_"
                                                                : this.state.supplier.general_manager
                                                                      .general_first_name === ""
                                                                ? "_"
                                                                : this.state.supplier.general_manager
                                                                      .general_first_name +
                                                                  " " +
                                                                  this.state.supplier.general_manager.general_surname
                                                        }
                                                        id="general_name"
                                                        style={{
                                                            marginTop: "15px",
                                                            color: "#000000",
                                                            fontWeight: "bold",
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl className={classes.margin}>
                                                    <InputLabel
                                                        style={{ color: "#000000", fontWeight: "bold" }}
                                                        shrink
                                                        htmlFor="bootstrap-input"
                                                    >
                                                        Financial Manager
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        variant="filled"
                                                        multiline
                                                        disabled={true}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        fullWidth
                                                        value={
                                                            this.state.supplier.financial_manager === undefined
                                                                ? "_"
                                                                : this.state.supplier.financial_manager
                                                                      .financial_first_name === ""
                                                                ? "_"
                                                                : this.state.supplier.financial_manager
                                                                      .financial_first_name +
                                                                  " " +
                                                                  this.state.supplier.financial_manager
                                                                      .financial_surname
                                                        }
                                                        id="financial_name"
                                                        style={{
                                                            marginTop: "15px",
                                                            color: "#000000",
                                                            fontWeight: "bold",
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl className={classes.margin}>
                                                    <InputLabel
                                                        style={{ color: "#000000", fontWeight: "bold" }}
                                                        shrink
                                                        htmlFor="bootstrap-input"
                                                    >
                                                        Company Email
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        variant="filled"
                                                        multiline
                                                        disabled={true}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        fullWidth
                                                        value={
                                                            this.state.supplier.contact_information === undefined
                                                                ? "_"
                                                                : this.state.supplier.contact_information
                                                                      .company_email === ""
                                                                ? "_"
                                                                : this.state.supplier.contact_information.company_email
                                                        }
                                                        id="email"
                                                        style={{
                                                            marginTop: "15px",
                                                            color: "#000000",
                                                            fontWeight: "bold",
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl className={classes.margin}>
                                                    <InputLabel
                                                        style={{ color: "#000000", fontWeight: "bold" }}
                                                        shrink
                                                        htmlFor="bootstrap-input"
                                                    >
                                                        Company Phone
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        variant="filled"
                                                        multiline
                                                        disabled={true}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        fullWidth
                                                        value={
                                                            this.state.supplier.contact_information === undefined
                                                                ? "_"
                                                                : this.state.supplier.contact_information
                                                                      .company_phone === ""
                                                                ? "_"
                                                                : this.state.supplier.contact_information.company_phone
                                                        }
                                                        id="phone"
                                                        style={{
                                                            marginTop: "15px",
                                                            color: "#000000",
                                                            fontWeight: "bold",
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <FormControl className={classes.margin}>
                                                    <InputLabel
                                                        style={{ color: "#000000", fontWeight: "bold" }}
                                                        shrink
                                                        htmlFor="bootstrap-input"
                                                    >
                                                        Company Fax
                                                    </InputLabel>
                                                    <BootstrapInput
                                                        variant="filled"
                                                        multiline
                                                        disabled={true}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        fullWidth
                                                        value={
                                                            this.state.supplier.contact_information === undefined
                                                                ? "_"
                                                                : this.state.supplier.contact_information.fax === ""
                                                                ? "_"
                                                                : this.state.supplier.contact_information.fax
                                                        }
                                                        id="fax"
                                                        style={{
                                                            marginTop: "15px",
                                                            color: "#000000",
                                                            fontWeight: "bold",
                                                        }}
                                                    />
                                                </FormControl>
                                            </GridItem>
                                            <Grid item xs={12}>
                                                <p></p>
                                                <Paper className={classes.paper}> </Paper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <p></p>
                                                <Paper className={classes.paper}> </Paper>
                                            </Grid>

                                            <div style={{ width: "100%" }}>
                                                <Button
                                                    onClick={() => this.goToDataEntryPage(PATHS.UPDATE_ACCOUNT_INFO)}
                                                    size="lg"
                                                    style={{
                                                        // border: "1px solid #4BB543",
                                                        marginLeft: "10px",
                                                        paddingLeft: "10px",
                                                        paddingRight: "10px",
                                                        paddingTop: "10px",
                                                        paddingBottom: "10px",
                                                        textTransform: "none",
                                                        backgroundColor: COLORS.accent,
                                                        color: COLORS.font,
                                                        fontWeight: "bold",
                                                    }}
                                                    type="submit"
                                                >
                                                    {" "}
                                                    Update{" "}
                                                </Button>
                                            </div>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div>
                    <Grid item xs={12}>
                        <p></p>
                        <Paper className={classes.paper}> </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <p></p>
                        <Paper className={classes.paper}> </Paper>
                    </Grid>
                </div>
            </div>
        );
    }
}
AccountInfo.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(componentsStyle)(AccountInfo);
