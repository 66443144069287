import { Card, Grid } from "@material-ui/core";
import { cloneDeep } from "lodash/fp";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { EnhancedTransferList } from "../../components";
import { useAuth } from "../../contexts/auth-context";
import { Autocomplete, Button, Header, LoadingOverlay, TransitionAlert } from "../../core-ui/custom";
import {
    getAllLinkedOutletBranches,
    linkOutletBranches,
    unlinkOutletBranches,
} from "../../services/firestore/Branch_Outlet_Branch";
import { getAllCities } from "../../services/firestore/City";
import { getOutlets } from "../../services/firestore/Outlet";
import { getOutletBranchesByCompanies } from "../../services/firestore/Outlet_Branch";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";
import { getLinkedOutlets } from "../../services/firestore/Supplier_Outlet_Product";
import styles from "./styles";

const initialPageState = {
    branchesList: [],
    selectedBranch: null,
    citiesList: [],
    selectedCity: null,
    outletsList: [],
    selectedOutlet: null,

    linkedOutletBranchIDs: [],
    outletBranchesList: [],

    leftList: [],
    rightList: [],
    checkedList: [],
};

const listItem = {
    data: {},
    id: null,
    primaryLabel: null,
    secondaryLabel: null,
    hidden: false,
};

const pageReducer = (state, action) => {
    switch (action.type) {
        case "init":
        case "update":
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

const SelectOutletBranches = () => {
    const classes = styles();
    const { companyData } = useAuth();
    const [pageState, dispatchPageState] = useReducer(pageReducer, { ...initialPageState });
    const [alertState, setAlertState] = useState({
        open: false,
        message: "",
        severity: "info",
    });
    const [loading, setLoading] = useState(true);

    const setAlertOpen = (open) => {
        setAlertState({ ...alertState, open });
    };

    //true = show, false = hidden
    const filterItem = (item, city, outlet) => {
        return (
            (city === null || item.data.city_id === city?.city_id) &&
            (outlet === null || item.data.outlet_id === outlet?.outlet_id)
        );
    };

    const filterList = (list, city, outlet) => {
        return list.map((item) => ({ ...item, hidden: !filterItem(item, city, outlet) }));
    };

    const filterLists = (lists, city, outlet) => {
        const { leftList, rightList, checkedList } = lists;

        const newCheckedList = filterList(checkedList, city, outlet).filter((item) => !item.hidden);

        return {
            leftList: filterList(leftList, city, outlet),
            rightList: filterList(rightList, city, outlet),
            checkedList: newCheckedList,
        };
    };

    const onBranchChange = (e, value) => {
        const { selectedBranch, outletBranchesList, linkedOutletBranchIDs, selectedOutlet, selectedCity } = pageState;
        if (value.branch_id === selectedBranch.branch_id) return;

        //setup tansfer lists
        let lists = setupTransferLists(value, outletBranchesList, linkedOutletBranchIDs);
        //filter by city and outlet
        lists = filterLists(lists, selectedCity, selectedOutlet);
        //update page state
        const { leftList, rightList, checkedList } = lists;

        dispatchPageState({ type: "update", payload: { selectedBranch: value, leftList, rightList, checkedList } });
    };

    const onOutletChange = (e, value) => {
        let { selectedCity } = pageState;
        let { leftList, rightList, checkedList } = filterLists(pageState, selectedCity, value);
        dispatchPageState({ type: "update", payload: { selectedOutlet: value, leftList, rightList, checkedList } });
    };

    const onCityChange = (e, value) => {
        let { selectedOutlet } = pageState;
        let { leftList, rightList, checkedList } = filterLists(pageState, value, selectedOutlet);
        dispatchPageState({ type: "update", payload: { selectedCity: value, leftList, rightList, checkedList } });
    };

    const setupTransferLists = (branch, outletBranchesList, linkedOutletBranchIDs) => {
        //filter the left list to remain only the unselected outlet branches across all supplier branches
        let leftList = cloneDeep(outletBranchesList);
        for (const sbID in linkedOutletBranchIDs) {
            if (Object.hasOwnProperty.call(linkedOutletBranchIDs, sbID)) {
                const obIDs = linkedOutletBranchIDs[sbID];
                leftList = leftList.filter((item) => !obIDs.includes(item.id));
            }
        }

        //filter the right list for the selected outlet branches
        const rightList = outletBranchesList.filter((item) =>
            linkedOutletBranchIDs[branch.branch_id].includes(item.id)
        );

        return { leftList, rightList, checkedList: [] };
    };

    const transferHandler = (newList, direction) => {
        //if an item is checked or unchecked
        if (direction === "checked") {
            dispatchPageState({ type: "update", payload: { checkedList: newList } });
            //if the checked items transfered to left
        } else if (direction === "left") {
            dispatchPageState({ type: "update", payload: { leftList: newList, checkedList: [] } });
        } else if (direction === "right") {
            dispatchPageState({ type: "update", payload: { rightList: newList, checkedList: [] } });
        }
    };

    const filterHandler = (e, list, direciton) => {
        const value = e.target.value.toUpperCase();
        let checkedList = cloneDeep(pageState.checkedList);

        list.forEach((item) => {
            item.hidden = item.primaryLabel.toUpperCase().indexOf(value) === -1;
            // && item.secondaryLabel.toUpperCase().indexOf(value) === -1;
            // if the item is checked AND hidden, uncheck (remove it from checkedList)
            if (item.hidden) {
                const index = checkedList.findIndex((ci) => ci.id === item.id);
                if (index === -1) return;
                checkedList.splice(index, 1);
            }
        });

        if (direciton === "left") {
            dispatchPageState({ type: "update", payload: { leftList: list } });
        } else if (direciton === "right") {
            dispatchPageState({ type: "update", payload: { rightList: list } });
        }
        //update checked list
        dispatchPageState({ type: "update", payload: { checkedList } });
    };

    const saveHandler = async () => {
        setLoading(true);
        try {
            const { company_id } = companyData;
            const { outletBranchesList, linkedOutletBranchIDs, selectedBranch, rightList } = pageState;
            //get the old right list
            const oldRightList = outletBranchesList.filter((item) =>
                linkedOutletBranchIDs[selectedBranch.branch_id].includes(item.id)
            );

            // save the items that exist in the new list but not exist in the old one
            const toBesaved = rightList.filter((item) => !oldRightList.map((i) => i.id).includes(item.id));
            // delete the items that exist in the old list but not exist in the new one
            const toBeDeleted = oldRightList.filter((item) => !rightList.map((i) => i.id).includes(item.id));

            //save
            const saveIds = toBesaved.map((item) => `${item.id}`);
            await linkOutletBranches(company_id, `${selectedBranch.branch_id}`, saveIds);

            //delete
            const deleteIds = toBeDeleted.map((item) => `${item.id}`);
            await unlinkOutletBranches(`${selectedBranch.branch_id}`, deleteIds);

            //update the linkedOutletBranchIDs
            const newLinkedOutletBranchIDs = { ...linkedOutletBranchIDs };
            toBesaved.forEach((item) => {
                newLinkedOutletBranchIDs[selectedBranch.branch_id].push(item.id);
            });
            toBeDeleted.forEach((item) => {
                newLinkedOutletBranchIDs[selectedBranch.branch_id] = newLinkedOutletBranchIDs[
                    selectedBranch.branch_id
                ].filter((i) => i !== item.id);
            });
            dispatchPageState({ type: "update", payload: { linkedOutletBranchIDs: newLinkedOutletBranchIDs } });

            setAlertState({
                open: true,
                message: "Successfully saved",
                severity: "success",
            });
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const init = useCallback(async () => {
        try {
            const { company_id } = companyData;
            let [branchesList, linkedOutlets, citiesList] = await Promise.all([
                getBranchesBySupplierID(company_id),
                getLinkedOutlets(company_id),
                getAllCities(),
            ]);

            if (branchesList.length <= 0) {
                setLoading(false);
                setAlertState({
                    open: true,
                    message: "No branches found",
                    severity: "warning",
                });
                throw new Error("No branches found");
            }

            //get outlet informations
            // get all outlet branches from linked outlets
            let outletIDs = linkedOutlets.map((outlet) => outlet.id);
            let [outletsList, outletBranchesList, linkedOutletBranchIDs] = await Promise.all([
                getOutlets(outletIDs),
                getOutletBranchesByCompanies(outletIDs),
                getAllLinkedOutletBranches(branchesList.map((branch) => branch.id)),
            ]);

            //convert documents into data objects
            [branchesList, outletsList, citiesList, outletBranchesList] = [
                branchesList,
                outletsList,
                citiesList,
                outletBranchesList,
            ].map((list) => list.map((item) => item.data()));

            //convert all outlet branches into list item objects
            outletBranchesList = outletBranchesList.map((ob) => {
                let item = { ...listItem };
                ob.city = citiesList.find((city) => city.city_id === ob.city_id);
                item.data = ob;
                item.id = ob.branch_id;
                item.primaryLabel = ob.En_short_name;
                item.secondaryLabel = ob.city.En_name;
                return item;
            });

            const selectedBranch = branchesList[0];
            const { leftList, rightList, checkedList } = setupTransferLists(
                selectedBranch,
                outletBranchesList,
                linkedOutletBranchIDs
            );

            //set state
            dispatchPageState({
                type: "init",
                payload: {
                    branchesList,
                    outletsList,
                    citiesList,
                    selectedBranch,
                    outletBranchesList,
                    linkedOutletBranchIDs,
                    leftList,
                    rightList,
                    checkedList,
                },
            });
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }, [companyData]);

    useEffect(() => {
        init();
    }, [init]);

    const { leftList, rightList, checkedList } = pageState;
    // console.log(leftList, rightList, checkedList);

    return (
        <section className={classes.root}>
            <TransitionAlert
                variant="filled"
                severity={alertState.severity}
                open={alertState.open}
                setOpen={setAlertOpen}
                className={classes.stickyAlert}
            >
                {alertState.message}
            </TransitionAlert>
            {loading && <LoadingOverlay />}
            <Header className={classes.header}>Link Your Branch with Outlet Branches</Header>
            <Card className={classes.card}>
                {/* filters */}
                <Grid container spacing={1}>
                    {/* supplier branches */}
                    <Grid item xs={12} sm={12} md={4}>
                        <Autocomplete
                            id="branches-list"
                            inputLabel={"Branch"}
                            optionLabelProp="En_name"
                            options={pageState.branchesList}
                            onChange={onBranchChange}
                            getOptionSelected={(option, value) => option?.branch_id === value?.branch_id}
                            value={pageState.selectedBranch}
                            disableClearable
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Autocomplete
                            id="outlets-list"
                            inputLabel={"Outlet"}
                            optionLabelProp="En_short_name"
                            options={pageState.outletsList}
                            onChange={onOutletChange}
                            value={pageState.selectedOutlet}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <Autocomplete
                            id="cities-list"
                            inputLabel={"City"}
                            optionLabelProp="En_name"
                            options={pageState.citiesList}
                            onChange={onCityChange}
                            value={pageState.selectedCity}
                        />
                    </Grid>
                </Grid>
            </Card>

            <div className={classes.card}>
                <EnhancedTransferList
                    leftList={leftList}
                    rightList={rightList}
                    checkedList={checkedList}
                    onTransfer={transferHandler}
                    onFilter={filterHandler}
                />
                <Button className={classes.saveBtn} onClick={saveHandler}>
                    Save
                </Button>
            </div>
        </section>
    );
};

export default SelectOutletBranches;
