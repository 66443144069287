import { cloneDeep } from "lodash";
import { useCallback, useState } from "react";
import { PLATFORM, STATUS } from "../../constants/global";
import { useAuth } from "../../contexts/auth-context";
import useTransferList from "../../hooks/use-transfer-list";
import { queryLinkedOutlets, updateLinkedOutlet } from "../../services/firestore/Linked_Outlets";
import { getOutlet } from "../../services/firestore/Outlet";
import { useSearchParams } from "react-router-dom-v5-compat";
// import { migrateBranchOutletBranch, migrateSupplierOutletProduct } from "../../services/migration";
import { getSupplierProducts } from "../../services/firestore/Product";
import { addLinkedOutletProducts, deleteLinkedProducts } from "../../services/firestore/Supplier_Outlet_Product";

const FILTERS = {
    outlet: {
        label: "Outlet",
        options: [],
        initialValue: null,
    },
};

const useLinkProductsWithOutlets = () => {
    const { companyData } = useAuth();
    const [searchParams] = useSearchParams();

    const {
        INITIAL_LIST_ITEM,
        leftList,
        setLeftList,
        rightList,
        setRightList,
        checkedList,
        setCheckedList,
        transferHandler,
        filterHandler,
    } = useTransferList();

    const [alertState, setAlertState] = useState({
        open: false,
        message: "",
        severity: "info",
    });

    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const [linkedOutletsList, setLinkedOutletsList] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedLinkedOutlet, setSelectedLinkedOutlet] = useState(null);

    const setAlertOpen = (open) => {
        setAlertState({ ...alertState, open });
    };

    const lagacySave = async () => {
        try {
            const { company_id } = companyData;

            // save the items that exist in the new list but not exist in the old one
            const toBeSaved = rightList.filter((item) => !selectedLinkedOutlet.products.includes(item.id));
            // delete the items that exist in the old list but not exist in the new one
            const toBeDeleted = leftList.filter((item) => selectedLinkedOutlet.products.includes(item.id));

            //add
            const addedData = toBeSaved.map((item) => ({
                En_name: item.data.En_name,
                outlet_id: selectedLinkedOutlet.outlet_id,
                platform: PLATFORM,
                product_id: item.id,
                supplier_id: company_id,
            }));
            await addLinkedOutletProducts(company_id, selectedLinkedOutlet.outlet_id, addedData);

            //delete
            if (toBeDeleted.length > 0) {
                await deleteLinkedProducts(
                    company_id,
                    selectedLinkedOutlet.outlet_id,
                    toBeDeleted.map((i) => i.id)
                );
            }
        } catch (error) {
            throw error;
        }
    };

    const saveHandler = async () => {
        setSaving(true);
        try {
            if (!selectedLinkedOutlet) {
                return;
            }

            //!lagacy
            await lagacySave();

            //overwrite products property in selected outlet with products from rightList
            await updateLinkedOutlet(selectedLinkedOutlet.id, { products: rightList.map((item) => item.id) });

            setAlertState({
                open: true,
                message: "Successfully saved",
                severity: "success",
            });
        } catch (error) {
            console.error(error);
        }
        setSaving(false);
    };

    const applyFilters = useCallback(
        async (values, context) => {
            try {
                setLoading(true);
                const { outlet } = values;

                //reset
                setLeftList([]);
                setRightList([]);
                setCheckedList([]);
                setSelectedLinkedOutlet(null);

                if (!outlet?.value || linkedOutletsList.length <= 0) {
                    setLoading(false);
                    return;
                }

                //setup transfer list
                const rightList = outlet.data.products.map((productID) => {
                    let item = cloneDeep(INITIAL_LIST_ITEM);
                    const product = products.find((p) => p.product_id === productID);
                    item.id = productID;
                    item.data = product;
                    item.primaryLabel = product.En_name ?? "-";
                    item.secondaryLabel = `${product.barcode ?? ""}`;
                    return item;
                });

                const leftList = products.reduce((prev, current) => {
                    //if exist on the other list, skip
                    const exist = rightList.find((r) => r.id === current.product_id);
                    if (exist) return prev;

                    let item = cloneDeep(INITIAL_LIST_ITEM);
                    prev.push({
                        ...item,
                        id: current.product_id,
                        data: current,
                        primaryLabel: current.En_name,
                        secondaryLabel: `${current.barcode}`,
                    });
                    return prev;
                }, []);

                setLeftList(leftList);
                setRightList(rightList);
                setCheckedList([]);
                setSelectedLinkedOutlet(outlet.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                throw error;
            }
        },
        [INITIAL_LIST_ITEM, products, setCheckedList, setLeftList, setRightList, linkedOutletsList.length]
    );

    const init = useCallback(async () => {
        try {
            const { company_id } = companyData;

            //filters
            let [linkedOutlets, products] = await Promise.all([
                queryLinkedOutlets([
                    { key: "supplier_id", operator: "==", value: company_id },
                    { key: "status", operator: "==", value: STATUS.ACTIVE },
                ]),
                getSupplierProducts(company_id),
            ]);

            //doc to list item and get outlets data
            const linkedOutletsList = await Promise.all(
                linkedOutlets.map(async (o) => {
                    const outlet = (await getOutlet(o.data().outlet_id)).data();
                    const data = { ...o.data(), outlet };
                    return {
                        value: data.outlet_id,
                        label: outlet.En_short_name,
                        data,
                    };
                })
            );

            //this is where you store options and products
            setLinkedOutletsList(linkedOutletsList);
            setProducts(products.map((doc) => doc.data()));
            return { linkedOutletsList };
        } catch (error) {
            throw error;
        }
    }, [companyData]);

    //entry point after load
    const initFilters = useCallback(
        async (context) => {
            try {
                const { linkedOutletsList } = await init();
                const filters = cloneDeep(FILTERS);

                filters.outlet.options = linkedOutletsList;

                //check if there is any search params in URL
                if (searchParams.get("outlet")) {
                    const id = searchParams.get("outlet");
                    filters.outlet.initialValue = linkedOutletsList.find((b) => b.value === id);
                }

                context.setFilters(filters);
            } catch (error) {
                console.error(error);
            }
            setInitializing(false);
        },
        [searchParams, init]
    );

    return {
        initializing,
        loading,
        saving,
        // transferListState,
        leftList,
        rightList,
        checkedList,

        transferHandler,
        filterHandler,
        saveHandler,
        alertState,
        setAlertOpen,
        initFilters,
        applyFilters,
    };
};

export default useLinkProductsWithOutlets;
