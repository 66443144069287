import { STATUS } from "../../constants/global";
import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";
import { queryLinkedOutlets } from "./Linked_Outlets";

export const queryLinkedOutletBranches = async (q) => {
    try {
        return query(COLLECTIONS.LINKED_OUTLET_BRANCHES, q);
    } catch (error) {
        throw error;
    }
};

export const addLinkedOutletBranch = async (data) => {
    try {
        const ref = db.collection(COLLECTIONS.LINKED_OUTLET_BRANCHES).doc();

        data.id = ref.id;
        await ref.set(data);
        return data;
    } catch (error) {
        throw error;
    }
};

export const updateLinkedOutletBranch = async (id, data) => {
    try {
        await db.collection(COLLECTIONS.LINKED_OUTLET_BRANCHES).doc(id).update(data);
    } catch (error) {
        throw error;
    }
};

export const deactivateLinkedOutletBranchesWithLatestLinkedOutlets = async (supplier_id) => {
    try {
        const linkedOutlets = (
            await queryLinkedOutlets([
                { key: "supplier_id", operator: "==", value: supplier_id },
                { key: "status", operator: "==", value: STATUS.INACTIVE },
            ])
        ).map((doc) => doc.data());

        //each inactive linked outlets, deactivate its linked outlet branches
        await Promise.all(
            linkedOutlets.map(async (lo) => {
                //get
                const linkedOutletBranches = await queryLinkedOutletBranches([
                    { key: "supplier_id", operator: "==", value: supplier_id },
                    { key: "outlet_id", operator: "==", value: lo.outlet_id },
                    { key: "status", operator: "==", value: STATUS.ACTIVE },
                ]);

                //deactivate
                await Promise.all(
                    linkedOutletBranches.map(async (lobDoc) => lobDoc.ref.update({ status: STATUS.INACTIVE }))
                );
                return;
            })
        );
    } catch (error) {
        throw error;
    }
};
