import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    pageRoot: {
        padding: 20,
    },

    pageHeader: {
        color: COLORS.ACCENT,
    },
    content: {
        maxWidth: 920,
        margin: "15px auto 0 auto",
    },

    tableWrapper: {
        marginTop: 7,
    },

    editLink: {
        color: COLORS.ACCENT,
    },

    image: {
        display: "block",
        width: 40,
        height: 40,
        objectFit: "cover",
        borderRadius: "50%",
        margin: "7px auto",
    },
});

export default styles;
