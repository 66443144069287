import { darken } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

export default makeStyles({
    root: {
        padding: 20,
    },

    header: {
        color: COLORS.ACCENT,
        marginBottom: 15,
    },

    stickyAlert: {
        position: "sticky",
        top: 10,
        zIndex: 10,
    },

    card: {
        maxWidth: 1200,
        margin: "15px auto 0 auto",
        overflow: "visible",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "center",
    },

    // saveBtn: {
    //     width: 108,
    //     marginTop: 10,
    //     alignSelf: "center",
    // },
    saveBtn: {
        width: 80,
        alignSelf: "center",
        padding: 6,
        maxHeight: 56,
        backgroundColor: COLORS.SUBMIT,
        textTransform: "none",

        "&:hover": {
            backgroundColor: darken(COLORS.SUBMIT, 0.1),
        },
        "&$disabled": {
            backgroundColor: "#ccc",
            color: "#fff",
        },
    },
    circularProgress: {
        display: "flex",
        alignItems: "center",
        color: COLORS.WHITE,
        padding: 0,
    },

    disabled: {},
});
