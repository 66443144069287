import { USER_TYPES } from "./global";

const PATHS = {
    SIGN_IN: "/sign-in",
    SIGN_UP: "/sign-up",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    VERIFY_EMAIL: "/verify-email",

    TERMS_AND_CONDITIONS: "https://derwaza.tech/privacy-policy/",
    PRIVACY_POLICY: "https://derwaza.tech/terms-and-conditions/",

    USERS: "/users",

    DASHBOARD: "/dashboard",
    HELP: "/help",
    TUTORIALS: "/Tutorials",

    ACCOUNT_INFO: "/account-info",
    UPDATE_ACCOUNT_INFO: "/update-account-info",

    BRANCHES: "/branches",
    ADD_BRANCH: "/add-branch",
    UPDATE_BRANCH: "/update-branch",

    EMPLOYEES: "/employees",
    ADD_EMPLOYEE: "/add-employee",
    UPDATE_EMPLOYEE: "/update-employee",

    BRANDS: "/brands",

    PRODUCTS: "/products",
    ADD_PRODUCT: "/add-product",
    UPDATE_PRODUCT: "/update-product",

    COMPETITOR_BRANDS: "/competitor_brands",

    COMPETITOR_PRODUCTS: "/competitor_products",
    ADD_COMPETITOR_PRODUCT: "/add_competitor_product",
    UPDATE_COMPETITOR_PRODUCT: "/update_competitor_product",

    PROMOTION_PRICES: "/promotion_prices",
    ADD_PROMOTION_PRICES: "/add_promotion_prices",
    UPDATE_PROMOTION_PRICES: "/update_prmotion_prices",

    EXTRA_DISPLAY_CONTRACTS: "/extra-display-contract",
    ADD_EXTRA_DISPLAY_CONTRACT: "/add-extra-display-contract",
    UPDATE_EXTRA_DISPLAY_CONTRACT: "/update-extra-display-contract",

    LINK_OUTLETS: "/link-outlets",
    LINK_OUTLET_BRANCHES: "/link-outlet-branches",
    // LINKED_OUTLETS: "/linked-outlets",
    // LINK_OUTLET_WITH_PRODUCTS: "/link-outlet-products",
    LINK_PRODUCTS_WITH_OUTLETS: "/link-products-with-outlets",
    LINK_PRODUCTS_WITH_OUTLET_BRANCHES: "/link-products-with-outlet-branches",

    //* SERVICES
    INSOURCE_PRODUCT_MERCHANDISING_PROJECTS: "/insource-product-merchandising-projects",
    ADD_INSOURCE_PRODUCT_MERCHANDISING_PROJECT: "/add-insource-product-merchandising-project",
    MANAGE_INSOURCE_PRODUCT_MERCHANDISING_PROJECT: "/manage-insource-product-merchandising-project",

    INSOURCE_PROJECTS: "/insource-projects",
    ADD_INSOURCE_PROJECT: "/add-insource-project",
    MANAGE_INSOURCE_PROJECT: "/manage-insource-project",

    OUTSOURCE_PROJECTS: "/outsource-projects",
    ADD_OUTSOURCE_PROJECT: "/add-outsource-project",
    MANAGE_OUTSOURCE_PROJECT: "/manage-outsource-project",

    PROMOTING_PROJECTS: "/promoting_projects",
    ADD_PROMOTING_PROJECT: "/add_promoting_project",
    MANAGE_PROMOTING_PROJECT: "/manage_promoting_project",

    //* OPERATIONS
    INSOURCE_VISITS_MANAGEMENT: "/insource-visits-management",
    INSOURCE_VISIT_DETAILS: "/insource-visit-details",
    INSOURCE_VISIT_REPORT: "/insource-visit-report",

    OUTSOURCE_VISITS_MANAGEMENT: "/outsource-visits-management",
    OUTSOURCE_VISIT_DETAILS: "/outsource-visit-details",
    OUTSOURCE_VISIT_REPORT: "/outsource-visit-report",

    ROUTE_PLAN_MANAGEMENT: "/route-plan-management",
    ROUTE_VISITS_MANAGEMENT: "/route-visits-management",

    EXCLUSIVITY_CONTRACTS: "/exclusivity-contracts",
    ADD_EXCLUSIVITY_CONTRACT: "/add-exclusivity-contract",
    UPDATE_EXCLUSIVITY_CONTRACT: "/update-exclusivity-contract",

    GALLERY: "/gallery",

    VISITS_POOL: "/visits-pool",

    //* REPORTS
    DAILY_MERCHANDISING_REPORT: "/daily-merchandising-report",
    MERCHANDISER_ATTENDANCE_REPORT: "/merchandiser-attendance-report",
    EXPIRY_AND_DAMAGE_REPORT: "/expiry-and-damage-report",
    NEAR_EXPIRY_REPORT: "/near-expiry-report",
    OUT_OF_STOCK_REPORT: "/out-of-stock-report",

    //* SUBSCRIPTION
    SUBSCRIPTIONS: "/subscriptions",
};

const PUBLIC_PATHS = [
    PATHS.SIGN_IN,
    PATHS.SIGN_UP,
    PATHS.FORGOT_PASSWORD,
    PATHS.RESET_PASSWORD,
    PATHS.TERMS_AND_CONDITIONS,
    PATHS.PRIVACY_POLICY,
];

export const PATHS_ACCESS = {
    [USER_TYPES.ADMIN]: Object.values(PATHS).reduce((prev, path) => ({ ...prev, [path]: true }), {}),
    [USER_TYPES.BRANCH_MANAGER]: Object.values(PATHS).reduce((prev, path) => {
        let permission = true;
        if (path === PATHS.USERS) permission = false;
        return { ...prev, [path]: permission };
    }, {}),
};

export { PUBLIC_PATHS };
export default PATHS;
