import React from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    darken,
    Grid,
    lighten,
    TextField,
    Typography,
    withStyles,
} from "@material-ui/core";
import { WEEKDAYS_FULL, COLORS, TASKS_STATUS } from "../../../constants/constants-lagacy";
import { setupAllScheduleRows, setupScheduleRows, updateSchedule } from "../../../services/firestore/Project";
import { getMerchandiserName } from "../../../helpers/merchandiser-utils";
import WeekdayTabs from "../../../components-lagacy/CustomTabs/WeekdayTabs";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FullPageLoader from "../../../components-lagacy/Loader/FullPageLoader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { cloneDeep } from "lodash";
import { convertMinutesToHoursAndMinutes, getToday, getTomorrow } from "../../../helpers/date-utils";
import { getRoutes, isEmptyRoute, queryRoutes, saveRoutes, updateRoute } from "../../../services/firestore/Route";
import { generateNewId, getNewDoc } from "../../../helpers/firestore-utils";
import RouteEditDialog from "../../../components-lagacy/Dialog/RouteEditDialog";
import Swal from "sweetalert2";
import { getSupervisorsBySupplierBranch } from "../../../services/firestore/Merchandiser";
import { queryTasks, queryTasks_V2 } from "../../../services/firestore/Task";
import AuthContext from "../../../contexts/auth-context";

const useStyles = (theme) => ({
    root: {
        width: "100%",
        maxWidth: 1920,
    },
    container: {
        width: "100%",
        padding: "10px 20px",
        position: "relative",
    },

    contentCard: {
        width: "100%",
        // maxWidth: "1290px",
    },
    loader: {
        marginTop: "130px",
    },

    colorGuideGrid: {
        marginBottom: 10,
    },

    thisProjectText: {
        border: "1px solid green",
        textAlign: "center",
        fontSize: "0.8rem",
        marginRight: 5,
    },

    otherProjectsText: {
        border: "1px solid darkorange",
        textAlign: "center",
        fontSize: "0.8rem",
    },

    merchandiserCardContainer: {},
    addRouteCardContainer: {},

    addRouteCardBtn: {
        width: "100%",
        backgroundColor: "lightgrey",
        fontSize: "1.5rem",
    },
    cardHeader: {
        textAlign: "center",
        backgroundColor: COLORS.background,
    },

    inactiveCardHeader: {
        textAlign: "center",
        backgroundColor: "#d7d7d7",
    },
    scheduleCardHeader: {
        backgroundColor: lighten(COLORS.accent, 0.8),
    },
    headerText: {
        fontSize: "1rem",
        color: COLORS.primary,
    },
    inactiveHeaderText: {
        color: "#565656",
    },
    scheduleHeaderText: {
        fontSize: "1rem",
        color: COLORS.accent,
    },
    subheaderText: {
        fontSize: "0.7rem",
        color: COLORS.accent,
    },
    inactiveSubheaderText: {
        color: "#565656",
    },

    cardItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid green",
        marginBottom: "8px",
        padding: "8px",
        backgroundColor: "#fff",
        "& p": {
            margin: 0,
        },
    },

    supermarketCardItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid green",
        margin: "0 auto 8px auto",
        width: "calc(100% - 16px)",
        padding: "8px",
        backgroundColor: "#fff",
        "& p": {
            margin: 0,
        },
    },

    otherProjectsBorder: {
        border: "1px solid darkorange",
    },

    cardItemMainText: {
        textAlign: "left",
        fontSize: "0.8rem",
    },

    cardItemSubText: {
        textAlign: "right",
        fontSize: "0.8rem",
    },

    outsiderCardItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid gray",
        marginBottom: "8px",
        color: "gray",
        padding: "8px",
        backgroundColor: "#fff",
        "& p": {
            margin: 0,
        },
    },

    controlsCard: {
        width: "100%",
        maxWidth: "1200px",
        margin: "10px auto",
        overflow: "visible",
        "& .MuiGrid-spacing-xs-1 > .MuiGrid-item": {
            "@media (min-width: 960px)": {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    },

    autoCompleteList: {
        width: "100%",
        height: "100%",
    },
    filterInput: {
        width: "100%",
        height: "100%",
    },
    button: {
        width: "100%",
        height: "100%",
        backgroundColor: COLORS.submit,
        color: "#fff",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
            color: "#fff",
        },
    },

    disabledBtn: {
        backgroundColor: "#c9c9c9",
        color: "#00000042",
    },

    routeCardActions: {
        backgroundColor: COLORS.background,
        justifyContent: "center",
        "& .MuiButton-root": {
            minWidth: "auto",
        },
        "& .MuiButton-label": {
            fontSize: "0.6rem",
        },
    },

    supermarketCardContent: {
        padding: "0px",
    },

    supermarketFilter: {
        width: "calc(100% - 8px)",
        margin: "2px auto 10px auto",
        display: "flex",
        "& input": {
            padding: "8px 14px",
        },
    },
});

class ProjectRoutePlanManagement extends React.Component {
    static contextType = AuthContext;
    state = {
        daysSelectedStatus: { sun: true, mon: false, tue: false, wed: false, thu: false, fri: false, sat: false },
        daySelected: "sun",

        routes: [],
        isRouteEditDialogOpened: false,
        routeIndex: -1,

        allSchedulesDetails: [],

        merchandisersList: [], // {info, branch}
        // merchandisersOrder: [],
        supervisorsList: [],
        vacantSchedulesDetailsIDs: { sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: [] },

        schedulesDetails: [
            /*      
            {
                id: sbID-obID-SchID
                //supplierBranchInfo: supplierBranch.info.data(),
                outletBranchInfo: outletBranch.info.data(),
                scheduleData: { ...schedule.data, merchandising_days: merchandising_days },
                //products: schedule.products,
                //linkedProducts: outletBranch.linkedProducts,
                toBeRemoved: false,
                toBeAdded: false,
                toBeUpdated: false, 

                routeDoc, order
                order: {sun: int, .....}
            }    
            */
        ],
        avgTravelTime: 15,
        supplierBranches: [],
        sbIndex: 0,

        supermarketFilter: "",

        loading: true,
    };
    init = async () => {
        try {
            //get supplier branches list
            const supplierBranchesList = this.props.projectFullData.supplierBranches.map((sp) => ({
                info: sp.info.data(),
                id: sp.data.branch_id,
                name: sp.info.data().En_name,
            }));
            if (supplierBranchesList.length === 0) {
                this.setState({ loading: false });
                return;
            }

            // let avgTravelTime = this.props.projectFullData.data.average_travel_time;
            // avgTravelTime = avgTravelTime ? avgTravelTime : 15;

            //get all schedules details object inside a project
            // let allSchedulesDetails = setupScheduleRows(this.props.projectFullData);
            let allSchedulesDetails = await setupAllScheduleRows(this.context.companyData.company_id);

            this.setState(
                {
                    // avgTravelTime: avgTravelTime,
                    supplierBranches: supplierBranchesList,
                    allSchedulesDetails: allSchedulesDetails,
                },
                () => {
                    this.onSupplierBranchSelect(this.state.supplierBranches[0]);
                    this.setState({ loading: false });
                }
            );
        } catch (error) {
            console.log(error);
            this.setState({ loading: false });
        }
    };

    refreshVacantSchedulesList = (schedulesDetails) => {
        ////filter by not taken by merchandiser
        //// const notAssignedSchedules = schedulesDetails.filter((sd) => sd.scheduleData.merchandiser_id === null);
        // filter supermarket not included in a route
        const notAssignedSchedules = schedulesDetails.filter((sd) => sd.scheduleData.route_id === null);
        let vacantSchedulesDetailsIDs = { sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: [] };
        for (const day in vacantSchedulesDetailsIDs) {
            if (Object.hasOwnProperty.call(vacantSchedulesDetailsIDs, day)) {
                notAssignedSchedules.forEach((sd) => {
                    if (sd.scheduleData.merchandising_days[day].selected) {
                        //// const path = `${sd.scheduleData.supplier_branch_id}:${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}`;
                        // const path = `${sd.scheduleData.project_id}:${sd.scheduleData.supplier_branch_id}:${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}`;
                        const path = {
                            project_id: sd.scheduleData.project_id,
                            branch_id: sd.scheduleData.supplier_branch_id,
                            outlet_branch_id: sd.scheduleData.outlet_branch_id,
                            schedule_id: sd.scheduleData.id,
                            sub_schedule_id: null,
                        };
                        vacantSchedulesDetailsIDs[day].push(path);
                    }
                });
            }
        }

        this.setState({
            vacantSchedulesDetailsIDs: vacantSchedulesDetailsIDs,
        });
    };

    // setupScheduleItem = async (scheduleItem) => {
    //     try {
    //         //init unique id for the item
    //         scheduleItem.id = `${scheduleItem.supplierBranchInfo.branch_id}-${scheduleItem.outletBranchInfo.branch_id}-${scheduleItem.scheduleData.id}`;
    //         let route = null;
    //         //get route if schedule has a merchandiser assigned to
    //         if (scheduleItem.scheduleData.merchandiser_id) route = await getRouteBySchedule(scheduleItem.scheduleData);
    //         scheduleItem.routeDoc = route && route.exists ? route : null;

    //         //init order of the item in a merchandiser's route plan
    //         scheduleItem.order = { sun: -1, mon: -1, tue: -1, wed: -1, thu: -1, fri: -1, sat: -1 };
    //         if (scheduleItem.routeDoc) {
    //             scheduleItem.order = scheduleItem.routeDoc.data().order;
    //         }
    //         return scheduleItem;
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    // setupMerchandiserRoutesDetails = async (merchandiser) => {
    //     try {
    //         const routes = merchandiser.routes;
    //         merchandiser.routes = await Promise.all(routes.map(setupRouteDetails));

    //         return merchandiser;
    //     } catch (error) {
    //         throw error;
    //     }
    // };

    getListFromDropId = (droppableId) => {
        if (droppableId === "supermarkets") return this.state.vacantSchedulesDetailsIDs[this.state.daySelected];

        //// let merchandiserRoutes = this.state.merchandisersList.find((mr) => mr.info.uid === droppableId);
        //// let projectID = this.props.projectFullData.data.project_id;
        //// return merchandiserRoutes.route.routes[this.state.daySelected][projectID] || [];
        let route = this.state.routes.find((r) => r.id === droppableId);
        return route.plan[this.state.daySelected].route || [];
    };

    updateSourceListsOnOtherdays = (droppableId, draggableId) => {
        let lists = null;
        //// let merchandisersList = null;
        if (droppableId === "supermarkets") lists = this.state.vacantSchedulesDetailsIDs;
        else {
            //// merchandisersList = this.state.merchandisersList;
            //// let merchandiserRoutes = merchandisersList.find((mr) => mr.info.uid === droppableId);
            //// lists = merchandiserRoutes.route.routes;

            let route = this.state.routes.find((r) => r.id === droppableId);
            lists = route.plan;
        }

        for (const day in lists) {
            if (Object.hasOwnProperty.call(lists, day)) {
                if (day === this.state.daySelected) continue;
                let index;
                if (droppableId === "supermarkets") {
                    //find the index of the dragged schedule in the array
                    // index = lists[day].findIndex((path) => path === draggableId);
                    index = lists[day].findIndex((obj) => {
                        const path = `${obj.project_id}:${obj.branch_id}:${obj.outlet_branch_id}:${obj.schedule_id}`;
                        return path === draggableId;
                    });

                    if (index === -1) continue;
                    //romove
                    lists[day].splice(index, 1);
                } else {
                    //// const projectID = this.props.projectFullData.data.project_id;
                    //// let list = lists[day][projectID] || [];
                    let list = lists[day].route || [];
                    // index = list.findIndex((path) => path === draggableId);
                    index = list.findIndex((obj) => {
                        const path = `${obj.project_id}:${obj.branch_id}:${obj.outlet_branch_id}:${obj.schedule_id}`;
                        return path === draggableId;
                    });

                    if (index === -1) continue;
                    list.splice(index, 1);

                    //// lists[day][projectID] = list;
                    //// if (lists[day][projectID].length === 0) delete lists[day][projectID];
                    lists[day].route = list;
                }
            }
        }

        if (droppableId === "supermarkets") {
            this.setState({ vacantSchedulesDetailsIDs: { ...lists } });
        } else {
            let newRoutes = [...this.state.routes];
            const index = newRoutes.findIndex((r) => r.id === droppableId);
            let route = { ...newRoutes[index] };
            route.plan = lists;
            newRoutes[index] = route;
            this.setState({ routes: newRoutes });
            // this.setState({ merchandisersList: merchandisersList });
        }
    };

    updateDestinationListsOnOtherdays = (droppableId, draggableId, index) => {
        let lists = null;
        const ids = draggableId.split(":");
        const draggableObject = {
            project_id: ids[0],
            branch_id: ids[1],
            outlet_branch_id: ids[2],
            schedule_id: ids[3],
            sub_schedule_id: ids[4] || null,
        };
        //// let merchandisersList = null;
        //get the dragged schedule details
        let scheduleDetails = this.state.schedulesDetails.find((sd) => {
            const path = `${sd.scheduleData.project_id}:${sd.scheduleData.supplier_branch_id}:${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}`;
            return path === draggableId;
        });

        if (droppableId === "supermarkets") lists = this.state.vacantSchedulesDetailsIDs;
        else {
            //// merchandisersList = this.state.merchandisersList;
            //// let merchandiserRoutes = merchandisersList.find((mr) => mr.info.uid === droppableId);
            //// lists = merchandiserRoutes.route.routes;
            let route = this.state.routes.find((r) => r.id === droppableId);
            lists = route.plan;
        }

        for (const day in lists) {
            if (Object.hasOwnProperty.call(lists, day)) {
                if (day === this.state.daySelected) continue;
                //if it is not a working day for the dragged schedule, skip
                if (!scheduleDetails.scheduleData.merchandising_days[day].selected) continue;

                if (droppableId === "supermarkets") {
                    let newIndex = index > lists[day].length ? lists[day].length : index;
                    lists[day].splice(newIndex, 0, draggableObject);
                } else {
                    //// const projectID = this.props.projectFullData.data.project_id;
                    //// let list = lists[day][projectID] || [];
                    let list = lists[day].route || [];
                    let newIndex = index > list.length ? list.length : index;
                    list.splice(newIndex, 0, draggableObject);

                    //// lists[day][projectID] = list;
                    lists[day].route = list;
                }
            }
        }

        if (droppableId === "supermarkets") {
            this.setState({ vacantSchedulesDetailsIDs: { ...lists } });
        } else {
            let newRoutes = [...this.state.routes];
            const routeIndex = newRoutes.findIndex((r) => r.id === droppableId);
            let route = { ...newRoutes[routeIndex] };
            route.plan = lists;
            newRoutes[routeIndex] = route;
            this.setState({ routes: newRoutes });
            //// this.setState({ merchandisersList: merchandisersList });
        }
    };

    updateOrderList = (droppableId, newList) => {
        if (droppableId === "supermarkets") {
            let vacantSchedulesDetailsIDs = { ...this.state.vacantSchedulesDetailsIDs };
            vacantSchedulesDetailsIDs[this.state.daySelected] = newList;
            this.setState({ vacantSchedulesDetailsIDs: vacantSchedulesDetailsIDs });
        } else {
            //// let merchandisersList = this.state.merchandisersList;
            //// let merchandiser = merchandisersList.find((mr) => mr.info.uid === droppableId);
            //// let projectID = this.props.projectFullData.data.project_id;

            //// merchandiser.route.routes[this.state.daySelected][projectID] = newList;
            //// if (newList.length === 0) delete merchandiser.route.routes[this.state.daySelected][projectID];
            //// merchandiser.toBeUpdated = true;
            //// this.setState({ merchandisersList: merchandisersList });

            let newRoutes = [...this.state.routes];
            const index = newRoutes.findIndex((r) => r.id === droppableId);
            let route = { ...newRoutes[index] };
            route.plan[this.state.daySelected].route = newList;
            newRoutes[index] = route;
            this.setState({ routes: newRoutes });
        }
    };

    onDaySelect = (day) => {
        if (day === this.state.daySelected) return;
        let daysSelectedStatus = { ...this.state.daysSelectedStatus };
        daysSelectedStatus[day] = true;
        daysSelectedStatus[this.state.daySelected] = false;

        this.setState({
            daysSelectedStatus: daysSelectedStatus,
            daySelected: day,
        });
    };

    //// checkDestinationIsActive = (merchandiserID) => {
    ////     const merchandiserRoutes = this.state.merchandisersList.find((mr) => mr.info.uid === merchandiserID);
    ////     const merchStatus = merchandiserRoutes?.info?.account_information?.state;

    ////     return merchStatus !== MERCHANDISER_ACCOUNT_STATUS.INACTIVE;
    //// };

    onDragEnd = (result) => {
        //droppableId could be 'supermarkets' or route id, draggableId is schedule path: outlet branch id:schedule id
        const { destination, source, draggableId } = result;
        if (!destination) return;
        //// if (destination.droppableId !== "supermarkets" && !this.checkDestinationIsActive(destination.droppableId))
        // dropped on the same route card and on same order
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        let schedulesDetails = this.state.schedulesDetails;
        //get supermarket info
        let scheduleDetails = schedulesDetails.find((sd) => {
            const path = `${sd.scheduleData.project_id}:${sd.scheduleData.supplier_branch_id}:${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}`;
            return path === draggableId;
        });

        let draggableIds = draggableId.split(":");
        const draggableObject = {
            project_id: draggableIds[0],
            branch_id: draggableIds[1],
            outlet_branch_id: draggableIds[2],
            schedule_id: draggableIds[3],
            sub_schedule_id: draggableIds[4] || null,
        };

        //re-order in the same route card
        if (destination.droppableId === source.droppableId) {
            // list of schedule paths
            let list = this.getListFromDropId(source.droppableId);
            let newList = Array.from(list);
            newList.splice(source.index, 1);
            newList.splice(destination.index, 0, draggableObject);

            this.updateOrderList(source.droppableId, newList);

            ////if the schedule is assigned to merchandiser but not exist in the current supplier branch
            //// if (!scheduleDetails) return;

            if (destination.droppableId === "supermarkets") {
                scheduleDetails.scheduleData.route_id = null;
                scheduleDetails.scheduleData.merchandiser_id = null;
                scheduleDetails.scheduleData.supervisor_id = null;
            } else {
                scheduleDetails.scheduleData.route_id = destination.droppableId;
                scheduleDetails.scheduleData.merchandiser_id =
                    this.state.routes.find((r) => r.id === destination.droppableId).merchandiser_id || null;
                scheduleDetails.scheduleData.supervisor_id =
                    this.state.routes.find((r) => r.id === destination.droppableId).supervisor_id || null;
            }

            console.log("re-order in the same route card");
            console.log(scheduleDetails.scheduleData.route_id, scheduleDetails.scheduleData.merchandiser_id);

            scheduleDetails.toBeUpdated = true;
            this.setState({ schedulesDetails: schedulesDetails });
            return;
        }

        ////if the schedule is assigned to merchandiser but not exist in the current supplier branch
        //// if (!scheduleDetails) return;

        //the schedule is travelled from one to another
        let sourceList = Array.from(this.getListFromDropId(source.droppableId));
        let destinationList = Array.from(this.getListFromDropId(destination.droppableId));

        //remove
        sourceList.splice(source.index, 1);
        //remove the schedule also in other days
        this.updateSourceListsOnOtherdays(source.droppableId, draggableId);

        //add at the specifiied order
        destinationList.splice(destination.index, 0, draggableObject);
        //add the schedule also in other days
        this.updateDestinationListsOnOtherdays(destination.droppableId, draggableId, destination.index);

        this.updateOrderList(source.droppableId, sourceList);
        this.updateOrderList(destination.droppableId, destinationList);

        if (destination.droppableId === "supermarkets") {
            scheduleDetails.scheduleData.route_id = null;
            scheduleDetails.scheduleData.merchandiser_id = null;
            scheduleDetails.scheduleData.supervisor_id = null;
        } else {
            scheduleDetails.scheduleData.route_id = destination.droppableId;
            scheduleDetails.scheduleData.merchandiser_id =
                this.state.routes.find((r) => r.id === destination.droppableId).merchandiser_id || null;
            scheduleDetails.scheduleData.supervisor_id =
                this.state.routes.find((r) => r.id === destination.droppableId).supervisor_id || null;
        }

        console.log("the schedule is travelled from one to another");
        console.log(scheduleDetails.scheduleData.route_id, scheduleDetails.scheduleData.merchandiser_id);
        //// scheduleDetails.scheduleData.merchandiser_id =
        ////     destination.droppableId === "supermarkets" ? null : destination.droppableId;
        scheduleDetails.toBeUpdated = true;
        this.setState({ schedulesDetails: schedulesDetails });
    };

    findScheduleInProject = (scheduleData) => {
        let projectFullData = this.props.projectFullData;
        let supplierBranchID = scheduleData.supplier_branch_id;
        let outletBranchID = scheduleData.outlet_branch_id;
        let scheduleID = scheduleData.id;

        console.log(projectFullData, outletBranchID);
        let supplierBranch = projectFullData.supplierBranches.find((sb) => sb.doc.id === supplierBranchID);
        let outletBranch = supplierBranch.outletBranches.find((ob) => ob.doc.id === outletBranchID);
        let schedule = outletBranch.schedules.find((sch) => sch.doc.id === scheduleID);

        return schedule;
    };

    onSupplierBranchSelect = async (supplierBranch) => {
        if (!supplierBranch) {
            return;
        }
        this.setState({ loading: true });
        try {
            //get selected supplier branch index
            const supplierBranchesList = this.state.supplierBranches;
            let sbIndex = supplierBranchesList.findIndex((sb) => sb.id === supplierBranch.id);

            if (sbIndex === -1) return;

            //get merchandisers based on the current selected supplier branch city
            let merchandisers = cloneDeep(
                this.props.merchandisers.filter((merch) => {
                    let supplier_branch_id = merch.info.account_information.supplier_branch_id;
                    if (merch.contract) {
                        let merchContractInfo = merch.contract.merchandisers.find((m) => m.id === merch.info.uid);
                        if (!merchContractInfo) {
                            throw new Error("outsource merchandiser has no contract");
                        }
                        supplier_branch_id = merchContractInfo.supplier_branch_id;
                    }
                    // console.log(supplier_branch_id, supplierBranchesList[sbIndex].id);
                    return supplier_branch_id === supplierBranchesList[sbIndex].id;
                })
            );

            //get supervisors
            let supervisors = (await getSupervisorsBySupplierBranch(supplierBranch.id)).map((doc) => doc.data());

            //init cards of merchandisers order array
            merchandisers.sort((a, b) => {
                const nameA = getMerchandiserName(a.info);
                const nameB = getMerchandiserName(b.info);
                return nameA < nameB ? -1 : 1;
            });
            // const merchandisersOrder = merchandisers.map((mr) => mr.info.uid);

            // const merchandisersOrder = merchandisersList.map((mr) => mr.merchandiserDoc.id);

            //get all schedules
            //// let allSchedulesDetails = setupScheduleRows(this.props.projectFullData);
            let allSchedulesDetails = this.state.allSchedulesDetails;
            //filter schedulesDetails by selected Supplier Branch
            let schedulesDetails = allSchedulesDetails.filter(
                (sch) => sch.scheduleData.supplier_branch_id === supplierBranch.id
            );

            //get all routes
            let routes = await queryRoutes([
                { key: "company_id", operator: "==", value: this.context.companyData.company_id },
                { key: "company_type", operator: "==", value: "supplier" },
                { key: "is_deleted", operator: "==", value: false },
                { key: "branch_id", operator: "==", value: supplierBranch.id },
            ]);
            routes = routes.map((r) => r.data());

            //setup supermarket list
            this.refreshVacantSchedulesList(schedulesDetails);

            this.setState({
                sbIndex: sbIndex,
                merchandisersList: merchandisers,
                // merchandisersOrder: merchandisersOrder,
                schedulesDetails: schedulesDetails,
                supervisorsList: supervisors,

                routes: routes,
            });
        } catch (error) {
            console.log(error);
        }
        this.setState({ loading: false });
    };

    addRouteCard = async () => {
        this.setState({ loading: true });
        try {
            const { supplierBranches, sbIndex, routes } = this.state;
            //get a vacant id from firestore
            // let short_id = await generateNewId("Route", "short_id");
            const id = (await getNewDoc("Route")).id;

            // if id is used by other unsaved route card, incerement
            // short_id = this.state.routes.reduce((id, route) => (route.short_id === id ? id + 1 : id), +short_id);
            // const project_id = this.props.projectFullData.data.project_id;
            const supplier_branch_id = supplierBranches[sbIndex].id;

            let newRoute = {
                id: id,
                name: `Route ${this.state.routes.length}`,
                merchandiser_id: null,
                supervisor_id: null,
                company_id: this.context.companyData.company_id,
                company_type: "supplier",
                branch_id: supplier_branch_id,
                type: "non-exclusive",
                exclusive_to: null,
                platform: "emaham",
                is_deleted: false,
                plan: {
                    sun: {
                        average_travel_time: 15,
                        route: [],
                    },
                    mon: {
                        average_travel_time: 15,
                        route: [],
                    },
                    tue: {
                        average_travel_time: 15,
                        route: [],
                    },
                    wed: {
                        average_travel_time: 15,
                        route: [],
                    },
                    thu: {
                        average_travel_time: 15,
                        route: [],
                    },
                    fri: {
                        average_travel_time: 15,
                        route: [],
                    },
                    sat: {
                        average_travel_time: 15,
                        route: [],
                    },
                },
            };
            // let newRoute = {
            //     id: `${project_id}-${supplier_branch_id}-${short_id}`,
            //     project_id,
            //     supplier_branch_id,
            //     short_id,
            //     plan: { sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: [] },
            //     name: `Route ${this.state.routes.length}`,
            //     avg_travel_time: 15,
            //     merchandiser_id: null,
            //     is_deleted: false,
            // };

            let temp = [...routes, newRoute];
            this.setState({ routes: temp });
        } catch (error) {
            console.log(error);
        }

        this.setState({ loading: false });
    };

    onSave = async () => {
        this.setState({ loading: true });
        try {
            let projectFullData = this.props.projectFullData;
            let schedulesDetails = this.state.schedulesDetails;

            //// projectFullData.data.average_travel_time = this.state.avgTravelTime;
            //// projectFullData.toBeUpdated = true;

            //loop through all schedules details to update schedule data in project and schedules from other projects
            let otherSchedules = [];
            for (const sd of schedulesDetails) {
                let scheduleData = sd.scheduleData;

                //sync merchandiser id between schedule and assigned route
                if (scheduleData.route_id) {
                    //! look using ids instead of route id because route id is old format inside schedule
                    let route = this.state.routes.find((r) => {
                        const path = `${scheduleData.project_id}:${scheduleData.supplier_branch_id}:${scheduleData.outlet_branch_id}:${scheduleData.id}`;
                        const plan = r.plan;
                        for (const day in plan) {
                            if (Object.hasOwnProperty.call(plan, day)) {
                                if (!scheduleData.merchandising_days[day].selected) {
                                    continue;
                                }
                                const dayRoute = plan[day].route;
                                const index = dayRoute.findIndex(
                                    (dr) =>
                                        path ===
                                        `${dr.project_id}:${dr.branch_id}:${dr.outlet_branch_id}:${dr.schedule_id}`
                                );

                                if (index !== -1) return true;
                            }
                        }
                        return false;
                    });
                    if (!route) {
                        scheduleData.route_id = null;
                        scheduleData.merchandiser_id = null;
                        scheduleData.supervisor_id = null;
                    } else {
                        scheduleData.route_id = route.id;
                        scheduleData.merchandiser_id = route.merchandiser_id;
                        scheduleData.supervisor_id = route.supervisor_id || null;
                    }
                } else {
                    scheduleData.merchandiser_id = null;
                    scheduleData.supervisor_id = null;
                }

                //schedules from other projects will have seperate saving process
                if (this.props.projectFullData.data.project_id === scheduleData.project_id) {
                    let projectSchedule = this.findScheduleInProject(scheduleData);
                    projectSchedule.data = { ...scheduleData };
                    projectSchedule.toBeUpdated = true;
                } else {
                    otherSchedules.push(sd);
                }
            }

            //save other Schedules from different project
            await Promise.all(
                otherSchedules.map(async (sch) => {
                    const projectID = sch.scheduleData.project_id;
                    const supplierBranchID = sch.scheduleData.supplier_branch_id;
                    const outletBranchID = sch.scheduleData.outlet_branch_id;
                    const scheduleID = sch.scheduleData.id;
                    const { route_id, merchandiser_id, supervisor_id } = sch.scheduleData;
                    return updateSchedule(projectID, supplierBranchID, outletBranchID, scheduleID, {
                        route_id,
                        merchandiser_id,
                        supervisor_id,
                    });
                })
            );

            //// let updatedMerchandisers = cloneDeep(this.props.merchandisers);
            //// updatedMerchandisers = updatedMerchandisers.map((merch) => {
            ////     let updatedMerchandiser = this.state.merchandisersList.find(
            ////         (updatedMerch) => updatedMerch.info.uid === merch.info.uid
            ////     );
            ////     return updatedMerchandiser ? updatedMerchandiser : merch;
            //// });

            // // delete routes with a empty plan
            // let split = this.state.routes.reduce(
            //     (obj, route) => {
            //         const empty = isEmptyRoute(route.plan);
            //         if (empty) obj.delete.push(route);
            //         else obj.keep.push(route);
            //         return obj;
            //     },
            //     { delete: [], keep: [] }
            // );
            // await deleteRoutes(split.delete.map((route) => route.id));

            //update / create routes
            // await saveRoutes(split.keep);
            await saveRoutes(this.state.routes);
            // await this.props.sync(projectFullData, updatedMerchandisers);
            await this.props.sync(projectFullData);
            this.setState({ schedulesDetails: schedulesDetails });
        } catch (error) {
            console.log(error);
        }
        this.setState({ loading: false });
    };

    //// onAvgTravelTimeChange = (e) => {
    ////     let value = e.target.value;
    ////     if (value < 0 || value === "") value = 0;
    ////     let num = Number(value);
    ////     this.setState({ avgTravelTime: num });
    //// };

    getRouteSchedulePaths = (route) => {
        let plan = route.plan;
        let unique = [];
        for (const day in plan) {
            if (Object.hasOwnProperty.call(plan, day)) {
                const paths = plan[day].route;
                unique = [...new Set([...paths, ...unique])];
            }
        }

        return unique;
    };

    deleteRoute = async (index) => {
        try {
            const { isConfirmed } = await Swal.fire({
                title: "Are you sure?",
                text: "This route will be deleted and open/booked tasks will be affected from tomorrow onwards.",
                icon: "question",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                confirmButtonText: "Delete",
            });
            if (!isConfirmed) return;

            this.setState({ loading: true });
            //!temp
            const { id: routeID, company_id, plan } = this.state.routes[index];
            this.clearRoute(index);

            //get route tasks from tommorow
            const tmw = getTomorrow();
            // const tmw = getToday();
            // const tasks = await queryTasks({ routeID: routeID, date: tmw });
            let tasks = await queryTasks_V2([
                // { key: "route_id", operator: "==", value: routeID },
                { key: "supplier_id", operator: "==", value: Number(company_id) },
                { key: "date_time_from", operator: ">=", value: tmw },
            ]);

            //filter the tasks that are included in the route
            tasks = tasks.filter((t) => {
                const data = t.data();
                const path = `${data.project_id}:${data.supplier_branch_id}:${data.outlet_branch_id}:${data.schedule_id}`;
                for (const day in plan) {
                    if (Object.hasOwnProperty.call(plan, day)) {
                        const dayRoute = plan[day].route;
                        const index = dayRoute.findIndex(
                            (dr) => path === `${dr.project_id}:${dr.branch_id}:${dr.outlet_branch_id}:${dr.schedule_id}`
                        );

                        if (index !== -1) return true;
                    }
                }
                return false;
            });

            //unlink route and merchandiser if they are open, booked or reserved, otherwise skip
            await Promise.all(
                tasks.map(async (task) => {
                    let { state, route_id, merchandiser_id, supervisor_id } = task.data();
                    if (
                        state === TASKS_STATUS.OPEN ||
                        state === TASKS_STATUS.BOOKED ||
                        state === TASKS_STATUS.RESERVED
                    ) {
                        state = TASKS_STATUS.OPEN;
                        route_id = null;
                        merchandiser_id = null;
                        supervisor_id = null;
                        return await task.ref.update({
                            route_id,
                            merchandiser_id,
                            supervisor_id,
                            state,
                        });
                    }
                })
            );

            //flag the route as deleted
            await updateRoute(routeID, {
                is_deleted: true,
            });

            let routes = [...this.state.routes];
            routes.splice(index, 1);
            this.setState(
                {
                    routes,
                },
                this.onSave
            );
        } catch (error) {
            console.log(error);
        }
        this.setState({ loading: false });
    };

    clearRoute = (index) => {
        try {
            let routes = [...this.state.routes];
            let route = routes[index];
            let schedulePaths = this.getRouteSchedulePaths(route);

            //clear merchandiser id and route in all schedules
            let schedulesDetails = this.state.schedulesDetails;
            for (const path of schedulePaths) {
                let scheduleDetails = this.state.schedulesDetails.find(
                    (sd) => {
                        const { project_id, supplier_branch_id, outlet_branch_id, id } = sd.scheduleData;
                        return (
                            project_id === path.project_id &&
                            supplier_branch_id === path.branch_id &&
                            outlet_branch_id === path.outlet_branch_id &&
                            id === path.schedule_id
                        );
                    }

                    // `${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}` === path
                );

                scheduleDetails.scheduleData.route_id = null;
                scheduleDetails.scheduleData.merchandiser_id = null;
                scheduleDetails.scheduleData.supervisor_id = null;
                scheduleDetails.toBeUpdated = true;
            }

            //clear plan
            for (const day in route.plan) {
                if (Object.hasOwnProperty.call(route.plan, day)) {
                    route.plan[day].route = [];
                }
            }

            this.refreshVacantSchedulesList(schedulesDetails);

            this.setState({ schedulesDetails: schedulesDetails, routes: routes });
        } catch (error) {
            console.log(error);
        }
    };

    openRouteCardEditor = (index) => {
        this.setState({
            isRouteEditDialogOpened: true,
            routeIndex: index,
        });
    };

    closeRouteCardEditor = () => {
        this.setState({
            isRouteEditDialogOpened: false,
            routeIndex: -1,
        });
    };

    handleRouteDialogSave = (route, newValues) => {
        const { name, travelTime, merchandiser, supervisor } = newValues;
        let plan = { ...route.plan };
        for (const day in plan) {
            if (Object.hasOwnProperty.call(plan, day)) {
                plan[day].average_travel_time = travelTime && travelTime > 0 ? travelTime : 15;
            }
        }
        let modified = {
            ...route,
            name: name || route.name,
            plan: plan,
            // avg_travel_time: travelTime && travelTime > 0 ? travelTime : 15,
            merchandiser_id: merchandiser?.info.uid || null,
            supervisor_id: supervisor?.uid || null,
        };

        let newRoutes = [...this.state.routes];
        newRoutes[this.state.routeIndex] = modified;
        this.setState({ routes: newRoutes, isRouteEditDialogOpened: false, routeIndex: -1 });
    };

    componentDidMount() {
        this.init();
    }
    render() {
        const classes = this.props.classes;
        // const loader = <div className={classes.loader}></div>;
        return (
            <div className={classes.root}>
                <Card className={classes.controlsCard}>
                    <Grid container spacing={1}>
                        {/* supplier branches list */}
                        <Grid item xs={12} sm={6} md={8} className={classes.controlsGridItem} style={{ paddingTop: 0 }}>
                            <Autocomplete
                                // autoSelect
                                disableClearable
                                id="supplier-branches-list"
                                className={classes.autoCompleteList}
                                options={this.state.supplierBranches}
                                value={this.state.supplierBranches[this.state.sbIndex] || null}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} label="Choose Supplier Branches" variant="outlined" />
                                )}
                                onChange={(event, supplierBranch) => this.onSupplierBranchSelect(supplierBranch)}
                            />
                        </Grid>
                        {/* average travel time */}
                        {/* <Grid item xs={12} sm={6} md={2} className={classes.controlsGridItem}>
                            <TextField
                                variant="outlined"
                                type="number"
                                label="Average Travel Time"
                                placeholder="Average Travel Time (In minutes)"
                                className={classes.filterInput}
                                onChange={this.onAvgTravelTimeChange}
                                value={this.state.avgTravelTime}
                            />
                        </Grid> */}
                        {/* save button */}
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className={classes.controlsGridItem}
                            style={{ paddingBottom: 0 }}
                        >
                            <Button
                                className={classes.button}
                                classes={{ disabled: classes.disabledBtn }}
                                onClick={this.onSave}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Card>

                {/* weekday tabs */}
                <Grid container justifyContent="center">
                    <WeekdayTabs days={this.state.daysSelectedStatus} onDaySelect={this.onDaySelect} />
                </Grid>

                {/* color guide */}
                <Grid container justifyContent="center" className={classes.colorGuideGrid}>
                    <Grid item xs={1}>
                        <Typography className={classes.thisProjectText}>This Project</Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography className={classes.otherProjectsText}>Other Projects</Typography>
                    </Grid>
                </Grid>

                <FullPageLoader loading={this.state.loading} />

                {/* cards */}
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Grid container spacing={2} justifyContent="center">
                        {/* routes */}
                        <Grid container item spacing={2} xs={6} sm={9} md={10}>
                            {this.state.routes.map((route, routeIndex) => {
                                const {
                                    id,
                                    name,
                                    // project_id,
                                    // supplier_branch_id,
                                    merchandiser_id,
                                    supervisor_id,
                                    plan,
                                    // avg_travel_time,
                                } = route;

                                const selectedPlan = plan[this.state.daySelected];
                                //get schedules data that are included in the plan
                                let schedules = this.state.schedulesDetails.filter((sd) => {
                                    // if (
                                    //     sd.scheduleData.project_id !== project_id ||
                                    //     sd.scheduleData.supplier_branch_id !== supplier_branch_id
                                    // )
                                    //     return false;

                                    // console.log(sd, selectedPlan.route);

                                    const path = `${sd.scheduleData.project_id}:${sd.scheduleData.supplier_branch_id}:${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}`;
                                    return (
                                        selectedPlan.route.findIndex((r) => {
                                            return (
                                                `${r.project_id}:${r.branch_id}:${r.outlet_branch_id}:${r.schedule_id}` ===
                                                path
                                            );
                                        }) !== -1
                                    );
                                });

                                // console.log(schedules);

                                //get total duration of the route
                                let totalDuration = schedules.reduce((sum, sd) => sum + sd.scheduleData.duration, 0);
                                totalDuration +=
                                    selectedPlan.route.length === 0
                                        ? 0
                                        : (selectedPlan.route.length - 1) * selectedPlan.average_travel_time;

                                const durationStr = convertMinutesToHoursAndMinutes(totalDuration);
                                let totalSchedulesStr = `No visits`;
                                if (schedules.length > 1) {
                                    totalSchedulesStr = `${schedules.length} visits`;
                                } else if (schedules.length === 1) {
                                    totalSchedulesStr = `${schedules.length} visit`;
                                }

                                const durationAndTotalStr = `${`${durationStr} ` ?? ""}(${totalSchedulesStr})`;

                                const merchandiser = this.state.merchandisersList.find(
                                    (mr) => mr.info.uid === merchandiser_id
                                )?.info;

                                const supervisor = this.state.supervisorsList.find((sv) => sv.uid === supervisor_id);

                                const merchName = merchandiser ? getMerchandiserName(merchandiser) : "-";
                                const supervisorName = supervisor ? getMerchandiserName(supervisor) : "-";

                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={2}
                                        key={`${id}-daily-card`}
                                        className={classes.merchandiserCardContainer}
                                    >
                                        <Card variant="outlined">
                                            <CardHeader
                                                title={name}
                                                subheader={
                                                    <>
                                                        S: {supervisorName}
                                                        <br />
                                                        M: {merchName}
                                                        <br />
                                                        {durationAndTotalStr}
                                                    </>
                                                }
                                                className={classes.cardHeader}
                                                titleTypographyProps={{
                                                    className: classes.headerText,
                                                }}
                                                subheaderTypographyProps={{
                                                    className: classes.subheaderText,
                                                }}
                                            ></CardHeader>

                                            <Droppable droppableId={id}>
                                                {(provided) => (
                                                    <CardContent
                                                        innerRef={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                        {/* map the plan array itself to keep and show the order */}
                                                        {selectedPlan.route.map((obj, index) => {
                                                            const path = `${obj.project_id}:${obj.branch_id}:${obj.outlet_branch_id}:${obj.schedule_id}`;
                                                            const schedule = schedules.find(
                                                                (sd) =>
                                                                    `${sd.scheduleData.project_id}:${sd.scheduleData.supplier_branch_id}:${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}` ===
                                                                    path
                                                            );

                                                            let otherProjectClass =
                                                                obj.project_id !==
                                                                this.props.projectFullData.data.project_id
                                                                    ? classes.otherProjectsBorder
                                                                    : null;
                                                            return (
                                                                <Draggable
                                                                    key={`merch-schedule-item-${path}`}
                                                                    draggableId={`${path}`}
                                                                    index={index}
                                                                >
                                                                    {(provided) => (
                                                                        <Typography
                                                                            component="div"
                                                                            className={`${classes.cardItem} ${otherProjectClass}`}
                                                                            innerRef={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <p className={classes.cardItemMainText}>
                                                                                {schedule
                                                                                    ? schedule.outletBranchInfo
                                                                                          .En_short_name
                                                                                    : "No Data"}
                                                                            </p>
                                                                            <p className={classes.cardItemSubText}>
                                                                                {schedule
                                                                                    ? `${schedule.scheduleData.duration} Min`
                                                                                    : ""}
                                                                            </p>
                                                                        </Typography>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        })}
                                                        {provided.placeholder}
                                                    </CardContent>
                                                )}
                                            </Droppable>
                                            <CardActions className={classes.routeCardActions}>
                                                <Button size="small" onClick={() => this.clearRoute(routeIndex)}>
                                                    Clear
                                                </Button>
                                                <Button
                                                    size="small"
                                                    onClick={() => this.openRouteCardEditor(routeIndex)}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    size="small"
                                                    style={{ color: "red" }}
                                                    onClick={() => this.deleteRoute(routeIndex)}
                                                >
                                                    Delete
                                                </Button>
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                );
                            })}
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={2}
                                key={`daily-card-add-button`}
                                className={classes.addRouteCardContainer}
                            >
                                <Button className={classes.addRouteCardBtn} onClick={this.addRouteCard}>
                                    +
                                </Button>
                            </Grid>
                        </Grid>
                        {/* merchandisers */}
                        {/* <Grid container item spacing={2} xs={12} sm={12} md={9}>
                            {this.state.merchandisersOrder.map((merchID) => {
                                const merchandiserRoutes = this.state.merchandisersList.find(
                                    (mr) => mr.info.uid === merchID
                                );
                                const merchStatus = merchandiserRoutes?.info?.account_information?.state;
                                const projectID = this.props.projectFullData.data.project_id;
                                const merchSchedulesPaths =
                                    merchandiserRoutes.route.routes[this.state.daySelected][projectID] || [];

                                let merchSchedules = this.state.allSchedulesDetails.filter((sd) => {
                                    const path = `${sd.scheduleData.supplier_branch_id}:${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}`;
                                    return merchSchedulesPaths.indexOf(path) !== -1;
                                });

                                let totalDuration = merchSchedules.reduce(
                                    (sum, sd) => sum + sd.scheduleData.duration,
                                    0
                                );
                                totalDuration +=
                                    merchSchedulesPaths.length === 0
                                        ? 0
                                        : (merchSchedulesPaths.length - 1) * this.state.avgTravelTime;
                                const durationStr = convertMinutesToHoursAndMinutes(totalDuration);
                                // let totalDuration = this.state.schedulesDetails.reduce((totalDuration, sd) => {
                                //     const path = `${sd.scheduleData.supplier_branch_id}:${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}`;
                                //     let index = merchSchedulesPaths.indexOf(path);
                                //     if (index === -1) return totalDuration;
                                //     return totalDuration + sd.scheduleData.duration;
                                // }, 0);
                                const merchName = getMerchandiserName(merchandiserRoutes.info);
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        key={`${merchName}-daily-card`}
                                        className={classes.merchandiserCardContainer}
                                    >
                                        <Card variant="outlined">
                                            <CardHeader
                                                title={merchName}
                                                subheader={`${WEEKDAYS_FULL[this.state.daySelected]}${
                                                    durationStr ? ` (${durationStr})` : ""
                                                }`}
                                                className={
                                                    merchStatus !== MERCHANDISER_ACCOUNT_STATUS.INACTIVE
                                                        ? classes.cardHeader
                                                        : classes.inactiveCardHeader
                                                }
                                                titleTypographyProps={{
                                                    className:
                                                        merchStatus !== MERCHANDISER_ACCOUNT_STATUS.INACTIVE
                                                            ? classes.headerText
                                                            : classes.inactiveHeaderText,
                                                }}
                                                subheaderTypographyProps={{
                                                    className:
                                                        merchStatus !== MERCHANDISER_ACCOUNT_STATUS.INACTIVE
                                                            ? classes.subheaderText
                                                            : classes.inactiveSubheaderText,
                                                }}
                                            ></CardHeader>

                                            <Droppable droppableId={merchID}>
                                                {(provided) => (
                                                    <CardContent
                                                        innerRef={provided.innerRef}
                                                        {...provided.droppableProps}
                                                    >
                                                        {merchSchedulesPaths.map((path, index) => {
                                                            let isOutsiderSchedule = false;
                                                            let scheduleItem = this.state.schedulesDetails.find(
                                                                (sd) =>
                                                                    `${sd.scheduleData.supplier_branch_id}:${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}` ===
                                                                    path
                                                            );

                                                            if (!scheduleItem) {
                                                                isOutsiderSchedule = true;
                                                                scheduleItem = this.state.allSchedulesDetails.find(
                                                                    (sd) =>
                                                                        `${sd.scheduleData.supplier_branch_id}:${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}` ===
                                                                        path
                                                                );
                                                            }

                                                            return (
                                                                <Draggable
                                                                    key={`merch-schedule-item-${path}`}
                                                                    draggableId={`${path}`}
                                                                    index={index}
                                                                >
                                                                    {(provided) => (
                                                                        <Typography
                                                                            component="div"
                                                                            className={
                                                                                isOutsiderSchedule
                                                                                    ? classes.outsiderCardItem
                                                                                    : classes.cardItem
                                                                            }
                                                                            innerRef={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        >
                                                                            <p style={{ textAlign: "left" }}>
                                                                                {scheduleItem
                                                                                    ? scheduleItem.outletBranchInfo
                                                                                          .En_short_name
                                                                                    : "No Data"}
                                                                            </p>
                                                                            <p style={{ textAlign: "right" }}>
                                                                                {scheduleItem
                                                                                    ? `${scheduleItem.scheduleData.duration} Min`
                                                                                    : ""}
                                                                            </p>
                                                                        </Typography>
                                                                    )}
                                                                </Draggable>
                                                            );
                                                        })}
                                                        {provided.placeholder}
                                                    </CardContent>
                                                )}
                                            </Droppable>
                                            
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid> */}
                        {/* schedules list */}

                        <Grid container item xs={6} sm={3} md={2}>
                            <Grid item xs={12}>
                                <Card variant="outlined">
                                    <CardHeader
                                        title={"Supermarkets"}
                                        subheader={`${WEEKDAYS_FULL[this.state.daySelected]}`}
                                        className={`${classes.cardHeader} ${classes.scheduleCardHeader}`}
                                        titleTypographyProps={{ className: classes.scheduleHeaderText }}
                                        subheaderTypographyProps={{ className: classes.subheaderText }}
                                    />
                                    <Droppable droppableId={`supermarkets`}>
                                        {(provided) => (
                                            <CardContent
                                                className={classes.supermarketCardContent}
                                                innerRef={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                <TextField
                                                    className={classes.supermarketFilter}
                                                    value={this.state.supermarketFilter}
                                                    variant="outlined"
                                                    placeholder="Filter Supermarkets"
                                                    onChange={(e) =>
                                                        this.setState({
                                                            supermarketFilter: e.target.value,
                                                        })
                                                    }
                                                />
                                                {this.state.vacantSchedulesDetailsIDs[this.state.daySelected].map(
                                                    (obj, index) => {
                                                        const path = `${obj.project_id}:${obj.branch_id}:${obj.outlet_branch_id}:${obj.schedule_id}`;
                                                        const scheduleItem = this.state.schedulesDetails.find(
                                                            (sd) =>
                                                                `${sd.scheduleData.project_id}:${sd.scheduleData.supplier_branch_id}:${sd.scheduleData.outlet_branch_id}:${sd.scheduleData.id}` ===
                                                                path
                                                        );

                                                        const filter = this.state.supermarketFilter.toLowerCase();
                                                        const itemName =
                                                            scheduleItem?.outletBranchInfo?.En_short_name.toLowerCase();
                                                        let hidden = false;
                                                        if (filter) {
                                                            hidden = !itemName.includes(filter);
                                                        }
                                                        if (hidden) {
                                                            return null;
                                                        }

                                                        let otherProjectClass =
                                                            obj.project_id !==
                                                            this.props.projectFullData.data.project_id
                                                                ? classes.otherProjectsBorder
                                                                : null;

                                                        return (
                                                            <Draggable
                                                                key={`vacant-item-${path}`}
                                                                draggableId={`${path}`}
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <Typography
                                                                        component="div"
                                                                        className={`${classes.supermarketCardItem} ${otherProjectClass}`}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        innerRef={provided.innerRef}
                                                                    >
                                                                        <p
                                                                            className={classes.cardItemMainText}
                                                                            style={{ textAlign: "left" }}
                                                                        >
                                                                            {scheduleItem
                                                                                ? scheduleItem.outletBranchInfo
                                                                                      .En_short_name
                                                                                : "No Data"}
                                                                        </p>
                                                                        <p
                                                                            className={classes.cardItemSubText}
                                                                            style={{ textAlign: "right" }}
                                                                        >
                                                                            {scheduleItem
                                                                                ? `${scheduleItem.scheduleData.duration} Min`
                                                                                : ""}
                                                                        </p>
                                                                    </Typography>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    }
                                                )}
                                                {provided.placeholder}
                                            </CardContent>
                                        )}
                                    </Droppable>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </DragDropContext>
                <RouteEditDialog
                    open={this.state.isRouteEditDialogOpened}
                    onClose={this.closeRouteCardEditor}
                    onSubmit={this.handleRouteDialogSave}
                    title="Route Header Editor"
                    submitText="Select"
                    route={this.state.isRouteEditDialogOpened ? this.state.routes[this.state.routeIndex] : null}
                    merchandisersList={this.state.merchandisersList}
                    supervisorsList={this.state.supervisorsList}
                />
            </div>
        );
    }
}

export default withStyles(useStyles)(ProjectRoutePlanManagement);
