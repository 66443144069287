import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Button } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";

const RefreshButton = ({ onClick, ...otherProps }) => {
    const classes = styles();
    return (
        <Button variant="contained" className={classes.refreshButton} onClick={onClick} {...otherProps}>
            <RefreshIcon />
        </Button>
    );
};

RefreshButton.propTypes = {
    classes: PropTypes.object,
};

export default RefreshButton;
