import { useLoadScript } from "@react-google-maps/api";

const useMap = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    return { isLoaded };
};

export default useMap;
