import React from "react";
import { makeStyles } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import COLORS from "../../../constants/colors";
import CustomDatePicker from "../../../components-lagacy/CustomInput/CustomDatePicker";

const useRootStyle = makeStyles({
    root: {
        "& label.Mui-focused": {
            color: COLORS.FRENSH_BLUE,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: COLORS.FRENSH_BLUE,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&:hover fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&.Mui-focused fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
        },
    },
});

const DateTimePicker = ({ name, type = "date", onChange, ...otherProps }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);
    const rootStyle = useRootStyle();

    const config = {
        ...field,
        ...otherProps,
        type: type,
        inputVariant: "outlined",
        fullWidth: true,
        InputLabelProps: {
            shrink: true,
        },
        classes: {
            root: rootStyle.root,
        },
        onChange: (value) => {
            if (onChange) onChange(value);
            setFieldValue(name, value);
        },
    };

    if (meta && meta.touched && meta.error) {
        config.error = true;
        config.helperText = meta.error;
    }

    // return <TextField {...config} />;
    return <CustomDatePicker rootProps={config} />;
};

export default DateTimePicker;
