import React from "react";
import styles from "./styles";
import {
    alpha,
    Card,
    CardContent,
    CardHeader,
    lighten,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import { DATE_TO_WEEKDAY_SHORT as WEEKDAYS_SHORT } from "../../../constants/constants-lagacy";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { CircularLoader } from "../../../core-ui/custom";
import COLORS from "../../../constants/colors";
import { convertMinutesToHoursAndMinutes } from "../../../helpers/date-utils";

// const merchCellColors = ["#FAD8DD", "#D3B7C5"];
const merchCellColors = [lighten(COLORS.VALENTINE_RED, 0.8), "#FFFFFF"];

const RoutePlanSchedule = ({ merchandisers, routes, schedules, outletBranches, projects, projectColors, loading }) => {
    const classes = styles();
    const columns = [
        {
            field: "merchandiser",
            headerName: "M",
            className: `${classes.merchHeaderCell}`,
            classes: { stickyHeader: classes.stickyHeader },
        },
        { field: "sunday", headerName: "Sunday", classes: { stickyHeader: classes.stickyHeader } },
        { field: "monday", headerName: "Monday", classes: { stickyHeader: classes.stickyHeader } },
        { field: "tuesday", headerName: "Tuesday", classes: { stickyHeader: classes.stickyHeader } },
        { field: "wednesday", headerName: "Wednesday", classes: { stickyHeader: classes.stickyHeader } },
        { field: "thursday", headerName: "Thursday", classes: { stickyHeader: classes.stickyHeader } },
        { field: "friday", headerName: "Friday", classes: { stickyHeader: classes.stickyHeader } },
        { field: "saturday", headerName: "Saturday", classes: { stickyHeader: classes.stickyHeader } },
    ];

    const rows = merchandisers.map((merch, rowIndex) => {
        return (
            <TableRow key={`${merch.uid} row`}>
                <TableCell
                    className={classes.merchColumn}
                    style={{ backgroundColor: merchCellColors[rowIndex % merchCellColors.length] }}
                >
                    <Typography
                        component="span"
                        style={{
                            writingMode: "vertical-lr",
                            transform: "rotate(-180deg)",
                        }}
                    >
                        {merch.merch_name}
                    </Typography>
                </TableCell>
                {WEEKDAYS_SHORT.map((day) => {
                    let dayRoutes = merch.plan[day].map((routeID) => routes.find((r) => r.id === routeID));
                    dayRoutes = dayRoutes.filter(
                        (r) => projects.findIndex((p) => p.project_id === r.project_id) !== -1
                    );
                    return (
                        <Droppable
                            key={`${merch.uid} ${day}`}
                            droppableId={`${merch.uid} ${day}`}
                            direction="vertical"
                            type={`routes-${day}`}
                        >
                            {(routesDropProvided) => (
                                <TableCell
                                    size="small"
                                    style={{ verticalAlign: "top" }}
                                    {...routesDropProvided.droppableProps}
                                    innerRef={routesDropProvided.innerRef}
                                >
                                    {dayRoutes.map((route, index) => {
                                        const merchSchedules = route.plan[day].map((path) => {
                                            const fullPath = `${route.project_id}:${route.supplier_branch_id}:${path}`;
                                            return schedules.find((rs) => {
                                                return (
                                                    `${rs.project_id}:${rs.supplier_branch_id}:${rs.outlet_branch_id}:${rs.id}` ===
                                                    fullPath
                                                );
                                            });
                                        });

                                        //get total duration of the route
                                        let totalDuration = merchSchedules.reduce((sum, sd) => sum + sd.duration, 0);
                                        totalDuration +=
                                            route.plan[day].length === 0
                                                ? 0
                                                : (route.plan[day].length - 1) * route.avg_travel_time;

                                        const durationStr = convertMinutesToHoursAndMinutes(totalDuration);

                                        const project = projects.find((proj) => proj.project_id === route.project_id);

                                        return (
                                            <Draggable
                                                key={`${route.id} ${day}`}
                                                draggableId={`${route.id} ${day}`}
                                                index={index}
                                            >
                                                {(routeDragProvided) => (
                                                    <Card
                                                        className={classes.routeCard}
                                                        {...routeDragProvided.draggableProps}
                                                        innerRef={routeDragProvided.innerRef}
                                                    >
                                                        <CardHeader
                                                            className={classes.routeCardHeader}
                                                            style={{
                                                                backgroundColor: alpha(
                                                                    projectColors[route.project_id],
                                                                    0.2
                                                                ),
                                                                borderRight: `1px solid ${
                                                                    projectColors[route.project_id]
                                                                }`,
                                                                borderLeft: `1px solid ${
                                                                    projectColors[route.project_id]
                                                                }`,
                                                            }}
                                                            title={route.name}
                                                            titleTypographyProps={{
                                                                component: "span",
                                                                className: classes.routeCardHeaderText,
                                                            }}
                                                            subheader={
                                                                <span>
                                                                    {project?.projName}

                                                                    {durationStr ? (
                                                                        <>
                                                                            <br />
                                                                            {durationStr}
                                                                        </>
                                                                    ) : null}
                                                                </span>
                                                            }
                                                            subheaderTypographyProps={{
                                                                component: "span",
                                                                className: classes.routeCardSubHeader,
                                                            }}
                                                            {...routeDragProvided.dragHandleProps}
                                                        />
                                                        <Droppable
                                                            droppableId={`${route.id} ${day}`}
                                                            direction="vertical"
                                                            type={`schedules-${route.project_id}-${route.supplier_branch_id}-${day}`}
                                                        >
                                                            {(schedulesDropProvided) => (
                                                                <CardContent
                                                                    {...schedulesDropProvided.droppableProps}
                                                                    innerRef={schedulesDropProvided.innerRef}
                                                                >
                                                                    {merchSchedules.map((ms, index) => {
                                                                        const outletBranch = outletBranches.find(
                                                                            (ob) => ob.branch_id === ms.outlet_branch_id
                                                                        );
                                                                        const fullPath = `${ms.project_id}:${ms.supplier_branch_id}:${ms.outlet_branch_id}:${ms.id}`;

                                                                        return (
                                                                            <Draggable
                                                                                key={`${fullPath} ${day}`}
                                                                                draggableId={`${fullPath} ${day}`}
                                                                                index={index}
                                                                            >
                                                                                {(schedulesDragProvided) => (
                                                                                    <Typography
                                                                                        className={classes.scheduleCard}
                                                                                        component="div"
                                                                                        {...schedulesDragProvided.dragHandleProps}
                                                                                        {...schedulesDragProvided.draggableProps}
                                                                                        innerRef={
                                                                                            schedulesDragProvided.innerRef
                                                                                        }
                                                                                    >
                                                                                        {outletBranch.En_short_name}
                                                                                    </Typography>
                                                                                )}
                                                                            </Draggable>
                                                                        );
                                                                    })}
                                                                    {schedulesDropProvided.placeholder}
                                                                </CardContent>
                                                            )}
                                                        </Droppable>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {routesDropProvided.placeholder}
                                </TableCell>
                            )}
                        </Droppable>
                    );
                })}
            </TableRow>
        );
    });

    return (
        <Table
            stickyHeader
            classes={{
                root: classes.tableRoot,
            }}
        >
            <TableHead>
                <TableRow>
                    {columns.map(({ field, headerName, ...cellProps }) => (
                        <TableCell key={field} {...cellProps}>
                            {headerName}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {loading ? (
                    <TableRow>
                        <TableCell style={{ textAlign: "center" }} colSpan={8}>
                            <CircularLoader />
                        </TableCell>
                    </TableRow>
                ) : (
                    rows
                )}
            </TableBody>
        </Table>
    );
};

export default RoutePlanSchedule;
