import { makeStyles } from "@material-ui/core";
import COLORS from "../../../constants/colors";
import { darken } from "@material-ui/core";

// const borderStyle = `1px ${COLORS.NAV_COLOR_2} solid`;

export default makeStyles({
    expandedSubMenuItem: {
        listStyle: "none",
        "& > button, & > a > button": {
            width: "100%",
            justifyContent: "flex-start",
            color: COLORS.WHITE,
            fontWeight: 400,
            textTransform: "none",
            fontSize: "0.7rem",
            textAlign: "left",
            padding: " 15px 8px",
        },
    },

    "@media (min-width: 1050px)": {
        expandedSubMenuItem: {
            "& > button, & > a > button": {
                position: "relative",
                left: "-1px",
                padding: " 15px 23px",
                borderLeft: `3px solid transparent`,
                borderRadius: "0",
                "&:before": {
                    content: "''",
                    display: "block",
                    position: "absolute",
                    top: "0",
                    left: "-1px",
                    height: "100%",
                    // width: "38vw",
                    width: "100%",
                    maxWidth: "397px",
                    zIndex: -1,
                },
            },
            "&:hover": {
                "& > button, & > a > button": {
                    borderLeftColor: COLORS.NAV_COLOR_3,
                    "&:before": {
                        backgroundColor: darken(COLORS.NAV_COLOR_1, 0.4),
                    },
                },
            },
        },
    },

    "@media screen and (min-width: 1400px)": {
        expandedSubMenuItem: {
            "& > button, & > a > button": {
                fontSize: "0.75rem",
            },
        },
    },
});
