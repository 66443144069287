import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../contexts/auth-context";

import EditIcon from "@material-ui/icons/Edit";

import PATHS from "../constants/paths";
import { queryPromotionPrices } from "../services/firestore/Promotion_Prices";
import { useNavigate, useSearchParams, Link, useLocation } from "react-router-dom-v5-compat";
import { cloneDeep } from "lodash";

const FILTERS = {
    search: {
        label: "Search Promotion No. or Name",
        component: "text",
        initialValue: "",
    },
    status: {
        label: "Status",
        options: [
            { label: "Active", value: "1" },
            { label: "Inactive", value: "0" },
        ],
        initialValue: null,
    },
};

const HEADROW = [
    { id: "id", label: "No.", hidden: true, excelHidden: true, style: { textAlign: "center" } },
    { id: "number", label: "Number", style: { textAlign: "left" } },
    { id: "name", label: "Name", style: { textAlign: "left" } },
    { id: "start_date", label: "Start", style: { textAlign: "left" } },
    { id: "end_date", label: "End", style: { textAlign: "left" } },
    { id: "products", label: "Products", style: { textAlign: "left" } },
    { id: "status", label: "Status", style: { textAlign: "left" } },
    { id: "action", label: "", excelHidden: true },
];

// const EXCEL_COLUMNS = [
//     { key: "number", header: "Number", type: "normal", width: "auto" },
//     { key: "name", header: "Name", type: "normal", width: "auto" },
//     { key: "start_date", header: "Start", type: "normal", width: "auto" },
//     { key: "end_date", header: "End", type: "normal", width: "auto" },
//     { key: "product", header: "Products", type: "normal", width: "auto" },
//     { key: "price", header: "Price", type: "normal", width: "auto" },
//     { key: "status", header: "Status", type: "normal", width: "auto" },
// ];

const usePromotionPrices = ({ classes }) => {
    const { companyData } = useAuth();
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);

    // const history = useHistory();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    // const { search: queryString, ...p } = useLocation();
    // const params = new URLSearchParams(queryString);
    // console.log(searchParams, params);

    // const [filters, setFilters] = useState(cloneDeep(FILTERS));
    const [rows, setRows] = useState([]);

    // const generateExcel = async () => {
    //     try {
    //         exportExcel({
    //             filename: "Promotion Prices",
    //             columns: EXCEL_COLUMNS,
    //             rows: rows,
    //             worksheetTitle: "Promotion Prices",
    //             user: userData,
    //             company: companyData,
    //         });
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const TABLE_TOOLBAR_ACTIONS = [
        {
            title: "Add New Prices",
            // action: () => history.push(PATHS.ADD_PROMOTION_PRICES),
            action: () => navigate(PATHS.ADD_PROMOTION_PRICES),
            icon: "add",
            enabled: () => true,
        },
        // {
        //     title: "Excel",
        //     action: generateExcel,
        //     icon: "excel",
        //     enabled: () => true,
        // },
    ];

    const generateActionButton = useCallback(
        (row, urlParams) => {
            return (
                <Link
                    to={`${PATHS.UPDATE_PROMOTION_PRICES}/${row.id}`}
                    state={{ from: `${location.pathname}?${urlParams.toString()}` }}
                    className={classes.editLink}
                >
                    <EditIcon />
                </Link>
            );
        },
        [location.pathname, classes.editLink]
    );

    const loadOptions = useCallback(async () => {
        try {
            setInitializing(true);

            setInitializing(false);
        } catch (error) {
            setInitializing(false);
            throw error;
        }
    }, []);

    // after the filters component is mounted, load the data
    const initFilters = useCallback(
        async (context) => {
            try {
                const filters = cloneDeep(FILTERS);
                //check if there is any search params in URL
                if (searchParams.get("search")) filters.search.initialValue = searchParams.get("search");
                if (searchParams.get("status")) {
                    const id = searchParams.get("status");
                    filters.status.initialValue = filters.status.options.find((s) => s.value === id);
                }

                context.setFilters(filters);

                const initialValues = {
                    search: filters.search.initialValue,
                    status: filters.status.initialValue,
                };
                // load products with default filters
                context.submit(initialValues);
            } catch (error) {
                console.log(error);
            }
        },
        [searchParams]
    );

    const setQueryParams = useCallback(
        (filters) => {
            const { search, status } = filters;
            const urlParams = new URLSearchParams({});

            if (search) urlParams.append("search", search);
            if (status) urlParams.append("status", status.value);

            // history.replace({ search: urlParams.toString() });
            setSearchParams(urlParams, { replace: true });
            // navigate(`${PATHS.PROMOTION_PRICES}?${urlParams.toString()}`, { replace: true });
            return urlParams;
        },
        [setSearchParams]
    );

    const search = useCallback(
        async ({ search, status }) => {
            try {
                setLoading(true);
                const urlParams = setQueryParams({ search, status });
                const { company_id } = companyData;
                let query = [{ key: "supplier_id", operator: "==", value: company_id }];

                if (status)
                    query.push({
                        key: "status",
                        operator: "==",
                        value: status.value,
                    });

                let prices = (await queryPromotionPrices(query)).map((doc) => doc.data());

                if (search) {
                    prices = prices.filter((price) => {
                        const name = price.name.toLowerCase();
                        const number = price.number.toLowerCase();
                        return name.includes(search.toLowerCase()) || number.includes(search.toLowerCase());
                    });
                }

                //init rows
                const rows = prices.map((price) => {
                    const products = Object.values(price.products).length;
                    return {
                        id: price.id,
                        number: price.number,
                        name: price.name,
                        start_date: price.start_date.toDate(),
                        end_date: price.end_date.toDate(),
                        products: products,
                        status: FILTERS.status.options.find((s) => s.value === price.status)?.label ?? "-",
                        action: generateActionButton(price, urlParams),
                        cellProps: { action: { className: classes.action } },

                        //for excel
                    };
                });

                setRows(rows);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                throw error;
            }
        },
        [classes.action, companyData, generateActionButton, setQueryParams]
    );

    useEffect(() => {
        loadOptions();
    }, [loadOptions]);

    return {
        initializing,
        loading,
        initFilters,
        HEADROW,
        TABLE_TOOLBAR_ACTIONS,
        rows,
        search,
    };
};

export default usePromotionPrices;
