import { cloneDeep } from "lodash";
import { DEFAULT_PROJECT_OPTIONS, PLATFORM, STATUS } from "../../constants/global";
import { dayNameToIndex, getDatesfromRange } from "../../helpers/date-utils";
import { getCurrentUser, serverTimestamp } from "../../helpers/firebase-helpers";
import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";
import { queryLinkedOutlets } from "./Linked_Outlets";
import { queryLinkedOutletBranches } from "./Linked_Outlet_Branches";
import { SCHEDULE_DISPLAY_DOCUMENT_VALUES, setScheduleDisplay } from "./Schedule_Displays";

export const PROJECT_SCHEDULES_DOCUMENT_VALUES = {
    //ids
    id: null,
    platform: PLATFORM,
    supplier_id: null,
    mp_id: null,
    store_id: null,
    project_id: null,
    supplier_branch_id: null,
    outlet_id: null,
    outlet_branch_id: null,
    outlet_branch_class_id: null,
    city_id: null,

    project_type: null,

    //scheduling
    options: DEFAULT_PROJECT_OPTIONS,
    duration: 15,
    minimum_duration: 15,
    duration_unit: "minute", //hour, minute
    lead_time: 30,
    handover: false,

    merchandising_days: {
        sun: {
            selected: false,
            start_time: new Date(2000, 1, 29, 0, 0, 0),
            end_time: new Date(2000, 1, 29, 0, 0, 0),
            routes: {}, // element[display_group_id] = {route_id, display_group_id}
        },
        mon: {
            selected: false,
            start_time: new Date(2000, 1, 29, 0, 0, 0),
            end_time: new Date(2000, 1, 29, 0, 0, 0),
            routes: {}, // element[display_group_id] = {route_id, display_group_id}
        },
        tue: {
            selected: false,
            start_time: new Date(2000, 1, 29, 0, 0, 0),
            end_time: new Date(2000, 1, 29, 0, 0, 0),
            routes: {}, // element[display_group_id] = {route_id, display_group_id}
        },
        wed: {
            selected: false,
            start_time: new Date(2000, 1, 29, 0, 0, 0),
            end_time: new Date(2000, 1, 29, 0, 0, 0),
            routes: {}, // element[display_group_id] = {route_id, display_group_id}
        },
        thu: {
            selected: false,
            start_time: new Date(2000, 1, 29, 0, 0, 0),
            end_time: new Date(2000, 1, 29, 0, 0, 0),
            routes: {}, // element[display_group_id] = {route_id, display_group_id}
        },
        fri: {
            selected: false,
            start_time: new Date(2000, 1, 29, 0, 0, 0),
            end_time: new Date(2000, 1, 29, 0, 0, 0),
            routes: {}, // element[display_group_id] = {route_id, display_group_id}
        },
        sat: {
            selected: false,
            start_time: new Date(2000, 1, 29, 0, 0, 0),
            end_time: new Date(2000, 1, 29, 0, 0, 0),
            routes: {}, // element[display_group_id] = {route_id, display_group_id}
        },
    },
    //weekly
    number_of_days: 0,
    //the whole project period
    total_number_of_days: 0,
    excluded_dates: [],

    //products and displays
    number_of_products: 0,
    number_of_displays: 0,

    //linked products
    outlet_products: [],
    outlet_branch_products: [],

    //display groups
    display_groups: {
        default: {
            name: "default",
            displays: [],
        },
    },

    //all extra displays contracts
    extra_display_contracts: {}, // item[contract_id] = {contract docuemnt}
    // item[extra_display_id] = {extra_display_id, extra_display_contract_id, extra_display_type_id, display_ids}
    extra_display_contract_displays: {},

    //prices
    //*switch to map if there is a unique id for each item which is better because of the lookup

    //all prices
    normal_prices: {}, // {regular: null, seasonal: {{}, {}, ...}, weekend: {{}, {},...}
    extra_display_prices: {}, // item[type_id] = {regular: null, seasonal: {}, weekend: {}}
    total_price: {
        totalBonus: 0,
        bonus: 0,
        coefficient: 1,
        handover: 0,
        selling: {
            bonus: 0,
            warehouse: 0,
            task_per_hour: 0,
            total: 0,
            extra_display: 0,
            general_merchandising: 0,
            handover: 0,
            task_per_minute: 0,
        },
        cost: {
            task_per_hour: 0,
            bonus: 0,
            warehouse: 0,
            extra_display: 0,
            general_merchandising: 0,
            handover: 0,
            task_per_minute: 0,
            total: 0,
        },
    },
    status: STATUS.ACTIVE,
};

export const queryProjectSchedules = async (q) => {
    try {
        return await query(COLLECTIONS.PROJECT_SCHEDULES, q);
    } catch (error) {
        throw error;
    }
};

export const addProjectSchedule = async (data) => {
    try {
        const ref = db.collection(COLLECTIONS.PROJECT_SCHEDULES).doc();

        data.id = ref.id;
        await ref.set({
            ...data,
            //timestamps and logging
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            created_by: getCurrentUser().uid,
            updated_by: getCurrentUser().uid,
        });
        return data;
    } catch (error) {
        throw error;
    }
};

export const updateProjectSchedule = async (id, data) => {
    try {
        await db
            .collection(COLLECTIONS.PROJECT_SCHEDULES)
            .doc(id)
            .update({
                ...data,
                //timestamps and logging
                updated_at: serverTimestamp(),
                updated_by: getCurrentUser().uid,
            });
    } catch (error) {
        throw error;
    }
};

// ############################ //
// business logic
// ############################ //

export const createNewScheduleWithProducts = async (project, outletBranch, supplierBranch) => {
    let newSchedule = {
        ...PROJECT_SCHEDULES_DOCUMENT_VALUES,
        //ids
        platform: PLATFORM,
        supplier_id: project.supplier_id,
        mp_id: project.mp_id,
        project_id: project.project_id,
        supplier_branch_id: supplierBranch.branch_id,
        outlet_id: outletBranch.outlet_id,
        outlet_branch_id: outletBranch.branch_id,
        outlet_branch_class_id: outletBranch.class_id,
        city_id: `${outletBranch.city_id}`,

        project_type: project.project_type,
        //copy options from project document
        options: project.options ?? DEFAULT_PROJECT_OPTIONS,
    };

    //ref to get a new id
    const scheduleRef = db.collection(COLLECTIONS.PROJECT_SCHEDULES).doc();
    newSchedule.id = scheduleRef.id;

    //copy outlet products and outlet branch products from Linked_Outlet and Linked_Outlet_branch
    let linked_outlets = await queryLinkedOutlets([
        { key: "supplier_id", operator: "==", value: newSchedule.supplier_id },
        { key: "outlet_id", operator: "==", value: newSchedule.outlet_id },
    ]);
    if (linked_outlets.length > 0) {
        newSchedule.outlet_products = linked_outlets[0].data().products;
    }

    let linked_outlet_branches = await queryLinkedOutletBranches([
        { key: "supplier_id", operator: "==", value: newSchedule.supplier_id },
        { key: "outlet_branch_id", operator: "==", value: newSchedule.outlet_branch_id },
    ]);
    if (linked_outlet_branches.length > 0) {
        newSchedule.outlet_branch_products = linked_outlet_branches[0].data().products;
    }

    newSchedule.number_of_products = newSchedule.outlet_branch_products.length;

    let schedule_displays = newSchedule.outlet_branch_products.map((productID) => ({
        ...SCHEDULE_DISPLAY_DOCUMENT_VALUES,
        product_id: productID,
        //ids from schedule
        schedule_id: newSchedule.id,
        supplier_id: newSchedule.project_id,
        mp_id: newSchedule.mp_id,
        store_id: newSchedule.store_id,
        project_id: newSchedule.project_id,
        supplier_branch_id: newSchedule.supplier_branch_id,
        outlet_id: newSchedule.outlet_id,
        outlet_branch_id: newSchedule.outlet_branch_id,
        outlet_branch_class_id: newSchedule.outlet_branch_class_id,
        city_id: newSchedule.city_id,
        project_type: newSchedule.project_type,
    }));

    newSchedule.number_of_displays = schedule_displays.length;

    if (schedule_displays.length > 0) {
        schedule_displays = await Promise.all(
            schedule_displays.map(async (display) => {
                return await setScheduleDisplay(newSchedule.id, display);
            })
        );
    }

    newSchedule.display_groups.default.displays = schedule_displays.map((d) => d.id);

    await scheduleRef.set(newSchedule);
    newSchedule.schedule_displays = schedule_displays;

    return newSchedule;
};

// =============== UTILS ================= //

export const calculateDuration = (scheduleDisplays) => {
    const extraDisplays = scheduleDisplays.filter((sd) => sd.type === "extra_display");
    const totalDisplays = scheduleDisplays.length;

    let minimumDuration = 0;
    const minutes = 1.71;
    const minutesForExtraDisplay = 10;

    // group extra displays by display_id
    let extraDisplayGroups = extraDisplays.reduce((arr, display) => {
        const index = arr.findIndex((group) => group.display_id === display.extra_display_id);
        if (index === -1) {
            arr.push({ display_id: display.extra_display_id, extra_displays: [display] });
        } else {
            arr[index].extraDisplays.push(display);
        }
        return arr;
    }, []);

    if (totalDisplays < 10) minimumDuration = Math.round(Math.max(15, totalDisplays * 2.2));
    else minimumDuration = totalDisplays * minutes;

    minimumDuration += extraDisplayGroups.length * minutesForExtraDisplay;

    return Math.round(minimumDuration);
};

export const calculateEndTime = (startTime, duration) => {
    let hr = startTime.getHours();
    let min = startTime.getMinutes() + hr * 60;
    let new_time_to = min + duration;
    min = new_time_to % 60;
    hr = (new_time_to - min) / 60;

    return new Date(2000, 2, 1, hr, min);
};

export const getSelectedDayIndices = (merchandising_days) => {
    const indices = [];
    for (const key in merchandising_days) {
        if (Object.hasOwnProperty.call(merchandising_days, key)) {
            const day = merchandising_days[key];
            if (!day.selected) continue;
            const index = dayNameToIndex(key);
            indices.push(index);
        }
    }

    return indices;
};

export const calculateScheduleOptionsFrequancies = (options, merchandising_days, startDate, endDate) => {
    try {
        const newOptions = cloneDeep(options);
        const dayIndices = getSelectedDayIndices(merchandising_days);
        for (const key in newOptions) {
            if (Object.hasOwnProperty.call(newOptions, key)) {
                const option = newOptions[key];
                if (!option.interval) continue;
                newOptions[key].dates = getDatesfromRange(startDate, endDate, option.interval, dayIndices);
            }
        }
        return newOptions;
    } catch (error) {
        throw error;
    }
};
