import { auth, firebase, firestore as db } from "../services/firebase";
import { getAllCities } from "../services/firestore/City";

export const isEmailExist = async (email) => {
    try {
        const signInMethods = await auth.fetchSignInMethodsForEmail(email);
        return signInMethods.length > 0;
    } catch (error) {
        throw error;
    }
};

export const getCitiesList = async () => {
    try {
        let list = [];
        const citiesDocs = await getAllCities();
        list = citiesDocs.map((city) => ({ label: city.data().En_name, value: city.id }));
        return list;
    } catch (error) {
        throw error;
    }
};

export const generateNewDocID = async (collectionName, incrementProperty, dbAlt = null) => {
    let newID = 100;
    let dbWorker = dbAlt ? dbAlt : db;
    try {
        let docs = (await dbWorker.collection(collectionName).orderBy(incrementProperty, "desc").limit(1).get()).docs;
        for (const doc of docs) {
            newID = Number(doc.data()[incrementProperty]) + 1;
        }

        return `${newID}`;
    } catch (e) {
        throw e;
    }
};

export const serverTimestamp = () => {
    return firebase.firestore.FieldValue.serverTimestamp();
};

export const getCurrentUser = () => {
    return auth.currentUser;
};
