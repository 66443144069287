import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons

import componentsStyle from "../core-ui/core-styles/jss/general-component-style";

import Button from "../core-ui/material-kit-ui/buttons/Button";
//import MaterialTable from 'material-table';
import { Redirect } from "react-router-dom";
import GridContainer from "../components-lagacy/Grid/GridContainer";
import GridItem from "../components-lagacy/Grid/GridItem";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import outlet from "../assets/images/store.png";
import Card from "@material-ui/core/Card";
import CardBody from "../core-ui/material-kit-ui/cards/CardBody";
import { COLORS } from "../constants/constants-lagacy";
import PATHS from "../constants/paths";
import { firestore as db } from "../services/firebase";

class OutletsSupplierList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supplierOutlets: [],
            company_id: "",
            supplier_name: "",

            pathProductsOutlet: "",
            outlet_id: "",
            outlet_name: "",
            pathBack: "",
        };
    }

    componentDidMount() {
        let datat4 = [];
        var outletsRef = db.collection("Supplier_Outlet_Product").doc(this.props.id).collection("Outlet_Product");
        outletsRef
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    var outletsRef = db.collection("Outlet").where("outlet_id", "==", doc.data().outlet_id);
                    outletsRef.get().then((snapshot2) => {
                        snapshot2.forEach((doc2) => {
                            datat4.push({ outlet_id: doc.data().outlet_id, En_short_name: doc2.data().En_short_name });
                        });
                    });
                });
                this.setState({ supplierOutlets: datat4 });
            })
            .then(() => {
                var supplierRef = db.collection("Supplier").where("company_id", "==", this.props.id);
                supplierRef.get().then((snapshot) => {
                    snapshot.forEach((doc) => {
                        this.setState({ supplier_name: doc.data().En_short_name });
                    });
                });
            })
            .catch((err) => {
                console.log("Error getting documents", err);
            });
    }

    goToProductsOutletPage = (path, outlet_id, outlet_name) => {
        this.setState({ pathProductsOutlet: path, outlet_id: outlet_id, outlet_name: outlet_name });
    };

    goToSupplierDashboardPage = (path) => {
        this.setState({ pathBack: path });
    };
    //==================================================================================

    render() {
        const { classes } = this.props;
        if (this.state.pathProductsOutlet.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathProductsOutlet,
                        state: {
                            id: this.props.id,
                            outletID: this.state.outlet_id,
                            outletName: this.state.outlet_name,
                        },
                    }}
                />
            );
        } else {
            if (this.state.pathBack.length > 0) {
                return <Redirect push={true} to={{ pathname: this.state.pathBack }} />;
            } else {
                const classes2 = makeStyles((theme) => ({
                    root: {
                        width: "100%",
                        maxWidth: 360,
                        backgroundColor: theme.palette.background.paper,
                    },
                    inline: {
                        display: "inline",
                    },
                }));

                var fieldsArray = [];
                for (let i = 0; i < this.state.supplierOutlets.length; i++) {
                    fieldsArray.push(
                        <GridItem key={`outlet-${i}`} xs={12} sm={12} md={4}>
                            <List className={classes2.root}>
                                <ListItem
                                    alignItems="flex-start"
                                    style={{
                                        border: "2px solid #575757",
                                        borderRightColor: COLORS.primary,
                                        borderBottomColor: COLORS.primary,
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar alt="Outlet" src={outlet} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={this.state.supplierOutlets[i].En_short_name}
                                        secondary={
                                            <React.Fragment>
                                                <Button
                                                    onClick={() =>
                                                        this.goToProductsOutletPage(
                                                            PATHS.LINK_OUTLET_WITH_PRODUCTS,
                                                            this.state.supplierOutlets[i].outlet_id,
                                                            this.state.supplierOutlets[i].En_short_name
                                                        )
                                                    }
                                                    variant="outlined"
                                                    // size="small"
                                                    simple
                                                    //color="google"
                                                    block
                                                    className={classes.textCenter}
                                                    style={{
                                                        color: COLORS.accent,
                                                        width: "100%",
                                                        height: "100%",
                                                        justifyContent: "left",
                                                        borderRadius: "0",
                                                        textTransform: "none",
                                                        fontWeight: "700",
                                                        paddingLeft: "0px",
                                                        paddingRight: "0px",
                                                        margin: "0px",
                                                    }}
                                                >
                                                    Add Products
                                                </Button>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </GridItem>
                    );
                    //Add Divider after every 3 items
                    if (i > 0 && i % 3 === 0 && i !== this.state.supplierOutlets.length) {
                        fieldsArray.push(
                            <Divider
                                key={`divider-${i}`}
                                xs={12}
                                sm={12}
                                md={12}
                                style={{
                                    marginLeft: 0,
                                    marginRight: 0,
                                    marginTop: "3px",
                                    marginBottom: "3px",
                                    padding: 0,
                                    width: "100%",
                                }}
                                variant="inset"
                            />
                        );
                    }
                }

                return (
                    <div /*style={{backgroundColor:"#262626", margin:"0px", padding:"0px", height:'100vh'}}*/>
                        <div>
                            <div
                                className={classNames(classes.main, classes.textCenter)}
                                style={{ width: "100%", display: "contents" }}
                            >
                                <div
                                    className={classes.container}
                                    style={{ width: "100%", paddingTop: "22px", paddingBottom: "22px" }}
                                >
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        className={classes.container}
                                        style={{ overflow: "hidden", marginTop: "0px", paddingTop: "0px" }}
                                    >
                                        <Card color="primary">
                                            <CardBody>
                                                <GridContainer>
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div className={classes.title} style={{ width: "3%" }}>
                                                            <div
                                                                style={{
                                                                    fontWeight: "700",
                                                                    backgroundColor: COLORS.primary,
                                                                    color: "#EEEEEE",
                                                                    height: "30px",
                                                                    paddingTop: "3px",
                                                                    paddingBottom: "3px",
                                                                    border: "2px solid #575757",
                                                                    // borderRightColor: "#91041C",
                                                                    // borderBottomColor: "#91041C",
                                                                    borderRightColor: COLORS.primary,
                                                                    borderBottomColor: COLORS.primary,
                                                                }}
                                                            ></div>
                                                        </div>
                                                        <div
                                                            className={classes.title}
                                                            style={{ width: "96.7%", marginLeft: "2px" }}
                                                        >
                                                            <h4
                                                                style={{
                                                                    textAlign: "left",
                                                                    fontWeight: "700",
                                                                    // backgroundColor: "#EEEEEE",
                                                                    // color: "#91041C",
                                                                    backgroundColor: COLORS.background,
                                                                    color: COLORS.primary,
                                                                    height: "30px",
                                                                    paddingLeft: "10px",
                                                                    paddingTop: "3px",
                                                                    paddingBottom: "3px",
                                                                    border: "1px solid #000000",
                                                                }}
                                                            >
                                                                Register your products to outlet companies
                                                            </h4>
                                                        </div>
                                                    </div>

                                                    {fieldsArray}

                                                    <Grid item xs={12}>
                                                        <p></p>
                                                        <Paper className={classes.paper}> </Paper>
                                                    </Grid>

                                                    <Divider
                                                        style={{
                                                            marginLeft: 0,
                                                            marginRight: 0,
                                                            marginTop: "3px",
                                                            marginBottom: "3px",
                                                            padding: 0,
                                                            width: "100%",
                                                        }}
                                                        variant="inset"
                                                    />

                                                    <Grid item xs={12}>
                                                        <p></p>
                                                        <Paper className={classes.paper}> </Paper>
                                                    </Grid>
                                                </GridContainer>
                                            </CardBody>
                                        </Card>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                        </div>
                    </div>
                );
            }
        }
    }
}
OutletsSupplierList.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(componentsStyle)(OutletsSupplierList);
