import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import componentsStyle from "../../core-ui/core-styles/jss/general-component-style";
import Content from "./Content";

class RouteVisits extends React.Component {
    state = {
        loading: false,

        uid: this.props.uid,
        supplierID: this.props.id,
    };

    render() {
        const classes = this.props.classes;
        return (
            <div className={classes.pageHeader}>
                <Content supplierID={this.props.id} />
            </div>
        );
    }
}

RouteVisits.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(componentsStyle)(RouteVisits);
