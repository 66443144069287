import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import componentsStyle from "../../core-ui/core-styles/jss/general-component-style";
import VisitDetailsContent from "./content/VisitDetailsContent";
import { withRouter } from "react-router";
import PATHS from "../../constants/paths";

class VisitDetails extends React.Component {
    state = {
        loading: false,
        dialogOpen: false,
        dialogHeader: "",
        dialogMsg: "",
        dialogCloseHandler: null,
        uid: this.props.uid,
        supplierID: this.props.id,
        taskID: this.props.taskID,
    };

    gotoDailyTaskReport = (task, city, outletBranch) => {
        this.props.history.push({
            // pathname: "/task-products-report",
            pathname: PATHS.INSOURCE_VISIT_REPORT,
            state: {
                uid: this.props.uid,
                id: this.props.id,
                taskId: this.props.taskID,
                date: task.date_time_from.toDate(),
                city: city,
                outletBranch: outletBranch,
                is_admin: false,
            },
        });
    };

    render() {
        const { classes, type, taskID, history } = this.props;
        if (!taskID || !type) {
            history.push(PATHS.INSOURCE_VISITS_MANAGEMENT);
            return null;
        }
        return (
            <div className={classes.pageHeader}>
                <VisitDetailsContent
                    supplierID={this.props.id}
                    taskID={this.props.taskID}
                    type={this.props.type}
                    uid={this.state.uid}
                    gotoDailyTaskReport={this.gotoDailyTaskReport}
                />

                <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                    <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.state.dialogCloseHandler} style={{ backgroundColor: "#91041c" }}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

VisitDetails.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(componentsStyle)(withRouter(VisitDetails));
