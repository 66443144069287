import { cloneDeep } from "lodash";
import { PLATFORM, STATUS } from "../constants/global";
import { useAuth } from "../contexts/auth-context";
import { createNewProject } from "../services/firestore/Project";
import { getSeasons } from "../services/firestore/Season";
import { getVAT } from "../services/firestore/VAT";

const useAddProject = () => {
    const { companyData } = useAuth();

    const castValues = (values) => {
        const newValues = cloneDeep(values);

        const project = {
            platform: PLATFORM,
            project_id: null,
            short_id: null,
            mp_id: newValues.mp_id || null,
            supplier_id: companyData.company_id,
            store_id: newValues.outlet_id || null,

            project_type: newValues.project_type,
            date_from: newValues.date_from,
            date_to: newValues.date_to,

            remarks: newValues.remarks,
            projName: newValues.projName,

            authorization_letter: newValues.authorization_letter,
            planogram: null,

            project_date: new Date(),
            year: `${new Date().getFullYear()}`,
            month: `${new Date().getMonth()}`,
            day: `${new Date().getDate()}`,

            branches: [],
            merch_gender: "both",
            merch_class_level: 1,

            supplier_confirmation: false,
            store_confirmation: false,
            mp_confirmation: false,
            confirm: "0",
            isConfirmed: false,

            options: {
                availability: { active: newValues.formType === "simple" },
                quantity: { active: newValues.formType === "quantity" },
                pictures: { active: newValues.formType === "pictures" },
                inventory: { active: newValues.inventory !== "none", interval: newValues.inventory, dates: [] },
                near_expiration: {
                    active: newValues.near_expiration !== "none",
                    interval: newValues.near_expiration,
                    dates: [],
                },
                warehouse: { active: newValues.warehouse !== "none", interval: newValues.warehouse, dates: [] },
                shelf_price: { active: newValues.shelf_price !== "none", interval: newValues.shelf_price, dates: [] },
                display_audit: {
                    active: newValues.display_audit !== "none",
                    interval: newValues.display_audit,
                    dates: [],
                },
                competitor_activity: {
                    active: newValues.competitor_activity !== "none",
                    interval: newValues.competitor_activity,
                    dates: [],
                },

                damaged: {
                    active: newValues.damaged !== "none",
                    interval: newValues.damaged,
                    dates: [],
                },
            },

            //lagacy
            discount_amount: 0,
            projValue_cost: 0,
            projValue_selling: 0,
            generate_task: "0",
            date_from_task_generation: newValues.date_from,
            date_to_task_generation: newValues.date_to,

            seasons: {},
            vat: 0,
            paid_amount: 0,
            last_payment: null, //ref to last receipt voucher
            is_all_task_generated: false,

            //these are trackers to accumielate the price of all completed visits
            actual_total_selling: 0,
            actual_total_cost: 0,

            total_price: {
                totalBonus: 0,
                bonus: 0,
                coefficient: 1,
                handover: 0,
                selling: {
                    bonus: 0,
                    warehouse: 0,
                    task_per_hour: 0,
                    total: 0,
                    extra_display: 0,
                    general_merchandising: 0,
                    handover: 0,
                    task_per_minute: 0,
                },
                cost: {
                    task_per_hour: 0,
                    bonus: 0,
                    warehouse: 0,
                    extra_display: 0,
                    general_merchandising: 0,
                    handover: 0,
                    task_per_minute: 0,
                    total: 0,
                },
            },

            status: STATUS.ACTIVE,
        };

        return project;
    };

    const saveProject = async (values) => {
        try {
            const newValues = castValues(values);
            const data = { ...newValues };

            //setup initlal vat and seasons
            data.seasons = (await getSeasons(data.date_from, data.date_to)).reduce(
                (obj, doc) => ({ ...obj, [doc.id]: doc.data() }),
                {}
            );
            data.vat = (await getVAT())?.data() ?? 0;

            const project = await createNewProject(data);

            return project;
        } catch (error) {
            throw error;
        }
    };

    return {
        saveProject,
        castValues,
    };
};

export default useAddProject;
