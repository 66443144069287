import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import "../../../core-ui/core-styles/pdf/fonts";
import styles from "./styles";
import { REPORT_IDS } from "../../../constants/constants-lagacy";
let rowsPerPage = 26;
let counter = 0;
// let totalPages = 0;

let createRow = (branch, style) => {
    if (!branch)
        return (
            <View style={style} key={`PDF Row ${Math.random()}-${branch.id}`}>
                <Text style={styles.lastCell}>Error getting the branch's data</Text>
            </View>
        );
        counter++;

    return (
        <View style={style} key={`PDF Row ${Math.random()}-${branch.id}`}>
        <Text style={styles.indexCell}>{counter}</Text>
            <Text style={styles.nameCell}>{'\u00A0'}{branch.En_name}</Text>
            <Text style={styles.nameCell}>{'\u00A0'}{branch.Ar_name}</Text>
            <Text style={styles.cellNo}>{branch.city_name}</Text>

            {/* <Text style={styles.cellNo}>{branch.status}</Text> */}
            <Text style={styles.cellNo}>{branch.contact_information_phone}</Text>
            <Text style={styles.lastCell}>{'\u00A0'}{branch.contact_person_name}</Text>
        </View>
    );
};

let createPage = (branches, info, pageNum) => {
    //const rows = [...outlet.products];
    let rowsToDisplay = branches.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;

    return (
        <Page size="A4" orientation="portrait" style={styles.page} key={`Branches List ${Math.random()}-${pageNum}`}>
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View style={[styles.supplierHeaderText, !companyLogo ? styles.alignStart : ""]}>
                    {/* <Text style={styles.supplierText}>Supplier</Text> */}
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>Branches List</Text>

            <View style={styles.content}>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        <View style={[styles.row, styles.headerRow]}>
                            {/* <View style={styles.rowGroupRow}>lastRowGroupRow */}
                            {/* <View style={styles.columnGroup}> */}
                            <View style={styles.indexHeader}>
                                <Text style={styles.headerCellText}></Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Branch En. Name</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Branch Ar. Name</Text>
                            </View>
                            <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>City</Text>
                            </View>
                            {/* <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>Status</Text>
                            </View> */}
                            <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>Tel</Text>
                            </View>
                            <View style={styles.lastHeaderCell}>
                                <Text style={styles.headerCellText}>Contact Persons</Text>
                            </View>
                            {/* </View> */}
                            {/* </View> */}
                        </View>
                    </View>

                    {/* Table Body */}

                    <View style={styles.tableBody}>
                        {rowsToDisplay.map((branch, index) => {
                            let style = styles.row;
                            if (index % 2 !== 0 && index === rowsToDisplay.length - 1) style = styles.lastRow;
                            else if (index % 2 === 0 && index !== rowsToDisplay.length - 1) style = styles.coloredRow;
                            else if (index % 2 === 0 && index === rowsToDisplay.length - 1)
                                style = styles.lastColoredRow;
                            console.log("asdf " + branch.day);
                            return createRow(branch, style);
                        })}
                    </View>
                </View>
            </View>
            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.idContainer}>
                <Text>{REPORT_IDS.BRANCHES_LIST}</Text>
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <View style={styles.logoFooterFlex}>
                    <Text style={styles.logoFooterText}>Powered By:</Text>

                </View>
                <View style={styles.logoFooterFlex}>
                    <Image style={styles.logo} src={logo} />
                </View>


            </View>
        </Page>
    );
};

let createDoc = (info) => {
    const { branches } = info;

    let pages = [];
    let branchesPerPage = Math.ceil(branches.length / rowsPerPage);

    for (let pageNum = 0; pageNum < branchesPerPage; pageNum++) {
        pages[pageNum] = createPage(branches, info, pageNum);
    }

    return pages;
};

// Create Document Component
const BranchesPdf = (props) => {
    let document = createDoc(props.info);
    return <Document>{document}</Document>;
};

export default BranchesPdf;
