import { darken, lighten, makeStyles } from "@material-ui/core";
import COLORS from "../../../constants/colors";

export default makeStyles({
    buttonRoot: {
        padding: "10px 36px",
        textTransform: "none",
        backgroundColor: COLORS.FRENSH_BLUE,
        color: COLORS.WHITE,
        "&:hover": {
            backgroundColor: darken(COLORS.FRENSH_BLUE, 0.2),
        },
        "&$cancel": {
            backgroundColor: COLORS.VALENTINE_RED,
        },
        "&$disabled": {
            backgroundColor: lighten(COLORS.BLACK, 0.8),
            color: COLORS.WHITE,
        },
    },

    cancel: {},

    disabled: {},

    transparent: {
        backgroundColor: "transparent",
        color: "inherit",
        "&:hover": {
            backgroundColor: "transparent",
        },

        "&$disabled": {
            backgroundColor: "transparent",
            color: "gray",
        },
    },
});
