import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from "@material-ui/core";
import { Button } from "../../../core-ui/custom";
import Map from "../Map";
import { useState } from "react";

const MapCoordinatesDialog = ({ open, onClose, initialLatLng = null, onSubmit }) => {
    const classes = styles();
    const [latLng, setLatLng] = useState(initialLatLng);

    const clickHandler = (context) => {
        const {
            latLng: { lat, lng },
            setPins,
        } = context;

        const newPin = { id: "pinnedCoord", lat: lat(), lng: lng() };
        setPins([newPin]);
        setLatLng({ lat: lat(), lng: lng() });
    };

    const submitHandler = () => {
        if (onSubmit) onSubmit(latLng);
        onClose();
    };

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} className={classes.mapCoordinatesDialog}>
            <DialogTitle onClose={onClose}>
                {/* <Typography className={classes.DialogTitleText} variant="h5"> */}
                Pin Your Location To Get Coordinates
                {/* </Typography> */}
            </DialogTitle>

            <DialogContent dividers>
                <Grid container spacing={1}>
                    {/* Before Section */}
                    <Grid item xs={12}>
                        <Map
                            onClick={clickHandler}
                            initialMapCenter={initialLatLng}
                            initialPins={initialLatLng ? [{ id: "pinnedCoord", ...initialLatLng }] : []}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button className={classes.submitBtn} disabled={!latLng} onClick={submitHandler}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};

MapCoordinatesDialog.propTypes = {
    classes: PropTypes.object,
};

export default MapCoordinatesDialog;
