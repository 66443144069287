import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow } from "@material-ui/core";
import { TimeCell, ButtonCell, NumberCell, DaySelectCell, TextCell } from "../../../components-lagacy/Cell/Cells";
import { getMerchandiserName } from "../../../helpers/merchandiser-utils";
import { COLORS, PROJECT_TYPES } from "../../../constants/constants-lagacy";
import { formatTime } from "../../../helpers/date-utils";

const useStyles = makeStyles({
    outletBranchCell: {
        maxWidth: "250px",
        whiteSpace: "break-spaces",
    },
});

let calculateWeeklyVisits = (merchDays) => {
    let totalVisits = 0;
    for (const day in merchDays) {
        if (Object.hasOwnProperty.call(merchDays, day)) {
            totalVisits += merchDays[day].selected ? 1 : 0;
        }
    }

    return totalVisits;
};

let ScheduleRow = (props) => {
    const {
        rowData,
        merchandisers,
        onDayClick,
        onDurationChange,
        onStartTimeChange,
        // selfService,
        projectType,
        // isConfirmed,
        // openRoutePlan,
        openExtraDisplay,
        openProductsDialog,
    } = props;
    const classes = useStyles(props);

    let merchName = "-";
    const merchandiser = merchandisers.find((merch) => merch.info.uid === rowData.scheduleData.merchandiser_id);
    if (merchandiser) merchName = getMerchandiserName(merchandiser.info);

    //calculate the number of products in shelves
    let productsInExtraDisplay = rowData.products.reduce((acc, product) => {
        if (product.data.extra_displays.length > 0) return acc + 1;
        return acc;
    }, 0);

    return (
        <TableRow>
            <TextCell text={rowData.outletBranchInfo.En_short_name} className={classes.outletBranchCell} />

            {/* Days Selection */}
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.sun.selected}
                handleClick={onDayClick(rowData, "sun")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.mon.selected}
                handleClick={onDayClick(rowData, "mon")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.tue.selected}
                handleClick={onDayClick(rowData, "tue")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.wed.selected}
                handleClick={onDayClick(rowData, "wed")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.thu.selected}
                handleClick={onDayClick(rowData, "thu")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.fri.selected}
                iconStyle={{ color: COLORS.submit }}
                handleClick={onDayClick(rowData, "fri")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.sat.selected}
                handleClick={onDayClick(rowData, "sat")}
            />

            <TextCell
                text={calculateWeeklyVisits(rowData.scheduleData.merchandising_days)}
                style={{ textAlign: "center" }}
            />

            {/* Number input */}
            <NumberCell
                number={rowData.scheduleData.duration}
                changed={onDurationChange(rowData)}
                style={{ textAlign: "center" }}
            />

            {/* Time input */}
            {projectType === PROJECT_TYPES.SUPPLIER_INSOURCE ? null : (
                <TimeCell
                    id="start_time"
                    changed={onStartTimeChange(rowData)}
                    value={rowData.scheduleData.merchandising_days.sun.start_time}
                    style={{ textAlign: "center" }}
                />
            )}

            {projectType === PROJECT_TYPES.SUPPLIER_INSOURCE ? null : (
                <TextCell
                    text={formatTime(rowData.scheduleData.merchandising_days.sun.end_time, false)}
                    style={{ textAlign: "center" }}
                />
            )}

            <TextCell
                text={`${rowData.scheduleData.products} / ${rowData.linkedProducts.productsDocs.length}`}
                style={{ textAlign: "center" }}
            />
            {/* <TextCell text={`${(outletBranch.newData.total_price.selling.total || 0).toFixed(2)} SR`} style={{ textAlign: "center" }} /> */}

            {projectType !== PROJECT_TYPES.SUPPLIER_INSOURCE ? null : (
                <TextCell text={merchName} style={{ textAlign: "center" }} />
            )}

            {/* Buttons */}
            <ButtonCell
                text={`Shelf (${rowData.scheduleData.products} / ${rowData.linkedProducts.productsDocs.length})`}
                colorVariant="primary"
                clicked={openProductsDialog(rowData)}
            />
            <ButtonCell
                text={`E. Display (${productsInExtraDisplay} / ${rowData.linkedProducts.productsDocs.length})`}
                colorVariant="secondary"
                clicked={openExtraDisplay(rowData)}
                disabled={rowData.extraDisplayContracts.length === 0}
            />

            {/* {!selfService ? null : <ButtonCell text="Merchandiser" colorVariant="accent" clicked={openRoutePlan()} />} */}

            {/* {!this.state.confirm || this.state.selfService ? null : (
                <DetailsButton
                    clicked={this.handleShowProducts(outletBranch)}
                    show={outletBranch.showProducts}
                    menuClosed={this.handleShowProducts(outletBranch)}
                    listName={`${outletBranch.info.En_short_name} Products`}
                    items={outletBranch.products.map((product) => product.info.data().En_name)}
                />
            )} */}
        </TableRow>
    );
};

ScheduleRow.propTypes = {
    classes: PropTypes.object,
};

export default ScheduleRow;
