import React, { useMemo } from "react";
import {
    Card,
    CardHeader,
    Checkbox,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    TextField,
} from "@material-ui/core";
import { COLORS } from "../../constants/constants-lagacy";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
        width: "100%",
    },
    card: { flex: 1 },
    cardHeader: {
        padding: theme.spacing(1, 2),
        backgroundColor: COLORS.background,
        color: COLORS.primary,
    },
    filterInput: {
        width: "100%",
        height: "100%",
    },
    list: {
        // width: 200,
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: "auto",
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    listItemPrimary: {
        fontWeight: "600",
    },

    listItemSecondary: {
        color: COLORS.accent,
    },
}));

//return elements that exist in a but no in b
function not(a, b) {
    return a.filter((objA) => b.findIndex((objB) => objA.id === objB.id) === -1);
}

//return elements that exist in both a and b
function intersection(a, b) {
    return a.filter((objA) => b.findIndex((objB) => objA.id === objB.id) !== -1);
}

//return all elements from a and b with no duplicates
function union(a, b) {
    return [...a, ...not(b, a)];
}

const CustomList = ({
    title,
    items,
    onListFilter,
    disabled = false,
    checkedList = [],
    setChecked = () => {},
    ...otherProps
}) => {
    const classes = useStyles(otherProps);
    //filter only not hidden
    const shownItems = useMemo(() => {
        return items.filter((item) => !item.hidden);
    }, [items]);

    const handleToggle = (toggledObject) => () => {
        if (disabled) return;

        const currentIndex = checkedList.findIndex((obj) => obj.id === toggledObject.id);
        const newChecked = [...checkedList];

        //if not checked, push. otherwise, delete
        if (currentIndex === -1) {
            newChecked.push(toggledObject);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checkedList, items).length;

    const handleToggleAll = (items) => () => {
        if (disabled) return;
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checkedList, items));
        } else {
            setChecked(union(checkedList, items));
        }
    };

    return (
        <Card>
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(shownItems)}
                        checked={numberOfChecked(shownItems) === shownItems.length && shownItems.length !== 0}
                        indeterminate={
                            numberOfChecked(shownItems) !== shownItems.length && numberOfChecked(shownItems) !== 0
                        }
                        disabled={shownItems.length === 0 || disabled}
                        inputProps={{ "aria-label": "all items selected" }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(shownItems)}/${shownItems.length} selected`}
            />
            <Divider />
            {/* filter field */}
            <TextField
                variant="outlined"
                placeholder="Search"
                className={classes.filterInput}
                onChange={(e) => onListFilter(e, items)}
            />
            <List className={classes.list} dense component="div" role="list">
                {shownItems.map((object) => {
                    const labelId = `transfer-list-all-item-${object.id}-label`;

                    return (
                        <ListItem
                            key={`tansferList-item-${object.id}`}
                            role="listitem"
                            button
                            onClick={handleToggle(object)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    disabled={disabled}
                                    checked={checkedList.findIndex((obj) => obj.id === object.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={object.primaryLabel}
                                secondary={object.secondaryLabel}
                                primaryTypographyProps={{ className: classes.listItemPrimary }}
                                secondaryTypographyProps={{ className: classes.listItemSecondary }}
                            />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );
};

export default React.memo(CustomList);
