import { lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../constants/colors";

const styles = makeStyles({
    pageRoot: {
        padding: "20px 20px 50px 20px",
    },

    pageHeader: {
        color: COLORS.ACCENT,
    },
    content: {
        maxWidth: 920,
        margin: "15px auto 0 auto",
    },
    content: {
        maxWidth: 920,
        margin: "15px auto 0 auto",
    },
    cardContainer: {
        margin: "15px auto 0 auto",
        maxWidth: 720,
    },

    cardTitle: {
        fontSize: "0.85rem",
        fontWeight: "bold",
        color: "grey",
    },
    formCard: {
        padding: 15,
    },

    input: {
        "& .MuiInputBase-root": {
            fontSize: "0.8rem",
        },

        "& .MuiInputLabel-outlined": {
            fontSize: "0.7rem",
            transform: "translate(14px, 12px) scale(1)",
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
            fontSize: "0.7rem",
            transform: "translate(10px, 12px) scale(1)",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            padding: "6px 4px",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "4px 4px",
        },

        "& .MuiOutlinedInput-notchedOutline legend": {
            fontSize: "0.5rem",
        },

        "& .MuiFormHelperText-root.Mui-error": {
            position: "absolute",
            top: "35px",
            left: 0,
            fontSize: "0.6rem",
            margin: 0,
        },

        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },

    inputRtl: {
        direction: "rtl",

        "& label": {
            direction: "ltr",
        },
    },

    endAdornment: {
        marginRight: 5,
    },

    attachmentsZone: {
        width: "100%",
        position: "relative",
        padding: "8px 0",
        border: `1px dashed ${COLORS.FRENSH_BLUE}`,
        borderRadius: "5px",
        margin: "8px 0 0 0",
        "& > span": {
            position: "absolute",
            top: -12,
            left: 25,
            fontSize: "0.9rem",
            backgroundColor: "#f5f9ff",
            fontWeight: "bold",
            color: "grey",
            padding: "0 5px",
        },
    },

    fileButton: {
        width: "100%",
        height: 100,
        borderRadius: 5,
        backgroundColor: "#c3c3c3",

        "&:hover": {
            backgroundColor: "#c3c3c3",
        },
        "&:hover:before": {
            content: "'Change Health Card'",
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "white",
            fontSize: "0.65rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        "& img": {
            objectFit: "contain",
        },
    },

    fbTranparentBackground: {
        backgroundColor: "transparent",
        "&:hover": {
            backgroundColor: "transparent",
        },
    },

    deleteBtn: {
        minWidth: "100%",
        height: "100%",
        padding: 0,
        color: COLORS.ACCENT,
        backgroundColor: "transparent",
        transition: "color 0.3s linear",
        "&:hover": {
            backgroundColor: "transparent",
            color: lighten(COLORS.ACCENT, 0.3),
        },
    },

    errorMsg: {
        flex: 1,
        margin: 0,
        padding: "0 15px",
        color: "red",
    },

    addProductBtn: {
        minWidth: "100%",
        height: "100%",
        padding: "5px 0px",
        border: `1px solid ${COLORS.FRENSH_BLUE}`,
        color: COLORS.FRENSH_BLUE,
        backgroundColor: "transparent",
        transition: "all 0.3s linear",
        "&:hover": {
            backgroundColor: "transparent",
            border: `1px solid ${lighten(COLORS.FRENSH_BLUE, 0.3)}`,
            color: lighten(COLORS.FRENSH_BLUE, 0.3),
        },
    },

    option: {
        padding: "2px 8px",
        fontSize: "0.8rem",
    },

    submitBtn: {
        width: 80,
        marginTop: 5,
        marginBottom: 100,
        float: "right",
        textTransform: "none",
    },

    circularProgress: {
        padding: 2,
        textAlign: "center",
    },
});

export default styles;
