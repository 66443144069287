import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

export const queryPrices = async (q) => {
    try {
        return query(COLLECTIONS.PRICE, q);
    } catch (error) {
        throw error;
    }
};

export let getRegularPricesFromProject = async (project) => {
    let regularPrices = [];

    try {
        let projectTypeID = project.data.project_type;
        let uniqueClassIDs = [];
        //load regular prices provided by the outlets
        for (const supplierBranch of project.supplierBranches) {
            for (const outletBranch of supplierBranch.outletBranches) {
                let alreadyIncluded = uniqueClassIDs.includes(outletBranch.info.data().class_id);
                if (!alreadyIncluded) uniqueClassIDs.push(outletBranch.info.data().class_id);
                // const price = await getRegularPrices("101", outletBranch.info.data().class_id);
                // regularPrices.push(price[0]);
            }
        }

        regularPrices = await Promise.all(
            uniqueClassIDs.map(async (classID) => getRegularPrices(projectTypeID, classID))
        );
        regularPrices = regularPrices.map((pArr) => pArr[0]);
    } catch (error) {
        console.log(error);
    }
    return regularPrices;
};

export let getRegularPrices = async (projectType, outlet_class_id = null) => {
    let prices = [];
    try {
        let ref = db.collection("Price").where("project_type", "==", projectType);

        if (outlet_class_id) {
            ref = ref.where("class_id", "==", outlet_class_id);
        }

        prices = (await ref.get()).docs;
    } catch (error) {
        console.error(error);
    }

    return prices;
};
