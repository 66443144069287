import { cloneDeep } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { PLATFORM, STATUS } from "../../constants/global";
import { useAuth } from "../../contexts/auth-context";
import useTransferList from "../../hooks/use-transfer-list";
import { addLinkedOutlet, queryLinkedOutlets, updateLinkedOutlet } from "../../services/firestore/Linked_Outlets";
import {
    addLinkedOutlets,
    addSupplier,
    deleteLinkedOutlets,
    getLinkedOutlets,
    getSupplier,
} from "../../services/firestore/Supplier_Outlet_Product";
import { getAllOutlets } from "../../services/firestore/Outlet";
import COLORS from "../../constants/colors";
import Swal from "sweetalert2";
import { deactivateLinkedOutletBranchesWithLatestLinkedOutlets } from "../../services/firestore/Linked_Outlet_Branches";
// import { migrateBranchOutletBranch, migrateSupplierOutletProduct } from "../../services/migration";

const useLinkOutlets = () => {
    const { companyData } = useAuth();
    const [linkedOutlets, setLinkedOutlets] = useState([]);
    const [inactiveLinkedOutlets, setInactiveLinkedOutlets] = useState([]);

    // const { INITIAL_LIST_ITEM, transferListState, dispatchTransferListState, transferHandler, filterHandler } =
    //     useTransferList();
    const {
        INITIAL_LIST_ITEM,
        leftList,
        setLeftList,
        rightList,
        setRightList,
        checkedList,
        setCheckedList,
        transferHandler,
        filterHandler,
    } = useTransferList();

    const [alertState, setAlertState] = useState({
        open: false,
        message: "",
        severity: "info",
    });

    const [initializing, setInitializing] = useState(true);
    const [saving, setSaving] = useState(false);

    const setAlertOpen = (open) => {
        setAlertState({ ...alertState, open });
    };

    const lagacySave = async () => {
        try {
            const { company_id } = companyData;
            // const { leftList, rightList } = transferListState;

            // save the items that exist in the new list but not exist in the old one
            const toBeSaved = rightList.filter((item) => !linkedOutlets.map((i) => i.outlet_id).includes(item.id));
            // delete the items that exist in the old list but not exist in the new one
            const toBeDeleted = leftList.filter((item) => linkedOutlets.map((i) => i.outlet_id).includes(item.id));

            //save
            let sop = await getSupplier(company_id);
            if (!sop.exists) {
                sop = {
                    city_id: companyData.city_id,
                    company_id: company_id,
                    platform: PLATFORM,
                    supplier_id: company_id,
                    En_name: companyData.En_short_name,
                };
                await addSupplier(sop);
            }
            const saveOutlets = toBeSaved.map((item) => ({
                En_short_name: item.primaryLabel,
                outlet_id: item.id,
                supplier_id: company_id,
                platform: PLATFORM,
            }));
            await addLinkedOutlets(company_id, saveOutlets);

            //delete
            await deleteLinkedOutlets(
                company_id,
                toBeDeleted.map((d) => d.id)
            );
        } catch (error) {
            throw error;
        }
    };

    const saveHandler = async () => {
        try {
            const response = await Swal.fire({
                title: `Are you sure?`,
                text: `Please keep in mind if you apply these changes, 
                It will affect the corresponsing linked outlet branches.`,
                icon: "warning",
                confirmButtonText: "Confirm",
                confirmButtonColor: COLORS.SUBMIT,
                showDenyButton: true,
                denyButtonText: "Cancel",
                denyButtonColor: COLORS.CANCEL,
            });

            if (response.isDenied) return;

            setSaving(true);
            //!lagacy
            await lagacySave();

            const { company_id } = companyData;
            // const { leftList, rightList } = transferListState;

            // save the items that exist in the new list but not exist in the old one
            // and does not have a document in firestore
            let toBeAdded = rightList.filter(
                (item) =>
                    !linkedOutlets.map((i) => i.outlet_id).includes(item.id) &&
                    !inactiveLinkedOutlets.map((i) => i.outlet_id).includes(item.id)
            );
            //add new docs
            toBeAdded = await Promise.all(
                toBeAdded.map(async (listItem) => {
                    let data = {
                        id: null,
                        supplier_id: company_id,
                        outlet_id: listItem.id,
                        products: [],
                        status: STATUS.ACTIVE,
                    };
                    data = await addLinkedOutlet(data);
                    listItem.data = data;
                    return listItem;
                })
            );

            // save the items that exist in the new list but not exist in active list
            // and have a document in firestore
            let toBeActive = rightList.filter(
                (item) =>
                    !linkedOutlets.map((i) => i.outlet_id).includes(item.id) &&
                    inactiveLinkedOutlets.map((i) => i.outlet_id).includes(item.id)
            );
            //update existing docs
            toBeActive = await Promise.all(
                toBeActive.map(async (listItem) => {
                    let data = {
                        status: STATUS.ACTIVE,
                    };
                    await updateLinkedOutlet(listItem.data.id, data);
                    listItem.data.status = STATUS.ACTIVE;
                    return listItem;
                })
            );

            // deactivate the items that exist in the left list and exist in the active linked outlets
            let toBeInactive = leftList.filter((item) => linkedOutlets.map((i) => i.outlet_id).includes(item.id));
            //update existing docs
            toBeInactive = await Promise.all(
                toBeInactive.map(async (listItem) => {
                    let data = {
                        status: STATUS.INACTIVE,
                    };
                    await updateLinkedOutlet(listItem.data.id, data);
                    listItem.data.status = STATUS.INACTIVE;
                    return listItem;
                })
            );

            const newLinkedOutlets = cloneDeep(linkedOutlets);
            const newInactiveLinkedOutlets = cloneDeep(inactiveLinkedOutlets);

            //in active linked outlet, delete toBeInactive
            toBeAdded.forEach((item) => {
                newLinkedOutlets.push(item.data);
            });
            toBeActive.forEach((item) => {
                const index = newInactiveLinkedOutlets.findIndex((o) => o.id === item.data.id);
                //from inactive to active
                newLinkedOutlets.push(newInactiveLinkedOutlets[index]);
                //remove inactive
                newInactiveLinkedOutlets.splice(index, 1);
            });
            toBeInactive.forEach((item) => {
                const index = newLinkedOutlets.findIndex((o) => o.id === item.data.id);
                //from active to inactive
                newInactiveLinkedOutlets.push(newLinkedOutlets[index]);
                //remove active
                newLinkedOutlets.splice(index, 1);
            });

            //sync these with linked outletBranches
            await deactivateLinkedOutletBranchesWithLatestLinkedOutlets(company_id);

            setLinkedOutlets(newLinkedOutlets);
            setInactiveLinkedOutlets(newInactiveLinkedOutlets);

            setAlertState({
                open: true,
                message: "Successfully saved",
                severity: "success",
            });
        } catch (error) {
            console.error(error);
        }
        setSaving(false);
    };

    const init = useCallback(async () => {
        try {
            // migrateSupplierOutletProduct();
            // migrateBranchOutletBranch();
            const { company_id } = companyData;
            //get outlets info
            const outlets = (await getAllOutlets()).map((doc) => doc.data());

            //lagacy
            let lagacyLinkedOutlets = await getLinkedOutlets(company_id);
            //new
            let linkedOutlets = await queryLinkedOutlets([
                { key: "supplier_id", operator: "==", value: company_id },
                // { key: "status", operator: "==", value: STATUS.ACTIVE },
            ]);

            //doc to data and set outlet data
            lagacyLinkedOutlets = lagacyLinkedOutlets.map((item) => {
                const outlet = outlets.find((o) => o.outlet_id === item.data().outlet_id);
                return {
                    ...item.data(),
                    outlet,
                };
            });
            linkedOutlets = linkedOutlets.map((item) => {
                const outlet = outlets.find((o) => o.outlet_id === item.data().outlet_id);
                return {
                    ...item.data(),
                    outlet,
                };
            });

            let inactiveLinkedOutlets = linkedOutlets.filter((o) => o.status === STATUS.INACTIVE);
            linkedOutlets = linkedOutlets.filter((o) => o.status === STATUS.ACTIVE);

            //setup transfer list
            const rightList = lagacyLinkedOutlets.map((lo) => {
                let item = cloneDeep(INITIAL_LIST_ITEM);
                item.id = lo.outlet_id;
                item.data = linkedOutlets.find((o) => o.outlet_id === item.id) ?? null;
                item.lagacyData = lo;
                item.primaryLabel = lo.outlet.En_short_name;
                return item;
            });

            const leftList = outlets.reduce((prev, current) => {
                const exist = rightList.find((r) => r.id === current.outlet_id);
                if (exist) return prev;
                let item = cloneDeep(INITIAL_LIST_ITEM);
                prev.push({
                    ...item,
                    id: current.outlet_id,
                    data: inactiveLinkedOutlets.find((o) => o.outlet_id === current.outlet_id) ?? null,
                    primaryLabel: current.En_short_name,
                });
                return prev;
            }, []);

            setLinkedOutlets(linkedOutlets);
            setInactiveLinkedOutlets(inactiveLinkedOutlets);
            //set state
            // dispatchTransferListState({
            //     type: "INIT",
            //     payload: {
            //         leftList,
            //         rightList,
            //         checkedList: [],
            //     },
            // });
            setLeftList(leftList);
            setRightList(rightList);
            setCheckedList([]);
        } catch (error) {
            console.error(error);
        }
        setInitializing(false);
    }, [companyData, INITIAL_LIST_ITEM, setLeftList, setRightList, setCheckedList]);

    useEffect(() => {
        init();
    }, [init]);

    return {
        initializing,
        saving,
        // transferListState,
        leftList,
        rightList,
        checkedList,
        transferHandler,
        filterHandler,
        saveHandler,
        alertState,
        setAlertOpen,
    };
};

export default useLinkOutlets;
