import React, { useState, useReducer, useEffect, useCallback } from "react";
import { Filters } from "../../components";
import { TASKS_STATUS } from "../../constants/constants-lagacy";
import {
    formatDate,
    getToday,
    getDayName,
    calculateTimeDuration,
    convertMinutesToHoursAndMinutes,
} from "../../helpers/date-utils";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useAuth } from "../../contexts/auth-context";
import { Grid } from "@material-ui/core";
import { getDay } from "date-fns";
import { CircularLoader, Header } from "../../core-ui/custom";
import { getSupplierMerchandisers } from "../../services/firestore/Merchandiser";
import { queryTasks_V2 } from "../../services/firestore/Task";
import { generatePDFinNewTab } from "../../helpers/pdf-utils";
import { getMerchandiserName } from "../../services/firestore/Merchandiser";
import MerchandiserAttendanceReportPdf from "../../components-lagacy/PDF/MerchandiserAttendanceReport";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Button } from "@material-ui/core";
import { cloneDeep } from "lodash";

const INITIAL_FILTERS = {
    startDate: {
        label: "start Date",
        component: "date",
        initialValue: getToday(),
    },
    endDate: {
        label: "end Date",
        component: "date",
        initialValue: getToday(),
    },
    merchandisers: {
        label: "Merchandiser",
        componentProps: {
            multiple: true,
        },
        options: [],
        initialValue: [],
    },
};

const dataSourcesReducer = (state, { type, payload }) => {
    switch (type) {
        case "MULTI_SET":
            return {
                ...state,
                ...payload,
            };
        case "SET":
            return {
                ...state,
                [payload.key]: payload.value,
            };
        case "RESET":
            return {
                ...state,
                [payload.key]: payload.initialValue,
            };
        default:
            return state;
    }
};

const initialState = {
    merchandisers: [],
};

const MerchandiserAttendanceReport = (props) => {
    const { companyData } = useAuth();
    const classes = styles();
    // states
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dataSources, dispatchDataSources] = useReducer(dataSourcesReducer, initialState);
    const [merchandisers, setMerchandisers] = useState([]);
    const [suppliermerchandisers, setSupplierMerchandisers] = useState([]);
    const [startDate, setStartDate] = useState(getToday());
    const [endDate, setEndDate] = useState(getToday());
    const [isClicked, setIsCliced] = useState(false);

    const init = useCallback(async () => {
        try {
            const { company_id } = companyData;
            const [merchandisers] = (await Promise.all([getSupplierMerchandisers(company_id)])).map((docs) =>
                docs.map((doc) => doc.data())
            );
            setMerchandisers(merchandisers);

            dispatchDataSources({
                type: "MULTI_SET",
                payload: {
                    merchandisers: merchandisers.map((m) => ({ value: m.uid, label: getMerchandiserName(m) })),
                },
            });
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    }, [companyData]);

    const search = async (values) => {
        try {
            setLoading(true);
            const { startDate, endDate, merchandisers: selectedMerch } = values;
            endDate.setHours(23, 59, 59, 59);

            let suppliermerchandisers = [...merchandisers];
            if (selectedMerch.length > 0) {
                suppliermerchandisers = merchandisers.filter(
                    (merch) => selectedMerch.findIndex((sm) => sm.value === merch.uid) !== -1
                );
            }

            suppliermerchandisers = await Promise.all(
                suppliermerchandisers.map(async (merch) => {
                    const query = [
                        { key: "date_time_from", operator: ">=", value: startDate },
                        { key: "date_time_from", operator: "<=", value: endDate },
                        { key: "supplier_id", operator: "==", value: Number(companyData.company_id) },
                        { key: "uid", operator: "==", value: `${merch.uid}` },
                    ];
                    let merchTasks = (await queryTasks_V2(query)).map((doc) => doc.data());
                    let dates = [];
                    let loop = new Date(startDate);

                    while (loop < endDate) {
                        let tasks = merchTasks.filter((task) => {
                            return formatDate(task.date_time_from.toDate()) === formatDate(loop);
                        });

                        //sort tasks by started_at
                        tasks = tasks.sort((a, b) => {
                            if (a.started_at && b.started_at) {
                                return b.started_at - a.started_at;
                            } else if (a.started_at) {
                                return -1;
                            } else if (b.started_at) {
                                return 1;
                            } else {
                                return 0;
                            }
                        });

                        const attendance = {
                            date: new Date(loop),
                            tasks: tasks,
                            totalDone: 0,
                            total: 0,
                            timeIn: "-",
                            timeOut: "-",
                            duration: "-",
                        };
                        dates.push(attendance);

                        loop.setDate(loop.getDate() + 1);
                        // let newDate = loop.setDate(loop.getDate() + 1);
                        // loop = new Date(newDate);
                    }
                    merch.dates = dates;
                    let rows = [];

                    rows = dates.map((attendance) => {
                        if (attendance.tasks.length !== 0) {
                            let total = 0;
                            let totalDone = 0;
                            attendance.tasks.forEach((task) => {
                                if (task.state !== TASKS_STATUS.CANCELED) {
                                    total += 1;
                                }
                                if (task.state === TASKS_STATUS.FINISHED) {
                                    totalDone += 1;
                                }
                            });
                            attendance.totalDone = totalDone;
                            attendance.total = total;
                            // calculate time in between under process and finish tasks
                            const tasksForTimein = attendance.tasks.filter((task) =>
                                [TASKS_STATUS.UNDER_PROCESS, TASKS_STATUS.FINISHED].includes(task.state)
                            );
                            const timeIn = tasksForTimein.reduce((prev, curr) => {
                                if (prev === "-") return curr.started_at;
                                return prev <= curr.started_at ? prev : curr.started_at;
                            }, "-");
                            // calculate time out between finished tasks
                            const tasksForTimeOut = tasksForTimein.filter(
                                (task) => task.state === TASKS_STATUS.FINISHED
                            );
                            const timeOut = tasksForTimeOut.reduce((prev, curr) => {
                                if (prev === "-") return curr.finished_at;
                                return prev >= curr.finished_at ? prev : curr.finished_at;
                            }, "-");

                            let duration = "-";
                            if (timeIn !== "-" && timeOut !== "-") {
                                duration = calculateTimeDuration(timeIn.toDate(), timeOut.toDate());
                                duration = convertMinutesToHoursAndMinutes(duration);
                            }
                            // let date = attendance.tasks[0].date_time_from.toDate();
                            attendance.timeIn = timeIn;
                            attendance.timeOut = timeOut;
                            attendance.duration = duration;
                            // date.date = date;
                            return attendance;
                        } else {
                            attendance.total = "-";
                            attendance.totalDone = "-";
                            attendance.duration = "-";
                            // attendance.date = date;
                            attendance.timeIn = "-";
                            attendance.timeOut = "-";
                            return attendance;
                        }
                    });
                    merch.rows = rows;
                    return merch;
                })
            );
            suppliermerchandisers.sort(function (a, b) {
                if (getMerchandiserName(a) < getMerchandiserName(b)) {
                    return -1;
                }
                if (getMerchandiserName(a) > getMerchandiserName(b)) {
                    return 1;
                }
                return 0;
            });
            setSupplierMerchandisers(suppliermerchandisers);
            setStartDate(startDate);
            setEndDate(endDate);
            setLoading(false);
            setIsCliced(true);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const initFilters = useCallback(
        (context) => {
            const filters = cloneDeep(INITIAL_FILTERS);
            filters.merchandisers.options = dataSources.merchandisers;

            context.setFilters(filters);
        },
        [dataSources.merchandisers]
    );

    useEffect(() => {
        init();
    }, [init]);

    function createHeaderData(name, attributes) {
        return { name, attributes };
    }

    const secondRowheaders = [createHeaderData("P", {}), createHeaderData("D", {})];

    const handleMerchandiserAttendanceReportPdf = () => {
        generatePDFinNewTab(
            <MerchandiserAttendanceReportPdf
                info={{
                    companyData: companyData,
                    startDate: startDate,
                    endDate: endDate,
                    suppliermerchandisers: suppliermerchandisers,
                }}
            />,
            `Merchandiser Attendance Report`
        );
    };
    function timestamp(time) {
        let date = time.toDate();
        const hour = `${date.getHours() < 10 ? "0" : ""}${date.getHours()}`;
        const minute = `${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}`;
        const second = `${date.getSeconds() < 10 ? "0" : ""}${date.getSeconds()}`;
        return `${hour}:${minute}:${second}`;
    }
    return (
        <section className={classes.section}>
            <Header>
                <span className={classes.headerColor}>Merchandiser Attendance Report</span>
            </Header>
            <div className={classes.containerDiv}>
                {!initializing && (
                    <Grid className={classes.grid} container spacing={2}>
                        <Grid item sm={10} md={8}>
                            <Filters init={initFilters} onSubmit={search} />
                        </Grid>
                    </Grid>
                )}
                {initializing || loading ? <CircularLoader /> : null}
                <div className={classes.container}>
                    {isClicked ? (
                        <div className={classes.alignRight}>
                            <Button
                                className={classes.pdfButton}
                                title="print pdf"
                                onClick={handleMerchandiserAttendanceReportPdf}
                            >
                                <div className={classes.pdfIcon}>
                                    <PictureAsPdfIcon />
                                </div>
                            </Button>
                            {/* <Button className={classes.pdfButton} title="export Excel">
                                <div className={classes.excelIcon}>
                                    <ListAltSharpIcon />
                                </div>
                            </Button> */}
                        </div>
                    ) : (
                        <div></div>
                    )}
                    {suppliermerchandisers.map((merch) => {
                        let counter = 0;
                        return (
                            <div key={`attendance-${merch.uid}`} className={classes.padding}>
                                <div>
                                    <div className={classes.merchName}>
                                        <span className={classes.flexing}>
                                            Merchandiser's Name: <span>&nbsp;</span>
                                            <span className={classes.boldFont}> {getMerchandiserName(merch)}</span>
                                        </span>
                                        <span className={classes.flexing}>
                                            Date from: <span>&nbsp;</span>
                                            <span className={classes.boldFont}> {formatDate(startDate)}</span>
                                        </span>
                                        <span className={classes.flexing}>
                                            Date to: <span>&nbsp;</span>
                                            <span className={classes.boldFont}> {formatDate(endDate)}</span>
                                        </span>
                                    </div>
                                </div>
                                <Table id="merchAttendanceSheet" className={classes.table}>
                                    {/* headers */}
                                    <TableHead>
                                        <TableRow className={classes.headerRow}>
                                            <TableCell
                                                padding="none"
                                                rowSpan={2}
                                                className={`${classes.header} ${classes.indexHeader}`}
                                            ></TableCell>
                                            <TableCell
                                                padding="none"
                                                rowSpan={2}
                                                className={`${classes.header} ${classes.dayHeader}`}
                                            >
                                                Day
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                rowSpan={2}
                                                className={`${classes.header} ${classes.dateHeader}`}
                                            >
                                                Date
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                rowSpan={1}
                                                colSpan={2}
                                                className={`${classes.header} ${classes.visitsHeader}`}
                                            >
                                                Visits
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                rowSpan={2}
                                                className={`${classes.header} ${classes.timeHeader}`}
                                            >
                                                Time in
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                rowSpan={2}
                                                className={`${classes.header} ${classes.timeHeader}`}
                                            >
                                                Time Out
                                            </TableCell>
                                            <TableCell
                                                padding="none"
                                                rowSpan={2}
                                                className={`${classes.header} ${classes.durationHeader}`}
                                            >
                                                Duration
                                            </TableCell>
                                        </TableRow>
                                        <TableRow className={classes.headerRow}>
                                            {secondRowheaders.map((header, index) => (
                                                <TableCell
                                                    padding="none"
                                                    key={`thr2-${index}-${merch.uid}`}
                                                    {...header.attributes}
                                                    className={classes.secondHeader}
                                                >
                                                    {header.name}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {merch.rows.map((row, index) => {
                                            counter++;
                                            return (
                                                <TableRow
                                                    key={`tdr-${index}-${merch.uid}`}
                                                    className={index % 2 === 0 ? classes.rowEven : classes.row}
                                                >
                                                    <TableCell padding="none" className={`${classes.blueCell}`}>
                                                        {counter}
                                                    </TableCell>
                                                    <TableCell padding="normal" className={`${classes.blueCell}`}>
                                                        {getDayName(getDay(row.date))}
                                                    </TableCell>
                                                    <TableCell padding="normal" className={`${classes.blueCell}`}>
                                                        {formatDate(row.date)}
                                                    </TableCell>
                                                    <TableCell padding="normal" className={`${classes.blueCell}`}>
                                                        {row.total}
                                                    </TableCell>
                                                    <TableCell padding="normal" className={`${classes.blueCell}`}>
                                                        {row.totalDone}
                                                    </TableCell>
                                                    <TableCell padding="normal" className={`${classes.blueCell}`}>
                                                        {row.timeIn === "-" ? "-" : timestamp(row.timeIn)}
                                                    </TableCell>
                                                    <TableCell padding="normal" className={`${classes.blueCell}`}>
                                                        {row.timeOut === "-" ? "-" : timestamp(row.timeOut)}
                                                    </TableCell>
                                                    <TableCell padding="normal" className={`${classes.blueCell}`}>
                                                        {row.duration}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

MerchandiserAttendanceReport.propTypes = {
    classes: PropTypes.object,
};

export default MerchandiserAttendanceReport;
