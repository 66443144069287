import React, { useEffect } from "react";
import styles from "./styles";
import { flatten } from "lodash/fp";
import { useState } from "react";
import { useCallback } from "react";
import { RoutePlanFilters, RoutePlanSchedule } from "../../components";
import { useAuth } from "../../contexts/auth-context";
import { Button, TransitionAlert } from "../../core-ui/custom";
import { getToday } from "../../helpers/date-utils";
import {
    getMerchandiserName,
    getMerchandisersBySupplierBranch,
    getOutsourcedMerchandisers,
} from "../../services/firestore/Merchandiser";
import { getOutletBranchesBySchedules } from "../../services/firestore/Outlet_Branch";
import { getSchedulesByRoutes, getFreeSchedules, queryProjects, saveSchedules } from "../../services/firestore/Project";
import { queryRoutes, saveRoutes } from "../../services/firestore/Route";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";
import { Grid } from "@material-ui/core";
import { useReducer } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useMemo } from "react";
import randomColor from "randomcolor";
import { PROJECT_TYPES } from "../../constants/constants-lagacy";

const createRoutelessArray = (schedules) => {
    return schedules.reduce((routelessArr, schedule) => {
        const { route_id, ...rest } = schedule;
        if (route_id) return routelessArr;

        //find the index of routeless item based on project id and supplier branch id
        let index = routelessArr.findIndex(
            (item) => item.project_id === rest.project_id && item.supplier_branch_id === rest.supplier_branch_id
        );
        //if not found, create a new item
        if (index === -1) {
            routelessArr.push({
                project_id: rest.project_id,
                supplier_branch_id: rest.supplier_branch_id,
                short_id: "000",
                id: `${rest.project_id}-${rest.supplier_branch_id}-000`,
                name: "Routeless",
                merchandiser_id: null,
                avg_travel_time: 0,
                plan: {
                    sun: [],
                    mon: [],
                    tue: [],
                    wed: [],
                    thu: [],
                    fri: [],
                    sat: [],
                },
            });
            index = routelessArr.length - 1;
        }

        //update the plan of the routeless item
        for (const day in schedule.merchandising_days) {
            if (Object.hasOwnProperty.call(schedule.merchandising_days, day)) {
                const element = schedule.merchandising_days[day];
                if (element.selected) {
                    routelessArr[index].plan[day].push(`${schedule.outlet_branch_id}:${schedule.id}`);
                }
            }
        }
        return routelessArr;
    }, []);
};

const generateProjectColors = (IDs) => {
    let colors = {};
    for (const id of IDs) {
        // colors[id] = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
        colors[id] = randomColor();
    }
    return colors;
};

const initStates = {
    supplierBranchesList: [],
    selectedSupplierBranch: null,

    merchandisersList: [],
    selectedMerchandisers: [],

    projectsList: [],
    selectedProjects: [],

    routes: [],

    schedules: [],
    // freePlan: { sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: [] },
    outletBranches: [],
};

const reducer = (state, action) => {
    const { type, value } = action;
    switch (type) {
        case "INIT":
            return { ...value };

        case "SUPPLIER_BRANCH_CHANGED":
            return { ...state, ...value };

        case "UPDATE_MERCH":
            return { ...state, merchandisersList: value.merchandisersList };
        case "UPDATE_SELECTED_MERCH":
            return { ...state, selectedMerchandisers: value.selectedMerchandisers };

        case "UPDATE_ROUTES":
            return { ...state, routes: value.routes };

        case "UPDATE_ROUTES_AND_MERCH":
            return {
                ...state,
                merchandisersList: value.merchandisersList,
                routes: value.routes,
                schedules: value.schedules,
            };

        case "UPDATE_ROUTES_AND_SCHEDULES":
            return { ...state, schedules: value.schedules, routes: value.routes };
        case "PROJECT_FILTER":
            return { ...state, selectedProjects: value.selectedProjects };

        // case "UPDATE_FREE_PLAN":
        //     return { ...state, freePlan: value.freePlan };
        default:
            break;
    }
};

const alertStateObj = {
    msg: "",
    severity: "info",
    open: false,
};

const RoutePlanManagement = () => {
    const classes = styles();
    const { companyData } = useAuth();
    const [alertState, setAlertState] = useState({ ...alertStateObj });
    const [loading, setLoading] = useState(false);

    const [
        {
            supplierBranchesList,
            selectedSupplierBranch,

            merchandisersList,
            selectedMerchandisers,

            projectsList,
            selectedProjects,

            routes,

            schedules,
            outletBranches,
        },
        dispatchStates,
    ] = useReducer(reducer, initStates);

    const setAlertOpen = (open) => setAlertState((prev) => ({ ...prev, open }));
    const projectColors = useMemo(() => generateProjectColors(projectsList.map((p) => p.project_id)), [projectsList]);

    const onProjectsChange = async (e, values) => {
        const selectedProjects = values;

        dispatchStates({
            type: "PROJECT_FILTER",
            value: {
                selectedProjects: selectedProjects,
            },
        });
        setLoading(false);
    };

    const onSupplierBranchChange = async (e, value) => {
        const { company_id } = companyData;
        const selectedSupplierBranch = value;

        const { merchandisers, selectedMerchandisers, projects, selectedProjects, routes, schedules, outletBranches } =
            await initData(company_id, selectedSupplierBranch);

        dispatchStates({
            type: "SUPPLIER_BRANCH_CHANGED",
            value: {
                selectedSupplierBranch: selectedSupplierBranch,

                merchandisersList: merchandisers,
                selectedMerchandisers: selectedMerchandisers,

                projectsList: projects,
                selectedProjects: selectedProjects,

                routes: routes,

                schedules: schedules,
                outletBranches: outletBranches,
            },
        });
        setLoading(false);
    };
    const onMerchandisersChange = (e, values) => {
        dispatchStates({ type: "UPDATE_SELECTED_MERCH", value: { selectedMerchandisers: values } });
    };

    //droppable id = merch UID - day, draggable id = route ID - day
    const dragEndRoutesHandle = ({ source, destination, draggableId }) => {
        let sourceUid = source.droppableId.split(" ")[0];
        sourceUid = sourceUid === "null" ? null : sourceUid;
        let destinationUid = destination.droppableId.split(" ")[0];
        destinationUid = destinationUid === "null" ? null : destinationUid;
        const day = destination.droppableId.split(" ")[1];
        const routeID = draggableId.split(" ")[0];

        //check if the it is routless card
        if (routeID.includes("-000")) return;

        //dropped on the same merch
        if (destination.droppableId === source.droppableId) {
            //get merchandiser's plan and update the order
            let newMerchandisers = [...merchandisersList];
            const index = newMerchandisers.findIndex((merch) => sourceUid === merch.uid);
            let plan = { ...newMerchandisers[index].plan };
            plan[day].splice(source.index, 1);
            plan[day].splice(destination.index, 0, routeID);
            newMerchandisers[index].plan = plan;
            dispatchStates({ type: "UPDATE_MERCH", value: { merchandisersList: newMerchandisers } });

            return;
        }

        //dropped on different merch

        const destinationMerchIndex = merchandisersList.findIndex((merch) => destinationUid === merch.uid);
        let newDestinationPlan = { ...merchandisersList[destinationMerchIndex].plan };
        const sourceMerchIndex = merchandisersList.findIndex((merch) => sourceUid === merch.uid);
        let newSourcePlan = { ...merchandisersList[sourceMerchIndex].plan };

        // insert route ID to destination merchandiser's plan
        newDestinationPlan[day].splice(destination.index, 0, routeID);
        for (const planDay in newDestinationPlan) {
            if (planDay === day) continue;
            if (Object.hasOwnProperty.call(newDestinationPlan, planDay)) {
                //on other days,transfer route id from source plan to destination plan
                const index = newSourcePlan[planDay].findIndex((rID) => rID === routeID);
                if (index !== -1) newDestinationPlan[planDay].splice(index, 0, routeID);
            }
        }

        //remove route ID from source merchandiser's plan all week
        // newSourcePlan[day].splice(source.index, 1);
        for (const planDay in newSourcePlan) {
            if (Object.hasOwnProperty.call(newSourcePlan, planDay)) {
                const index = newSourcePlan[planDay].findIndex((rID) => rID === routeID);
                if (index !== -1) newSourcePlan[planDay].splice(index, 1);
            }
        }

        let newMerchandisers = [...merchandisersList];
        newMerchandisers[sourceMerchIndex].plan = newSourcePlan;
        newMerchandisers[destinationMerchIndex].plan = newDestinationPlan;

        //update route data
        let newRoutes = Array.from(routes);
        const routeIndex = newRoutes.findIndex((r) => r.id === routeID);
        newRoutes[routeIndex].merchandiser_id = destinationUid;

        //update schedule data
        let newSchedules = Array.from(schedules);
        const { plan: routePlan, project_id, supplier_branch_id } = newRoutes[routeIndex];
        for (const day in routePlan) {
            if (Object.hasOwnProperty.call(routePlan, day)) {
                const paths = routePlan[day];
                for (const path of paths) {
                    const fullPath = `${project_id}:${supplier_branch_id}:${path}`;
                    const scheduleIndex = newSchedules.findIndex(
                        (sch) =>
                            `${sch.project_id}:${sch.supplier_branch_id}:${sch.outlet_branch_id}:${sch.id}` === fullPath
                    );
                    newSchedules[scheduleIndex].route_id = newRoutes[routeIndex].id;
                    newSchedules[scheduleIndex].merchandiser_id = newRoutes[routeIndex].merchandiser_id;
                }
            }
        }

        dispatchStates({
            type: "UPDATE_ROUTES_AND_MERCH",
            value: { routes: newRoutes, merchandisersList: newMerchandisers, schedules: newSchedules },
        });
    };

    //droppable id = route id + day, draggable id = schedule path + day
    const dragEndSchedulesHandle = ({ source, destination, draggableId }) => {
        const sourceRouteID = source.droppableId.split(" ")[0];
        const destinationRouteID = destination.droppableId.split(" ")[0];
        const [fullPath, day] = draggableId.split(" ");
        const outletBranchID = fullPath.split(":")[2];
        const scheduleID = fullPath.split(":")[3];
        const path = `${outletBranchID}:${scheduleID}`;

        //dropped on the same route
        if (source.droppableId === destination.droppableId) {
            let newRoutes = Array.from(routes);
            let routeIndex = newRoutes.findIndex((r) => r.id === sourceRouteID);
            let newPlan = { ...newRoutes[routeIndex].plan };
            newPlan[day].splice(source.index, 1);
            newPlan[day].splice(destination.index, 0, path);

            newRoutes[routeIndex].plan = newPlan;
            dispatchStates({ type: "UPDATE_ROUTES", value: { routes: newRoutes } });
            return;
        }

        //dropped on different route
        let newRoutes = Array.from(routes);
        let sourceRouteIndex = newRoutes.findIndex((r) => r.id === sourceRouteID);
        let sourceNewPlan = { ...newRoutes[sourceRouteIndex].plan };
        let destinationRouteIndex = newRoutes.findIndex((r) => r.id === destinationRouteID);
        let destinationNewPlan = { ...newRoutes[destinationRouteIndex].plan };

        // transfer schedule path from source route plan to destination route plan
        destinationNewPlan[day].splice(destination.index, 0, path);
        for (const planDay in destinationNewPlan) {
            if (planDay === day) continue;
            if (Object.hasOwnProperty.call(destinationNewPlan, planDay)) {
                const pathIndex = sourceNewPlan[planDay].findIndex((p) => p === path);
                if (pathIndex !== -1) destinationNewPlan[planDay].splice(pathIndex, 0, path);
            }
        }

        // remove schedule path from source route plan
        for (const planDay in sourceNewPlan) {
            if (Object.hasOwnProperty.call(sourceNewPlan, planDay)) {
                const pathIndex = sourceNewPlan[planDay].findIndex((p) => p === path);
                if (pathIndex !== -1) sourceNewPlan[planDay].splice(pathIndex, 1);
            }
        }

        //update schedule data
        let newSchedules = Array.from(schedules);
        const scheduleIndex = newSchedules.findIndex(
            (sch) => `${sch.project_id}:${sch.supplier_branch_id}:${sch.outlet_branch_id}:${sch.id}` === fullPath
        );
        newSchedules[scheduleIndex].route_id = newRoutes[destinationRouteIndex].id;
        newSchedules[scheduleIndex].merchandiser_id = newRoutes[destinationRouteIndex].merchandiser_id;

        dispatchStates({ type: "UPDATE_ROUTES_AND_SCHEDULES", value: { routes: newRoutes, schedules: newSchedules } });
    };

    const onDragEnd = ({ source, destination, draggableId, type }) => {
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        if (type.startsWith("routes")) {
            dragEndRoutesHandle({ source, destination, draggableId });
            return;
        }

        dragEndSchedulesHandle({ source, destination, draggableId });
    };

    const onSave = async () => {
        try {
            setLoading(true);
            await Promise.all([saveRoutes(routes), saveSchedules(schedules)]);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const initData = async (company_id, selectedSupplierBranch) => {
        try {
            setLoading(true);

            const today = getToday();
            const projectsQuery = [
                { key: "branches", value: selectedSupplierBranch.branch_id, operator: "array-contains" },
                { key: "supplier_id", value: company_id, operator: "==" },
                { key: "date_to", value: today, operator: ">" },
                { key: "project_type", value: PROJECT_TYPES.SUPPLIER_INSOURCE, operator: "==" },
            ];

            let [merchandisers, outsourcedMerchandisers, projects] = (
                await Promise.all([
                    getMerchandisersBySupplierBranch(selectedSupplierBranch.branch_id),
                    getOutsourcedMerchandisers(company_id, null, selectedSupplierBranch.branch_id),
                    queryProjects(projectsQuery),
                ])
            ).map((result) => result.map((r) => r.data?.() || r));

            merchandisers = merchandisers.map((m) => ({ ...m, merch_name: getMerchandiserName(m), contract: null }));
            outsourcedMerchandisers = outsourcedMerchandisers.map((m) => ({
                ...m.info,
                merch_name: getMerchandiserName(m.info),
                contract: m.contract,
            }));
            //mimic free as one of the merchandisers
            merchandisers.push({
                merch_name: "Free",
                uid: null,
                contract: null,
            });

            merchandisers = [...merchandisers, ...outsourcedMerchandisers];
            let selectedMerchandisers = merchandisers;

            const selectedProjects = [...projects];

            // Get all routes based on selected projects and supplier branches
            let routes = await Promise.all(
                selectedProjects.map(async (p) => {
                    return await queryRoutes([
                        { key: "project_id", operator: "==", value: p.project_id },
                        { key: "supplier_branch_id", operator: "==", value: selectedSupplierBranch.branch_id },
                        { key: "is_deleted", operator: "==", value: false },
                    ]);
                })
            );

            routes = flatten(routes).map((r) => r.data());

            // Get all schedules from route plans
            // Get all free schedules based on selected projects and supplier branches
            let [routeSchedules, freeSchedules] = await Promise.all([
                getSchedulesByRoutes(routes),
                getFreeSchedules(
                    selectedProjects.map((p) => p.project_id),
                    [selectedSupplierBranch.branch_id]
                ),
            ]);

            routeSchedules = routeSchedules.map((rs) => rs.data());

            freeSchedules = freeSchedules.map((fs) => fs.data());

            // create route-like array for free schedules
            const routelessArray = createRoutelessArray(freeSchedules);
            routes = [...routes, ...routelessArray];

            //merge
            const schedules = [...routeSchedules, ...freeSchedules];

            //get all outlet branches info from those schedules
            let outletBranches = (await getOutletBranchesBySchedules(schedules)).map((ob) => ob.data());

            //init merchandisers weekly route plan object
            selectedMerchandisers.forEach((merch) => {
                let plan = { sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: [] };
                let merchRoutes = routes.filter((r) => r.merchandiser_id === merch.uid);
                for (const route of merchRoutes) {
                    //if the route paths of a specific day exist, insert route id into that day's array
                    for (const day in route.plan) {
                        if (Object.hasOwnProperty.call(route.plan, day)) {
                            if (route.plan[day].length > 0) plan[day].push(route.id);
                        }
                    }
                }
                merch.plan = plan;
            });

            setLoading(false);
            return {
                merchandisers,
                projects,
                selectedMerchandisers,
                selectedProjects,
                routes,
                schedules,
                outletBranches,
            };
        } catch (error) {
            console.log(error);
        }
    };

    const init = useCallback(async () => {
        try {
            setLoading(true);

            const { company_id } = companyData;
            //get all supplier branches
            let supplierBranches = await getBranchesBySupplierID(company_id);

            if (supplierBranches.length <= 0) {
                setAlertState({
                    open: true,
                    msg: "Cannot display route plans due to the lack of your branches.",
                    severity: "warning",
                });
                setLoading(false);
                return;
            }

            supplierBranches = supplierBranches.map((br) => br.data());
            const selectedSupplierBranch = supplierBranches[0];

            const {
                merchandisers,
                selectedMerchandisers,
                projects,
                selectedProjects,
                routes,
                schedules,
                outletBranches,
            } = await initData(company_id, selectedSupplierBranch);

            dispatchStates({
                type: "INIT",
                value: {
                    supplierBranchesList: supplierBranches,
                    selectedSupplierBranch: selectedSupplierBranch,

                    merchandisersList: merchandisers,
                    selectedMerchandisers: selectedMerchandisers,

                    projectsList: projects,
                    selectedProjects: selectedProjects,

                    routes: routes,
                    // routeSchedules: routeSchedules,
                    // freeSchedules: freeSchedules,
                    schedules: schedules,
                    // freePlan: freePlan,
                    outletBranches: outletBranches,
                },
            });
        } catch (error) {
            console.log(error);
        }

        setLoading(false);
    }, [companyData]);

    useEffect(() => {
        init();
    }, [init]);

    return (
        <section>
            <TransitionAlert
                severity={alertState.severity}
                variant="filled"
                open={alertState.open}
                setOpen={setAlertOpen}
            >
                {alertState.msg}
            </TransitionAlert>

            <div className={classes.contentWrap}>
                {/* filters */}
                <RoutePlanFilters
                    lists={{
                        supplierBranchesList,
                        projectsList,
                        merchandisersList,
                    }}
                    selectedItems={{
                        selectedSupplierBranch,
                        selectedProjects,
                        selectedMerchandisers,
                    }}
                    listChangeHandlers={{
                        onSupplierBranchChange,
                        onProjectsChange,
                        onMerchandisersChange,
                    }}
                    loading={loading}
                />

                {/* save button */}
                <Grid container justifyContent="flex-end">
                    <Button onClick={onSave}>Save</Button>
                </Grid>

                {/* schedule */}
                <DragDropContext onDragEnd={onDragEnd}>
                    <RoutePlanSchedule
                        projects={selectedProjects}
                        merchandisers={selectedMerchandisers}
                        routes={routes}
                        // routeSchedules={routeSchedules}
                        // freeSchedules={freeSchedules}
                        schedules={schedules}
                        // freePlan={freePlan}
                        outletBranches={outletBranches}
                        loading={loading}
                        projectColors={projectColors}
                    />
                </DragDropContext>
            </div>
        </section>
    );
};

export default RoutePlanManagement;
