import React, { useCallback, useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";

import { convertMinutesToHoursAndMinutes } from "../../../helpers/date-utils";
import { TASKS_STATUS } from "../../../constants/constants-lagacy";
import { getMerchandiserName } from "../../../services/firestore/Merchandiser";
import { DashboardCard } from "../../../core-ui/custom";
import EnhancedTable from "../../../components-lagacy/Table/EnhancedTable";

const TABLE_HEADERS = [
    { id: "merchandiserName", label: "Merchandiser" },
    { id: "time", label: "Avg. Time" },
    { id: "branch", label: "Branch", textEllepsis: true },
];
const InstoreIdealTimeTable = ({ tasks, merchandisers, loading = false }) => {
    const classes = styles();
    const [rowsData, setRowsData] = React.useState([]);

    const init = useCallback(async () => {
        try {
            setRowsData([]);
            await onTasksUpdate(tasks, merchandisers);
        } catch (error) {
            console.log(error);
        }
    }, [tasks, merchandisers]);

    const onTasksUpdate = async (tasks, merchandisers) => {
        //filter tasks by state
        const tasks_filtered = tasks.filter((task) =>
            [TASKS_STATUS.FINISHED, TASKS_STATUS.AUDITING, TASKS_STATUS.COMPLETED].includes(task.state)
        );

        // setup rowsData
        setupTableData(tasks_filtered, merchandisers);
    };

    const setupTableData = (tasks, merchandisers) => {
        const rowsData = [];
        // for each merchandiser, calculate avg visit time of all his tasks
        merchandisers.forEach((merchandiser) => {
            // merchandiser tasks
            const tasksOfMerchandiser = tasks.filter((task) => task.uid === merchandiser.uid);
            // for each task, calculate actual duration in minutes
            const totalTime = tasksOfMerchandiser.reduce((total, task) => {
                return task.duration + total;
                // return calculateTimeDuration(start, end) + total;
            }, 0);

            // calculate avg time
            const avgTime = tasksOfMerchandiser.length === 0 ? 0 : totalTime / tasksOfMerchandiser.length;

            rowsData.push({
                id: merchandiser.uid,
                merchandiserName: getMerchandiserName(merchandiser),
                time: convertMinutesToHoursAndMinutes(avgTime) || "-",
                branch: merchandiser.branch.En_name,
            });
        });

        setRowsData(rowsData);
    };
    useEffect(() => {
        init();
    }, [init]);

    return (
        <DashboardCard title={"In-Store Ideal Time"} type="table">
            <div className={classes.tableWrapper}>
                <EnhancedTable
                    columns={TABLE_HEADERS}
                    rows={rowsData}
                    actions={[]}
                    updateSelected={() => {}}
                    loading={loading}
                    selectionDisabled
                    toolbarDisabled
                    paginationDisabled
                    emptyMessage="No Data"
                />
            </div>
        </DashboardCard>
    );
};

InstoreIdealTimeTable.propTypes = {
    classes: PropTypes.object,
};

export default InstoreIdealTimeTable;
