import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import COLORS from "../../../constants/colors";
import { CircularLoader } from "../../custom";
import RoomIcon from "@material-ui/icons/Room";
import useDialog from "../../../hooks/use-dialog";
import { MapCoordinatesDialog } from "../../../components";

export const blockInvalidNumberChar = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

const useRootStyle = makeStyles({
    root: {
        "& label.Mui-focused": {
            color: COLORS.FRENSH_BLUE,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: COLORS.FRENSH_BLUE,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&:hover fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&.Mui-focused fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
        },
    },
    mapIcon: {
        "& svg": {
            color: COLORS.ACCENT,
            cursor: "pointer",
        },
    },
});

const TextFieldWrapper = ({
    name,
    onChange,
    onBlur,
    validateOnChange = false,
    showValidationIcon = false,
    blockInvalidNumberCharacters = false,
    type = "text", //latLng or other HTML5 input types
    ...otherProps
}) => {
    const [field, meta, helpers] = useField(name);
    const context = useFormikContext();
    const rootStyle = useRootStyle();
    const { openDialog, closeDialog, isDialogOpen } = useDialog();

    const handleChange = (e) => {
        field.onChange(e);
        if (onChange) onChange(e, helpers);
    };

    const handleBlur = (e) => {
        field.onBlur(e);
        if (onBlur) onBlur(e, helpers);
    };

    const handleDialogSubmit = (latLng) => {
        helpers.setValue(`${latLng.lat}, ${latLng.lng}`);
    };

    const inputConfig = {
        endAdornment: showValidationIcon && context.isValidating ? <CircularLoader /> : null,
    };

    const config = {
        ...field,
        onChange: handleChange,
        onBlur: handleBlur,
        variant: "outlined",
        fullWidth: true,
        classes: {
            root: rootStyle.root,
        },
        InputProps: {
            ...inputConfig,
        },
        type,
        ...otherProps,
    };
    if (meta && (meta.touched || validateOnChange) && meta.error) {
        config.error = true;
        config.helperText = meta.error;
    }

    if (type === "number" && blockInvalidNumberCharacters) {
        config.onKeyDown = blockInvalidNumberChar;
    }

    if (type === "latLng") {
        config.InputProps.endAdornment = <RoomIcon onClick={openDialog} />;
        config.InputProps.classes = { adornedEnd: rootStyle.mapIcon };
        config.type = "text";
    }

    let initialLatLng = null;
    if (field.value && !meta.error && type === "latLng") {
        const [lat, lng] = field.value.split(",", 2);
        initialLatLng = { lat: Number(lat), lng: Number(lng) };
    }

    return (
        <>
            <TextField {...config} />
            {type === "latLng" && (
                <MapCoordinatesDialog
                    open={isDialogOpen}
                    onClose={closeDialog}
                    initialLatLng={initialLatLng}
                    onSubmit={handleDialogSubmit}
                />
            )}
        </>
    );
};

export default TextFieldWrapper;
