import { cloneDeep } from "lodash";
import { useState } from "react";

// const INITIAL_TRANSFER_LIST_STATE = {
//     leftList: [],
//     rightList: [],
//     checkedList: [],
// };

const INITIAL_LIST_ITEM = {
    data: {},
    lagacyData: {},
    id: null,
    primaryLabel: null,
    secondaryLabel: null,
    hidden: false,
};

// const transferListReducer = (state, action) => {
//     switch (action.type) {
//         case "INIT":
//             return { ...INITIAL_LIST_ITEM, ...action.payload };
//         case "UPDATE":
//             return { ...state, ...action.payload };
//         default:
//             return state;
//     }
// };

const useTransferList = () => {
    // const [transferListState, dispatchTransferListState] = useReducer(transferListReducer, {
    //     ...INITIAL_TRANSFER_LIST_STATE,
    // });
    const [leftList, setLeftList] = useState([]);
    const [rightList, setRightList] = useState([]);
    const [checkedList, setCheckedList] = useState([]);

    const transferHandler = (newList, direction) => {
        //if an item is checked or unchecked
        if (direction === "checked") {
            // dispatchTransferListState({ type: "UPDATE", payload: { checkedList: newList } });
            setCheckedList(newList);
            //if the checked items transfered to left
        } else if (direction === "left") {
            // dispatchTransferListState({ type: "UPDATE", payload: { leftList: newList, checkedList: [] } });
            setLeftList(newList);
            setCheckedList([]);
        } else if (direction === "right") {
            // dispatchTransferListState({ type: "UPDATE", payload: { rightList: newList, checkedList: [] } });
            setRightList(newList);
            setCheckedList([]);
        }
    };

    const filterHandler = (e, list, direciton) => {
        const value = e.target.value.toUpperCase();
        // let checkedList = cloneDeep(transferListState.checkedList);
        let newCheckedList = cloneDeep(checkedList);
        let newList = cloneDeep(list);

        newList.forEach((item) => {
            item.hidden =
                item.primaryLabel.toUpperCase().indexOf(value) === -1 &&
                (!item.secondaryLabel || item.secondaryLabel?.toUpperCase().indexOf(value) === -1);
            // if the item is checked AND hidden, uncheck (remove it from checkedList)
            if (item.hidden) {
                const index = newCheckedList.findIndex((ci) => ci.id === item.id);
                if (index === -1) return;
                newCheckedList.splice(index, 1);
            }
        });

        if (direciton === "left") {
            // dispatchTransferListState({ type: "UPDATE", payload: { leftList: list } });
            setLeftList(newList);
        } else if (direciton === "right") {
            // dispatchTransferListState({ type: "UPDATE", payload: { rightList: list } });
            setRightList(newList);
        }
        //UPDATE checked list
        // dispatchTransferListState({ type: "UPDATE", payload: { checkedList } });
        setCheckedList(newCheckedList);
    };

    return {
        INITIAL_LIST_ITEM,
        // transferListState,
        // dispatchTransferListState,
        leftList,
        setLeftList,
        rightList,
        setRightList,
        checkedList,
        setCheckedList,

        transferHandler,
        filterHandler,
    };
};

export default useTransferList;
