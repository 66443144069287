import { darken } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

const styles = makeStyles({
    dialogRoot: {
        width: "100%",
        // overflowY: "hidden",
    },

    closeButton: {
        position: "absolute",
        right: 7,
        top: 7,
        color: "gray",
    },

    titleText: {
        fontSize: "1.15rem",
        fontWeight: "bold",
    },

    dialogActions: {
        padding: "8px 24px",
        justifyContent: "flex-start",
    },

    cardContent: {
        // padding: 0,
    },

    input: {
        "& .MuiInputBase-root": {
            fontSize: "0.8rem",
        },

        "& .MuiInputLabel-outlined": {
            fontSize: "0.7rem",
            transform: "translate(14px, 12px) scale(1)",
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
            fontSize: "0.7rem",
            transform: "translate(10px, 12px) scale(1)",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            padding: "6px 4px",
            paddingRight: "27px",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "4px 4px",
        },

        "& .MuiOutlinedInput-notchedOutline legend": {
            fontSize: "0.5rem",
        },

        "& .MuiFormHelperText-root.Mui-error": {
            position: "absolute",
            top: "35px",
            left: 0,
            fontSize: "0.6rem",
            margin: 0,
        },
    },
    option: {
        padding: "2px 8px",
        fontSize: "0.8rem",
    },

    submitBtn: {
        margin: 0,
        padding: 0,
        height: "100%",
        maxWidth: "170px",
        backgroundColor: COLORS.SUBMIT,
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: darken(COLORS.SUBMIT, 0.1),
        },
    },
});

export default styles;
