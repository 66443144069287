import React from "react";
import styles from "./styles";
import NavigationExpandedSubMenuItem from "../navigation-expanded-sub-menu-item";
import { useAuth } from "../../../contexts/auth-context";

const NavigationExpandedSubMenu = (props) => {
    const classes = styles();
    const { userData } = useAuth();
    const { menu, level, activeMenuPath, onItemClick } = props;
    return (
        <ul className={classes.expandedSubMenu}>
            {menu.map((item, index) => {
                if (!item.access[userData.user_type]) return null;

                return (
                    <NavigationExpandedSubMenuItem
                        key={`nav-expanded-sub-menu-item-level${level}:${item.label}`}
                        index={index}
                        level={level}
                        label={item.label}
                        menu={item.menu}
                        link={item.link}
                        onClick={onItemClick}
                        activeMenuPath={activeMenuPath}
                    />
                );
            })}
        </ul>
    );
};

export default NavigationExpandedSubMenu;
