// import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

export const queryCategories = async (q) => {
    try {
        return await query(COLLECTIONS.CATEGORY, q);
    } catch (error) {
        throw error;
    }
};
