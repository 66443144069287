import React from "react";
import PropTypes from "prop-types";

import {
    Card,
    CardHeader,
    Grid,
    MenuItem,
    Paper,
    Table,
    TableCell,
    TableHead,
    TableBody,
    TableRow,
    TextField,
    withStyles,
} from "@material-ui/core";
import CardBody from "../../../components-lagacy/Card/CardBody";
import { COLORS, PROJECT_TYPES } from "../../../constants/constants-lagacy";
import ScheduleHeaderRow from "./ScheduleHeaderRow";
import ScheduleRow from "./ScheduleRow";
import TotalVisitsRow from "./TotalVisitsRow";
import { calculateDuration, calculateEndTime, initNewProductDocument } from "../../../services/firestore/Project";
import ExtraDisplayDialog from "../../../components-lagacy/Dialog/ExtraDisplayDialog";
import ProductsDialog from "../../../components-lagacy/Dialog/ProductsDialog";
import { getProduct } from "../../../services/firestore/Product";
import { getEDRegularPrices } from "../../../services/firestore/Extra_Display_Price";
import { cloneDeep } from "lodash";
import { guid } from "../../../helpers/firestore-utils";

const useStyles = (theme) => ({
    root: {
        width: "100%",
        padding: "10px 20px",
        position: "relative",
    },

    contentCard: {
        position: "relative",
        width: "100%",
        marginTop: "0",
    },

    cardHeader: {
        background: COLORS.background,
        color: COLORS.fontDark,
        paddingLeft: "10px",
        paddingTop: "10px",
        paddingBottom: "0px",
        marginTop: "0px",
        marginBottom: "0px",
    },
    cardBody: {
        width: "100%",
        height: "100%",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    tableContainer: { maxWidth: "100%", overflow: "auto", maxHeight: "640px" },
    table: { tableLayout: "auto", whiteSpace: "nowrap" },
    tableCell: {
        color: "#FFFFFF",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "5px",
        paddingTop: "5px",
        textAlign: "center",
        backgroundColor: COLORS.primary,
        position: "sticky",
        top: 0,
        zIndex: 100,
    },
});

let SchedulesRows = withStyles(useStyles)(
    ({
        filters,
        selfService,
        projectType,
        isConfirmed,
        rows,
        numberOfColumns,
        merchandisers,
        openRoutePlan,
        openExtraDisplay,
        openProductsDialog,
        onDayClick,
        onDurationChange,
        onStartTimeChange,
        classes,
    }) => {
        let generateRows = (rows) => {
            const supplierBranchData = rows[0].supplierBranchInfo;
            let coverageRows = [];

            //generate supplier branch row section
            coverageRows.push(
                <ScheduleHeaderRow
                    key={`supplier branch row ${supplierBranchData.branch_id}`}
                    supplierBranch={supplierBranchData}
                    numberOfColumns={numberOfColumns}
                />
            );
            //generate coverage rows for each outlet branch
            for (const row of rows) {
                coverageRows.push(
                    <ScheduleRow
                        key={`${row.supplierBranchInfo.branch_id}-${row.outletBranchInfo.En_short_name}`}
                        rowData={row}
                        merchandisers={merchandisers}
                        onDayClick={onDayClick}
                        openRoutePlan={openRoutePlan}
                        openProductsDialog={openProductsDialog}
                        openExtraDisplay={openExtraDisplay}
                        onDurationChange={onDurationChange}
                        onStartTimeChange={onStartTimeChange}
                        selfService={selfService}
                        projectType={projectType}
                        isConfirmed={isConfirmed}
                    />
                );
            }
            // let rows = [];
            // for (const outletBranch of outletBranches) {
            //     for (const schedule of outletBranch.schedules) {
            //         // rows.push(<SchedulesRow supplierBranch={}, outletBranch/>)
            //     }
            // }
            // coverageRows = [...coverageRows, ...outletBranches.map((ob) => this.generateCoverageRow(supplierBranch, ob))];
            return coverageRows;
        };

        let tableContent = (
            <TableRow>
                <TableCell align={"center"} colSpan={numberOfColumns}>
                    No Data
                </TableCell>
            </TableRow>
        );

        if (rows.length > 0) {
            //generate rows per supplier branch
            let rowsPersupplierBranches = rows.reduce((groupedRows, row) => {
                const supplierBranchID = row.scheduleData.supplier_branch_id;
                const outletID = row.outletBranchInfo.outlet_id;
                const cityID = `${row.outletBranchInfo.city_id}`;

                //check for supplier branch filter
                if(filters.branch !== "All Branches" && filters.branch !== supplierBranchID) {
                    return groupedRows;
                }

                //check for outlet branch filter
                if(filters.outlet !== "All Outlets" && filters.outlet !== outletID) {
                    return groupedRows;
                }

                //check for city filter
                if(filters.city !== "All Cities" && filters.city !== cityID) {
                    return groupedRows;
                }

                let groupedRowsIndex = groupedRows.findIndex(
                    (rowArr) => rowArr.length > 0 && rowArr[0].scheduleData.supplier_branch_id === supplierBranchID
                );
                if (groupedRowsIndex === -1) groupedRows.push([row]);
                else groupedRows[groupedRowsIndex].push(row);
                return groupedRows;
            }, []);

            tableContent = [];
            rowsPersupplierBranches.forEach(
                (groupRows) => (tableContent = [...tableContent, ...generateRows(groupRows)])
            );
            // tableContent.push(this.generateTotalVisitsRow());
        }

        return tableContent;
    }
);

let FilterMenu = withStyles(useStyles)(({ menu, textFieldProps, classes }) => {
    return (
        <Grid item xs={12} sm={12} md={4}>
            <TextField
                select
                variant="outlined"
                fullWidth
                style={{ textAlign: "left" }}
                placeholder=""
                margin="dense"
                rowsMax={1}
                {...textFieldProps}
            >
                {menu.map((option) => (
                    <MenuItem key={option.value + Math.random()} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
    );
});
class SchedulesManagement extends React.Component {
    state = {
        choosedBranch: this.props.filters.supplierBranchesList[0].value,
        choosedSupermarket: this.props.filters.outletsList[0].value,
        choosedCity: this.props.filters.citiesList[0].value,

        tableHeads: [
            { id: "outlet", label: "Outlet Branch" },
            { id: "u", label: "Su" },
            { id: "m", label: "Mo" },
            { id: "t", label: "Tu" },
            { id: "w", label: "We" },
            { id: "h", label: "Th" },
            { id: "f", label: "Fr" },
            { id: "s", label: "Sa" },
            { id: "no_visits", label: "T. Visits" },
            { id: "duration", label: "Duration" },
            { id: "no_products", label: "Total Products" },
        ],
        numberOfColumns: 11,

        openProductsDialog: false,
        productsInfo: [],
        // menu = {id, prrimaryLabel, hidden, secondaryLabel: null}
        productsLeftList: { list: [], set: () => {}, title: "Choose Products" },
        productsRightList: { list: [], set: () => {}, title: "Chosen Products" },
        checkedProducts: { list: [], set: () => {} },

        openExtraDisplay: false,
        extraDisplays: [],
        selectedRowData: null,
    };

    init = async () => {
        //* upadate table heads bases on project's confirmation
        let tableHeads = [...this.state.tableHeads];
        let numberOfColumns = tableHeads.length;

        if (this.props.projectType === PROJECT_TYPES.SUPPLIER_INSOURCE) {
            tableHeads.push({ id: "merchandiser", label: "Merchandiser" });
            numberOfColumns += 1;
        } else {
            tableHeads.splice(10, 0, { id: "time_from", label: "Start Time" }, { id: "time_to", label: "End Time" });
            numberOfColumns += 2;
        }

        tableHeads.push({ id: "actions", label: "Products", colSpan: 3, rowSpan: 3 });
        numberOfColumns += 3;

        this.setState({ tableHeads: tableHeads, numberOfColumns: numberOfColumns });
    };

    updateTransferList = (newList, stateKey) => {
        const productsList = { ...this.state[stateKey] };
        productsList.list = [...newList];

        this.setState({
            [stateKey]: productsList,
        });
    };

    onTransferListFilter = (e, list, listStateKey) => {
        const value = e.target.value.toUpperCase();
        const state = this.state[listStateKey];

        list.forEach((item) => {
            item.hidden =
                item.primaryLabel.toUpperCase().indexOf(value) === -1 &&
                item.secondaryLabel.toUpperCase().indexOf(value) === -1;
        });

        state.list = [...list];
        this.setState({ [listStateKey]: state });
    };

    //left and right
    setupProductsList = (ProductsList, stateKey, listTitle = "") => {
        //setup Menu
        // list = {id, primaryLabel, hidden, secondaryLabel: null}
        const list = ProductsList.map((info) => ({
            id: info.data().product_id,
            primaryLabel: info.data().En_name,
            secondaryLabel: info.data().product_number,
            hidden: false,
            toBeAdded: false,
            toBeUpdated: false,
            toBeRemoved: false,
        }));
        const set = (newList) => {
            this.updateTransferList(newList, stateKey);
        };

        const title = listTitle;

        const onFilter = (e, list) => {
            this.onTransferListFilter(e, list, stateKey);
        };

        if (title) return { list, set, title, onFilter };

        return { list, set };
    };

    openProductsDialog = (rowData) => async () => {
        this.props.setLoading(true);
        try {
            const scheduleProducts = rowData.products;
            let productsIDs = rowData.linkedProducts.productsDocs.map((p) => p.id);
            let productsInfo = await Promise.all(productsIDs.map(getProduct));

            //setup left list
            // productsLeftList: { list: [], set: () => {}, title: "Choose Products" },
            const leftList = productsInfo.filter(
                (info) =>
                    scheduleProducts.findIndex((sp) => sp.doc.id === info.id && sp.data.number_of_shelves !== 0) === -1
            );
            const productsLeftList = this.setupProductsList(leftList, "productsLeftList", "Choices");

            //setup right list
            // productsRightList: { list: [], set: () => {}, title: "Chosen Products" },
            const rightList = productsInfo.filter(
                (info) =>
                    scheduleProducts.findIndex((sp) => sp.doc.id === info.id && sp.data.number_of_shelves !== 0) !== -1
            );
            const productsRightList = this.setupProductsList(rightList, "productsRightList", "Chosen");
            //setup checked list
            // checkedProducts: { list: [], set: () => {} },
            const checkedProducts = this.setupProductsList([], "checkedProducts");

            this.setState({
                openProductsDialog: true,
                selectedRowData: rowData,
                productsLeftList,
                productsRightList,
                checkedProducts,
                productsInfo,
            });
        } catch (error) {
            console.log(error);
        }
        this.props.setLoading(false);
    };

    closeProductDialog = () => {
        this.setState({ openProductsDialog: false, products: [], selectedRowData: null });
    };

    saveProducts = async () => {
        this.closeProductDialog();
        if (this.props.projectType !== PROJECT_TYPES.SUPPLIER_INSOURCE && this.props.isConfirmed) return;

        this.props.setLoading(true);
        try {
            let projectFullData = this.props.projectFullData;
            let rowData = this.state.selectedRowData;
            let scheduleProducts = rowData.products;
            let left = this.state.productsLeftList;
            let right = this.state.productsRightList;
            let productsInfo = this.state.productsInfo;

            //loop through right list to mark items toBeAdded as new document or just update and increment the counter to 1
            for (const item of right.list) {
                let scheduleProduct = scheduleProducts.find((p) => p.data.id === item.id);
                if (scheduleProduct) {
                    scheduleProduct.data.number_of_shelves = 1;
                    scheduleProduct.data.reference_id = scheduleProduct.data.reference_id || guid();
                    scheduleProduct.data.reference_image = scheduleProduct.data.reference_image || null;
                    scheduleProduct.toBeUpdated = true;
                } else {
                    let info = productsInfo.find((pInfo) => pInfo.id === item.id);
                    scheduleProduct = initNewProductDocument(item.id, info);
                    scheduleProduct.data.number_of_shelves = 1;
                    scheduleProduct.toBeAdded = true;

                    scheduleProducts.push(scheduleProduct);
                }
            }

            //loop through left list to mark items toBeRemoved or just skip
            for (const item of left.list) {
                let scheduleProduct = scheduleProducts.find((p) => p.data.id === item.id);
                if (!scheduleProduct) continue;
                if (scheduleProduct.data.extra_displays.length === 0) scheduleProduct.toBeRemoved = true;
                else {
                    scheduleProduct.data.number_of_shelves = 0;
                    scheduleProduct.toBeUpdated = true;
                }
            }

            //calculate duration
            rowData.scheduleData.minimum_duration = calculateDuration(scheduleProducts);
            rowData.scheduleData.duration = Math.max(
                rowData.scheduleData.minimum_duration,
                rowData.scheduleData.duration
            );
            //calculate end time
            for (const dayName in rowData.scheduleData.merchandising_days) {
                if (Object.hasOwnProperty.call(rowData.scheduleData.merchandising_days, dayName)) {
                    const day = rowData.scheduleData.merchandising_days[dayName];
                    const startTime = day.start_time;
                    const endTime = calculateEndTime(startTime, rowData.scheduleData.duration);
                    day.end_time = endTime;
                }
            }
            rowData.scheduleData.products = scheduleProducts.filter(
                (sp) => !sp.toBeRemoved && sp.data.number_of_shelves === 1
            ).length;
            rowData.scheduleData.number_of_products = rowData.scheduleData.products;
            rowData.scheduleData.has_products = scheduleProducts.length > 0;

            let supplierBranch = projectFullData.supplierBranches.find(
                (sb) => sb.data.branch_id === rowData.scheduleData.supplier_branch_id
            );
            let outletBranch = supplierBranch.outletBranches.find(
                (ob) => ob.data.branch_id === rowData.scheduleData.outlet_branch_id
            );
            let schedule = outletBranch.schedules.find((sch) => sch.data.id === rowData.scheduleData.id);
            if (!schedule) throw new Error("could not update the changes due to the lack of schedule data record.");

            schedule.data = cloneDeep(rowData.scheduleData);
            schedule.products = scheduleProducts;
            schedule.toBeUpdated = true;

            await this.props.syncProject(projectFullData);
            this.props.updateSchedulesRows();
        } catch (error) {
            console.log(error);
        }
        this.props.setLoading(false);
    };

    openExtraDisplay = (rowData) => async () => {
        this.props.setLoading(true);
        try {
            let productsIDs = rowData.linkedProducts.productsDocs.map((p) => p.id);
            // products info are documents of Product collection
            let productsInfo = await Promise.all(productsIDs.map(getProduct));

            // let contractsInfo = await getExtraDisplayContracts(rowData.scheduleData.supplier_id, rowData.scheduleData.outlet_branch_id);
            //all extra display contracts under the same supplier and outlet branch
            let contractsInfo = rowData.extraDisplayContracts;

            rowData.contractsInfo = await Promise.all(
                contractsInfo.map(async (contract) => {
                    let types = contract.data().extra_display_type;
                    let typesInfo = (await getEDRegularPrices(types)).map((type) => type.data());
                    return { contract: contract.data(), types: typesInfo };
                })
            );

            let products = rowData.products;
            let extraDisplays = [];

            //loop through schedule products to get extra display data and group them by display_id
            for (const product of products) {
                let selectedProductIndex = productsInfo.findIndex((pInfo) => pInfo.id === product.data.id);
                for (const extraDisplay of product.data.extra_displays) {
                    let selectedContractIndex = rowData.contractsInfo.findIndex(
                        (cInfo) => cInfo.contract.contract_id === extraDisplay.contract_id
                    );
                    let selectedTypeIndex = rowData.contractsInfo[selectedContractIndex].types.findIndex(
                        (tInfo) => tInfo.extra_display_price_id === extraDisplay.extra_display_type_id
                    );

                    let referenceID = extraDisplay.reference_id || null;
                    let referenceImage = extraDisplay.reference_image || null;
                    let displayID = extraDisplay.display_id || 1;

                    // find the product in the products list of extra display by display_id
                    let displayIndex = extraDisplays.findIndex((extraDisplay) => extraDisplay.displayID === displayID);
                    if (displayIndex === -1) {
                        extraDisplays.push({
                            displayID: displayID,
                            productsList: productsInfo,
                            contracts: rowData.contractsInfo,
                            selectedProducts: [{ selectedProductIndex, referenceID, referenceImage }],
                            selectedContract: selectedContractIndex,
                            selectedType: selectedTypeIndex,
                            disabled: true,
                        });
                    } else {
                        extraDisplays[displayIndex].selectedProducts.push({
                            selectedProductIndex,
                            referenceID,
                            referenceImage,
                        });
                    }

                    // extraDisplays.push({
                    //     productsList: productsInfo,
                    //     contracts: rowData.contractsInfo,
                    //     selectedProduct: selectedProductIndex,
                    //     selectedContract: selectedContractIndex,
                    //     selectedType: selectedTypeIndex,
                    //     referenceID: referenceID,
                    //     referenceImage: referenceImage,
                    //     disabled: true,
                    // });
                }
            }

            //sort extra display by displayID
            extraDisplays.sort((a, b) => a.displayID - b.displayID);
            this.setState({
                openExtraDisplay: true,
                selectedRowData: rowData,
                productsInfo,
                extraDisplays: extraDisplays,
                contracts: rowData.contractsInfo,
            });
        } catch (error) {
            console.log(error);
        }
        this.props.setLoading(false);
    };

    closeExtraDisplay = () => {
        this.setState({ openExtraDisplay: false, extraDisplays: [], selectedRowData: null });
    };

    // addExtraDisplayRow = () => {
    //     let rowData = this.state.selectedRowData;
    //     let extraDisplays = [...this.state.extraDisplays];
    //     let productsInfo = this.state.productsInfo;
    //     if (productsInfo.length === 0 || rowData.contractsInfo.length === 0) return;

    //     extraDisplays.push({
    //         productsList: productsInfo,
    //         contracts: rowData.contractsInfo,
    //         selectedProduct: 0,
    //         selectedContract: 0,
    //         selectedType: 0,
    //         referenceID: null,
    //         referenceImage: null,
    //         disabled: false,
    //     });

    //     this.setState({ extraDisplays: extraDisplays });
    // };

    saveExtraDisplay = async (newExtraDisplays) => {
        this.closeExtraDisplay();
        if (this.props.projectType !== PROJECT_TYPES.SUPPLIER_INSOURCE && this.props.isConfirmed) return;

        this.props.setLoading(true);
        try {
            let projectFullData = this.props.projectFullData;
            let rowData = this.state.selectedRowData;
            let productsIDs = rowData.linkedProducts.productsDocs.map((p) => p.id);
            let scheduleProducts = rowData.products;
            // let extraDisplaysRows = [...this.state.extraDisplays];
            let productsInfo = this.state.productsInfo;

            //loop through extra displays to get contract id and type id
            let productsExtraDisplays = {};
            for (const ED of newExtraDisplays) {
                for (const product of ED.selectedProducts) {
                    let productID = ED.productsList[product.selectedProductIndex].id;
                    let selectedContract = ED.contracts[ED.selectedContract];
                    let selectedType = selectedContract.types[ED.selectedType];

                    let referenceID = ED.referenceID || guid();
                    let referenceImage = ED.referenceImage || null;
                    let displayID = ED.displayID;

                    if (!productsExtraDisplays[productID]) {
                        productsExtraDisplays[productID] = [
                            {
                                display_id: displayID,
                                reference_id: referenceID,
                                reference_image: referenceImage,
                                contract_id: selectedContract.contract.contract_id,
                                extra_display_type_id: selectedType.extra_display_price_id,
                            },
                        ];
                    } else {
                        productsExtraDisplays[productID].push({
                            display_id: displayID,
                            reference_id: referenceID,
                            reference_image: referenceImage,
                            contract_id: selectedContract.contract.contract_id,
                            extra_display_type_id: selectedType.extra_display_price_id,
                        });
                    }
                }
            }

            for (const productID of productsIDs) {
                let scheduleProduct = scheduleProducts.find((schProd) => schProd.data.id === productID);
                let extraDisplays = productsExtraDisplays[productID];

                if (scheduleProduct) {
                    scheduleProduct.data.extra_displays = extraDisplays || [];

                    if (
                        scheduleProduct.data.number_of_shelves === 0 &&
                        scheduleProduct.data.extra_displays.length === 0
                    ) {
                        scheduleProduct.toBeRemoved = true;
                    } else {
                        scheduleProduct.toBeUpdated = true;
                    }
                } else if (!scheduleProduct && extraDisplays) {
                    let info = productsInfo.find((pInfo) => pInfo.id === productID);
                    scheduleProduct = initNewProductDocument(productID, info);
                    scheduleProduct.data.extra_displays = extraDisplays;

                    scheduleProduct.toBeAdded = true;
                    scheduleProducts.push(scheduleProduct);
                }
            }

            //calculate duration
            rowData.scheduleData.minimum_duration = calculateDuration(scheduleProducts);
            rowData.scheduleData.duration = rowData.scheduleData.minimum_duration;

            // rowData.scheduleData.duration = Math.max(
            //     rowData.scheduleData.minimum_duration,
            //     rowData.scheduleData.duration
            // );
            //calculate end time
            for (const dayName in rowData.scheduleData.merchandising_days) {
                if (Object.hasOwnProperty.call(rowData.scheduleData.merchandising_days, dayName)) {
                    const day = rowData.scheduleData.merchandising_days[dayName];
                    const startTime = day.start_time;
                    const endTime = calculateEndTime(startTime, rowData.scheduleData.duration);
                    day.end_time = endTime;
                }
            }

            //update the display contracts
            let extraDisplayContracts = [];

            for (const product of scheduleProducts) {
                if (product.data.extra_displays.length === 0) continue;
                for (const extraDisplaysItem of product.data.extra_displays) {
                    let contractIndex = extraDisplayContracts.findIndex(
                        (contract) => contract.contract_id === extraDisplaysItem.contract_id
                    );
                    if (contractIndex === -1) {
                        extraDisplayContracts.push({
                            contract_id: extraDisplaysItem.contract_id,
                            types: [
                                {
                                    display_ids: [extraDisplaysItem.display_id],
                                    extra_display_id: extraDisplaysItem.extra_display_type_id,
                                    num_of_products: 1,
                                    product_ids: [product.data.id],
                                },
                            ],
                        });
                    } else {
                        let types = extraDisplayContracts[contractIndex].types;
                        let typeIndex = types.findIndex(
                            (t) => t.extra_display_id === extraDisplaysItem.extra_display_type_id
                        );
                        if (typeIndex === -1) {
                            types.push({
                                display_ids: [extraDisplaysItem.display_id],
                                extra_display_id: extraDisplaysItem.extra_display_type_id,
                                num_of_products: 1,
                                product_ids: [product.data.id],
                            });
                        } else {
                            types[typeIndex].display_ids = [
                                ...new Set([...types[typeIndex].display_ids, extraDisplaysItem.display_id]),
                            ];
                            types[typeIndex].num_of_products += 1;
                            types[typeIndex].product_ids.push(product.data.id);
                        }
                        extraDisplayContracts[contractIndex].types = types;
                    }
                }
            }

            rowData.scheduleData.extra_display_contracts = extraDisplayContracts;

            rowData.scheduleData.products = scheduleProducts.filter(
                (sp) => !sp.toBeRemoved && sp.data.number_of_shelves === 1
            ).length;
            rowData.scheduleData.number_of_products = rowData.scheduleData.products;
            rowData.scheduleData.has_products = scheduleProducts.length > 0;

            let supplierBranch = projectFullData.supplierBranches.find(
                (sb) => sb.data.branch_id === rowData.scheduleData.supplier_branch_id
            );
            let outletBranch = supplierBranch.outletBranches.find(
                (ob) => ob.data.branch_id === rowData.scheduleData.outlet_branch_id
            );
            let schedule = outletBranch.schedules.find((sch) => sch.data.id === rowData.scheduleData.id);
            if (!schedule) throw new Error("could not update the changes due to the lack of schedule data record.");

            schedule.data = cloneDeep(rowData.scheduleData);
            schedule.products = scheduleProducts;
            schedule.toBeUpdated = true;

            await this.props.syncProject(projectFullData);
            this.props.updateSchedulesRows();
        } catch (error) {
            console.log(error);
        }
        this.props.setLoading(false);
    };

    // onProductSelectInExtraDisplay = (index, product) => {
    //     let extraDisplays = [...this.state.extraDisplays];
    //     let products = extraDisplays[index].productsList;
    //     let productIndex = products.findIndex((p) => p.id === product.id);
    //     extraDisplays[index].selectedProduct = productIndex;
    //     this.setState({ extraDisplays: extraDisplays });
    // };

    // onContractSelectinExtraDisplay = (index, contractInfo) => {
    //     let extraDisplays = [...this.state.extraDisplays];
    //     let contracts = extraDisplays[index].contracts;
    //     let contractIndex = contracts.findIndex((c) => c.contract.contract_id === contractInfo.contract.contract_id);
    //     extraDisplays[index].selectedContract = contractIndex;
    //     this.setState({ extraDisplays: extraDisplays });
    // };

    // onExtraDisplayTypeSelect = (index, exType) => {
    //     let extraDisplays = [...this.state.extraDisplays];
    //     let selectedContract = extraDisplays[index].selectedContract;
    //     let contracts = extraDisplays[index].contracts;

    //     let types = contracts[selectedContract].types;
    //     let typeIndex = types.findIndex((type) => type.extra_display_price_id === exType.extra_display_price_id);
    //     extraDisplays[index].selectedType = typeIndex;

    //     this.setState({ extraDisplays: extraDisplays });
    // };

    // onDeleteRowInExtraDisplay = (index) => {
    //     let extraDisplays = [...this.state.extraDisplays];
    //     extraDisplays.splice(index, 1);
    //     this.setState({ extraDisplays: extraDisplays });
    // };

    onBranchChange = (event) => {
        this.setState({
            choosedBranch: event.target.value,
            choosedSupermarket: "All Outlets",
            choosedCity: "All Cities",
        });
    };

    onDayClick = (rowData, dayName) => (event) => {
        if (this.props.isConfirmed && this.props.projectType !== PROJECT_TYPES.SUPPLIER_INSOURCE) return;
        //if the old value === the new value, exit
        if (rowData.scheduleData.merchandising_days[dayName].selected === (event.target.checked ? true : false)) return;

        //update the value and number of days
        rowData.scheduleData.merchandising_days[dayName].selected = event.target.checked ? true : false;
        let merchDays = rowData.scheduleData.merchandising_days;
        let numberOfDays = 0;
        for (const day in merchDays) {
            if (Object.hasOwnProperty.call(merchDays, day)) {
                numberOfDays = merchDays[day].selected === true ? numberOfDays + 1 : numberOfDays;
            }
        }
        rowData.scheduleData.number_of_days = numberOfDays;
        rowData.toBeUpdated = true;
        this.props.updateRowData(rowData);

        //if the rowdata has merchandiser id, update the route plan accordingly
        this.props.applyChangesOnRouteBySchedule(rowData.scheduleData);

        // this.calulateNumberOfVisitsForBranch(outletBranch, this.state.date_from, this.state.date_to);
        // this.setState({ branchesDetails: this.state.branchesDetails, formChanged: true }, () => {
        //     this.updateTotalPrice();
        //     this.updateTableContent(this.state.coverageRows);
        // });
    };

    onDurationChange = (rowData) => (event) => {
        if (this.props.isConfirmed && this.props.projectType !== PROJECT_TYPES.SUPPLIER_INSOURCE) return;

        // const minDuration = rowData.scheduleData.minimum_duration;
        let duration = Number(event.target.value);
        // duration = duration < minDuration ? minDuration : duration;

        //calculate end time
        for (const dayName in rowData.scheduleData.merchandising_days) {
            if (Object.hasOwnProperty.call(rowData.scheduleData.merchandising_days, dayName)) {
                const day = rowData.scheduleData.merchandising_days[dayName];
                const startTime = day.start_time;
                const endTime = calculateEndTime(startTime, duration);
                day.end_time = endTime;
            }
        }

        //update data
        rowData.scheduleData.duration = duration;

        this.props.updateRowData(rowData);
        // this.updateTableContent(this.state.coverageRows);
        // this.setState({ branchesDetails: this.state.branchesDetails, formChanged: true });
    };

    onStartTimeChange = (rowData) => (event) => {
        if (this.props.isConfirmed && this.props.projectType !== PROJECT_TYPES.SUPPLIER_INSOURCE) return;

        const startTime = event;
        const duration = rowData.scheduleData.duration;
        //assign start time and calculate end time
        for (const dayName in rowData.scheduleData.merchandising_days) {
            if (Object.hasOwnProperty.call(rowData.scheduleData.merchandising_days, dayName)) {
                const day = rowData.scheduleData.merchandising_days[dayName];
                const endTime = calculateEndTime(startTime, duration);
                day.start_time = startTime;
                day.end_time = endTime;
            }
        }

        this.props.updateRowData(rowData);
    };

    updateProjectData = (schedulesRows) => {
        let projectFullData = this.props.projectFullData;
        try {
            for (const row of schedulesRows) {
                let supplierBranch = projectFullData.supplierBranches.find(
                    (sb) => sb.data.branch_id === row.scheduleData.supplier_branch_id
                );
                let outletBranch = supplierBranch.outletBranches.find(
                    (ob) => ob.data.branch_id === row.scheduleData.outlet_branch_id
                );
                let schedule = outletBranch.schedules.find((sch) => sch.data.id === row.scheduleData.id);
                if (!schedule) throw new Error("could not update the changes due to the lack of schedule data record.");

                schedule.data = { ...row.scheduleData };
                schedule.toBeUpdated = true;
            }

            this.props.refreshProjectState(projectFullData);
        } catch (error) {
            throw error;
        }
    };

    componentDidMount() {
        this.init();
    }
    render() {
        const classes = this.props.classes;
        const { supplierBranchesList, citiesList, outletsList } = this.props.filters;

        return (
            <Grid container className={classes.root}>
                <Card className={classes.contentCard}>
                    <CardHeader
                        className={classes.cardHeader}
                        avatar={<p style={{ fontWeight: "450", textTransform: "none" }}>{"More Details"}</p>}
                        // title={"More Details"}
                    />
                    <CardBody className={classes.cardBody} align="center" justify="center">
                        <Grid container direction="row" alignItems="center" spacing={1}>
                            <FilterMenu
                                menu={supplierBranchesList}
                                textFieldProps={{
                                    id: "choosedBranch",
                                    label: "Branch",
                                    value: this.state.choosedBranch,
                                    // onChange: this.onBranchChange,
                                    onChange: (event) => this.setState({ choosedBranch: event.target.value }),
                                }}
                            />

                            <FilterMenu
                                menu={outletsList}
                                textFieldProps={{
                                    id: "choosedSupermarket",
                                    label: "Outlet",
                                    value: this.state.choosedSupermarket,
                                    onChange: (event) => this.setState({ choosedSupermarket: event.target.value }),
                                }}
                            />

                            <FilterMenu
                                menu={citiesList}
                                textFieldProps={{
                                    id: "choosedCity",
                                    label: "City",
                                    value: this.state.choosedCity,
                                    onChange: (event) => this.setState({ choosedCity: event.target.value }),
                                }}
                            />
                        </Grid>

                        <Paper className={classes.tableContainer}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow style={{ backgroundColor: COLORS.primary }}>
                                        {this.state.tableHeads.map((head) => (
                                            <TableCell
                                                className={classes.tableCell}
                                                key={head.id}
                                                style={head.style}
                                                colSpan={head.colSpan ? head.colSpan : 1}
                                                rowSpan={head.rowSpan ? head.rowSpan : 1}
                                            >
                                                {head.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <SchedulesRows
                                        filters={{city: this.state.choosedCity, branch: this.state.choosedBranch, outlet: this.state.choosedSupermarket}}
                                        selfService={this.props.selfService}
                                        projectType={this.props.projectType}
                                        isConfirmed={this.props.isConfirmed}
                                        openRoutePlan={this.props.openRoutePlan}
                                        openProductsDialog={this.openProductsDialog}
                                        openExtraDisplay={this.openExtraDisplay}
                                        rows={this.props.schedulesRows}
                                        numberOfColumns={this.state.numberOfColumns}
                                        merchandisers={this.props.merchandisers}
                                        onDayClick={this.onDayClick}
                                        onDurationChange={this.onDurationChange}
                                        onStartTimeChange={this.onStartTimeChange}
                                    />
                                    <TotalVisitsRow
                                        totalVisits={this.props.totalVisits}
                                        numberOfColumns={this.state.numberOfColumns}
                                    />
                                </TableBody>

                                {/* <TableBody>{tableContent}</TableBody> */}
                            </Table>
                        </Paper>
                    </CardBody>
                </Card>
                {this.state.openExtraDisplay ? (
                    <ExtraDisplayDialog
                        title="Extra Displays"
                        submitText={
                            this.props.projectType !== PROJECT_TYPES.SUPPLIER_INSOURCE && this.props.isConfirmed
                                ? "Close"
                                : "Save"
                        }
                        open={this.state.openExtraDisplay}
                        handleAction={this.saveExtraDisplay}
                        handleClose={this.closeExtraDisplay}
                        extraDisplaysSnapshot={this.state.extraDisplays}
                        contracts={this.state.contracts}
                        products={this.state.productsInfo}
                        // listItems={this.state.extraDisplays}
                        rowData={this.state.selectedRowData}
                        // addRow={this.addExtraDisplayRow}
                        // onProductSelect={this.onProductSelectInExtraDisplay}
                        // onDisplayIDChange={this.onDisplayIDChange}
                        // onContractSelect={this.onContractSelectinExtraDisplay}
                        // onExtraDisplayTypeSelect={this.onExtraDisplayTypeSelect}
                        // onDeleteRow={this.onDeleteRowInExtraDisplay}
                        disabled={this.props.projectType !== PROJECT_TYPES.SUPPLIER_INSOURCE && this.props.isConfirmed}
                    />
                ) : null}

                {this.state.openProductsDialog ? (
                    <ProductsDialog
                        handleClose={this.closeProductDialog}
                        open={this.state.openProductsDialog}
                        save={this.saveProducts}
                        left={this.state.productsLeftList}
                        right={this.state.productsRightList}
                        checked={this.state.checkedProducts}
                        disabled={this.props.projectType !== PROJECT_TYPES.SUPPLIER_INSOURCE && this.props.isConfirmed}
                    />
                ) : null}
            </Grid>
        );
    }
}

SchedulesManagement.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(useStyles)(SchedulesManagement);
