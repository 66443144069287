import { useState } from "react";

const useSelectProductsDialog = (handleSave) => {
    const [isOpened, setIsOpened] = useState(false);
    const [leftList, setLeftList] = useState({ a: 1 });
    const [rightList, setRightList] = useState({});
    const [selected, setSelected] = useState({});
    // return { open, close, isOpened, save, leftList, rightList, selected };

    const updateTransferList = (newList, side) => {
        let productsList = {};
        if (side === "left") {
            setLeftList((old) => ({ ...old, list: [...newList] }));
        } else if (side === "right") {
            setRightList((old) => ({ ...old, list: [...newList] }));
        } else {
            setSelected((old) => ({ ...old, list: [...newList] }));
        }
    };

    const onTransferListFilter = (e, list, side) => {
        const value = e.target.value.toUpperCase();
        const state = side === "left" ? { ...leftList } : { ...rightList };

        list.forEach((item) => {
            item.hidden =
                item.primaryLabel.toUpperCase().indexOf(value) === -1 &&
                item.secondaryLabel.toUpperCase().indexOf(value) === -1;
        });

        state.list = [...list];
        if (side === "left") {
            setLeftList(state);
        } else {
            setRightList(state);
        }
    };

    //left and right
    const setupProductsList = (ProductsList, side, listTitle = "") => {
        //setup Menu
        // list = {id, primaryLabel, hidden, secondaryLabel: null}
        const list = ProductsList.map((product) => ({
            id: product.data().product_id,
            primaryLabel: product.data().En_name,
            secondaryLabel: product.data().product_number,
            hidden: false,
            toBeAdded: false,
            toBeUpdated: false,
            toBeRemoved: false,
        }));
        const set = (newList) => {
            updateTransferList(newList, side);
        };

        const title = listTitle;

        const onFilter = (e, list) => {
            onTransferListFilter(e, list, side);
        };

        if (title) return { list, set, title, onFilter };

        return { list, set };
    };

    const open = (products, selectedProducts) => {
        try {
            const selectedProductIndices = selectedProducts.map((p) => p.selectedProductIndex);
            console.log(selectedProductIndices);
            //setup left list
            // productsLeftList: { list: [], set: () => {}, title: "Choose Products" },
            const newLeftList = products.filter((product, index) => !selectedProductIndices.includes(index));
            const productsLeftList = setupProductsList(newLeftList, "left", "Choices");

            //setup right list
            // productsRightList: { list: [], set: () => {}, title: "Chosen Products" },
            const newRightList = products.filter((product, index) => selectedProductIndices.includes(index));
            const productsRightList = setupProductsList(newRightList, "right", "Chosen");
            //setup checked list
            // checkedProducts: { list: [], set: () => {} },
            const checkedProducts = setupProductsList([], "selected");

            setLeftList(productsLeftList);
            setRightList(productsRightList);
            setSelected(checkedProducts);
            setIsOpened(true);
            // this.setState({
            //     openProductsDialog: true,
            //     selectedRowData: rowData,
            //     productsLeftList,
            //     productsRightList,
            //     checkedProducts,
            //     products,
            // });
        } catch (error) {
            console.log(error);
        }
    };

    const close = () => {
        setIsOpened(false);
    };

    const save = () => {
        handleSave(leftList.list, rightList.list);
        close();
    };

    return { open, close, isOpened, save, leftList, rightList, selected };
};

export default useSelectProductsDialog;
