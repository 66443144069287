import React from "react";

//related to meterial ui package
import styles from "./styles";
import { CircularLoader, Header, Seperator } from "../../core-ui/custom";
import { ELECTRONICS_ID, FORM_SCHEMA } from "./schema";
import { Form, Formik } from "formik";
import { Card, Grid, Typography } from "@material-ui/core";
import { Autocomplete, DateTimePicker, ImageFileButton, SubmitButton, TextField } from "../../core-ui/forms-ui";
import { Asterisk } from "../../components";

import useAddProduct from "../../hooks/use-add-product";
import { PACKING_UNITS } from "./schema";
import { DISPLAY_UNITS } from "./schema";
import { SIZE_UNITS } from "./schema";
import { PERIOD_UNITS } from "./schema";

const AddProduct = () => {
    const classes = styles();
    const {
        initValues,
        handleSubmit,
        initializing,
        saving,
        categoriesList,
        subcategoriesList,
        subsubcategoriesList,
        brandsList,
    } = useAddProduct();

    const handleCategoryChange = (selectedCategory, formik) => {
        let subcategory = subcategoriesList.find((sc) => sc.data.category_id === selectedCategory.value);
        let subsubcategory = subsubcategoriesList.find((ssc) => ssc.data.sub_category_id === subcategory.value);

        formik.getFieldHelpers("product_category.sub_category_id").setValue(subcategory);
        formik.getFieldHelpers("product_category.sub_sub_category_id").setValue(subsubcategory);
    };
    const handleSubcategoryChange = (selectedSubcategory, formik) => {
        let subsubcategory = subsubcategoriesList.find((ssc) => ssc.data.sub_category_id === selectedSubcategory.value);
        formik.getFieldHelpers("product_category.sub_sub_category_id").setValue(subsubcategory);
    };

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.pageHeader}>Add Product</Header>
            {initializing && <CircularLoader className={classes.circularProgress} />}
            <Formik
                initialValues={initValues}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={FORM_SCHEMA}
            >
                {(formik) => {
                    const currentCategory = formik.getFieldMeta("product_category.category_id").value;

                    if (initializing) return null;
                    return (
                        <Form>
                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Product Information
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="En_name"
                                                label={<Asterisk>Name (English)</Asterisk>}
                                                className={classes.input}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="Ar_name"
                                                label={<Asterisk>Name (Arabic)</Asterisk>}
                                                className={`${classes.input} ${classes.inputRtl}`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                name="brand_id"
                                                label={"Brand"}
                                                options={brandsList}
                                                className={classes.input}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                loading={initializing}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="product_number"
                                                label={<Asterisk>Product Number</Asterisk>}
                                                className={classes.input}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="barcode"
                                                label={<Asterisk>Barcode</Asterisk>}
                                                className={classes.input}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="carton_barcode"
                                                label={<Asterisk>Carton Barcode</Asterisk>}
                                                className={classes.input}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                name="product_description"
                                                label={"Description"}
                                                className={classes.input}
                                                multiline
                                                minRows={4}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>

                            <div className={classes.cardContainer}>
                                <Grid container spacing={2} className={classes.attachmentsZone}>
                                    <span>Product Attachments</span>
                                    <Grid item xs={12} sm={4}>
                                        <ImageFileButton name="product_image.product_image1" label="Image 1" />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ImageFileButton name="product_image.product_image2" label="Image 2" />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <ImageFileButton name="product_image.product_image3" label="Image 3" />
                                    </Grid>
                                </Grid>
                            </div>

                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Product Categories
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete
                                                name="product_category.category_id"
                                                label={<Asterisk>Category</Asterisk>}
                                                options={categoriesList}
                                                className={classes.input}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                loading={initializing}
                                                disableClearable
                                                onChange={(selected) => handleCategoryChange(selected, formik)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete
                                                name="product_category.sub_category_id"
                                                label={<Asterisk>Subcategory</Asterisk>}
                                                options={subcategoriesList.filter(
                                                    (sc) =>
                                                        sc.data.category_id ===
                                                        formik.getFieldMeta("product_category.category_id").value?.value
                                                )}
                                                className={classes.input}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                loading={initializing}
                                                disableClearable
                                                onChange={(selected) => handleSubcategoryChange(selected, formik)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete
                                                name="product_category.sub_sub_category_id"
                                                label={<Asterisk>Sub Subcategory</Asterisk>}
                                                options={subsubcategoriesList.filter(
                                                    (sc) =>
                                                        sc.data.sub_category_id ===
                                                        formik.getFieldMeta("product_category.sub_category_id").value
                                                            ?.value
                                                )}
                                                className={classes.input}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                loading={initializing}
                                                disableClearable
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>

                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Packing Details
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete
                                                name="packing.packing"
                                                label={<Asterisk>Packing</Asterisk>}
                                                options={PACKING_UNITS}
                                                className={classes.input}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                loading={initializing}
                                                disableClearable
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete
                                                name="display_unit"
                                                label={<Asterisk>Display Unit</Asterisk>}
                                                options={DISPLAY_UNITS}
                                                className={classes.input}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                loading={initializing}
                                                disableClearable
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                name="weight"
                                                label={<Asterisk>Packing Physical Weight</Asterisk>}
                                                className={classes.input}
                                                InputProps={{
                                                    endAdornment: <span className={classes.weightUnit}>kg</span>,
                                                }}
                                                type="number"
                                            />
                                        </Grid>
                                        <Seperator />
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                name="packing.packing_unit"
                                                disabled
                                                label={<Asterisk>Packing Units</Asterisk>}
                                                className={classes.input}
                                                type="number"
                                            />
                                        </Grid>
                                        <span>⨉</span>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                name="outer_unit"
                                                label={<Asterisk>Outer Units</Asterisk>}
                                                className={classes.input}
                                                type="number"
                                            />
                                        </Grid>
                                        <span>⨉</span>
                                        <Grid item xs={12} sm={2}>
                                            <TextField
                                                name="packing.no_units"
                                                label={<Asterisk>Pieces</Asterisk>}
                                                className={classes.input}
                                                type="number"
                                            />
                                        </Grid>
                                        <span>⨉</span>
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name="packing.size"
                                                label={<Asterisk>Mass/Volume</Asterisk>}
                                                className={classes.input}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Autocomplete
                                                name="packing.size_unit"
                                                // label={<Asterisk>Display Unit</Asterisk>}
                                                options={SIZE_UNITS}
                                                className={classes.input}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                loading={initializing}
                                                disableClearable
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>

                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Sales-Related Information
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="shelf_price"
                                                label={<Asterisk>Shelf Price</Asterisk>}
                                                className={classes.input}
                                                InputProps={{
                                                    endAdornment: <span className={classes.weightUnit}>SR</span>,
                                                }}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="minimum_quantity_alert"
                                                label={<Asterisk>Minimum Quantity To Alert</Asterisk>}
                                                className={classes.input}
                                                InputProps={{
                                                    endAdornment: <span className={classes.weightUnit}>Units</span>,
                                                }}
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                name="product_recommendations"
                                                label={"Recommendations"}
                                                className={classes.input}
                                                multiline
                                                minRows={4}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>

                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Additional Information
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3}>
                                        {currentCategory.value !== ELECTRONICS_ID && (
                                            <>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        name="shelf_life"
                                                        label={<Asterisk>Shelf Life</Asterisk>}
                                                        className={classes.input}
                                                        type="number"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Autocomplete
                                                        name="shelf_life_unit"
                                                        // label={<Asterisk></Asterisk>}
                                                        options={PERIOD_UNITS}
                                                        className={classes.input}
                                                        classes={{
                                                            option: classes.option,
                                                        }}
                                                        loading={initializing}
                                                        disableClearable
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        name="product_recall_period"
                                                        label={<Asterisk>Recall Period</Asterisk>}
                                                        className={classes.input}
                                                        type="number"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Autocomplete
                                                        name="product_recall_period_unit"
                                                        // label={<Asterisk></Asterisk>}
                                                        options={PERIOD_UNITS}
                                                        className={classes.input}
                                                        classes={{
                                                            option: classes.option,
                                                        }}
                                                        loading={initializing}
                                                        disableClearable
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <TextField
                                                        name="expiration_period"
                                                        label={<Asterisk>Expiry Pullout period</Asterisk>}
                                                        className={classes.input}
                                                        type="number"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={2}>
                                                    <Autocomplete
                                                        name="expiration_period_unit"
                                                        // label={<Asterisk></Asterisk>}
                                                        options={PERIOD_UNITS}
                                                        className={classes.input}
                                                        classes={{
                                                            option: classes.option,
                                                        }}
                                                        loading={initializing}
                                                        disableClearable
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                        {currentCategory.value === ELECTRONICS_ID && (
                                            <>
                                                <Grid item xs={12} sm={6}>
                                                    <DateTimePicker
                                                        name="manufacture_date"
                                                        label={<Asterisk>Manufacture Date</Asterisk>}
                                                        className={classes.input}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        name="manufacture_number"
                                                        label={<Asterisk>Manufacture Number</Asterisk>}
                                                        className={classes.input}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        name="model_number"
                                                        label={<Asterisk>Model Number</Asterisk>}
                                                        className={classes.input}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>
                                </Card>
                            </div>
                            <div className={classes.cardContainer}>
                                <SubmitButton
                                    type="submit"
                                    className={classes.submitBtn}
                                    loadingClassName={classes.circularProgress}
                                    submitting={saving}
                                >
                                    Create
                                </SubmitButton>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </section>
    );
};

export default AddProduct;
