import { TASKS_STATUS, DATE_TO_WEEKDAY_SHORT, PROJECT_TYPES } from "../../constants/constants-lagacy";
import { getTomorrow } from "../../helpers/date-utils";
import { generateNewId } from "../../helpers/firestore-utils";
import { getScheduleByTask, validateProjectPeriod } from "./Project";
import { firestore as db, functions } from "../firebase";
import { isDate, isObject } from "lodash/fp";
import { query } from "./helpers";
import { PLATFORM, TASK_TYPES } from "../../constants/global";
import { COLLECTIONS } from "./constants";
import { generateNewDocID } from "../../helpers/firebase-helpers";

const TASK_COLLECTION = "Task";
// const TASK_PRODUCTS_COLLECTION = "Task_Product";

const INITIAL_TASK_VALUE = {
    cost: {
        total: 0,
        general_merchandising: 0,
        task_per_hour: 0,
        task_per_minute: 0,
        warehouse: 0,
        extra_display: 0,
        handover: 0,
        bonus: 0,
    },
    selling: {
        total: 0,
        general_merchandising: 0,
        task_per_hour: 0,
        task_per_minute: 0,
        warehouse: 0,
        extra_display: 0,
        handover: 0,
        bonus: 0,
    },
    bonus: 0,
    totalBonus: 0,
    coefficient: 1,
    handover: 0,
};

const INITIAL_TASK_DATA = {
    platform: PLATFORM,

    options: {
        near_expiration: {
            active: true,
        },
        warehouse: {
            active: true,
        },
        quantity: {
            active: true,
        },
        availability: {
            active: false,
        },
        inventory: {
            active: false,
        },
        shelf_price: {
            active: false,
        },
    },

    task_id: null,
    supplier_branch_id: null,
    outlet_id: null,
    outlet_branch_id: null,
    schedule_id: null,
    project_id: null,
    supplier_id: null,
    mp_id: null,
    city_id: null,
    route_id: null,

    merch_gender: "both",
    merch_class_level: 1,

    confirmation: null,

    task_price: 0,
    task_value: INITIAL_TASK_VALUE.cost.total,
    coefficient: INITIAL_TASK_VALUE.coefficient,
    cost: { ...INITIAL_TASK_VALUE.cost },
    selling: { ...INITIAL_TASK_VALUE.selling },

    uid: null,
    supervisor_id: null,

    date_time_from: new Date(),
    date_time_to: new Date(),
    duration: 0,

    inhouse_service: false,
    // inhouse_barcode: `${projectID}-${lastID}`,
    inhouse_barcode: null,
    inhouse_preparation: false,
    inhouse_received: false,

    order: -1,
    dailyOrder: -1,
    type: TASK_TYPES.SUPPLIER_INSOURCE,
    only_header: true,
    modified_properties: [],

    started_at: null,
    started_in: null,
    started_by: null,

    finished_at: null,
    finished_in: null,
    finished_by: null,

    warehouse_started_at: null,
    warehouse_started_by: null,

    warehouse_ended_at: null,
    warehouse_ended_by: null,

    received_at: null,
    received_by: null,

    remarks: null,
    closing_remarks: null,

    pauses: [],

    state: TASKS_STATUS.OPEN,
};

export let getTask = async (id) => {
    try {
        const task = await db.collection("Task").doc(id).get();
        return task;
    } catch (error) {
        throw error;
    }
};

export const getTasksByID = async (ids) => {
    try {
        const tasks = await Promise.all(ids.map((id) => getTask(id)));
        return tasks;
    } catch (error) {
        throw error;
    }
};

export let getMerchandiserTasksByDate = async (merchandiserID, date) => {
    try {
        let dateStart = new Date(date);
        dateStart.setHours(0, 0, 0, 0);
        let dateEnd = new Date(date);
        dateEnd.setHours(23, 59, 59, 59);

        const tasks = (
            await db
                .collection("Task")
                .where("uid", "==", merchandiserID)
                .where("only_header", "==", false)
                .where("date_time_from", ">=", dateStart)
                .where("date_time_from", "<=", dateEnd)
                .get()
        ).docs;

        return tasks;
    } catch (error) {
        throw error;
    }
};

export let getMerchandiserTaskHeadersByDate = async (merchandiserID, date) => {
    try {
        let dateStart = new Date(date);
        dateStart.setHours(0, 0, 0, 0);
        let dateEnd = new Date(date);
        dateEnd.setHours(23, 59, 59, 59);

        let taskHeaders = (
            await db
                .collection("Task")
                .where("only_header", "==", true)
                .where("date_time_from", ">=", dateStart)
                .where("date_time_from", "<=", dateEnd)
                .get()
        ).docs.map((task) => task.data());

        taskHeaders = await Promise.all(
            taskHeaders.map(async (task) => {
                if (task.uid !== null) return task;

                const schedule = (await getScheduleByTask(task)).data();
                if (!schedule) {
                    throw new Error(`Could not find schedule for the task ID: ${task.id}`);
                }

                const modifiedProps = task.modified_properties ?? [];
                task.uid = modifiedProps.indexOf("uid") !== -1 ? task.uid : schedule.merchandiser_id;
                task.state =
                    modifiedProps.indexOf("state") !== -1
                        ? task.state
                        : task.uid
                        ? TASKS_STATUS.BOOKED
                        : TASKS_STATUS.OPEN;
                task.duration = schedule.duration;

                return task;
            })
        );

        return taskHeaders.filter((task) => task.uid === merchandiserID);
    } catch (error) {
        throw error;
    }
};

let getProjectTasks = async (projectID, start, end) => {
    try {
        const tasks = (
            await db
                .collection(TASK_COLLECTION)
                .where("project_id", "==", projectID)
                .where("date_time_from", ">=", start)
                .where("date_time_from", "<=", end)
                .get()
        ).docs;

        return tasks;
    } catch (error) {
        throw error;
    }
};

const processTaskHeadersByOutletBranch = async (date, outletBranch, project, oldTasks) => {
    const WEEKDAYS = DATE_TO_WEEKDAY_SHORT;
    const dayName = WEEKDAYS[date.getDay()];
    const projectID = project.data.project_id;
    const supplierID = project.data.supplier_id;
    const mpID = project.data.mp_id;
    const taskType = defineTaskType(project.data.project_type);
    let tasks = [];

    const taskValue = {
        cost: {
            total: 0,
            general_merchandising: 0,
            task_per_hour: 0,
            task_per_minute: 0,
            warehouse: 0,
            extra_display: 0,
            handover: 0,
            bonus: 0,
        },
        selling: {
            total: 0,
            general_merchandising: 0,
            task_per_hour: 0,
            task_per_minute: 0,
            warehouse: 0,
            extra_display: 0,
            handover: 0,
            bonus: 0,
        },
        bonus: 0,
        totalBonus: 0,
        coefficient: 1,
        handover: 0,
    };
    const outletID = outletBranch.data.outlet_id;
    const cityID = outletBranch.data.city_id;
    const inhouse_service = outletBranch.info.data().inhouse_service;

    for (const schedule of outletBranch.schedules) {
        const { supplier_branch_id, outlet_branch_id, id: schedule_id, merchandising_days } = schedule.data;
        //if the day is selected and task does not exist, create it
        if (merchandising_days[dayName].selected) {
            const hasProducts = schedule.products.length > 0;

            //find task if any
            const task = oldTasks.find((doc) => {
                const data = doc.data();
                return (
                    data.project_id === projectID &&
                    data.supplier_branch_id === Number(supplier_branch_id) &&
                    data.outlet_branch_id === Number(outlet_branch_id) &&
                    data.schedule_id === Number(schedule_id) &&
                    data.date_time_from.toDate().getTime() === date.getTime()
                );
            });

            if (!hasProducts) {
                if (!task) continue;

                task.toBeRemoved = true;
                continue;
            } else if (task) continue;

            tasks.push({
                platform: PLATFORM,

                options: {
                    near_expiration: {
                        active: true,
                    },
                    warehouse: {
                        active: true,
                    },
                    quantity: {
                        active: true,
                    },
                    availability: {
                        active: false,
                    },
                    inventory: {
                        active: false,
                    },
                },

                // task_id: Number(lastID),
                supplier_branch_id: Number(supplier_branch_id),
                outlet_id: Number(outletID),
                outlet_branch_id: Number(outlet_branch_id),
                schedule_id: Number(schedule_id),
                project_id: projectID,
                supplier_id: Number(supplierID),
                mp_id: mpID || null,
                city_id: Number(cityID),
                route_id: null,

                merch_gender: project.data.merch_gender,
                merch_class_level: 1,

                confirmation: null,

                task_price: 0,
                task_value: taskValue.cost.total,
                coefficient: taskValue.coefficient,
                cost: { ...taskValue.cost },
                selling: { ...taskValue.selling },

                uid: null,

                date_time_from: new Date(date),
                date_time_to: new Date(date),
                duration: 0,

                inhouse_service: inhouse_service,
                // inhouse_barcode: `${projectID}-${lastID}`,
                inhouse_preparation: false,
                inhouse_received: false,

                order: -1,
                dailyOrder: -1,
                type: taskType,
                only_header: true,
                modified_properties: [],

                started_at: null,
                started_in: null,
                finished_at: null,
                finished_in: null,
                warehouse_started_at: null,
                warehouse_ended_at: null,
                received_at: null,

                remarks: null,

                state: TASKS_STATUS.OPEN,
            });
        } else {
            //find task if any; in order to remove it
            const task = oldTasks.find((doc) => {
                const data = doc.data();
                return (
                    data.project_id === projectID &&
                    data.supplier_branch_id === Number(supplier_branch_id) &&
                    data.outlet_branch_id === Number(outlet_branch_id) &&
                    data.schedule_id === Number(schedule_id) &&
                    data.date_time_from.toDate().getTime() === date.getTime()
                );
            });
            if (task) task.toBeRemoved = true;
        }
    }

    return tasks;
};

const processTaskHeadersBySupplierBranch = async (date, supplierBranch, project, oldTasks) => {
    let tasks = await Promise.all(
        supplierBranch.outletBranches.map(async (ob) => processTaskHeadersByOutletBranch(date, ob, project, oldTasks))
    );

    return tasks;
};

const processTaskHeadersByDate = async (date, project, oldTasks) => {
    console.log(date);
    let tasks = await Promise.all(
        project.supplierBranches.map(async (sb) => processTaskHeadersBySupplierBranch(date, sb, project, oldTasks))
    );
    return tasks;
};

export let generateTaskHeadersFromProject = async (project) => {
    try {
        if (!project.data.isConfirmed) throw new Error("This Project is not yet confirmed");
        if (project.data.project_type !== PROJECT_TYPES.SUPPLIER_INSOURCE)
            throw new Error("This project must be of type 'insource'.");
        // const WEEKDAYS = DATE_TO_WEEKDAY_SHORT;
        const startDate = project.data.date_from;
        const endDate = project.data.date_to;
        const projectID = project.data.project_id;
        // const supplierID = project.data.supplier_id;
        // const mpID = project.data.mp_id;
        const taskType = defineTaskType(project.data.project_type);

        if (taskType === "unknown") throw new Error("Could define Task type from Unknown project Type.");

        if (!validateProjectPeriod(startDate, endDate, project.data.isConfirmed))
            throw new Error("Invalid Project Period. The end date must be at least tomorrow.");

        //remove all tasks after endDate
        await removeAllTaskAfterDate(projectID, endDate);

        //remove all tasks with no schedule
        await removeNoScheduleTasks(project);
        console.log("removeNoScheduleTasks");

        //get all project tasks during the project period
        const oldTasks = await getProjectTasks(projectID, getTomorrow(), endDate);
        console.log("getProjectTasks");
        // const oldTasks = await getProjectTasks(projectID, getToday(), endDate);
        let newTasks = [];
        let lastID = Number(await generateNewId(TASK_COLLECTION, "task_id"));

        //start generation from tomorrow if it falls during project period
        //but first store all dates in an array for later use in parallel loop
        let dates = [];
        for (let currDate = getTomorrow(); currDate <= endDate; currDate.setDate(currDate.getDate() + 1)) {
            if (currDate < startDate) continue;
            dates.push(new Date(currDate));
        }

        // async loop the dates array
        newTasks = await Promise.all(dates.map(async (date) => processTaskHeadersByDate(date, project, oldTasks)));

        //flatten the new tasks and set task_id and inhouse_barcode
        newTasks = newTasks.reduce((acc, curr) => acc.concat(curr), []);
        newTasks.forEach((task) => {
            task.task_id = Number(lastID);
            task.inhouse_barcode = `${projectID}-${lastID}`;
            lastID++;
        });

        // for (let currDate = getTomorrow(); currDate <= endDate; currDate.setDate(currDate.getDate() + 1)) {
        //     if (currDate < startDate) continue;
        //     const dayName = WEEKDAYS[currDate.getDay()];
        //     for (const supplierBranch of project.supplierBranches) {
        //         console.log("supplierBranch");
        //         for (const outletBranch of supplierBranch.outletBranches) {
        //             console.log("outletBranch");
        //             const outletID = outletBranch.data.outlet_id;
        //             const cityID = outletBranch.data.city_id;
        //             const inhouse_service = outletBranch.info.data().inhouse_service;

        //             for (const schedule of outletBranch.schedules) {
        //                 console.log("schedule");
        //                 const {
        //                     supplier_branch_id,
        //                     outlet_branch_id,
        //                     id: schedule_id,
        //                     merchandising_days,
        //                 } = schedule.data;
        //                 //if the day is selected and task does not exist, create it
        //                 if (merchandising_days[dayName].selected) {
        //                     const hasProducts = schedule.products.length > 0;

        //                     //find task if any
        //                     const task = oldTasks.find((doc) => {
        //                         const data = doc.data();
        //                         return (
        //                             data.project_id === projectID &&
        //                             data.supplier_branch_id === Number(supplier_branch_id) &&
        //                             data.outlet_branch_id === Number(outlet_branch_id) &&
        //                             data.schedule_id === Number(schedule_id) &&
        //                             data.date_time_from.toDate().getTime() === currDate.getTime()
        //                         );
        //                     });

        //                     if (!hasProducts) {
        //                         if (!task) continue;

        //                         task.toBeRemoved = true;
        //                         continue;
        //                     } else if (task) continue;

        //                     newTasks.push({
        //                         platform: PLATFORM,

        //                         options: {
        //                             near_expiration: {
        //                                 active: true,
        //                             },
        //                             warehouse: {
        //                                 active: true,
        //                             },
        //                             quantity: {
        //                                 active: true,
        //                             },
        //                             availability: {
        //                                 active: false,
        //                             },
        //                             inventory: {
        //                                 active: false,
        //                             },
        //                         },

        //                         task_id: Number(lastID),
        //                         supplier_branch_id: Number(supplier_branch_id),
        //                         outlet_id: Number(outletID),
        //                         outlet_branch_id: Number(outlet_branch_id),
        //                         schedule_id: Number(schedule_id),
        //                         project_id: projectID,
        //                         supplier_id: Number(supplierID),
        //                         mp_id: mpID || null,
        //                         city_id: Number(cityID),
        //                         route_id: null,

        //                         merch_gender: project.data.merch_gender,
        //                         merch_class_level: 1,

        //                         confirmation: null,

        //                         task_price: 0,
        //                         task_value: taskValue.cost.total,
        //                         coefficient: taskValue.coefficient,
        //                         cost: { ...taskValue.cost },
        //                         selling: { ...taskValue.selling },

        //                         uid: null,

        //                         date_time_from: new Date(currDate),
        //                         date_time_to: new Date(currDate),
        //                         duration: 0,

        //                         inhouse_service: inhouse_service,
        //                         inhouse_barcode: `${projectID}-${lastID}`,
        //                         inhouse_preparation: false,
        //                         inhouse_received: false,

        //                         order: -1,
        //                         dailyOrder: -1,
        //                         type: taskType,
        //                         only_header: true,
        //                         modified_properties: [],

        //                         started_at: null,
        //                         started_in: null,
        //                         finished_at: null,
        //                         finished_in: null,
        //                         warehouse_started_at: null,
        //                         warehouse_ended_at: null,
        //                         received_at: null,

        //                         remarks: null,

        //                         state: TASKS_STATUS.OPEN,
        //                     });

        //                     lastID++;
        //                 } else {
        //                     //find task if any; in order to remove it
        //                     const task = oldTasks.find((doc) => {
        //                         const data = doc.data();
        //                         return (
        //                             data.project_id === projectID &&
        //                             data.supplier_branch_id === Number(supplier_branch_id) &&
        //                             data.outlet_branch_id === Number(outlet_branch_id) &&
        //                             data.schedule_id === Number(schedule_id) &&
        //                             data.date_time_from.toDate().getTime() === currDate.getTime()
        //                         );
        //                     });
        //                     if (task) task.toBeRemoved = true;
        //                 }
        //             }
        //         }
        //     }
        // }

        console.log("loop end");
        //delete all marked to be removed tasks and its subcollection
        const toBeRemovedTasks = oldTasks.filter((t) => t.toBeRemoved);
        const toBeRemovedIDs = toBeRemovedTasks.map((t) => t.id);
        const removeTasks = functions.httpsCallable("removeTasksByIds");
        if (toBeRemovedIDs.length > 0) {
            await removeTasks({ ids: toBeRemovedIDs });
        }

        //add new tasks headers to the collection
        const setTasks = functions.httpsCallable("setTasks");
        await setTasks({ tasks: newTasks });

        return true;
    } catch (error) {
        throw error;
    }
};

let removeAllTaskAfterDate = async (projectID, date) => {
    try {
        const trigger = functions.httpsCallable("removeProjectTasksAfterDate");
        await trigger({ projectID, date });
        console.log("removeAllTaskAfterDate");
        return true;
        // const tasks = (
        //     await db
        //         .collection(TASK_COLLECTION)
        //         .where("project_id", "==", projectID)
        //         .where("date_time_from", ">", date)
        //         .get()
        // ).docs;

        // await Promise.all(tasks.map(async (task) => task.ref.delete()));

        // return true;
    } catch (error) {
        throw error;
    }
};

let removeNoScheduleTasks = async (project) => {
    const tmw = getTomorrow();
    const projectID = project.data.project_id;
    try {
        const tasks = (
            await db
                .collection(TASK_COLLECTION)
                .where("project_id", "==", projectID)
                .where("date_time_from", ">=", tmw)
                .get()
        ).docs;

        //scan for all project tasks that has no schedule
        for (const task of tasks) {
            const data = task.data();
            const { supplier_branch_id, outlet_branch_id, schedule_id } = data;

            const supplierBranch = project.supplierBranches.find((sb) => sb.data.branch_id === `${supplier_branch_id}`);
            if (!supplierBranch) {
                task.toBeRemoved = true;
                continue;
            }

            const outletBranch = supplierBranch.outletBranches.find(
                (ob) => ob.data.branch_id === `${outlet_branch_id}`
            );
            if (!outletBranch) {
                task.toBeRemoved = true;
                continue;
            }

            const schedule = outletBranch.schedules.find((sch) => sch.data.id === `${schedule_id}`);
            if (!schedule) {
                task.toBeRemoved = true;
                continue;
            }
        }

        const toBeRemovedTasks = tasks.filter((task) => task.toBeRemoved);

        const ids = toBeRemovedTasks.map((task) => task.id);
        if (ids.length > 0) {
            const remove = functions.httpsCallable("removeTasksByIds");
            await remove({ ids });
        }

        return true;
    } catch (error) {
        throw error;
    }
};

export const queryTasks = async (options) => {
    try {
        let ref = db.collection(TASK_COLLECTION);
        if (options.docID) {
            return await ref.doc(options.docID).get();
        }
        // console.log(options);

        for (const key in options) {
            if (Object.hasOwnProperty.call(options, key)) {
                let query = { value: null, operator: "==" };

                if (!isObject(options[key]) || isDate(options[key])) {
                    query.value = options[key];
                } else {
                    query = options[key];
                }
                const { value, operator } = query;
                if (key === "supplierBranchID") ref = ref.where("supplier_branch_id", operator, value);
                if (key === "mpID") ref = ref.where("mp_id", operator, value);
                if (key === "supplierID") ref = ref.where("supplier_id", operator, value);
                if (key === "date") ref = ref.where("date_time_from", operator, value);
                if (key === "type") ref = ref.where("type", operator, value);
                if (key === "routeID") ref = ref.where("route_id", operator, value);
            }
        }

        return (await ref.get()).docs;
    } catch (error) {
        throw error;
    }
};

export const queryTasks_v2 = async (options) => {
    try {
        return await query(TASK_COLLECTION, options);
    } catch (error) {
        throw error;
    }
};

export const populateTaskHeader = async (task) => {
    try {
        if (!task.only_header) return task;

        let schedule = await getScheduleByTask(task);
        if (!schedule) {
            throw new Error(`Could not find schedule for the task ID: ${task.id}`);
        }

        schedule = schedule.data();
        const modifiedProps = task.modified_properties ?? [];
        task.uid = modifiedProps.indexOf("uid") !== -1 ? task.uid : schedule.merchandiser_id;
        task.state =
            modifiedProps.indexOf("state") !== -1 ? task.state : task.uid ? TASKS_STATUS.BOOKED : TASKS_STATUS.OPEN;
        task.duration = schedule.duration;
        task.route_id = modifiedProps.indexOf("route_id") !== -1 ? task.route_id : schedule.route_id;

        return task;
    } catch (error) {
        throw error;
    }
};

const defineTaskType = (projectType) => {
    switch (projectType) {
        case PROJECT_TYPES.OPEN_MP_INSOURCE:
            return "mp-insource";

        case PROJECT_TYPES.SUPPLIER_INSOURCE:
            return "self-service-simple";
        default:
            return "unknown";
    }
};

export const queryTasksOnSnapshot = (options, snapshotListener, onTasksError) => {
    try {
        let ref = db.collection(TASK_COLLECTION);
        if (options.docID) {
            return ref.doc(options.docID).onSnapshot(snapshotListener, onTasksError);
        }
        // console.log(options);

        for (const op of options) {
            const { key, value, operator } = op;
            ref = ref.where(key, operator, value);
        }

        return ref.onSnapshot(snapshotListener, onTasksError);
    } catch (error) {
        throw error;
    }
};

export const queryTasks_V2 = async (options) => {
    try {
        let ref = db.collection(TASK_COLLECTION);
        if (options.docID) {
            return await ref.doc(options.docID).get();
        }
        // console.log(options);

        for (const op of options) {
            const { key, value, operator } = op;
            ref = ref.where(key, operator, value);
        }

        return (await ref.get()).docs;
    } catch (error) {
        throw error;
    }
};

export const getUnassignedTasksFromHeaders = async (headers) => {
    try {
        let emptyTasks = await Promise.all(headers.map(async (task) => isOpenTask(task)));
        emptyTasks = emptyTasks.filter((task) => task);
        return emptyTasks;
    } catch (error) {
        throw error;
    }
};

export const saveTaskAssignment = async (task) => {
    try {
        return await db
            .collection(TASK_COLLECTION)
            .doc(`${task.task_id}`)
            .update({
                uid: task.uid,
                uid_company: task.uid_company || null,
                state: task.state,
                dailyOrder: task.dailyOrder,
                modified_properties: task.modified_properties,
            });
    } catch (error) {
        throw error;
    }
};

export const isOpenTask = async (task) => {
    try {
        const modifiedProps = task.modified_properties ?? [];
        if (!task.only_header && task.state === TASKS_STATUS.OPEN) return task;
        else if (!task.only_header && task.state !== TASKS_STATUS.OPEN) return false;
        else if (modifiedProps.indexOf("state") !== -1 && task.state === TASKS_STATUS.OPEN) return task;
        else if (modifiedProps.indexOf("state") !== -1 && task.state !== TASKS_STATUS.OPEN) return false;

        task = await populateTaskHeader(task);

        if (task.uid) return false;

        return task;
    } catch (error) {
        throw error;
    }
};

// let getTaskOrder = (task, routePlan) => {
//     if (task.uid !== routePlan.merchandiser_id) return -1;

//     const projectID = task.project_id;
//     const dayName = DATE_TO_WEEKDAY_SHORT[task.date_time_from.toDate().getDay()];
//     const path = `${task.supplier_branch_id}:${task.outlet_branch_id}:${task.schedule_id}`;

//     let orderArr = routePlan.routes[dayName][projectID];
//     if (!orderArr)
//         throw new Error(
//             `error in route (${routePlan.merchandiser_id}) : Could not find project's (${projectID}) route plan on ${dayName}`
//         );

//     //this is the task's order per day per project
//     let order = orderArr.indexOf(path);
//     if (order === -1)
//         throw new Error(
//             `error in route (${routePlan.merchandiser_id}) : schedule path ${path} was not found in route plan on ${dayName}. Project: ${projectID}`
//         );

//     return order;
// };

// let getTaskDailyOrder = (task, routePlan) => {
//     if (task.uid !== routePlan.merchandiser_id) return -1;

//     const taskProjectID = task.project_id;
//     const dayName = DATE_TO_WEEKDAY_SHORT[task.date_time_from.toDate().getDay()];
//     const path = `${taskProjectID}:${task.supplier_branch_id}:${task.outlet_branch_id}:${task.schedule_id}`;

//     let dailyOrderArr = [];
//     //combine all projects' arrays of the day in one array
//     for (const projectID in routePlan.routes[dayName]) {
//         if (Object.hasOwnProperty.call(routePlan.routes[dayName], projectID)) {
//             let arr = routePlan.routes[dayName][projectID];
//             //prefix project ID on each item
//             arr = arr.map((item) => `${projectID}:${item}`);
//             //combine
//             dailyOrderArr = [...dailyOrderArr, ...arr];
//         }
//     }

//     //this is task's order per day
//     let order = dailyOrderArr.indexOf(path);
//     if (order === -1)
//         throw new Error(
//             `error in route (${routePlan.merchandiser_id}) : schedule path ${path} was not found in route plan on ${dayName}. Project: ${taskProjectID}`
//         );

//     return order;
// };

export const setTask = async (data) => {
    try {
        const { project, outletBranch, schedule, route, merchandiser, products, date } = data;

        const newID = await generateNewDocID(COLLECTIONS.TASK, "task_id");
        const taskDoc = db.collection(COLLECTIONS.TASK).doc(newID);

        const task = {
            ...INITIAL_TASK_DATA,

            task_id: Number(newID),
            supplier_branch_id: Number(schedule.supplier_branch_id),
            outlet_id: Number(schedule.outlet_id) || Number(outletBranch.outlet_id),
            outlet_branch_id: Number(schedule.outlet_branch_id),
            schedule_id: Number(schedule.id),
            project_id: schedule.project_id,
            supplier_id: Number(schedule.supplier_id),
            mp_id: null,
            city_id: Number(schedule.city_id) || Number(outletBranch.city_id),
            route_id: schedule.route_id,

            merch_gender: project.merch_gender,
            merch_class_level: project.merch_class_level,
            uid: merchandiser?.uid || null,
            supervisor_id: route?.supervisor_id || null,

            date_time_from: new Date(date),
            date_time_to: new Date(date),
            duration: schedule.duration,

            // inhouse_service: outletBranch.info.inhouse_service,
            inhouse_service: false,
            inhouse_barcode: `${schedule.project_id}-${newID}`,
            inhouse_preparation: false,
            inhouse_received: false,

            order: -1,
            dailyOrder: -1,
            only_header: false,
            modified_properties: [],

            state: merchandiser?.uid ? TASKS_STATUS.BOOKED : TASKS_STATUS.OPEN,
        };

        await taskDoc.set(task);
        await products.map(async (product) =>
            taskDoc.collection(COLLECTIONS.TASK_PRODUCT).doc(product.id).set(product)
        );

        return task;
    } catch (error) {
        throw error;
    }
};
