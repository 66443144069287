import { COLLECTIONS } from "../constants";
import { query } from "../helpers";
import { queryProjectBranch } from "./Project_Branch";

export const queryProjectOutletBranch = async (projectID, supplierBranchID, options) => {
    try {
        const projectBranch = await queryProjectBranch(projectID, { docID: supplierBranchID });
        return query(COLLECTIONS.PROJECT_OUTLET_BRANCH, options, projectBranch.ref);
    } catch (error) {
        throw error;
    }
};
