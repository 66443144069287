import React from "react";

//related to meterial ui package
import styles from "./styles";
import { CircularLoader, Header } from "../../core-ui/custom";
import { FORM_SCHEMA } from "./schema";
import { Form, Formik } from "formik";
import { Card, Grid, Typography } from "@material-ui/core";
import { Autocomplete, SubmitButton, TextField } from "../../core-ui/forms-ui";
import { Asterisk } from "../../components";

import useAddCompetitorProduct from "../../hooks/use-add-competitor-product";

const AddCompetitorProduct = () => {
    const classes = styles();
    const {
        initValues,
        handleSubmit,
        initializing,
        saving,
        categoriesList,
        subcategoriesList,
        subsubcategoriesList,
        brandsList,
    } = useAddCompetitorProduct();

    const handleCategoryChange = (selectedCategory, formik) => {
        let subcategory = subcategoriesList.find((sc) => sc.data.category_id === selectedCategory.value);
        let subsubcategory = subsubcategoriesList.find((ssc) => ssc.data.sub_category_id === subcategory.value);

        formik.getFieldHelpers("product_category.sub_category_id").setValue(subcategory);
        formik.getFieldHelpers("product_category.sub_sub_category_id").setValue(subsubcategory);
    };
    const handleSubcategoryChange = (selectedSubcategory, formik) => {
        let subsubcategory = subsubcategoriesList.find((ssc) => ssc.data.sub_category_id === selectedSubcategory.value);
        formik.getFieldHelpers("product_category.sub_sub_category_id").setValue(subsubcategory);
    };

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.pageHeader}>Add Competitor Product</Header>
            {initializing && <CircularLoader className={classes.circularProgress} />}
            <Formik
                initialValues={initValues}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={FORM_SCHEMA}
            >
                {(formik) => {
                    if (initializing) return null;
                    return (
                        <Form>
                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Product Information
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="En_name"
                                                label={<Asterisk>Name (English)</Asterisk>}
                                                className={classes.input}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="Ar_name"
                                                label={<Asterisk>Name (Arabic)</Asterisk>}
                                                className={`${classes.input} ${classes.inputRtl}`}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                name="brand_id"
                                                label={"Brand"}
                                                options={brandsList}
                                                className={classes.input}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                loading={initializing}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                name="model_number"
                                                label={"Model Number"}
                                                className={classes.input}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                name="product_description"
                                                label={"Description"}
                                                className={classes.input}
                                                multiline
                                                minRows={4}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>

                            <div className={classes.cardContainer}>
                                <Typography component="h2" className={classes.cardTitle}>
                                    Product Categories
                                </Typography>
                                <Card className={classes.formCard}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete
                                                name="product_category.category_id"
                                                label={<Asterisk>Category</Asterisk>}
                                                options={categoriesList}
                                                className={classes.input}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                loading={initializing}
                                                disableClearable
                                                onChange={(selected) => handleCategoryChange(selected, formik)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete
                                                name="product_category.sub_category_id"
                                                label={<Asterisk>Subcategory</Asterisk>}
                                                options={subcategoriesList.filter(
                                                    (sc) =>
                                                        sc.data.category_id ===
                                                        formik.getFieldMeta("product_category.category_id").value?.value
                                                )}
                                                className={classes.input}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                loading={initializing}
                                                disableClearable
                                                onChange={(selected) => handleSubcategoryChange(selected, formik)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Autocomplete
                                                name="product_category.sub_sub_category_id"
                                                label={<Asterisk>Sub Subcategory</Asterisk>}
                                                options={subsubcategoriesList.filter(
                                                    (sc) =>
                                                        sc.data.sub_category_id ===
                                                        formik.getFieldMeta("product_category.sub_category_id").value
                                                            ?.value
                                                )}
                                                className={classes.input}
                                                classes={{
                                                    option: classes.option,
                                                }}
                                                loading={initializing}
                                                disableClearable
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </div>

                            <div className={classes.cardContainer}>
                                <SubmitButton
                                    type="submit"
                                    className={classes.submitBtn}
                                    loadingClassName={classes.circularProgress}
                                    submitting={saving}
                                >
                                    Create
                                </SubmitButton>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </section>
    );
};

export default AddCompetitorProduct;
