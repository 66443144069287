import React, { Component } from "react";
import styles from "./DashboardContent.module.css";
import { firestore as db } from "../../services/firebase";
import PATHS from "../../constants/paths";

const setCards = (header, description, path, isCompleted, checkCompletion) => {
    return { header, description, path, isCompleted, checkCompletion };
};

const checkSupplierbranches = async (id) => {
    let branches = await db.collection("Branch").where("supplier_id", "==", id).get();
    return !branches.empty;
};

const checkSupplierProducts = async (id) => {
    let products = await db.collection("Product").where("supplier_id", "==", id).get();
    return !products.empty;
};

const checkOutlets = async (id) => {
    let outlets = await db.collection(`Supplier_Outlet_Product/${id}/Outlet_Product`).get();
    return !outlets.empty;
};

const checkOutletBranches = async (id) => {
    let branches = (await db.collection("Branch").where("supplier_id", "==", id).get()).docs.map((doc) => doc.data());

    let result = branches.reduce(async (acc, branch) => {
        let outletBranches = await db.collection(`Branch_Outlet_Branch/${branch.branch_id}/Outlet_Branch`).get();
        return !outletBranches.empty || acc;
    }, false);

    return result;
};

const checkProductWithOutletBranchLink = async (id) => {
    let outletIds = (await db.collection(`Supplier_Outlet_Product/${id}/Outlet_Product`).get()).docs.map(
        (doc) => doc.data().outlet_id
    );

    let result = outletIds.reduce(async (acc, outletId) => {
        let products = await db.collection(`Supplier_Outlet_Product/${id}/Outlet_Product/${outletId}/Product`).get();
        return !products.empty || acc;
    }, false);

    return result;
};

const checkExtraDisplay = async (id) => {
    let extraDisplays = await db.collection("Contract_Extra_Display").where("supplier_id", "==", id).get();
    return !extraDisplays.empty;
};

const checkProjectInit = async (id) => {
    let projects = await db.collection("Project").where("supplier_id", "==", id).get();
    return !projects.empty;
};

const checkProjectConfirmation = async (id) => {
    const confirmedProjects = await db
        .collection("Project")
        .where("supplier_id", "==", id)
        .where("isConfirmed", "==", true)
        .get();
    return !confirmedProjects.empty;
};

class DashboardContent extends Component {
    state = {
        guideCards: [
            setCards(
                "Add Your Branches",
                "Provide information of your branch",
                PATHS.ADD_BRANCH,
                false,
                checkSupplierbranches
            ),
            setCards(
                "Add Your Products",
                "Give us the details about your products",
                PATHS.ADD_PRODUCT,
                false,
                checkSupplierProducts
            ),
            setCards(
                "Outlet Companies",
                "Select outlet companies you are dealing with",
                PATHS.LINK_OUTLETS,
                false,
                checkOutlets
            ),
            setCards(
                "Outlet Branches",
                "Select outlet branches based on your choices in step 3",
                PATHS.LINK_OUTLET_BRANCHES,
                false,
                checkOutletBranches
            ),
            setCards(
                "Link",
                "Link your products with outlet companies",
                PATHS.LINK_PRODUCTS_WITH_OUTLETS,
                false,
                checkProductWithOutletBranchLink
            ),
            setCards(
                "Extra-Display",
                "Add an extra display contract at a specific outlet branch - optional",
                PATHS.ADD_EXTRA_DISPLAY_CONTRACT,
                false,
                checkExtraDisplay
            ),
            setCards(
                "Initialize a Project",
                "Put your basic information about your merchandising project",
                PATHS.ADD_OUTSOURCE_PROJECT,
                false,
                checkProjectInit
            ),
            setCards(
                "Confirm a Project",
                "Follow the step and provide all the information needed before confirmation",
                PATHS.OUTSOURCE_PROJECTS,
                false,
                checkProjectConfirmation
            ),
        ],
    };

    updateCards = async () => {
        let newCards = await Promise.all(
            this.state.guideCards.map(async (card) => {
                const result = await card.checkCompletion(this.props.supplierId);
                card.isCompleted = result;
                return card;
            })
        );

        this.setState({
            ...this.state,
            guideCards: newCards,
        });
    };

    componentDidMount() {
        this.updateCards();
    }
    render() {
        return (
            <div className={styles.Container}>
                <div className={styles.Content}>
                    <h2 className={styles.GuideHeader}>Your journey to start your project begins here</h2>
                    <div className={styles.CardsContainer}>
                        <div className={styles.Cards}>
                            {this.state.guideCards.map((card, index) => {
                                return (
                                    <div
                                        key={`guide-card-${index}`}
                                        className={`${styles.CardInner} ${card.isCompleted ? styles.Completed : null}`}
                                        onClick={() => {
                                            this.props.goTo(card.path);
                                        }}
                                    >
                                        {/* Number */}
                                        <div className={styles.CardNumber}>{index + 1}</div>
                                        {/* Text */}
                                        <div className={styles.CardText}>
                                            <span className={styles.Header}>{card.header}</span>
                                            <span className={styles.Description}>{card.description}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DashboardContent;
