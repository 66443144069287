import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Checkbox,
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Menu,
    TableCell,
    TextField,
} from "@material-ui/core";
import Button from "../../components-lagacy/CustomButtons/Button";
import { COLORS } from "../../constants/constants-lagacy";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Autocomplete } from "../../core-ui/custom";

const buttonStyle = (color) => ({
    textTransform: "none",
    maxWidth: "140px",
    fontSize: "1em",
    // whiteSpace: "break-spaces",
    fontWeight: "bold",
    backgroundColor: "transparent",
    color: color,
    border: `2px ${color} solid`,
    padding: "5px",
    margin: 0,
    "&:hover": {
        backgroundColor: color,
        color: "white",
        border: `2px ${color} solid`,
    },
    "&:focus": {
        backgroundColor: "transparent",
        color: color,
        border: `2px ${color} solid`,
    },
    "&$disabled": {
        backgroundColor: "transparent",
        color: "grey",
        border: `2px grey solid`,
    },
});
const useStyles = makeStyles({
    disabled: {},
    buttonPrimary: { ...buttonStyle(COLORS.primary) },
    buttonSecondary: { ...buttonStyle(COLORS.secondary) },
    buttonSecondary2: { ...buttonStyle(COLORS.accent) },
});

export let TextCell = (props) => (
    <TableCell
        {...props}
        style={{
            padding: "5px",
            fontWeight: "bold",
            ...props.style,
        }}
        // component="th"
        scope="row"
    >
        {props.text}
    </TableCell>
);

export let DaySelectCell = (props) => (
    <TableCell
        style={{
            padding: "5px",
            width: "25px",
            textAlign: "center",
            ...props.style,
        }}
        component="td"
        scope="row"
        // button
        onClick={props.handleClick}
    >
        <Checkbox
            style={{ color: COLORS.accent, margin: "0px", padding: "0px", ...props.iconStyle }}
            checked={props.checked}
            tabIndex={-1}
            disableRipple
        />
    </TableCell>
);

export let TimeCell = (props) => (
    <TableCell
        style={{
            padding: "5px",
            textAlign: "left",
            width: "50px",
            ...props.style,
        }}
        component="td"
        scope="row"
    >
        {/* <TextField
            id={props.id}
            type="time"
            value={props.value}
            // className={classes.textField}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                step: 300, // 5 min
            }}
            onChange={props.changed}
        /> */}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
                ampm={false}
                //  label="24 hours"
                value={props.value}
                onChange={props.changed}
            />
        </MuiPickersUtilsProvider>
    </TableCell>
);

export let NumberCell = (props) => (
    <TableCell
        style={{
            padding: "5px",
            textAlign: "left",
            ...props.style,
        }}
        component="td"
        scope="row"
    >
        <TextField
            disableunderline={1}
            type="number"
            value={props.number}
            onChange={props.changed}
            inputProps={{
                step: 1,
                disableunderline: 1,
            }}
            style={{ width: "50px" }}
        />
    </TableCell>
);

export let MenuCell = ({ style = {}, options = [], ...otherProps }) => (
    <TableCell
        style={{
            padding: "5px",
            textAlign: "left",
            ...style,
        }}
        component="td"
        scope="row"
    >
        <Autocomplete optionLabelProp="label" options={options} {...otherProps} />
    </TableCell>
);

export let ButtonCell = ({
    cellStyle,
    colorVariant,
    clicked,
    disabled = false,
    text,
    className = "",
    classes: customClasses = {},
}) => {
    const classes = useStyles();
    return (
        <TableCell
            justify="center"
            alignitems="center"
            justifycontent="center"
            style={{
                padding: "5px",
                textAlign: "center",
                ...cellStyle,
            }}
            component="td"
            scope="row"
            className={`${className}`}
        >
            <Button
                simple
                size="lg"
                // style={{
                //     textTransform: "none",
                //     backgroundColor: COLORS.accent,
                //     color: "#FFFFFF",
                //     padding: 7,
                //     fontSize: "12px",
                //     width: "69px",
                //     // width: "60%",
                //     ...props.style,
                // }}
                className={`${
                    colorVariant === "primary"
                        ? classes.buttonPrimary
                        : colorVariant === "secondary"
                        ? classes.buttonSecondary
                        : classes.buttonSecondary2
                } ${customClasses.button ?? ""}`}
                type="submit"
                // round
                onClick={clicked}
                // container
                spacing={0}
                align="center"
                justify="center"
                disabled={disabled}
            >
                {text}
            </Button>
        </TableCell>
    );
};

export let DetailsButton = (props) => (
    <TableCell>
        {/* {console.log(props)} */}
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={props.clicked}
                container
                spacing={0}
                align="center"
                justify="center"
            >
                <i
                    style={{
                        padding: "0px",
                        margin: "0px",
                        paddingRight: "3px",
                    }}
                    class="material-icons"
                >
                    {" "}
                    arrow_forward_ios{" "}
                </i>
            </IconButton>
            <Menu
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "center", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                id="long-menu"
                // anchorEl={props.show}
                keepMounted
                open={props.show}
                onClose={props.menuClosed}
                PaperProps={{
                    style: {
                        marginTop: "90px",
                        maxHeight: 48 * 7,
                        width: 300,
                    },
                }}
            >
                <List
                    style={{
                        paddingTop: "0px",
                        marginTop: "0px",
                        marginLeft: "0px",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    // className={classes.root}
                >
                    <ListItem
                        style={{
                            backgroundColor: COLORS.primary,
                            color: "#EEEEEE",
                        }}
                    >
                        <ListItemText primary={props.listName} />
                    </ListItem>
                    <Collapse in={true} timeout="auto" unmountOnExit>
                        <List style={{ marginLeft: "25px" }} component="div" disablePadding>
                            {props.items.map((item) => (
                                <ListItem key={`${item}-${Math.random()}`}>
                                    <ListItemText primary={item} />
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </List>
            </Menu>
        </div>
    </TableCell>
);
