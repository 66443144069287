import React, { useCallback, useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";

import { filterDuplicates } from "../../../helpers/array-helpers";
import { Button, CircularLoader, DashboardCard } from "../../../core-ui/custom";
import BarChart from "../../../core-ui/charts/bar-chart";
import { Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import COLORS from "../../../constants/colors";
import { generateCompetitorSelloutExcel } from "../../../services/excel";

const barSettings = {
    keys: ["noOfOutletBranches"],
    colors: [COLORS.MALIBU],
    indexBy: "index",
    layout: "vertical",
    axisBottom: {
        tickSize: 5,
        tickPadding: 8,
        // tickRotation: 29,
        legend: "Competitors",
        legendPosition: "middle",
        legendOffset: 32,
    },

    axisLeft: {
        tickSize: 5,
        tickPadding: 5,
        // tickRotation: 25,
        legend: "Sales",
        legendPosition: "middle",
        legendOffset: -40,
    },

    minValue: 0,

    tooltip: (d) => <Card style={{ padding: 5, fontSize: "0.6rem" }}>{d.data.product}</Card>,
};

const CompetitorSoldBar = ({ tasks, taskDetails, loading = false }) => {
    const classes = styles();
    const [barData, setBarData] = React.useState([]);

    const init = useCallback(async () => {
        try {
            //reset
            setBarData([]);
            setup(tasks, taskDetails);
        } catch (error) {
            console.log(error);
        }
    }, [tasks, taskDetails]);

    const setup = (tasks, taskDetails) => {
        let names = [
            { brand: "LG", sales: 4 },
            { brand: "Samsung", sales: 6 },
            { brand: "Hitachi", sales: 3 },
            { brand: "Sony", sales: 7 },
            { brand: "TCL", sales: 8 },
            { brand: "Haier", sales: 2 },
            { brand: "Others", sales: 4 },
        ];

        let outOfStock = {};
        for (const name of names) {
            outOfStock[name.brand] = {
                product: name.brand,
                noOfOutletBranches: name.sales,
                index: -1,
            };
        }

        // let outOfStock = taskDetails.reduce((result, td, index) => {
        //     //determine if the product is out of stock
        //     const isOutOfStock = td.quantity_on + td.quantity_added === 0;
        //     if (!isOutOfStock) return result;
        //     // if(!isOutOfStock || result[td.product_id].outletBranches.has(td.task.outlet_branch_id)) return result;

        //     if (!result[td.product_id]) {
        //         result[td.product_id] = {
        //             product: names[index % 5],
        //             // product: td.product_name.en,
        //             outletBranches: new Set([td.task.outlet_branch_id]),
        //             noOfOutletBranches: 5,
        //             index: -1,
        //         };
        //     } else {
        //         result[td.product_id].outletBranches.add(td.task.outlet_branch_id);
        //         result[td.product_id].noOfOutletBranches = result[td.product_id].outletBranches.size;
        //     }

        //     return result;
        // }, {});
        //compute all these products to get chart data
        // let chartData = taskDetails
        //     .map((td) => {
        //         //get task info
        //         // const task = tasks.find((task) => `${task.task_id}` === `${td.task_id}`);
        //         const task = td.task;

        //         //determine if the product is out of stock
        //         const isOutOfStock = td.quantity_on + td.quantity_added === 0;

        //         return {
        //             id: td.id,
        //             productID: td.product_id,
        //             product: td.product_name.en,
        //             outletBranchID: task.outlet_branch_id,
        //             isOutOfStock,
        //         };
        //     })
        //     .filter((td) => td.isOutOfStock);

        // //filter out duplicates by product id and outlet branch id
        // chartData = filterDuplicates(chartData, ["productID", "outletBranchID"]);

        // // combine each product's id
        // const combined = chartData.reduce((acc, curr) => {
        //     const { productID } = curr;
        //     if (acc[productID]) {
        //         acc[productID].noOfOutletBranches += 1;
        //     } else {
        //         acc[productID] = { product: curr.product, noOfOutletBranches: 1, index: -1 };
        //     }
        //     return acc;
        // }, {});

        // sort combined by noOfOutletBranches
        // const sorted = Object.values(combined).sort((a, b) => b.noOfOutletBranches - a.noOfOutletBranches);
        const sorted = Object.values(outOfStock).sort((a, b) => b.noOfOutletBranches - a.noOfOutletBranches);
        // get top 10 products
        const top10 = sorted.slice(0, 10);

        // store index in each product
        top10.forEach((product, index) => (product.index = product.product));

        // see if max value is under 10
        if (top10.length > 0 && top10[0].noOfOutletBranches < 10) {
            barSettings.maxValue = 10;
        }

        setBarData(top10);
    };

    useEffect(() => {
        init();
    }, [init]);

    let content = barData.length > 0 ? <BarChart data={barData} settings={barSettings} /> : <span>No Sales</span>;

    if (loading) {
        content = <CircularLoader />;
    }

    return (
        <DashboardCard
            title={"Competitor Sales"}
            classes={{
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
                cardActionArea: classes.cardActionArea,
            }}
            footerComponent={
                <Button className={classes.button} onClick={() => generateCompetitorSelloutExcel()}>
                    {`Export Excel >`}
                </Button>
            }
        >
            {content}
        </DashboardCard>
    );
};

export default CompetitorSoldBar;
