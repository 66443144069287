import { cloneDeep } from "lodash";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import COLORS from "../constants/colors";
import { useAuth } from "../contexts/auth-context";
import { FORM_SCHEMA, INITIAL_VALUES, STATUS } from "../pages/update-promotion-prices/schema";
import { getSupplierProducts } from "../services/firestore/Product";
import { getPromotionPrices, updatePromotionPrices } from "../services/firestore/Promotion_Prices";
import { useNavigate, useParams, useLocation } from "react-router-dom-v5-compat";
import PATHS from "../constants/paths";

const useUpdatePromotionPrices = () => {
    const { companyData } = useAuth();

    const navigate = useNavigate();
    const { id: promotion_prices_id } = useParams();
    const location = useLocation();

    const [authorized, setAuthorized] = useState(true);
    const [initializing, setInitializing] = useState(true);
    const [saving, setSaving] = useState(false);
    const [initValues, setInitValues] = useState(INITIAL_VALUES);
    const [products, setProducts] = useState([]);
    const [promotionPrices, setPromotionPrices] = useState(null);

    const init = useCallback(async () => {
        try {
            setInitializing(true);
            const promotionPrices = (await getPromotionPrices(promotion_prices_id)).data();
            if (!promotionPrices || promotionPrices.supplier_id !== companyData.company_id) {
                setAuthorized(false);
                setInitializing(false);
                return;
            }
            const products = (await getSupplierProducts(companyData.company_id)).map((doc) => ({
                label: doc.data().En_name,
                value: doc.id,
                data: doc.data(),
            }));

            if (products.length > 0) {
                setInitValues((prev) => ({
                    ...prev,
                    products: [{ product_id: products[0], price: 0 }],
                }));
            }

            //object to array
            let array = [];
            for (const key in promotionPrices.products) {
                if (Object.hasOwnProperty.call(promotionPrices.products, key)) {
                    const product = promotionPrices.products[key];
                    product.product_id = products.find((p) => p.value === product.product_id);
                    array.push({ ...product });
                }
            }
            promotionPrices.products = array;

            setProducts(products);
            setPromotionPrices(promotionPrices);
            setInitValues({
                ...INITIAL_VALUES,
                name: promotionPrices.name,
                number: promotionPrices.number,

                start_date: promotionPrices.start_date.toDate(),
                end_date: promotionPrices.end_date.toDate(),

                status: STATUS.find((s) => s.value === promotionPrices.status),

                products: promotionPrices.products,
            });
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    }, [companyData.company_id, promotion_prices_id]);

    useEffect(() => {
        init();
    }, [init]);

    const castValues = (values) => {
        //get values from auto complete
        const newValues = cloneDeep(FORM_SCHEMA.cast(values));
        newValues.status = newValues.status.value ?? STATUS[0].value;
        newValues.products = {};
        for (let index = 0; index < values.products.length; index++) {
            const product = values.products[index];
            if (!product.product_id?.value) throw new Error(`product un-seletected at ${index}`);

            newValues.products[product.product_id.value] = {
                product_id: product.product_id.value,
                price: product.price,
            };
        }

        return newValues;
    };

    const handleSubmit = async (values, helpers) => {
        try {
            setSaving(true);

            const newValues = castValues(values);

            const data = { ...newValues, id: promotionPrices.id };
            await updatePromotionPrices(data);

            const result = await Swal.fire({
                title: `${data.name} prices has been updated!`,
                text: `${data.name} has been successfully added.`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
            });

            if (!result.value) {
                return false;
            } else {
                setSaving(false);
                navigate(`${location.state?.from ?? PATHS.PROMOTION_PRICES}`);
            }
        } catch (error) {
            console.log(error);
            setSaving(false);
        }
    };

    return {
        authorized,
        initValues,
        handleSubmit,
        initializing,
        saving,
        products,
    };
};

export default useUpdatePromotionPrices;
