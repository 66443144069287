import React from "react";
import NavigationExpandedMenuItem from "../navigation-expanded-menu-item";
import styles from "./styles";
import { useAuth } from "../../../contexts/auth-context";

const NavigationExpandedMenu = (props) => {
    const classes = styles();
    const { userData } = useAuth();
    const { menu, activeMenuPath, onItemClick } = props;
    return (
        <ul className={classes.secondMenu}>
            {menu.map((item, index) => {
                console.log(item);
                if (!item.access[userData.user_type]) return null;

                return (
                    <NavigationExpandedMenuItem
                        key={`nav-expanded-item-${index}:${item.label}`}
                        index={index}
                        label={item.label}
                        menu={item.menu}
                        link={item.link}
                        activeMenuPath={activeMenuPath}
                        level={1}
                        onClick={onItemClick}
                    />
                );
            })}
        </ul>
    );
};

export default NavigationExpandedMenu;
