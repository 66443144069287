import React, { useCallback, useEffect } from "react";

//related to meterial ui package
import styles from "./styles";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import { useState } from "react";
import { useAuth } from "../../../contexts/auth-context";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Autocomplete, SubmitButton } from "../../../core-ui/forms-ui";

import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";
import COLORS from "../../../constants/colors";
import { setBrand } from "../../../services/firestore/Brands";
import { getOutletBranchesBySupplierBranchCoverage } from "../../../services/firestore/Outlet_Branch";

const FORM_SCHEMA = Yup.object().shape({
    outlet_branch: Yup.object().typeError("required").shape({
        label: Yup.string(),
        value: Yup.string(),
    }),
});

let INITIAL_FORM_VALUES = {
    outlet_branch: null,
};

const AddOutletBranchDialog = ({
    open,
    onClose,
    supplierBranch,
    schedules = [],
    createProjectSchedule = async () => {},
}) => {
    const classes = styles();
    const { companyData } = useAuth();
    const [creating, setCreating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [outletBranches, setOutletBranches] = useState([]);

    const loadOutletBranches = useCallback(async () => {
        try {
            setLoading(true);
            let outletBranches = (await getOutletBranchesBySupplierBranchCoverage(supplierBranch.branch_id)).map(
                (o) => ({
                    label: o.data().En_short_name,
                    value: o.id,
                    data: o.data(),
                })
            );

            // outletBranches = outletBranches.filter(
            //     (o) => schedules.findIndex((s) => s.outlet_branch_id === o.value) === -1
            // );
            setOutletBranches(outletBranches);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }, [supplierBranch]);

    useEffect(() => {
        if (open) {
            loadOutletBranches();
        }
    }, [open]);

    const handleSubmit = async (values) => {
        try {
            setCreating(true);
            const { outlet_branch } = values;

            await createProjectSchedule(outlet_branch.data, supplierBranch);
            Swal.fire({
                title: `${outlet_branch.label} Has Been Added`,
                text: `${outlet_branch.label} has been successfully added into the project.`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
            });
            onClose();
        } catch (error) {
            console.log(error);
        }
        setCreating(false);
    };

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} className={classes.dialogRoot}>
            <DialogTitle disableTypography>
                <Typography className={classes.titleText} variant="h5">
                    Add Outlet Branch
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers className={classes.cardContent}>
                <Formik
                    initialValues={INITIAL_FORM_VALUES}
                    enableReinitialize
                    validationSchema={FORM_SCHEMA}
                    onSubmit={handleSubmit}
                >
                    {(props) => (
                        <Form>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={10}>
                                    <Autocomplete
                                        name="outlet_branch"
                                        label={"Select Outlet Branch To Add"}
                                        options={outletBranches}
                                        className={classes.input}
                                        classes={{
                                            option: classes.option,
                                        }}
                                        loading={loading}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <SubmitButton type="submit" className={classes.submitBtn} submitting={creating}>
                                        Create
                                    </SubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AddOutletBranchDialog;
