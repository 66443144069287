import { useCallback } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { PROJECT_TYPES } from "../constants/global";
import { useAuth } from "../contexts/auth-context";
import { formatDate, getToday, getTomorrow, isValidDate } from "../helpers/date-utils";
import { getAllCities } from "../services/firestore/City";
import { getAllOutlets } from "../services/firestore/Outlet";
import { getOutletBranchesByCompany } from "../services/firestore/Outlet_Branch";
import { getSupplierProducts } from "../services/firestore/Product";
import { queryProjects } from "../services/firestore/Project";
import { getBranchesBySupplierID } from "../services/firestore/Supplier_Branch";

const FILTERS = {
    date: {
        component: "dateRange",
        initialValue: {
            startDate: getToday(),
            endDate: getTomorrow(),
            key: "selection",
        },
    },

    supplierBranch: {
        label: "Supplier Branch",
        options: [],
        initialValue: null,
    },
    project: {
        label: "Project",
        options: [],
        initialValue: null,
    },

    outlet: {
        label: "Outlet",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },

    outletBranch: {
        label: "Outlet Branch",
        options: [],
        initialValue: null,
    },

    product: {
        label: "Product",
        options: [],
        initialValue: null,
    },

    city: {
        label: "City",
        options: [],
        initialValue: null,
    },
};

const initFilterValues = () => {
    let values = {};
    for (const key in FILTERS) {
        if (Object.hasOwnProperty.call(FILTERS, key)) {
            const filter = FILTERS[key];
            values[key] = filter.initialValue;
        }
    }
    return values;
};

const useDashboardFilters = () => {
    const { companyData } = useAuth();
    const history = useHistory();
    const { search: queryString } = useLocation();

    // const [initializing, setInitializing] = useState(false);
    const [values, setValues] = useState(initFilterValues());
    // const [supplierBranchesList, setSupplierBranchesList] = useState([]);
    // const [projectsList, setProjectsList] = useState([]);
    // const [outletsList, setOutletsList] = useState([]);
    // const [outletBranchesList, setOutletBranchesList] = useState([]);
    // const [citiesList, setCitiesList] = useState([]);
    // const [productsList, setProductsList] = useState([]);

    const loadOptions = useCallback(async () => {
        try {
            // setInitializing(true);
            const { company_id } = companyData;
            const { OPEN_MP_INSOURCE, STORE_INSOURCE, SUPPLIER_INSOURCE } = PROJECT_TYPES;

            let [supplierBranches, projects, outlets, cities, products] = await Promise.all([
                getBranchesBySupplierID(company_id),
                queryProjects([
                    { key: "supplier_id", operator: "==", value: company_id },
                    {
                        key: "project_type",
                        operator: "in",
                        value: [OPEN_MP_INSOURCE, STORE_INSOURCE, SUPPLIER_INSOURCE],
                    },
                ]),
                getAllOutlets(),
                getAllCities(),
                getSupplierProducts(company_id),
            ]);

            //docs to list options
            supplierBranches = supplierBranches.map((doc) => ({
                label: doc.data().En_name,
                value: doc.id,
                data: doc.data(),
            }));
            projects = projects.map((doc) => ({ label: doc.data().projName, value: doc.id, data: doc.data() }));
            outlets = outlets.map((doc) => ({ label: doc.data().En_short_name, value: doc.id, data: doc.data() }));
            cities = cities.map((doc) => ({ label: doc.data().En_name, value: doc.id, data: doc.data() }));
            products = products.map((doc) => ({ label: doc.data().En_name, value: doc.id, data: doc.data() }));

            // setSupplierBranchesList(supplierBranches);
            // setProjectsList(projects);
            // setOutletsList(outlets);
            // setCitiesList(cities);
            // setProductsList(products);

            return { supplierBranches, projects, outlets, cities, products };
        } catch (error) {
            console.log(error);
        }
        // setInitializing(false);
    }, [companyData]);

    // a callback after the filters component is mounted, load the data
    const initFilters = useCallback(
        async (context) => {
            try {
                const params = new URLSearchParams(queryString);

                const { supplierBranches, projects, outlets, cities, products } = await loadOptions();
                FILTERS.supplierBranch.options = supplierBranches;
                FILTERS.project.options = projects;
                FILTERS.outlet.options = outlets;
                FILTERS.city.options = cities;
                FILTERS.product.options = products;

                // outlet branch options depends on outlet value
                FILTERS.outlet.onChange = handleOutletChange;

                //check if there is any search params in URL
                if (isValidDate(params.get("from")) && isValidDate(params.get("to"))) {
                    FILTERS.date.initialValue.startDate = new Date(params.get("from"));
                    FILTERS.date.initialValue.endDate = new Date(params.get("to"));
                }
                if (params.get("supplier_branch")) {
                    const sbID = params.get("supplier_branch");
                    FILTERS.supplierBranch.initialValue = supplierBranches.find((sb) => sb.value === sbID) || null;
                }
                if (params.get("project")) {
                    const projectID = params.get("project");
                    FILTERS.project.initialValue = projects.find((project) => project.value === projectID) || null;
                }
                if (params.get("outlet")) {
                    const outletID = params.get("outlet");
                    FILTERS.outlet.initialValue = outlets.find((outlet) => outlet.value === outletID) || null;
                }
                if (params.get("city")) {
                    const cityID = params.get("city");
                    FILTERS.city.initialValue = cities.find((city) => city.value === cityID) || null;
                }
                if (params.get("product")) {
                    const productID = params.get("product");
                    FILTERS.product.initialValue = products.find((product) => product.value === productID) || null;
                }

                context.setFilters(FILTERS);

                //load outlet branch options
                if (FILTERS.outlet.initialValue !== null) {
                    FILTERS.outletBranch.options = await handleOutletChange(FILTERS.outlet.initialValue, context);
                    if (params.get("outlet_branch")) {
                        const outletBranchID = params.get("outlet_branch");
                        FILTERS.outletBranch.initialValue =
                            FILTERS.outletBranch.options.find(
                                (outletBranch) => outletBranch.value === outletBranchID
                            ) || null;
                    }
                }
                //initial values
                const initValues = {
                    date: FILTERS.date.initialValue,
                    supplierBranch: FILTERS.supplierBranch.initialValue,
                    project: FILTERS.project.initialValue,
                    outlet: FILTERS.outlet.initialValue,
                    outletBranch: FILTERS.outletBranch.initialValue,
                    city: FILTERS.city.initialValue,
                    product: FILTERS.product.initialValue,
                };
                //apply filters
                context.submit(initValues);
            } catch (error) {
                console.log(error);
            }
        },
        [loadOptions, queryString]
    );

    const handleOutletChange = async (option, context) => {
        try {
            if (!option) {
                // setOutletBranchesList([]);
                context.dispatchFilters({
                    type: "CLEAR_FILTER",
                    payload: { id: "outletBranch" },
                });
                return;
            }

            let outletBranches = await getOutletBranchesByCompany(option.value);
            //filter out unlinked outlet branches
            // outletBranches = outletBranches.filter((ob) =>
            //     dataSources.linkedOutletBranches.find((lob) => lob.branch_id === ob.branch_id)
            // );

            outletBranches = outletBranches.map((doc) => ({
                value: doc.id,
                label: doc.data().En_short_name,
                data: doc.data(),
            }));

            // setOutletBranchesList(outletBranches);
            context.dispatchFilters({
                type: "SET_FILTER_OPTIONS",
                payload: { id: "outletBranch", options: outletBranches },
            });

            return outletBranches;
        } catch (error) {
            throw error;
        }
    };

    const setQueryParams = (results) => {
        const { date, supplierBranch, project, outlet, outletBranch, city, product } = results;
        const urlParams = new URLSearchParams({});

        urlParams.append("from", formatDate(date.startDate, "-", true));
        urlParams.append("to", formatDate(date.endDate, "-", true));

        if (supplierBranch) urlParams.append("supplier_branch", supplierBranch.value);
        if (project) urlParams.append("project", project.value);
        if (outlet) urlParams.append("outlet", outlet.value);
        if (outletBranch) urlParams.append("outlet_branch", outletBranch.value);
        if (city) urlParams.append("city", city.value);
        if (product) urlParams.append("product", product.value);

        history.replace({ search: urlParams.toString() });
    };

    const updateFilterValues = (results) => {
        setValues({ ...results });
    };

    return {
        initFilters,
        setQueryParams,
        filterValues: values,
        updateFilterValues,
    };
};

export default useDashboardFilters;
