import { COLLECTIONS } from "../constants";
import { query } from "../helpers";
import { queryProjects } from "./Project";

export const queryProjectBranch = async (projectID, options) => {
    try {
        const project = await queryProjects({ docID: projectID });
        return query(COLLECTIONS.PROJECT_BRANCH, options, project.ref);
    } catch (error) {
        throw error;
    }
};
