import React from "react";
import { withRouter } from "react-router";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
// core components
import GridContainer from "../components-lagacy/Grid/GridContainer";
import GridItem from "../components-lagacy/Grid/GridItem";

import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
import withStyles from "@material-ui/core/styles/withStyles";

import Card from "../components-lagacy/Card/Card.jsx";
import CardBody from "../components-lagacy/Card/CardBody.jsx";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Button from "../components-lagacy/CustomButtons/Button.jsx";
//import { ReCaptcha } from "react-recaptcha-google";
import Paper from "@material-ui/core/Paper";
import { Redirect } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
//const callback = function () {};
//const expiredCallback = function () {};
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CardHeader from "@material-ui/core/CardHeader";
import Swal from "sweetalert2";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { COLORS, PROJECT_TYPES } from "../constants/constants-lagacy";
import PATHS from "../constants/paths";
import { firestore as db } from "../services//firebase";
import { PLATFORM } from "../constants/global";

class AddInsourceProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            supplier_id: "",
            city_id: "",
            outlet_id: "",
            product_id: "",
            date_from: new Date(),
            date_to: new Date(),
            time_from: new Date("2000-01-01T06:00:00"),
            time_to: new Date("2000-01-01T06:00:00"),
            projName: "",
            merch_class_level: 1,
            merch_gender: "both",
            remarks: "",

            branch_outlet_products_names_id: "",
            cities: [],
            branch_outlet_products: [],
            outlet_products: [],
            products: [],
            suppliers: [],
            branch_outlet_products_names: [],
            projects: [],

            branches: [],

            year: `${new Date().getFullYear()}`,
            month: `${new Date().getMonth() + 1}`,
            day: `${new Date().getDate()}`,
            project_date: new Date(),

            loading: false,
            dialogOpen: false,
            dialogHeader: "",
            dialogMsg: "",
            dialogCloseHandler: null,

            selfService: this.props.selfService,

            pathBack: "",
        };
    }

    //==================================================================================
    showDialogMsg = (title, msg, closeHandler) => {
        this.setState({
            loading: false,
            dialogHeader: title,
            dialogMsg: msg,
            dialogOpen: true,
            dialogCloseHandler: closeHandler,
        });
    };

    hideDialogMsg = (exitTo = null) => {
        if (exitTo) {
            this.setState({ dialogMsg: "", dialogOpen: false, ...exitTo });
        } else {
            this.setState({ dialogMsg: "", dialogOpen: false });
        }
    };

    componentDidMount() {
        // copyProject("M-109-2106-129");
        this.setState({ loading: true });
        let branches = [];
        var branchesRef = db.collection("Branch").where("supplier_id", "==", this.props.id).where("status", "==", "1");
        branchesRef
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    let data = { name: doc.data().En_name, branch_id: doc.data().branch_id, selected: false };
                    branches.push(data);
                });
                this.setState({ branches: branches, loading: false });
            })
            .catch((err) => {
                console.log("Error getting documents", err);
                this.setState({ loading: false });
            });
    }

    validateForm() {
        let validate =
            this.state.year.length > 0 &&
            this.state.month.length > 0 &&
            this.state.projName.length > 0 &&
            this.state.merch_class_level >= 1 &&
            this.state.merch_gender.length > 0 &&
            this.state.branches.length > 0;

        return validate;
    }

    handleChange = (event, id) => {
        this.setState({ [id]: event.target.value });
    };

    handleChangeBranchSelected = (id) => {
        let index = this.state.branches.findIndex((post) => {
            return post.branch_id === id;
        });

        let branches = [...this.state.branches];
        branches[index].selected = !branches[index].selected;
        this.setState({ branches: branches });
    };
    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };
    handleClickShowConfPassword = () => {
        this.setState({ showConfPassword: !this.state.showConfPassword });
    };
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    handleChangeDateFrom = (date) => {
        this.setState({ date_from: date });
    };
    handleChangeDateTo = (date) => {
        this.setState({ date_to: date });
    };
    handleChangeTimeFrom = (time) => {
        this.setState({ time_from: time });
    };
    handleChangeTimeTo = (time) => {
        this.setState({ time_to: time });
    };

    writeProjectData = (e) => {
        let date_from = this.state.date_from === undefined ? null : this.state.date_from;
        let date_to = this.state.date_to === undefined ? null : this.state.date_to;
        let supplier_id = this.props.id;
        let year = this.state.year === undefined ? null : this.state.year;
        let month = this.state.month === undefined ? null : this.state.month;
        let projName = this.state.projName === undefined ? null : this.state.projName;
        let merch_class_level = this.state.merch_class_level === undefined ? null : this.state.merch_class_level;
        let merch_gender = this.state.merch_gender === undefined ? null : this.state.merch_gender;
        let remarks = this.state.remarks === undefined ? null : this.state.remarks;
        let branches = [];
        for (let i = 0; i < this.state.branches.length; i++) {
            if (this.state.branches[i].selected) {
                branches.push(this.state.branches[i].branch_id);
            }
        }

        this.setState({ loading: true });
        let project_id = "100";
        let short_id = "100";
        let swap = 99;
        let datat = "";
        var projectsRef = db.collection("Project");
        projectsRef
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    datat = doc.data();

                    var id = Number((" " + datat.short_id).toString().valueOf());
                    if (swap < id) {
                        swap = id;
                    }
                });

                let monthString = month.toString();
                if (monthString.length === 1) {
                    monthString = "0" + monthString;
                }
                let yearString = year.toString();
                yearString = yearString.slice(2, 4);
                project_id = "M-" + supplier_id + "-" + yearString + monthString + "-" + (swap + 1).toString();
                short_id = (swap + 1).toString();
                //add project info. to Project collection
                let projectsRef2 = db.collection("Project");
                projectsRef2
                    .doc(project_id.toString())
                    .set({
                        platform: PLATFORM,
                        project_id: project_id.toString(),
                        short_id: short_id,
                        // year: year,
                        // month: month,
                        // day: day,
                        year: `${new Date().getFullYear()}`,
                        month: `${new Date().getMonth() + 1}`,
                        day: `${new Date().getDate()}`,
                        project_date: new Date(),
                        mp_id: null,
                        supplier_id: supplier_id,
                        date_from: new Date(date_from.setHours(0, 0, 0, 0)),
                        date_from_task_generation: new Date(date_from.setHours(0, 0, 0, 0)),
                        date_to: new Date(date_to.setHours(23, 59, 59, 999)),
                        date_to_task_generation: new Date(date_from.setHours(0, 0, 0, 0)),
                        projName: projName,
                        merch_class_level: merch_class_level,
                        merch_gender: merch_gender,
                        remarks: remarks,
                        branches: branches,
                        confirm: "0",
                        isConfirmed: false,
                        generate_task: "0",
                        projValue_cost: 0,
                        projValue_selling: 0,
                        project_type: PROJECT_TYPES.SUPPLIER_INSOURCE,
                        authorization_letter: null,
                        planogram: null,
                        discount_amount: 0,

                        supplier_confirmation: false,
                        mp_confirmation: false,
                    })
                    .then(() => {
                        this.setState({
                            supplier_id: "",
                            date_from: new Date(),
                            date_to: new Date(),
                            time_from: new Date("2000-01-01T06:00:00"),
                            time_to: new Date("2000-01-01T06:00:00"),
                            year: "",
                            month: "",
                            remarks: "",
                            projName: "",
                            merch_class_level: 0,
                            merch_gender: "both",
                            branches: [],
                        });
                        this.setState({ loading: false });
                        this.showDialogMsg(
                            "Success",
                            "Project added Successfully, and its ID Number: " + project_id,
                            () => {
                                this.hideDialogMsg();
                                // const path = "/SupplierProjectConfirmationStep5";
                                const path = PATHS.INSOURCE_PROJECTS;
                                const state = {
                                    // selfService: this.state.selfService,
                                    // Sid: this.props.id,
                                    // id: project_id,
                                };
                                this.goTo(path, state);
                            }
                        );
                    });
            })

            .catch((error) => {
                console.error(error);
                this.setState({ loading: false });
                this.showDialogMsg(
                    "Alert",
                    "Cwnnot add project, please check your internet connection and try again.",
                    () => {
                        this.hideDialogMsg();
                    }
                );
            });
    };

    goTo = (path, state) => {
        this.props.history.push({
            pathname: path,
            state: state,
        });
    };

    goToProjectListPage = (e) => {
        if (
            this.state.supplier_id.length > 0 ||
            this.state.year.length > 0 ||
            this.state.month.length > 0 ||
            this.state.projName.length > 0 ||
            this.state.merch_class_level.length > 0 ||
            this.state.merch_gender.length > 0 ||
            this.state.branches.length > 0
        ) {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Discard All Changes!",
                text: "Are you sure you want to discard the changes?",
            }).then((result) => {
                if (!result.value) {
                    this.setState({ pathBack: PATHS.INSOURCE_PROJECTS });
                }
            });
        } else {
            this.setState({ pathBack: PATHS.INSOURCE_PROJECTS });
        }
    };
    //==================================================================================
    render() {
        const { classes } = this.props;
        if (this.state.pathBack.length > 0) {
            return (
                <Redirect
                    to={{
                        pathname: this.state.pathBack,
                        state: { id: this.props.id },
                    }}
                />
            );
        } else {
            const suppliers_list = [];
            for (let i = 0; i < this.state.suppliers.length; i++) {
                suppliers_list.push({
                    value: this.state.suppliers[i].company_id,
                    label: this.state.suppliers[i].En_short_name,
                });
            }

            const years_list = [];
            const date = new Date();
            let currentYearS = date.getFullYear(); //string
            let currentYearN = Number((" " + currentYearS).toString().valueOf()); // number

            //console.log(currentYearN + 1)
            for (let i = 0; i < 4; i++) {
                years_list.push({ value: currentYearS.toString(), label: currentYearS });
                currentYearN = currentYearN + 1;
                currentYearS = currentYearN.toString();
            }

            var monthNames = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const monthNames_list = [];
            for (let i = 0; i < monthNames.length; i++) {
                monthNames_list.push({ value: (i + 1).toString(), label: monthNames[i] });
            }

            const merch_class_level_list = [
                {
                    value: 1,
                    label: "Beginner",
                },
                {
                    value: 2,
                    label: "Intermediate",
                },
                {
                    value: 3,
                    label: "Advanced",
                },
            ];

            // const merch_gender_list = [
            //     {
            //         value: "male",
            //         label: "Male",
            //     },
            //     {
            //         value: "female",
            //         label: "Female",
            //     },
            //     {
            //         value: "both",
            //         label: "Both",
            //     },
            // ];

            let branches = [];

            branches.push(
                <GridItem key={"branches"} align="center" xs={12} sm={12} md={12}>
                    <Card style={{ width: "70%", align: "center" }}>
                        <CardHeader
                            style={{
                                background: COLORS.background,
                                color: COLORS.primary,
                                paddingLeft: "0px",
                                margin: "0px",
                                paddingTop: "5px",
                                paddingBottom: "0px",
                                //"rgba(145, 4, 28,0.83)", "#91041C"
                            }}
                            className={classes.cardHeader}
                            title={
                                <p
                                    style={{
                                        color: COLORS.primary,
                                        fontSize: "17px",
                                        paddingBottom: "0px",
                                        margin: "0px",
                                    }}
                                >
                                    {"Branches"}
                                </p>
                            }
                            subheader={
                                <p style={{ color: COLORS.primary, fontSize: "13px", paddingTop: "0px" }}>{`select`}</p>
                            }
                        />
                        <Divider />
                        <List className={classes.list} dense component="div" role="list">
                            {this.state.branches.map((row) => (
                                <ListItem
                                    style={{ padding: "0", margin: "0" }}
                                    key={row.branch_id}
                                    role="listitem"
                                    button
                                    onClick={() => this.handleChangeBranchSelected(row.branch_id)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            style={{
                                                color: COLORS.primary,
                                            }}
                                            checked={row.selected}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ "aria-labelledby": row.selected }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={row.branch_id} primary={`${row.name}`} />
                                </ListItem>
                            ))}
                        </List>
                    </Card>
                </GridItem>
            );

            return (
                <div className={classes.pageHeader}>
                    <div>
                        <div
                            style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}
                            className={classes.container}
                        >
                            <div
                                className={`${this.props.classes.backdrop} ${this.props.classes.absolutePosition} ${
                                    this.state.loading ? null : this.props.classes.loadingHidden
                                }`}
                            ></div>
                            <div
                                className={`${this.props.classes.loaderContainer} ${
                                    this.props.classes.absolutePosition
                                } ${this.state.loading ? null : this.props.classes.loadingHidden}`}
                            >
                                <CircularProgress />
                            </div>
                            <div pl={5} style={{ width: "100%" }}>
                                <Grid>
                                    <Card color="primary">
                                        <CardBody>
                                            <GridContainer>
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div className={classes.title} style={{ width: "3%" }}>
                                                        <div
                                                            style={{
                                                                fontWeight: "700",
                                                                // backgroundColor: "#91041C",
                                                                color: "#EEEEEE",
                                                                height: "30px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "2px solid #575757",
                                                                backgroundColor: COLORS.primary,
                                                                borderRightColor: COLORS.primary,
                                                                borderBottomColor: COLORS.primary,
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div
                                                        className={classes.title}
                                                        style={{ width: "96.7%", marginLeft: "2px" }}
                                                    >
                                                        <h4
                                                            style={{
                                                                fontWeight: "700",
                                                                backgroundColor: COLORS.background,
                                                                color: COLORS.primary,
                                                                height: "30px",
                                                                paddingLeft: "10px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "1px solid #000000",
                                                            }}
                                                        >
                                                            Add New Project (Project Information)
                                                        </h4>
                                                    </div>
                                                </div>

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            select
                                                            className={classNames(classes.textField)}
                                                            value={this.state.year}
                                                            variant="filled"
                                                            fullWidth
                                                            id="year"
                                                            label="Year*"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "year")}
                                                            margin="dense"
                                                        >
                                                            {years_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem> */}

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            select
                                                            className={classNames(classes.textField)}
                                                            value={this.state.month}
                                                            variant="filled"
                                                            fullWidth
                                                            id="month"
                                                            label="Month*"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "month")}
                                                            margin="dense"
                                                        >
                                                            {monthNames_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem> */}

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <MuiPickersUtilsProvider style={{ selectColor: "#91041C" }} utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                //disableToolbar
                                                                className={classes.textField}
                                                                fullWidth
                                                                multiline
                                                                inputVariant="filled"
                                                                format="dd/MM/yyyy"
                                                                // format="MM/dd/yyyy"
                                                                margin="dense"
                                                                id="date_from"
                                                                label="Project Date*"
                                                                selectColor="#91041C"
                                                                // InputProps={{  }}
                                                                style={{ selectColor: "#91041C" }}
                                                                // value={this.state.expiry_date==='' ? this.state.bufDate :this.state.expiry_date}
                                                                value={this.state.project_date}
                                                                onChange={(event) => this.handleChange(event, "project_date")}
                                                                KeyboardButtonProps={{
                                                                    "aria-label": "change date",
                                                                }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </GridItem> */}

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <MuiPickersUtilsProvider
                                                        style={{ selectColor: "#91041C" }}
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            //disableToolbar
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.textFieldLabel,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            className={classes.textField}
                                                            fullWidth
                                                            multiline
                                                            inputVariant="filled"
                                                            format="dd/MM/yyyy"
                                                            // format="MM/dd/yyyy"
                                                            margin="dense"
                                                            id="date_from"
                                                            label="From*"
                                                            // selectColor="#91041C"
                                                            // InputProps={{  }}
                                                            style={{ selectColor: "#91041C" }}
                                                            // value={this.state.expiry_date==='' ? this.state.bufDate :this.state.expiry_date}
                                                            value={this.state.date_from}
                                                            onChange={this.handleChangeDateFrom}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <MuiPickersUtilsProvider
                                                        style={{ selectColor: "#91041C" }}
                                                        utils={DateFnsUtils}
                                                    >
                                                        <KeyboardDatePicker
                                                            //disableToolbar
                                                            InputProps={{
                                                                classes: {
                                                                    root: classes.textFieldRoot,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            InputLabelProps={{
                                                                classes: {
                                                                    root: classes.textFieldLabel,
                                                                    disabled: classes.disabled,
                                                                },
                                                            }}
                                                            className={classes.textField}
                                                            fullWidth
                                                            multiline
                                                            inputVariant="filled"
                                                            format="dd/MM/yyyy"
                                                            // format="MM/dd/yyyy"
                                                            margin="dense"
                                                            id="date_to"
                                                            label="To*"
                                                            // selectColor="#91041C"
                                                            // InputProps={{  }}
                                                            style={{ selectColor: "#91041C" }}
                                                            // value={this.state.expiry_date==='' ? this.state.bufDate :this.state.expiry_date}
                                                            value={this.state.date_to}
                                                            onChange={this.handleChangeDateTo}
                                                            KeyboardButtonProps={{
                                                                "aria-label": "change date",
                                                            }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6} style={{ display: "none" }}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        select
                                                        value={this.state.merch_class_level}
                                                        variant="filled"
                                                        fullWidth
                                                        id="merch_class_level"
                                                        label="Merchandiser Class Level"
                                                        placeholder=""
                                                        multiline
                                                        style={{ textAlign: "left" }}
                                                        //value={"" + this.props.input}
                                                        onChange={(event) =>
                                                            this.handleChange(event, "merch_class_level")
                                                        }
                                                        className={classes.textField}
                                                        margin="dense"
                                                    >
                                                        {merch_class_level_list.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>

                                                {/* <GridItem xs={12} sm={12} md={6}>
                                                        <TextField
                                                            select
                                                            value={this.state.merch_gender}
                                                            variant="filled"
                                                            fullWidth
                                                            id="merch_gender"
                                                            label="Merchandiser Gender"
                                                            placeholder=""
                                                            multiline
                                                            style={{ textAlign: "left" }}
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChange(event, "merch_gender")}
                                                            className={classes.textField}
                                                            margin="dense"
                                                        >
                                                            {merch_gender_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem> */}

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        value={this.state.projName}
                                                        variant="filled"
                                                        fullWidth
                                                        id="projName"
                                                        label="Project Name*"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "projName")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        value={this.state.remarks}
                                                        variant="filled"
                                                        fullWidth
                                                        id="remarks"
                                                        label="Remarks"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) => this.handleChange(event, "remarks")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>

                                                {branches}
                                                <Grid item xs={12}>
                                                    <p></p>
                                                    <Paper className={classes.paper}> </Paper>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <p></p>
                                                    <Paper className={classes.paper}> </Paper>
                                                </Grid>

                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Button
                                                        simple
                                                        size="lg"
                                                        disabled={!this.validateForm()}
                                                        onClick={this.writeProjectData}
                                                        style={{
                                                            float: "right",
                                                            textTransform: "none",
                                                            backgroundColor: COLORS.submit,
                                                            color: COLORS.font,
                                                            marginTop: 14,
                                                            padding: 10,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            // borderColor: "#4BB543",
                                                            border: "2",
                                                            fontWeight: "400",
                                                        }}
                                                        type="submit"
                                                    >
                                                        {" "}
                                                        Create{" "}
                                                    </Button>
                                                    <Button
                                                        simple
                                                        size="lg"
                                                        style={{
                                                            float: "right",
                                                            textTransform: "none",
                                                            backgroundColor: COLORS.accent,
                                                            color: COLORS.font,
                                                            marginTop: 14,
                                                            padding: 10,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            // borderColor: "#4BB543",
                                                            border: "2",
                                                            fontWeight: "400",
                                                        }}
                                                        type="submit"
                                                        className={classes.navLink}
                                                        onClick={this.goToProjectListPage}
                                                    >
                                                        {" "}
                                                        Cancel{" "}
                                                    </Button>
                                                </GridItem>

                                                {/* <div style={{ width: "100%" }}>
                                                        <Button
                                                            simple
                                                            onClick={this.goToProjectListPage}
                                                            size="lg"
                                                            // disabled={!this.validateThirdForm()}
                                                            style={{
                                                                marginTop: 10,
                                                                marginLeft: "10px",
                                                                padding: 10,
                                                                paddingLeft: 20,
                                                                paddingRight: 20,
                                                                textTransform: "none",
                                                                backgroundColor: "transparent",
                                                                color: "#91041C",
                                                                fontWeight: "400",
                                                                float: "left",
                                                            }}
                                                            type="submit"
                                                        >
                                                            <ChevronLeft /> Back{" "}
                                                        </Button>
                                                    </div> */}
                                            </GridContainer>
                                        </CardBody>
                                    </Card>
                                </Grid>
                            </div>
                            {/* <div style={{ width: "22%" }}>
                                    <Grid style={{ overflow: "hidden" }} xs={12} sm={12} md={12}>
                                        <Card
                                            style={{
                                                backgroundColor: "#EEEEEE",
                                                boxShadow: "none",
                                                height: "80px",
                                            }}
                                        >
                                            <CardBody style={{ padding: "5px" }}>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Button
                                                        simple
                                                        size="lg"
                                                        // disabled={!this.validateFirstForm()}
                                                        onClick={() => this.handleChangeFormNumber(1)}
                                                        style={{
                                                            paddingLeft: "0px",
                                                            paddingRight: "0",
                                                            paddingTop: "10px",
                                                            paddingBottom: "10px",
                                                            textTransform: "none",
                                                            fontWeight: "700",
                                                            backgroundColor: "#91041C",
                                                            color: "#EEEEEE",
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: "0",
                                                        }}
                                                    >
                                                        {" "}
                                                        Project Information{" "}
                                                    </Button>
                                                </GridItem>
                                            </CardBody>
                                        </Card>
                                    </Grid>
                                </div> */}
                        </div>
                    </div>
                    <div>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                    </div>

                    <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                        <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.state.dialogCloseHandler} style={{ backgroundColor: "#91041c" }}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }
}
AddInsourceProject.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(componentsStyle)(withRouter(AddInsourceProject));
