import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../contexts/auth-context";
import { Button } from "../../core-ui/custom";
import EditIcon from "@material-ui/icons/Edit";
import { COMPANY_TYPES, STATUS, USER_TYPES } from "../../constants/global";
import { useSearchParams } from "react-router-dom-v5-compat";
import { cloneDeep } from "lodash";
import { queryUser } from "../../services/firestore/User";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";

const FILTERS = {
    search: {
        label: "Search Name, email or phone",
        component: "text",
        value: "",
    },
    supplierBranch: {
        label: "Supplier Branch",
        options: [],
        value: null,
    },
    role: {
        label: "Role",
        options: [
            { label: "Admin", value: USER_TYPES.ADMIN },
            { label: "Branch Manager", value: USER_TYPES.BRANCH_MANAGER },
        ],
        value: null,
    },
    status: {
        label: "Status",
        options: [
            { label: "Active", value: STATUS.ACTIVE },
            { label: "Inactive", value: STATUS.INACTIVE },
        ],
        value: null,
    },
};

const HEADROW = [
    { id: "id", label: "No.", hidden: true },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "user_type", label: "Role" },
    { id: "branches", label: "Branches" },
    { id: "status", label: "Status" },
    { id: "action", label: "Actions", style: { width: 20, textAlign: "right" } },
];

const useUsers = ({ classes }) => {
    const { companyData } = useAuth();
    const [initializing, setInitializing] = useState(false);
    const [initialSearching, setInitialSearching] = useState(false);
    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const [rows, setRows] = useState([]);
    const [showAddUserDialog, setShowAddUserDialog] = useState(false);
    const [showUpdateUserDialog, setShowUpdateUserDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [supplierBranchesList, setSupplierBranchesList] = useState([]);
    const [filters, setFilters] = useState(FILTERS);

    const TABLE_TOOLBAR_ACTIONS = [
        {
            title: "Add New User",
            action: () => setShowAddUserDialog(true),
            icon: "add",
            enabled: () => true,
        },
    ];

    const generateActionButton = useCallback(
        (user) => {
            return (
                <Button
                    className={classes.editButton}
                    onClick={() => {
                        setShowUpdateUserDialog(true);
                        setSelectedUser(user);
                    }}
                >
                    <EditIcon />
                </Button>
            );
        },
        [classes.editButton]
    );

    const setQueryParams = useCallback(
        (filters) => {
            const { search, role, status, supplierBranch } = filters;
            const urlParams = new URLSearchParams({});

            if (search) urlParams.append("search", search);
            if (role) urlParams.append("role", role.value);
            if (supplierBranch) urlParams.append("supplier_branch", supplierBranch.value);
            if (status) urlParams.append("status", status.value);

            setSearchParams(urlParams, { replace: true });
            return urlParams;
        },
        [setSearchParams]
    );

    const searchUsers = useCallback(
        async ({ search, role, status, supplierBranch }) => {
            try {
                setLoading(true);
                setQueryParams({ search, role, status, supplierBranch });
                const { company_id } = companyData;
                let query = [
                    { key: "company_id", operator: "==", value: company_id },
                    { key: "company_type", operator: "==", value: COMPANY_TYPES.SUPPLIER },
                    { key: "owner", operator: "==", value: false },
                ];

                if (role) query.push({ key: "user_type", operator: "==", value: role.value });
                if (status) query.push({ key: "status", operator: "==", value: status.value });
                if (supplierBranch)
                    query.push({ key: "branch_ids", operator: "array-contains", value: supplierBranch.value });

                let users = (await queryUser(query)).map((doc) => doc.data());

                if (search) {
                    users = users.filter((user) => {
                        const name = `${user.first_name} ${user.surname}`.toLowerCase();
                        const email = user.email;
                        const phone = user.phone;

                        return (
                            name.includes(search.toLowerCase()) ||
                            email.includes(search.toLowerCase() || phone.includes(search.toLowerCase()))
                        );
                    });
                }

                //init rows
                const rows = users.map((user) => {
                    let branchesLength = user.branch_ids?.length ?? 0;
                    return {
                        id: user.uid,
                        name: `${user.first_name} ${user.surname}`,
                        email: user.email,
                        phone: user.phone,
                        user_type: FILTERS.role.options.find((s) => s.value === user.user_type)?.label ?? "-",
                        branches: `${branchesLength} ${branchesLength > 1 ? "Branches" : "Branch"}`,
                        status: FILTERS.status.options.find((s) => s.value === user.status)?.label ?? "-",
                        action: generateActionButton(user),
                    };
                });

                setRows(rows);

                // setSearchParams(urlParams, { replace: true });
                // history.replace({ search: urlParams.toString() });
                setLoading(false);
            } catch (error) {
                setLoading(false);
                throw error;
            }
        },
        [companyData, generateActionButton, setQueryParams, supplierBranchesList]
    );

    // after the filters component is mounted, load the data
    const initFilters = useCallback(
        async (supplierBranchesList) => {
            try {
                const filters = cloneDeep(FILTERS);
                //init
                filters.supplierBranch.options = supplierBranchesList;

                //check if there is any search params in URL
                if (searchParams.get("search")) filters.search.value = searchParams.get("search");
                if (searchParams.get("role")) {
                    const id = searchParams.get("role");
                    filters.role.value = filters.role.options.find((s) => s.value === id);
                }
                if (searchParams.get("supplier_branch")) {
                    const id = searchParams.get("supplier_branch");
                    filters.supplierBranch.value = filters.supplierBranch.options.find((s) => s.value === id);
                }
                if (searchParams.get("status")) {
                    const id = searchParams.get("status");
                    filters.status.value = filters.status.options.find((s) => s.value === id);
                }

                return filters;
            } catch (error) {
                throw error;
            }
        },
        [searchParams]
    );

    const init = useCallback(async () => {
        try {
            setInitializing(true);
            const supplierBranchesList = (await getBranchesBySupplierID(companyData.company_id)).map((doc) => ({
                label: doc.data().En_name,
                value: doc.id,
                data: doc.data(),
            }));

            const filters = await initFilters(supplierBranchesList);

            setFilters(filters);
            setSupplierBranchesList(supplierBranchesList);
            //after setting states which the search function depends on, enable the initial search
            setInitialSearching(true);
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    }, [companyData.company_id, initFilters, setFilters]);

    useEffect(() => {
        if (initialSearching) {
            setInitialSearching(false);
            // load tasks and merchandisers with default filters
            searchUsers({
                search: filters.search.value,
                role: filters.role.value,
                status: filters.status.value,
                supplierBranch: filters.supplierBranch.value,
            });
        }
    }, [initialSearching, searchUsers, filters, supplierBranchesList]);

    useEffect(() => {
        init();
    }, [init]);

    return {
        initializing,
        loading,
        filters,
        HEADROW,
        TABLE_TOOLBAR_ACTIONS,
        rows,
        showAddUserDialog,
        setShowAddUserDialog,
        showUpdateUserDialog,
        setShowUpdateUserDialog,
        selectedUser,
        setSelectedUser,
        searchUsers,
    };
};

export default useUsers;
