import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import CustomTextField from "../../../components-lagacy/CustomInput/CustomTextField";
import CustomDatePicker from "../../../components-lagacy/CustomInput/CustomDatePicker";
import CustomFileInput from "../../../components-lagacy/CustomInput/CustomFileInput";

const useStyles = makeStyles({
    inputsGrid: {
        margin: "0",
        padding: "0 14px",
        width: "100%",
    },
    fileInputWrap: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
    },
});

let createInputEl = (inputProps, gridProps, type = "text", inputFileInfo = {}) => {
    let inputEl = null;
    switch (type) {
        case "text":
            inputEl = <CustomTextField rootProps={inputProps} />;
            break;
        case "date":
            inputEl = <CustomDatePicker rootProps={inputProps} />;
            break;
        case "file":
            inputEl = <CustomFileInput rootProps={inputProps} {...inputFileInfo} />;
            break;
        default:
            inputEl = null;
    }
    return (
        <Grid item xs={12} sm={12} md={6} {...gridProps}>
            {inputEl}
        </Grid>
    );
};

let ProjectHeaderInputs = (props) => {
    const classes = useStyles(props);
    const {
        projectID,
        isConfirmed,
        enableEdit,
        projectNameProps,
        startDateProps,
        endDateProps,
        remarksProps,
        authProps,
    } = props;

    const inputsEl = [
        //project id
        createInputEl(
            { disabled: true, value: projectID, id: "projId", label: "Project Number" },
            { md: 3, key: "projKeyInput" }
        ),

        //project confirmation
        createInputEl(
            {
                disabled: true,
                value: isConfirmed ? "Confirmed" : "Not Confirmed",
                id: "confirm",
                label: "Confirmation",
            },
            { md: 3, key: "confirmKeyInput" }
        ),

        //project name
        createInputEl(
            {
                disabled: !enableEdit,
                id: "projName",
                label: "Project Name",
                ...projectNameProps,
            },
            { md: 6, key: "projNameKeyInput" }
        ),
        // start date
        createInputEl(
            { disabled: !enableEdit, id: "Start_date", label: "Start Date", ...startDateProps },
            { md: 3, key: "startDateKeyInput" },
            "date"
        ),

        // end date
        createInputEl(
            {
                disabled: !enableEdit,
                id: "end_date",
                label: "End Date",
                ...endDateProps,
            },
            { md: 3, key: "endDateKeyInput" },
            "date"
        ),

        //remarks
        createInputEl(
            {
                disabled: !enableEdit,
                id: "remarks",
                label: "Remarks",
                ...remarksProps,
            },
            { md: 6, key: "remarksKeyInput" }
        ),

        //auth file input
        createInputEl(
            {
                disabled: true,
                id: "auth-file-name",
                label: "Authoriazation Letter",
                ...(authProps ? authProps.inputProps : null),
            },
            { md: 6, key: "authLetterKeyInput", className: classes.fileInputWrap },
            "file",
            { ...authProps }
        ),
    ];
    return (
        <Grid container spacing={2} className={classes.inputsGrid}>
            {inputsEl}
        </Grid>
    );
};

ProjectHeaderInputs.propTypes = {
    classes: PropTypes.object,
};

export default ProjectHeaderInputs;
