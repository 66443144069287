import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { TASKS_STATUS } from "../../constants/constants-lagacy";
import { Card, CardContent, CardHeader, Grid, TextField, Typography } from "@material-ui/core";
import { getMerchandiserName } from "../../helpers/merchandiser-utils";
import { convertMinutesToHoursAndMinutes } from "../../helpers/date-utils";
import { TASK_TYPES } from "../../constants/global";
import { cloneDeep } from "lodash";
import { useAuth } from "../../contexts/auth-context";

const VisitsPoolManager = ({ merchandisers, updateMerchandisers, tasks, updateTasks }) => {
    const classes = styles();
    const { companyData } = useAuth();

    const getStatusStyleClass = (id) => {
        switch (id) {
            case TASKS_STATUS.COMPLETED:
                return classes.completed;
            case TASKS_STATUS.OPEN:
                return classes.open;
            case TASKS_STATUS.RESERVED:
            case TASKS_STATUS.BOOKED:
                return classes.scheduled;
            case TASKS_STATUS.NO_SHOW:
                return classes.noShow;
            case TASKS_STATUS.DELAY:
            case TASKS_STATUS.UNDER_PROCESS:
                return classes.underProcess;
            case TASKS_STATUS.FINISHED:
                return classes.finished;

            case TASKS_STATUS.REJECTED:
            case TASKS_STATUS.AUDITING:
            case TASKS_STATUS.FINISHED_LATE:
            case TASKS_STATUS.FINISHED_R:
                return classes.auditing;

            case TASKS_STATUS.CANCELED:
                return classes.canceled;

            default:
                return classes.default;
        }
    };

    const getListFromDropId = (droppableId) => {
        if (droppableId === "unassigned-tasks") return tasks;

        //find the merchandiser
        const merchandiser = merchandisers.find((merchandiser) => merchandiser.uid === droppableId);
        return merchandiser.tasks;
    };

    const updateOrderList = (droppableId, newList) => {
        if (droppableId === "unassigned-tasks") {
            newList.forEach((task, index) => {
                if (task.state !== TASKS_STATUS.CANCELED) {
                    task.state = TASKS_STATUS.OPEN;
                }
                task.uid = null;
                task.uid_company = null;
                task.dailyOrder = index;
                task.modified_properties = ["dailyOrder", "uid", "state", "uid_company"];
            });

            updateTasks(newList);
        } else {
            const newMerchandisers = [...merchandisers];
            // find the merchandiser
            const mIndex = newMerchandisers.findIndex((merchandiser) => merchandiser.uid === droppableId);

            newList.forEach((task, index) => {
                task.state = TASKS_STATUS.BOOKED;
                task.uid = newMerchandisers[mIndex].uid;
                task.uid_company = companyData.company_id;
                task.dailyOrder = index;
                task.modified_properties = ["dailyOrder", "uid", "state", "uid_company"];
            });

            merchandisers[mIndex].tasks = newList;

            updateMerchandisers(newMerchandisers);
        }
    };

    const isTaskEligibleToDrag = (task) => {
        return (
            task.state === TASKS_STATUS.OPEN ||
            task.state === TASKS_STATUS.BOOKED ||
            task.state === TASKS_STATUS.RESERVED ||
            task.state === TASKS_STATUS.CANCELED
        );
    };

    const onDragEnd = (result) => {
        //droppableId could be 'unassigned-tasks' or a merchandiser uid, draggableId is task id
        const { destination, source, draggableId } = result;
        if (!destination) return;

        // dropped on the same place
        if (destination.droppableId === source.droppableId && destination.index === source.index) return;

        // find the item (task) by draggableId (task id)
        let item = null;
        if (source.droppableId === "unassigned-tasks") {
            item = tasks.find((t) => `${t.task_id}` === draggableId);
        } else {
            const merchandiser = merchandisers.find((mr) => mr.uid === source.droppableId);
            item = merchandiser.tasks.find((t) => `${t.task_id}` === draggableId);
        }
        //check task status before apply the drag and drop
        if (!isTaskEligibleToDrag(item)) {
            return;
        }
        //re-order in the same card
        if (destination.droppableId === source.droppableId) {
            let list = getListFromDropId(source.droppableId);
            let newList = cloneDeep(list);
            newList.splice(source.index, 1);
            newList.splice(destination.index, 0, item);
            updateOrderList(source.droppableId, newList);
            return;
        }

        //the schedule is travelled from one to another
        let sourceList = cloneDeep(getListFromDropId(source.droppableId));
        let destinationList = cloneDeep(getListFromDropId(destination.droppableId));
        //remove
        sourceList.splice(source.index, 1);
        //add at the specifiied order
        destinationList.splice(destination.index, 0, item);
        updateOrderList(source.droppableId, sourceList);
        updateOrderList(destination.droppableId, destinationList);
    };

    return (
        <section>
            {/* cards */}
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid container justifyContent="center">
                    {/* merchandisers */}
                    <Grid container item spacing={2} xs={6} sm={9} md={10} style={{ marginRight: 2 }}>
                        {merchandisers.map((merchandiser) => {
                            const { uid, tasks } = merchandiser;

                            let totalDuration = tasks.reduce((sum, task) => sum + task.duration, 0);
                            // totalDuration += tasks.length === 0 ? 0 : (tasks.length - 1) * avg_travel_time;
                            const durationStr = convertMinutesToHoursAndMinutes(totalDuration);

                            const merchName = getMerchandiserName(merchandiser);
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    lg={2}
                                    key={`${uid}-daily-card`}
                                    className={classes.merchandiserCardContainer}
                                >
                                    <Card variant="outlined">
                                        <CardHeader
                                            title={merchName}
                                            subheader={`${durationStr ? ` (${durationStr} )` : ""}`}
                                            className={classes.cardHeader}
                                            titleTypographyProps={{
                                                className: classes.headerText,
                                            }}
                                            subheaderTypographyProps={{
                                                className: classes.subheaderText,
                                            }}
                                        ></CardHeader>

                                        <Droppable droppableId={uid}>
                                            {(provided) => (
                                                <CardContent innerRef={provided.innerRef} {...provided.droppableProps}>
                                                    {tasks.map((task, index) => {
                                                        const className = getStatusStyleClass(task.state);
                                                        const projectID = task.project_id;
                                                        return (
                                                            <Draggable
                                                                key={`route-task-item-${task.task_id}`}
                                                                draggableId={`${task.task_id}`}
                                                                index={index}
                                                            >
                                                                {(provided) => (
                                                                    <Typography
                                                                        component="div"
                                                                        className={`${classes.cardItem} ${className}`}
                                                                        innerRef={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <div>
                                                                            <p className={classes.cardItemMainText}>
                                                                                {`[${task.task_id}] `}
                                                                                <b>{`${task.outlet_branch.En_short_name}`}</b>
                                                                            </p>
                                                                            <p className={classes.cardItemSubText}>
                                                                                {projectID}
                                                                            </p>
                                                                        </div>
                                                                        <p
                                                                            className={classes.cardItemRightText}
                                                                        >{`${task.duration} Min`}</p>
                                                                    </Typography>
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </CardContent>
                                            )}
                                        </Droppable>
                                        {/* <CardActions className={classes.routeCardActions}>
                                            <Button size="small" onClick={() => this.openRouteCardEditor(routeIndex)}>
                                                Edit
                                            </Button>
                                        </CardActions> */}
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>

                    {/* not assigned task list */}

                    <Grid container item xs={6} sm={3} md={2}>
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardHeader
                                    title={"Visits"}
                                    className={`${classes.cardHeader} ${classes.scheduleCardHeader}`}
                                    titleTypographyProps={{ className: classes.scheduleHeaderText }}
                                    subheaderTypographyProps={{ className: classes.subheaderText }}
                                />
                                <Droppable droppableId={`unassigned-tasks`}>
                                    {(provided) => (
                                        <CardContent innerRef={provided.innerRef} {...provided.droppableProps}>
                                            {tasks.map((task, index) => {
                                                const className = getStatusStyleClass(task.state);
                                                const projectID = task.project_id;
                                                return (
                                                    <Draggable
                                                        key={`vacant-item-${task.task_id}`}
                                                        draggableId={`${task.task_id}`}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <Typography
                                                                component="div"
                                                                className={`${classes.cardItem} ${className}`}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                innerRef={provided.innerRef}
                                                            >
                                                                <div>
                                                                    <p className={classes.cardItemMainText}>
                                                                        {`[${task.task_id}] `}
                                                                        <b>{`${task.outlet_branch.En_short_name}`}</b>
                                                                    </p>
                                                                    <p className={classes.cardItemSubText}>
                                                                        {projectID}
                                                                    </p>
                                                                </div>
                                                                <p
                                                                    className={classes.cardItemRightText}
                                                                >{`${task.duration} Min`}</p>
                                                            </Typography>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </CardContent>
                                    )}
                                </Droppable>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </DragDropContext>
        </section>
    );
};

VisitsPoolManager.propTypes = {
    classes: PropTypes.object,
};

export default VisitsPoolManager;
