import React from "react";
import { EnhancedTransferList, Filters } from "../../components";
import { Button, CircularLoader, Header, LoadingOverlay, TransitionAlert } from "../../core-ui/custom";
import styles from "./styles";
import useLinkProductsWithOutlets from "./use-link-products-with-outlets";

const LinkProductsWithOutlets = () => {
    const classes = styles();

    const {
        initializing,
        loading,
        saving,
        leftList,
        rightList,
        checkedList,
        transferHandler,
        filterHandler,
        saveHandler,
        alertState,
        setAlertOpen,
        initFilters,
        applyFilters,
    } = useLinkProductsWithOutlets();

    return (
        <section className={classes.root}>
            <TransitionAlert
                variant="filled"
                severity={alertState.severity}
                open={alertState.open}
                setOpen={setAlertOpen}
                className={classes.stickyAlert}
                timer={3000}
            >
                {alertState.message}
            </TransitionAlert>
            {(initializing || loading) && <LoadingOverlay />}

            <div className={classes.card}>
                <Header className={classes.header}>Link Your Products With Outlets</Header>
                <Filters
                    init={initFilters}
                    onSubmit={applyFilters}
                    classes={{ root: classes.filtersRoot, gridContainer: classes.filtersContainer }}
                    submitText="Apply"
                />
                <EnhancedTransferList
                    leftList={leftList}
                    rightList={rightList}
                    checkedList={checkedList}
                    onTransfer={transferHandler}
                    onFilter={filterHandler}
                />
                <Button className={classes.saveBtn} onClick={saveHandler} disabled={initializing || saving}>
                    {saving ? <CircularLoader size={24} className={classes.circularProgress} /> : "Save"}
                </Button>
            </div>
        </section>
    );
};

export default LinkProductsWithOutlets;
