import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles";

import { Grid } from "@material-ui/core";

import {
    DailyVisitsPie,
    DateRangePicker,
    ExpiredDamagedProductsTable,
    InstoreIdealTimeTable,
    InstoreTimeTable,
    MissedVisitsTable,
    NearExpiryProductsTable,
    OutOfStockBar,
    OutOfStockTable,
    OutOfStockTrendLine,
    // UnavailableSKUsBar,
} from "../../components";
import { Header, RefreshButton } from "../../core-ui/custom";
import { queryTasks_V2 } from "../../services/firestore/Task";
import { useAuth } from "../../contexts/auth-context";
import { getToday, getTomorrow } from "../../helpers/date-utils";
import { getOutletBranchesFromTasks } from "../../services/firestore/Outlet_Branch";
import { getDetailsFromTasks } from "../../services/firestore/Task_Details";
import { getMerchandisersFromTasks } from "../../services/firestore/Merchandiser";
import { getProducts, getSupplierProducts } from "../../services/firestore/Product";
import { firestore } from "../../services/firebase";
import { TASK_TYPES } from "../../constants/global";
import { useParams } from "react-router-dom";

const Dashboard = () => {
    const classes = styles();
    const { id: supplier_id } = useParams();

    // listeners for tasks documents
    // let unsub = useRef([]);
    // listeners for task_details documents
    // let unsubDetails = useRef([]);
    // const [taskDetailsSnapshotResults, setTaskDetailsSnapshotResults] = useState([]);
    // const [taskDetailsSnapshotsCounter, setTaskDetailsSnapshotsCounter] = useState(0);

    const [tasks, setTasks] = useState([]);
    const [taskDetails, setTaskDetails] = useState([]);
    const [outletBranches, setOutletBranches] = useState([]);
    const [merchandisers, setMerchandisers] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: getToday(),
        endDate: getTomorrow(),
        key: "selection",
    });

    //loading flags
    const [tasksLoading, setTasksLoading] = useState(true);
    const [taskDetailsLoading, setTaskDetailsLoading] = useState(true);

    const onDateRangeChange = (dateRange) => {
        //sometimes startDate and endDate refrences to the same object
        //so we need to clone it
        dateRange = { ...dateRange, startDate: new Date(dateRange.startDate), endDate: new Date(dateRange.endDate) };
        dateRange.startDate.setHours(0, 0, 0, 0);
        dateRange.endDate.setHours(23, 59, 59, 999);
        setDateRange(dateRange);

        //reset
        // setTaskDetailsSnapshotsCounter(0);
        // setTaskDetailsSnapshotResults([]);
        setTasks([]);
        setTaskDetails([]);
        //unsubscribe
        // unsub.current?.();
        // unsubDetails.current.forEach((unsub) => unsub?.());
    };

    const getTasks = useCallback(
        async (startDate, endDate) => {
            try {
                setTasksLoading(true);

                const tasks = await queryTasks_V2([
                    { key: "supplier_id", operator: "==", value: Number(supplier_id) },
                    { key: "date_time_from", operator: ">=", value: startDate },
                    { key: "date_time_from", operator: "<", value: endDate },
                    { key: "type", operator: "in", value: [TASK_TYPES.SUPPLIER_INSOURCE, TASK_TYPES.MP_INSOURCE] },
                ]);
                onTasksUpdate(tasks);
                setTasksLoading(false);
            } catch (error) {
                setTasksLoading(false);
                console.log(error);
            }
        },
        [supplier_id]
    );

    const onTasksUpdate = async (snapshot) => {
        try {
            setTaskDetailsLoading(true);
            // const newTasks = snapshot.docs.map((doc) => doc.data());
            const newTasks = snapshot.map((doc) => doc.data());
            // console.log(newTasks);

            // get outlet branch info from tasks
            const outletBranches = (await getOutletBranchesFromTasks(newTasks)).map((branch) => branch.data());

            // get merchandisers info from tasks
            const newMerchandisers = await getMerchandisersFromTasks(newTasks, merchandisers);

            // listen to task details from tasks
            //this can be muiltiple snapshot listeners for every 10 tasks
            const options = {
                isEnded: false,
                enableOnSnapshot: false,
                // onSnapshot: (snapshot, index, length) => onTaskDetailsUpdate(snapshot, index, length),
                // onError: (error) => console.log(error),
            };
            // unsubDetails.current = await getDetailsFromTasks(newTasks, options);
            const taskDetails = await getDetailsFromTasks(newTasks, options);

            // unique product id
            const productIds = taskDetails.map((taskDetail) => taskDetail.product_id);
            const uniqueProductIds = [...new Set(productIds)];
            //get product info
            const products = (await getProducts(uniqueProductIds)).map((product) => product.data());
            //assign product info to task details
            taskDetails.forEach((taskDetail) => {
                const product = products.find((product) => product.product_id === taskDetail.product_id);
                taskDetail.product_name = {
                    en: product.En_name,
                    ar: product.Ar_name,
                };
            });

            //if task details listeners are empty, there is no task details to listen to
            // if (unsubDetails.current.length === 0) {
            // setTaskDetailsLoading(false);
            // }

            setTasks(newTasks);
            setOutletBranches(outletBranches);
            setMerchandisers(newMerchandisers);
            // setTasksLoading(false);

            setTaskDetailsLoading(false);
            setTaskDetails(taskDetails);
        } catch (error) {
            setTasksLoading(false);
            console.log(error);
        }
    };

    // const onTasksError = (error) => {
    //     setTasksLoading(false);
    //     console.log(error);
    // };

    // const onTaskDetailsUpdate = async (snapshot, index, length) => {
    //     const taskDetails = snapshot.docs.map((doc) => doc.data());
    //     // set task details in listeners results array so we can fletten it later
    //     setTaskDetailsSnapshotResults((prev) => {
    //         let newResults = [...prev];
    //         newResults[index] = taskDetails;
    //         return newResults;
    //     });
    //     //increment counter
    //     setTaskDetailsSnapshotsCounter((prev) => prev + 1);
    // };

    useEffect(() => {
        getTasks(dateRange.startDate, dateRange.endDate);
    }, [getTasks, dateRange.startDate, dateRange.endDate]);

    // flatten task details results (because we have multiple snapshot listeners for every 10 tasks)
    // useEffect(() => {
    //     if (taskDetailsSnapshotResults.length > 0) {
    //         // flatten snapshotResults
    //         const flat = taskDetailsSnapshotResults.reduce((acc, curr) => acc.concat(curr ?? []), []);
    //         setTaskDetails(flat);
    //     }
    // }, [taskDetailsSnapshotResults]);

    // wait for all task details to be loaded
    // useEffect(() => {
    //     if (taskDetailsSnapshotsCounter >= unsubDetails.current?.length) {
    //         setTaskDetailsLoading(false);
    //     }
    // }, [taskDetails]);

    //on unmount effect
    // useEffect(() => {
    //     return () => {
    //         //unsubscribe
    //         unsub.current?.();
    //         unsubDetails.current.forEach((unsub) => unsub?.());
    //     };
    // }, []);

    return (
        <section className={classes.dashboard}>
            {/* date range filter */}
            <DateRangePicker onApply={onDateRangeChange} ranges={[dateRange]} />
            {/* refresh button */}
            <RefreshButton onClick={() => getTasks(dateRange.startDate, dateRange.endDate)} />

            <Header className={classes.header}>Operational</Header>
            <Grid container spacing={2} className={classes.dashboardContent}>
                {/* Pie Chart for tasks */}
                <Grid item xs={12} sm={12} md={3} className={classes.chart}>
                    <DailyVisitsPie tasks={tasks} />
                </Grid>

                <Grid item xs={12} sm={12} md={3} className={classes.chart}>
                    <OutOfStockTable
                        tasks={tasks}
                        taskDetails={taskDetails}
                        outletBranches={outletBranches}
                        loading={taskDetailsLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3} className={classes.chart}>
                    <ExpiredDamagedProductsTable
                        tasks={tasks}
                        taskDetails={taskDetails}
                        outletBranches={outletBranches}
                        loading={taskDetailsLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={3} className={classes.chart}>
                    <NearExpiryProductsTable
                        tasks={tasks}
                        taskDetails={taskDetails}
                        outletBranches={outletBranches}
                        loading={taskDetailsLoading}
                    />
                </Grid>
            </Grid>

            <Header className={classes.header}>Productivity</Header>
            <Grid container spacing={2} className={classes.dashboardContent}>
                {/* <Grid item xs={12} sm={12} md={12} className={classes.chart}>
                    <UnavailableSKUsBar tasks={tasks} />
                </Grid> */}
                <Grid item xs={12} sm={12} md={3} className={classes.chart}>
                    <OutOfStockBar tasks={tasks} taskDetails={taskDetails} loading={taskDetailsLoading} />
                </Grid>
                <Grid item xs={12} sm={12} md={3} className={classes.chart}>
                    <MissedVisitsTable tasks={tasks} outletBranches={outletBranches} loading={tasksLoading} />
                </Grid>
                <Grid item xs={12} sm={12} md={3} className={classes.chart}>
                    <InstoreTimeTable tasks={tasks} merchandisers={merchandisers} loading={tasksLoading} />
                </Grid>
                <Grid item xs={12} sm={12} md={3} className={classes.chart}>
                    <InstoreIdealTimeTable tasks={tasks} merchandisers={merchandisers} loading={tasksLoading} />
                </Grid>
            </Grid>

            <Header className={classes.header}>Performance</Header>
            <Grid container spacing={2} className={classes.dashboardContent}>
                <Grid item xs={12} sm={12} md={12} className={classes.chart}>
                    <OutOfStockTrendLine
                        tasks={tasks}
                        taskDetails={taskDetails}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        loading={taskDetailsLoading}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} className={classes.chart}>
                    <ConsecutiveUnavailabilityTable tasks={tasks} taskDetails={taskDetails} outletBranches={outletBranches} loading={taskDetailsLoading} />
                </Grid> */}
            </Grid>
        </section>
    );
};

export default Dashboard;
