import * as Yup from "yup";
import { PLATFORM, PROJECT_TYPES } from "../../constants/global";
import { getToday, getTomorrow } from "../../helpers/date-utils";

const arabicRegex = /^[\u0600-\u06FF_-\d\s]+$/;
const englishRegex = /^[A-Za-z_-\d\s]+$/;
const phoneRegExp =
    /^((\+?\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

const MAX_FILE_SIZE = 1_048_576; //1MB
const SUPPORTED_FILE_FORMATS = ["application/pdf"];

Yup.addMethod(Yup.string, "phone", function (msg) {
    return this.matches(phoneRegExp, msg);
});

Yup.addMethod(Yup.mixed, "fileFormat", function () {
    return this.test(
        "file-format",
        "Only allowed .pdf format",
        (value) => !value || SUPPORTED_FILE_FORMATS.includes(value?.type)
    );
});

Yup.addMethod(Yup.mixed, "fileSize", function () {
    return this.test("file-size", "Max size: 1 MB", (value) => !value || value?.size <= MAX_FILE_SIZE);
});

export const FORM_SCHEMA = Yup.object().shape({
    authorization_letter: Yup.lazy((value) => {
        if (typeof value === "object") {
            return Yup.mixed().fileFormat().fileSize();
        } else {
            return Yup.string();
        }
    }),
    projName: Yup.string().required("Required Field"),
    date_from: Yup.date("Invalid Date").required("Required Field"),
    date_to: Yup.date("Invalid Date").required("Required Field"),
    remarks: Yup.string(),
});

export const INITIAL_VALUES = {
    // platform: PLATFORM,

    // year: null,
    // branches: [],
    // merch_gender: "both",
    // mp_id: null,
    // supplier_id: null,
    // outlet_id: null,
    // discount_amount: 0,
    remarks: "",
    // confirm: "1",
    projName: "",
    // supplier_confirmation: false,
    date_from: getTomorrow(),
    // month: null,
    // day: null,
    // projValue_cost: 0,
    // short_id: null,
    project_type: PROJECT_TYPES.SUPPLIER_INSOURCE,
    // generate_task: "0",
    // merch_class_level: 1,
    // date_from_task_generation: getTomorrow(),
    // project_date: new Date(),
    // mp_confirmation: false,
    date_to: getTomorrow(),
    // projValue_selling: 0,
    authorization_letter: null,
    // isConfirmed: false,
    // project_id: null,
    // date_to_task_generation: getTomorrow(),
    // planogram: null,

    formType: "quantity", // simple, quantity or pictures
    inventory: "none",
    near_expiration: "monthly",
    warehouse: "daily",
    shelf_price: "weekly",
    display_audit: "none",
    competitor_activity: "none",
    damaged: "daily",

    // options: {
    //     availability: { active: false },
    //     quantity: { active: true },
    //     pictures: { active: false },
    //     inventory: { active: false, interval: "daily" },
    //     near_expiration: { active: false, interval: "daily" },
    //     warehouse: { active: true },
    //     shelf_price: { active: false },
    // },
};
