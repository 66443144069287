import { useEffect, useState } from "react";
import { useAuth } from "../contexts/auth-context";
import { getDaysSinceSpecificDate } from "../helpers/date-utils";

const ACCOUNT_VERIFICATION_STATUS = {
    PENDING: "PENDING",
    VERIFIED: "VERIFIED",
    EXPIRED: "EXPIRED",
};
const MAX_PENDING_DAYS = 10;

const useEmailVerification = () => {
    const { currentUser, userData, sendVerificationEmail } = useAuth();
    const [isSendingEmail, setIsSendingEmail] = useState(false);

    let isVerified = false;
    let pendingElapsedDays = -1;

    if (currentUser && userData) {
        isVerified = currentUser.emailVerified;
        pendingElapsedDays = getDaysSinceSpecificDate(userData.created_at);
    }

    const verificationStatus = () => {
        if (isVerified) {
            return ACCOUNT_VERIFICATION_STATUS.VERIFIED;
        } else if (pendingElapsedDays > MAX_PENDING_DAYS) {
            return ACCOUNT_VERIFICATION_STATUS.EXPIRED;
        } else {
            return ACCOUNT_VERIFICATION_STATUS.PENDING;
        }
    };

    const resendVerificationEmail = async () => {
        try {
            setIsSendingEmail(true);
            await sendVerificationEmail(currentUser.email);
            setIsSendingEmail(false);
            return true;
        } catch (error) {
            console.error(error.message);
            setIsSendingEmail(false);
            return false;
        }
    };

    return {
        ACCOUNT_VERIFICATION_STATUS,
        isVerified,
        pendingElapsedDays,
        verificationStatus,
        resendVerificationEmail,
        isSendingEmail,
    };
};

export default useEmailVerification;
