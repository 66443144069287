import React, { useCallback, useEffect, useReducer, useState } from "react";
import { Collapse, Tooltip } from "@material-ui/core";
import { Filters, VisitsPoolManager } from "../../components";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { useAuth } from "../../contexts/auth-context";
import { Button, CircularLoader, Header, TransitionAlert } from "../../core-ui/custom";
import { getToday } from "../../helpers/date-utils";
import { getAllCities } from "../../services/firestore/City";
import { getAllOutlets, getSupplierOutlets } from "../../services/firestore/Outlet";
import { getOutletBranches, getOutletBranchesByCompany } from "../../services/firestore/Outlet_Branch";

//related to meterial ui package
import styles from "./styles";
import { TASK_TYPES } from "../../constants/global";
import {
    getMerchandiserTaskHeadersByDate,
    getMerchandiserTasksByDate,
    getUnassignedTasksFromHeaders,
    queryTasks_V2,
    saveTaskAssignment,
} from "../../services/firestore/Task";
import { getMerchandiserName, getMerchandisersBySupplierBranch } from "../../services/firestore/Merchandiser";
import { TASKS_STATUS } from "../../constants/constants-lagacy";
import Swal from "sweetalert2";
import COLORS from "../../constants/colors";
import { cloneDeep } from "lodash";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";

const FILTERS = {
    branch: {
        label: "Branch",
        options: [],
        initialValue: null,
        componentProps: {
            disableClearable: true,
        },
    },
    merchandiserName: {
        label: "Merchandiser Search",
        component: "text",
        initialValue: "",
    },
    date: {
        label: "Date",
        component: "date",
        initialValue: getToday(),
    },
    cities: {
        label: "City",
        options: [],
        initialValue: null,
    },
    outlets: {
        label: "Outlet",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    outletBranches: {
        label: "Outlet Branch",
        options: [],
        initialValue: null,
    },
};

const dataSourcesReducer = (state, { type, payload }) => {
    switch (type) {
        case "MULTI_SET":
            return {
                ...state,
                ...payload,
            };
        case "SET":
            return {
                ...state,
                [payload.key]: payload.value,
            };
        case "RESET":
            return {
                ...state,
                [payload.key]: payload.initialValue,
            };
        default:
            return state;
    }
};

const initialState = {
    branches: [],
    cities: [],
    outlets: [],
    outletBranches: [],
    selectedBranch: "",
    merchandiserName: "",
    date: getToday(),
    selectedCity: null,
    selectedOutlet: null,
    selectedOutletBranch: null,
};

const VisitsPool = (props) => {
    const classes = styles();
    const { companyData } = useAuth();

    // loading flags
    const [initializing, setInitializing] = useState(true);
    const [applyingFilters, setApplyingFilters] = useState(false);
    const [saving, setSaving] = useState(false);
    // filters state
    const [dataSources, dispatchDataSources] = useReducer(dataSourcesReducer, initialState);
    const [filtersVisible, setFiltersVisible] = useState(false);

    const [alert, setAlert] = useState({
        isOpen: false,
        message: "",
        variant: "filled",
        type: "success",
    });

    // states to keep track of changes
    const [tasks, setTasks] = useState([]);
    const [merchandisers, setMerchandisers] = useState([]);
    const [updatedTasks, setUpdatedTasks] = useState([]);
    const [updatedMerchandisers, setUpdatedMerchandisers] = useState([]);
    // const [selectedBranch, setSelectedBranch] = useState(null);

    const init = useCallback(async () => {
        try {
            const { company_id } = companyData;
            // load filters options
            let [branches, cities, outlets] = (
                await Promise.all([getBranchesBySupplierID(company_id), getAllCities(), getSupplierOutlets(company_id)])
            ).map((docs) => docs.map((doc) => doc.data()));

            //change the structure of the data
            branches = branches.map((s) => ({ value: s.branch_id, label: s.En_name, data: s }));
            cities = cities.map((c) => ({ value: `${c.city_id}`, label: c.En_name, data: c }));
            outlets = outlets.map((o) => ({ value: o.outlet_id, label: o.En_short_name, data: o }));

            dispatchDataSources({
                type: "MULTI_SET",
                payload: {
                    branches,
                    cities,
                    outlets,
                    selectedBranch: branches[0] || null,
                },
            });

            // if(branches.length > 0)
            // handleOutletsChange(branches[0].branch_id);
            // applyTasksFilters({date: FILTERS.date.initialValue, cities, outlets, outletBranches, insource_included, outsource_included});
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    }, [companyData]);

    const handleOutletsChange = useCallback(async (option, context) => {
        try {
            if (!option) {
                dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: [] } });
                context.dispatchFilters({
                    type: "CLEAR_FILTER",
                    payload: { id: "outletBranches" },
                });
                return;
            }

            let outletBranches = (await getOutletBranchesByCompany(option.value)).map((doc) => doc.data());

            outletBranches = outletBranches.map((ob) => ({ value: ob.branch_id, label: ob.En_short_name }));

            dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: outletBranches } });
            context.dispatchFilters({
                type: "SET_FILTER_OPTIONS",
                payload: { id: "outletBranches", options: outletBranches },
            });
        } catch (error) {
            throw error;
        }
    }, []);

    const getChangedTasks = useCallback(() => {
        const changed = updatedTasks.filter((taskBuffer) => {
            const task = tasks.find((task) => task.task_id === taskBuffer.task_id);
            return !task || task.dailyOrder !== taskBuffer.dailyOrder;
        });

        return changed;
    }, [tasks, updatedTasks]);

    const getChangedMerchandisers = useCallback(() => {
        const changed = updatedMerchandisers.filter((merchBuffer) => {
            const merch = merchandisers.find((merch) => merch.uid === merchBuffer.uid);
            const changedTasks = merchBuffer.tasks.filter((taskBuffer) => {
                const task = merch.tasks.find((task) => task.task_id === taskBuffer.task_id);
                return !task || task.dailyOrder !== taskBuffer.dailyOrder;
            });
            return changedTasks.length > 0;
        });

        return changed;
    }, [merchandisers, updatedMerchandisers]);

    const onSave = useCallback(async () => {
        try {
            setSaving(true);
            //compare tasks with buffer and extract the difference for saving
            const tasksToSave = getChangedTasks();
            // in each merchandiser, compare tasks with buffer and extract the difference for saving
            let merchandisersToSave = getChangedMerchandisers();
            // filter the tasks in each merchandiser to only save the ones that have changed
            merchandisersToSave = updatedMerchandisers.map((merchBuffer) => {
                //find the saved counterpart of the merchandiser
                const savedMerch = merchandisers.find((merch) => merch.uid === merchBuffer.uid);
                //compare tasks with buffer and extract the difference for saving
                const tasksToSave = merchBuffer.tasks.filter((taskBuffer) => {
                    const task = savedMerch.tasks.find((task) => task.task_id === taskBuffer.task_id);
                    return !task || task.dailyOrder !== taskBuffer.dailyOrder;
                });
                return { ...merchBuffer, tasks: tasksToSave };
            });

            //save tasks
            const tasksPromises = tasksToSave.map((task) => saveTaskAssignment(task));
            //save merchandisers
            const merchandisersPromises = merchandisersToSave.map((merch) => {
                const tasksPromises = merch.tasks.map((task) => saveTaskAssignment(task));
                return Promise.all(tasksPromises);
            });

            await Promise.all([...tasksPromises, ...merchandisersPromises]);

            setAlert({
                type: "success",
                message: "Saved successfully",
                isOpen: true,
                variant: "filled",
            });
            setTasks(updatedTasks);
            setMerchandisers(updatedMerchandisers);
        } catch (error) {
            console.log(error);
        }
        setSaving(false);
    }, [getChangedMerchandisers, getChangedTasks, merchandisers, updatedMerchandisers, updatedTasks]);

    const applyMerchandisersFilters = useCallback(async (values) => {
        try {
            const { merchandiserName, branch, date } = values;

            let newMerchandisers = branch?.value ? await getMerchandisersBySupplierBranch(branch.value) : [];
            //doc to data
            newMerchandisers = newMerchandisers.map((doc) => doc.data());
            //filter by name
            if (merchandiserName) {
                newMerchandisers = newMerchandisers.filter((merch) =>
                    getMerchandiserName(merch).toLowerCase().includes(merchandiserName.toLowerCase())
                );
            }
            newMerchandisers = await searchMerchandisersTasks(newMerchandisers, date);

            setMerchandisers(newMerchandisers);
            setUpdatedMerchandisers(cloneDeep(newMerchandisers));
            dispatchDataSources({ type: "SET", payload: { selectedBranch: branch } });
        } catch (error) {
            throw error;
        }
    }, []);

    const getUnassignedTasks = useCallback(
        async (startDate, endDate, cities, outlets, outletBranches) => {
            try {
                const query = [
                    { key: "supplier_id", operator: "==", value: Number(companyData.company_id) },
                    { key: "date_time_from", operator: ">=", value: startDate },
                    { key: "date_time_from", operator: "<=", value: endDate },
                    {
                        key: "type",
                        operator: "in",
                        value: [TASK_TYPES.SUPPLIER_INSOURCE, TASK_TYPES.SUPPLIER_INSOURCE_ELC],
                    },
                    { key: "state", operator: "==", value: TASKS_STATUS.OPEN },
                ];

                if (cities?.value) query.push({ key: "city_id", operator: "==", value: Number(cities.value) });
                if (outlets?.value) query.push({ key: "outlet_id", operator: "==", value: Number(outlets.value) });
                if (outletBranches?.value)
                    query.push({ key: "outlet_branch_id", operator: "==", value: Number(outletBranches.value) });

                return (await queryTasks_V2(query)).map((doc) => doc.data());
            } catch (error) {
                throw error;
            }
        },
        [companyData.company_id]
    );

    const applyTasksFilters = useCallback(
        async (values) => {
            try {
                const { date, cities, outlets, outletBranches } = values;
                const startDate = new Date(date);
                const endDate = new Date(date);
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(23, 59, 59, 999);

                let tasks = await getUnassignedTasks(startDate, endDate, cities, outlets, outletBranches);
                // get open tasks and populate them with data (because they're headers)
                tasks = await getUnassignedTasksFromHeaders(tasks);

                // get unique outlet branch ids
                let outletBranchIds = tasks.map((task) => `${task.outlet_branch_id}`);
                outletBranchIds = [...new Set(outletBranchIds)];
                // get outlet branch info from Outlet_Branch
                const tasksOutletBranches = (await getOutletBranches(outletBranchIds)).map((doc) => doc.data());
                // set outlet branch info into task
                for (const task of tasks) {
                    const outletBranch = tasksOutletBranches.find(
                        (outletBranch) => outletBranch.branch_id === `${task.outlet_branch_id}`
                    );
                    task.outlet_branch = outletBranch;
                }

                setTasks(tasks);
                setUpdatedTasks(cloneDeep(tasks));
            } catch (error) {
                throw error;
            }
        },
        [getUnassignedTasks]
    );

    const applyFilters = useCallback(
        async (filters) => {
            try {
                //check if there are any unsaved changes by comparing the buffer with the current merchandisers
                const changed = getChangedMerchandisers();
                //check if there are any unsaved changes by comparing the buffer with the current Tasks
                const changedTasks = getChangedTasks();

                if (changed.length > 0 || changedTasks.length > 0) {
                    let result = await Swal.fire({
                        icon: "warning",
                        showCloseButton: true,
                        showCancelButton: true,
                        confirmButtonColor: COLORS.SUBMIT,
                        cancelButtonColor: "",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        reverseButtons: true,
                        title: "Do You Want To Save Changes?",
                        text: "Before you can apply filters, you must save your changes.",
                    });

                    if (!result.value) {
                        return false;
                    }
                    await onSave();
                }

                setApplyingFilters(true);
                await applyTasksFilters(filters);
                await applyMerchandisersFilters(filters);
            } catch (error) {
                console.log(error);
            }

            setApplyingFilters(false);
        },
        [onSave, applyMerchandisersFilters, applyTasksFilters, getChangedMerchandisers, getChangedTasks]
    );

    // after the filters component is mounted, load the data
    const initFilters = useCallback(
        (context) => {
            const filters = cloneDeep(FILTERS);
            filters.branch.options = dataSources.branches;
            filters.branch.initialValue = dataSources.selectedBranch || null;

            filters.cities.options = dataSources.cities;
            filters.outlets.options = dataSources.outlets;

            filters.outlets.onChange = handleOutletsChange;

            context.setFilters(filters);
            // load tasks and merchandisers with default filters
            applyFilters({
                date: filters.date.initialValue,
                merchandiserName: filters.merchandiserName.initialValue,
                branch: filters.branch.initialValue,
            });
        },
        [
            dataSources.branches,
            dataSources.selectedBranch,
            dataSources.cities,
            dataSources.outlets,
            handleOutletsChange,
            applyFilters,
        ]
    );

    const filterBtnClicked = () => {
        setFiltersVisible((prev) => !prev);
    };

    const searchMerchandisersTasks = async (merchandisers, date) => {
        try {
            let newMerchandisers = await Promise.all(
                merchandisers.map(async (merch) => {
                    const tasks = (await getMerchandiserTasksByDate(merch.uid, date)).map((doc) => doc.data());
                    const headerTasks = await getMerchandiserTaskHeadersByDate(merch.uid, date);
                    const tasksWithHeaders = [...tasks, ...headerTasks];
                    //sort tasks by dailyOrder
                    tasksWithHeaders.sort((a, b) => a.dailyOrder - b.dailyOrder);

                    // get unique outlet branch ids
                    let outletBranchIds = tasksWithHeaders.map((task) => `${task.outlet_branch_id}`);
                    outletBranchIds = [...new Set(outletBranchIds)];
                    // get outlet branch info from Outlet_Branch
                    const tasksOutletBranches = (await getOutletBranches(outletBranchIds)).map((doc) => doc.data());
                    // set outlet branch info into task
                    tasksWithHeaders.forEach((task, index) => {
                        const outletBranch = tasksOutletBranches.find(
                            (outletBranch) => outletBranch.branch_id === `${task.outlet_branch_id}`
                        );
                        task.outlet_branch = outletBranch;
                        if (task.dailyOrder === -1) {
                            task.dailyOrder = index;
                        }
                    });

                    return { ...merch, tasks: tasksWithHeaders };
                })
            );

            return newMerchandisers;
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        init();
    }, [init]);

    return (
        <section className={classes.visitsPool}>
            <TransitionAlert
                variant={alert.variant}
                open={alert.isOpen}
                setOpen={(bool) => setAlert((prev) => ({ ...prev, isOpen: bool }))}
                className={classes.stickyAlert}
                type={alert.type}
                timer={3000}
            >
                {alert.message}
            </TransitionAlert>
            <Header className={classes.header}>Visits Pool</Header>
            {!initializing && (
                <div className={classes.actions}>
                    <Tooltip title="Filters" arrow>
                        <>
                            <Button className={classes.filterBtn} onClick={() => filterBtnClicked()}>
                                <FormatListBulletedIcon />
                            </Button>
                        </>
                    </Tooltip>

                    <Button className={classes.saveBtn} onClick={onSave} disabled={saving}>
                        {saving ? "Saving..." : "Save"}
                    </Button>
                    <div className={classes.filters}>
                        <Collapse in={filtersVisible}>
                            <Filters init={initFilters} onSubmit={applyFilters} submitText="Apply" />
                        </Collapse>
                    </div>
                </div>
            )}

            {initializing || applyingFilters ? <CircularLoader /> : null}
            {!initializing && !applyingFilters ? (
                <VisitsPoolManager
                    merchandisers={updatedMerchandisers}
                    tasks={updatedTasks}
                    updateMerchandisers={(updatedMerchandisers) => setUpdatedMerchandisers(updatedMerchandisers)}
                    updateTasks={(updatedTasks) => setUpdatedTasks(updatedTasks)}
                />
            ) : null}
        </section>
    );
};

export default VisitsPool;
