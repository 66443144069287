import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import PATHS from "../../constants/paths";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import Button from "../../core-ui/custom/button";

const styles = makeStyles({
    pageRoot: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
});

const PageErrorMessage = ({ message, goBackPath = PATHS.DASHBOARD }) => {
    const classes = styles();

    return (
        <section className={classes.pageRoot}>
            <p>{message}</p>
            <Link to={goBackPath}>
                <Button>Go Back</Button>
            </Link>
        </section>
    );
};

PageErrorMessage.propTypes = {
    classes: PropTypes.object,
};

export default PageErrorMessage;
