import React from "react";
import { EnhancedTransferList } from "../../components";
import { Button, CircularLoader, Header, LoadingOverlay, TransitionAlert } from "../../core-ui/custom";
import styles from "./styles";
import useLinkOutlets from "./use-link-outlets";

const LinkOutlets = () => {
    const classes = styles();
    const {
        initializing,
        saving,
        // transferListState,
        leftList,
        rightList,
        checkedList,
        transferHandler,
        filterHandler,
        saveHandler,
        alertState,
        setAlertOpen,
    } = useLinkOutlets();

    // const { leftList, rightList, checkedList } = transferListState;

    return (
        <section className={classes.root}>
            <TransitionAlert
                variant="filled"
                severity={alertState.severity}
                open={alertState.open}
                setOpen={setAlertOpen}
                className={classes.stickyAlert}
                timer={3000}
            >
                {alertState.message}
            </TransitionAlert>
            {initializing && <LoadingOverlay />}

            <div className={classes.card}>
                <Header className={classes.header}>Select Outlet Companies You to Deal With</Header>
                <EnhancedTransferList
                    leftList={leftList}
                    rightList={rightList}
                    checkedList={checkedList}
                    onTransfer={transferHandler}
                    onFilter={filterHandler}
                />
                <Button className={classes.saveBtn} onClick={saveHandler} disabled={initializing || saving}>
                    {saving ? <CircularLoader size={24} className={classes.circularProgress} /> : "Save"}
                </Button>
            </div>
        </section>
    );
};

export default LinkOutlets;
