import React from "react";
import { Checkbox, makeStyles, TextField } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import { Autocomplete } from "@material-ui/lab";
import COLORS from "../../../constants/colors";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useRootStyle = makeStyles({
    root: {
        "& label.Mui-focused": {
            color: COLORS.FRENSH_BLUE,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: COLORS.FRENSH_BLUE,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&:hover fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&.Mui-focused fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
        },
    },
    input: {
        "& .MuiInputBase-root": {
            fontSize: "0.8rem",
        },

        "& .MuiInputLabel-outlined": {
            fontSize: "0.7rem",
            transform: "translate(14px, 12px) scale(1)",
        },

        "& .MuiInputLabel-outlined.MuiInputLabel-marginDense": {
            fontSize: "0.7rem",
            transform: "translate(10px, 12px) scale(1)",
        },
        "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root": {
            padding: "6px 4px",
            paddingRight: "27px",
        },

        "& .MuiInputBase-root.MuiOutlinedInput-root .MuiInputBase-input.MuiOutlinedInput-input": {
            padding: "4px 4px",
        },

        "& .MuiOutlinedInput-notchedOutline legend": {
            fontSize: "0.5rem",
        },

        "& .MuiFormHelperText-root.Mui-error": {
            position: "absolute",
            top: "35px",
            left: 0,
            fontSize: "0.6rem",
            margin: 0,
        },
    },
    option: {
        padding: "2px 8px",
        fontSize: "0.8rem",
    },
});

const AutocompleteWrapper = ({ name, options, label, onChange, multiple = false, ...otherProps }) => {
    const [field, meta] = useField(name);
    const { setFieldValue } = useFormikContext();
    const rootStyle = useRootStyle();

    const handleChange = (e, value) => {
        setFieldValue(name, value);
        if (onChange) onChange(value);
    };

    const textFieldConfig = {
        name: name,
        label: label,
        variant: "outlined",
        classes: {
            root: rootStyle.root,
        },
    };

    let config = {
        ...field,
        className: rootStyle.input,
        classes: { option: rootStyle.option },
        options: options,
        getOptionLabel: (opt) => opt.label,
        getOptionSelected: (opt, selected) => opt.value === selected.value,
        fullWidth: true,
        onChange: handleChange,
        renderInput: (params) => <TextField {...textFieldConfig} {...params} />,
        ...otherProps,
    };

    if (multiple) {
        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;

        config = {
            ...config,
            multiple: true,
            disableCloseOnSelect: true,
            renderInput: (params) => (
                <TextField
                    {...textFieldConfig}
                    {...params}
                    variant="outlined"
                    placeholder={`${field.value?.length} Selected`}
                />
            ),
            renderTags: (value, getTagProps) => value.map((option, index) => null),
            renderOption: (option, { selected }) => (
                <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option["label"] ?? "-"}
                </React.Fragment>
            ),
        };
    }

    if (meta && meta.touched && meta.error) {
        textFieldConfig.error = true;
        textFieldConfig.helperText = meta.error;
    }

    return <Autocomplete {...config} />;
};

export default AutocompleteWrapper;
