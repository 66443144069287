import { makeStyles } from "@material-ui/core";

export default makeStyles({
    headerMenu: {
        "& > ul": {
            margin: "0",
            padding: "0",
        },
    },

    "@media (min-width: 1050px)": {
        headerMenu: {
            "& > ul": {
                display: "flex",
            },
        },
    },
});
