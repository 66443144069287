import React, { useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";

import { useAuth } from "../../contexts/auth-context";
import { CircularLoader, Header } from "../../core-ui/custom";
import { useCallback } from "react";
import { querySubscription } from "../../services/firestore/Subscription/Subscription";
import { SubscriptionList } from "../../components";
import { deleteAllTasks, deleteOtherCollections, migrateProducts, migrateSupplier } from "../../services/migration";
import { firestore } from "../../services/firebase";

const Subscription = (props) => {
    const classes = styles();
    const [loading, setLoading] = React.useState(true);
    const [subscriptions, setSubscriptions] = React.useState([]);
    const { companyData } = useAuth();

    const init = useCallback(async () => {
        setLoading(true);
        try {
            //migrate supplier
            // let refs = [];
            // for (let index = 104; index < 839; index++) {
            //     refs.push(firestore.collection("Task").doc(`${index}`));
            // }

            // await Promise.all(refs.map(async (ref) => ref.set({ a: 1, supplier_id: 45 })));

            // await deleteAllTasks("113");
            // await migrateSupplier("113");
            // console.log("migrate supplier done");

            // await migrateProducts("113", 213);
            // console.log("migrate products done");

            // await deleteOtherCollections();
            // console.log("delete other collections done");

            // const routev2 = (await firestore.collection("Route_V2").get()).docs;
            // await Promise.all(
            //     routev2.map(async (doc) => {
            //         // firestore.collection("Route").doc(doc.id).set(doc.data());
            //         return doc.ref.delete();
            //     })
            // );

            //get all subscriptions
            let subscriptions = await querySubscription([
                { key: "company_id", operator: "==", value: companyData.company_id },
                { key: "company_type", operator: "==", value: "supplier" },
            ]);

            //process data
            subscriptions = subscriptions.map((subscription) => {
                return {
                    ...subscription.data(),
                    start_date: subscription.data().start_date.toDate(),
                    end_date: subscription.data().end_date.toDate(),
                    date: subscription.data().date.toDate(),
                };
            });

            setSubscriptions(subscriptions);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }, [companyData.company_id]);
    useEffect(() => {
        init();
    }, [init]);

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.header}>Subscriptions</Header>
            {loading ? <CircularLoader /> : <SubscriptionList subscriptions={subscriptions} refresh={init} />}
        </section>
    );
};

Subscription.propTypes = {
    classes: PropTypes.object,
};

export default Subscription;
