import React, { useCallback, useEffect, useState } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { Button, CircularLoader, DashboardCard } from "../../../core-ui/custom";
import LineChart from "../../../core-ui/charts/line-chart";
import { formatDate } from "../../../helpers/date-utils";
import { Card } from "@material-ui/core";
import { Link } from "react-router-dom";
import COLORS from "../../../constants/colors";
import { exportExcel } from "../../../services/excel";

const COLUMNS = [
    { key: "date", header: "Date", type: "normal", width: "auto" },
    { key: "outlet_branch", header: "Outlet Branch", type: "normal", width: "auto" },
    { key: "city", header: "City", type: "normal", width: "auto" },
    { key: "product_number", header: "Code", type: "normal", width: "auto" },
    { key: "brand", header: "Brand", type: "normal", width: "auto" },
    { key: "en_name", header: "Product", type: "normal", width: "auto" },
    { key: "shelf_price", header: "Shelf Price", type: "normal", width: "auto" },
    { key: "qty", header: "Qty.", type: "normal", width: "auto" },
    { key: "total_price", header: "Net Total", type: "normal", width: "auto" },
    { key: "promoter", header: "Promoter", type: "normal", width: "auto" },
];

const settings = {
    curve: "basis",
    enableArea: true,
    enableGridX: false,
    margin: { top: 10, right: 60, bottom: 30, left: 60 },
    axisTop: null,
    axisRight: null,
    axisBottom: null,
    pointSize: 1,
    pointBorderWidth: 2,
    pointLabelYOffset: -12,
    useMesh: true,
    colors: [COLORS.SUBMIT],
    // axisBottom: {
    //     orient: "bottom",
    //     tickSize: 5,
    //     tickPadding: 5,
    //     tickRotation: 25,
    //     legend: "Period",
    //     legendOffset: 66,
    //     legendPosition: "middle",
    // },
    axisLeft: {
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Period",
        legendOffset: -40,
        legendPosition: "middle",
    },
    // enableSlices: "y",
    tooltip: (d) => {
        return (
            <Card style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: 20, height: 20, backgroundColor: d.point.color, marginRight: 5 }}></div>
                <div style={{ marginRight: 5 }}>
                    <b>Sales</b> {d.point.data.y}
                </div>
                <div style={{ marginRight: 5 }}>
                    <b>Date</b> {d.point.data.xFormatted}
                </div>
            </Card>
        );
    },
};

const ProductSalesLine = ({ tasks, taskDetails, startDate, endDate, loading = false }) => {
    const classes = styles();
    const [lineData, setLineData] = useState([]);

    const [rows, setRows] = useState([]);

    const init = useCallback(async () => {
        try {
            setLineData([]);
            setup(tasks, taskDetails, startDate, endDate);
        } catch (error) {
            console.log(error);
        }
    }, [tasks, taskDetails, startDate, endDate]);

    const setup = useCallback(async (tasks, taskDetails, startDate, endDate) => {
        try {
            const lineData = [
                {
                    id: "Sales",
                    color: "hsl(84, 50%, 50%)",
                    data: [],
                },
            ];

            const sellers = ["Nizar Rajab", "Abdulmajeed BinSaad"];

            const ob = [
                "Extra Al Sultan",
                "Danube 2 - Khaldiya",
                "Extra Amir Fawwaz",
                "Extra Old Airport",
                "Danube - Watani",
                "Panda",
            ];
            const products = [
                {
                    product_number: "VBIO524000",
                    brand: "Toshiba",
                    en_name: "Toshiba 50 Inch, UHD 4K, SMART, 50U5069EE,60Hz",
                    shelf_price: 1129,
                },
                {
                    product_number: "Tosh4300",
                    brand: "Toshiba",
                    en_name: "TOSHIBA Canvio Advance 2TB HDD Portable, Green",
                    shelf_price: 600,
                },
                {
                    product_number: "123QABC",
                    brand: "Sharp",
                    en_name: "Sharp, 50 Inch, 4K HDR, Smart, 4T-C50BK1X,60Hz",
                    shelf_price: 2000,
                },
                {
                    product_number: "qaxd123",
                    brand: "Sharp",
                    en_name: "Sharp Microwave with Grill, 34L,1000W, Silver",
                    shelf_price: 500,
                },
                {
                    product_number: "qaxd1564",
                    brand: "Sharp",
                    en_name: "Sharp 70 Inch, 4K HDR, Android",
                    shelf_price: 3300,
                },
                {
                    product_number: "ds5235",
                    brand: "Toshiba",
                    en_name: "Toshiba Bottom Loading Water Dispenser 50/60Hz Stainless Steel Tank Hot Cold Black",
                    shelf_price: 300,
                },
            ];

            let rows = [];
            // traverse from start date to end date
            for (
                let currentDate = new Date(startDate);
                currentDate <= endDate;
                currentDate.setDate(currentDate.getDate() + 1)
            ) {
                // get task details for current date
                let totalDisplays = 70;
                let outOfStockDisplays = [];

                let p = products[Math.floor(Math.random() * (products.length - 1)) + 0];
                let qty = Math.floor(Math.random() * 5) + 1;
                let total_price = qty * p.shelf_price;
                let seller = sellers[Math.floor(Math.random() * (sellers.length - 1)) + 0];
                rows.push({
                    date: formatDate(currentDate, "-"),
                    outlet_branch: ob[Math.floor(Math.random() * (ob.length - 1)) + 0],
                    product_number: p.product_number,
                    brand: p.brand,
                    en_name: p.en_name,
                    shelf_price: p.shelf_price,
                    qty,
                    total_price,
                    promoter: seller,
                    city: "Jeddah",
                });

                // taskDetails.forEach((td) => {
                //     //get task info
                //     // const task = tasks.find((task) => `${task.task_id}` === `${td.task_id}`);
                //     const task = td.task;

                //     if (formatDate(task.date_time_from.toDate()) !== formatDate(currentDate)) return;
                //     totalDisplays += 1;
                //     // calculate the percentage of out of stock products
                //     //determine if the product is out of stock
                //     const isOutOfStock = td.quantity_on + td.quantity_added === 0;
                //     if (!isOutOfStock) return;

                //     outOfStockDisplays.push({
                //         id: td.id,
                //         productID: td.product_id,
                //         outletBranchID: task.outlet_branch_id,
                //     });
                // });

                // calculate the percentage of out of stock products
                const percentage =
                    totalDisplays > 0 ? ((outOfStockDisplays.length / totalDisplays) * 100).toFixed(2) : 0;
                // push the data to the line chart
                lineData[0].data.push({
                    x: formatDate(currentDate),
                    y: loading ? 0 : Math.random() * 70,
                });
            }
            setRows(rows);
            setLineData(lineData);
        } catch (error) {
            setLineData([]);
            console.log(error);
        }
    }, []);

    useEffect(() => {
        init();
    }, [init]);

    let content = lineData.length > 0 ? <LineChart data={lineData} settings={settings} /> : <span>No Data</span>;
    if (loading) {
        content = <CircularLoader />;
    }

    return (
        <DashboardCard
            title={"Product Sales"}
            classes={{
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
                cardActionArea: classes.cardActionArea,
            }}
            footerComponent={
                <Button
                    className={classes.button}
                    onClick={() =>
                        exportExcel({
                            columns: COLUMNS,
                            rows,
                            user: { first_name: "Luqman", surname: "Alattas" },
                            company: { company_name: "Derwaza" },
                            worksheetTitle: "Sellout Report",
                            filename: "Product Sellout Report",
                        })
                    }
                >
                    {`Export Excel >`}
                </Button>
            }
        >
            {content}
        </DashboardCard>
    );
};

export default ProductSalesLine;
