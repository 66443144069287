import { FormControlLabel, Radio } from "@material-ui/core";
import React from "react";
import COLORS from "../../../constants/colors";

//related to meterial ui package
import styles from "./styles";

const RadioButton = ({ label, value, selected = false, color = COLORS.FRENSH_BLUE, textColor = COLORS.BLACK }) => {
    const classes = styles({ color, textColor });

    return (
        <FormControlLabel
            value={value}
            control={<Radio style={{ display: "none" }} />}
            label={label}
            className={`${classes.formControlLabel} ${selected ? classes.selected : ""}`}
            classes={{
                label: classes.formControlLabelText,
            }}
        />
    );
};

export default RadioButton;
