import React, { useEffect } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import { useCallback } from "react";

import EnhancedTable from "../../../components-lagacy/Table/EnhancedTable";
import { DashboardCard } from "../../../core-ui/custom";
import { Link } from "react-router-dom";
import { isEqual } from "lodash";
import { memo } from "react";
import { formatDate } from "../../../helpers/date-utils";
import PATHS from "../../../constants/paths";

const TABLE_HEADERS = [
    { id: "id", label: "No.", hidden: true },
    { id: "outletBranchName", label: "Outlet Branch", textEllepsis: true },
    { id: "productName", label: "Product", textEllepsis: true },
    { id: "quantity", label: "Qty" },
    { id: "date", label: "Expiry" },
];

const NearExpiryProductsTable = ({ tasks, taskDetails, outletBranches, loading = false, filterValues = {} }) => {
    const classes = styles();
    const [rowsData, setRowsData] = React.useState([]);
    const [total, setTotal] = React.useState(0);

    const init = useCallback(async () => {
        try {
            //reset
            setRowsData([]);
            setup(tasks, taskDetails, outletBranches);
        } catch (error) {
            console.log(error);
        }
    }, [tasks, taskDetails, outletBranches]);

    const setup = (tasks, taskDetails, outletBranches) => {
        //compute all these products to get chart data
        const chartData = taskDetails
            .map((td) => {
                //get task info
                // const task = tasks.find((task) => `${task.task_id}` === `${td.task_id}`);
                const task = td.task;
                //get outlet branch name
                // const outletBranchName = outletBranches.find(
                //     (branch) => `${task.outlet_branch_id}` === `${branch.branch_id}`
                // ).En_short_name;
                const outletBranchName = task.outlet_branch.En_short_name;

                //determine if the product is near expired
                const isNearExpired = td.removed_from?.number_of_near_expiration > 0;

                let nearExpiry = [];
                if (isNearExpired) {
                    for (const key in td.removed_from.near_expiration) {
                        if (Object.hasOwnProperty.call(td.removed_from.near_expiration, key)) {
                            const element = td.removed_from.near_expiration[key];
                            nearExpiry.push({
                                date: element.date?.toDate() || "Not Set",
                                quantity: element.quantity,
                            });
                        }
                    }
                }
                return {
                    id: td.id,
                    productID: td.product_id,
                    outletBranchID: `${task.outlet_branch_id}`,
                    productName: `${td.product_name?.en}`,
                    outletBranchName,
                    nearExpiry,
                };
            })
            .filter((td) => td.nearExpiry.length > 0);

        let rows = process(chartData);
        //set
        setRowsData(rows);
    };

    const process = (chartData) => {
        //compute all these products to get table data and each
        let totalQty = 0;
        let rows = [];
        chartData.forEach((td) => {
            td.nearExpiry.forEach((ne, index) => {
                totalQty += ne.quantity ?? 0;
                rows.push({
                    id: `${td.id}-index-${index}`,
                    outletBranchName: index === 0 ? td.outletBranchName : null,
                    productName: index === 0 ? td.productName : null,
                    quantity: ne.quantity,
                    date: ne.date,
                    cellProps:
                        index === 0
                            ? {
                                  outletBranchName: { rowSpan: td.nearExpiry.length },
                                  productName: { rowSpan: td.nearExpiry.length },
                              }
                            : {},
                });
            });
        });
        setTotal(totalQty);

        return rows;
    };

    const generateQueryParams = (values) => {
        const { date, supplierBranch, project, outlet, outletBranch, city } = values;
        const urlParams = new URLSearchParams({});

        if (date) {
            urlParams.append("from", formatDate(date.startDate, "-", true));
            urlParams.append("to", formatDate(date.endDate, "-", true));
        }
        if (supplierBranch) urlParams.append("supplier_branch", supplierBranch.value);
        if (project) urlParams.append("project", project.value);
        if (outlet) urlParams.append("outlet", outlet.value);
        if (outletBranch) urlParams.append("outlet_branch", outletBranch.value);
        if (city) urlParams.append("city", city.value);

        return urlParams;
    };

    useEffect(() => {
        init();
    }, [init]);

    return (
        <DashboardCard
            title={`Near Expiry (${total})`}
            type="table"
            classes={{
                cardContainer: classes.cardContainer,
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
                cardActionArea: classes.cardActionArea,
            }}
            footerComponent={
                <Link
                    className={classes.link}
                    to={{
                        pathname: PATHS.NEAR_EXPIRY_REPORT,
                        search: generateQueryParams(filterValues).toString(),
                    }}
                >
                    {`View Details >`}
                </Link>
            }
        >
            <div className={classes.tableWrapper}>
                <EnhancedTable
                    columns={TABLE_HEADERS}
                    rows={rowsData}
                    actions={[]}
                    updateSelected={() => {}}
                    loading={loading}
                    selectionDisabled
                    toolbarDisabled
                    paginationDisabled
                    emptyMessage="No Near Expiry Products"
                />
            </div>
        </DashboardCard>
    );
};

NearExpiryProductsTable.propTypes = {
    classes: PropTypes.object,
};

export default memo(NearExpiryProductsTable, isEqual);
