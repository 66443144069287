import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../contexts/auth-context";
import { queryBrands } from "../services/firestore/Brands";
import { queryCategories } from "../services/firestore/Category";
import { querySubCategories } from "../services/firestore/SubCategory";
import { querySubSubCategories } from "../services/firestore/Sub_SubCategory";

const UNCALSSIFIED_CATEGORY = "110";
const UNCALSSIFIED_SUBCATEGORY = "140";
const UNCALSSIFIED_SUB_SUBCATEGORY = "158";

const useProduct = ({ onAfterInit = async () => {} }) => {
    const { companyData } = useAuth();

    const [initializing, setInitializing] = useState(true);
    const [saving, setSaving] = useState(false);
    const [categoriesList, setCategoriesList] = useState([]);
    const [subcategoriesList, setSubcategoriesList] = useState([]);
    const [subsubcategoriesList, setSubsubcategoriesList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);

    const init = useCallback(async () => {
        try {
            setInitializing(true);
            let [categories, subcategories, subsubcategories, brands] = await Promise.all([
                queryCategories([]),
                querySubCategories([]),
                querySubSubCategories([]),
                queryBrands([{ key: "supplier_id", operator: "==", value: companyData.company_id }]),
            ]);

            //docs to lists
            categories = categories.map((c) => ({
                label: c.data().En_name,
                value: c.id,
                data: c.data(),
            }));
            subcategories = subcategories.map((c) => ({
                label: c.data().En_name,
                value: c.id,
                data: c.data(),
            }));
            subsubcategories = subsubcategories.map((c) => ({
                label: c.data().En_name,
                value: c.id,
                data: c.data(),
            }));
            brands = brands.map((c) => ({
                label: c.data().en_name,
                value: c.id,
                data: c.data(),
            }));

            setCategoriesList(categories);
            setSubcategoriesList(subcategories);
            setSubsubcategoriesList(subsubcategories);
            setBrandsList(brands);
            await onAfterInit(categories, subcategories, subsubcategories, brands);
            setInitializing(false);
        } catch (error) {
            throw error;
        }
    }, []);

    useEffect(() => {
        init();
    }, [init]);

    return {
        initializing,
        saving,
        setSaving,
        categoriesList,
        subcategoriesList,
        subsubcategoriesList,
        brandsList,

        UNCALSSIFIED_CATEGORY,
        UNCALSSIFIED_SUBCATEGORY,
        UNCALSSIFIED_SUB_SUBCATEGORY,
    };
};

export default useProduct;
