import { flatten } from "lodash/fp";
import { arrayChunks } from "../../../helpers/array-helpers";
import { firestore as db } from "../../firebase";
import { COLLECTIONS } from "../constants";

export const querySlot = async (subscriptionID, options) => {
    try {
        let ref = db.collection(COLLECTIONS.SUBSCRIPTION).doc(subscriptionID).collection(COLLECTIONS.SUBSCRIPTION_SLOT);
        if (options.docID) {
            return await ref.doc(options.docID).get();
        }

        //check for in operator
        let inQuery = options.filter((op) => op?.operator === "in");
        if (inQuery.length > 1) throw new Error("There are more than 1 in operator");

        for (const option of options) {
            const { key, value = null, operator = "==" } = option;
            if (operator === "in") continue;

            ref = ref.where(key, operator, value);
        }

        if (inQuery.length === 1) {
            const { key, value = null, operator = "==" } = inQuery[0];

            //inQuery value should an array
            const chunks = arrayChunks(value, 10);
            const res = await Promise.all(
                chunks.map(async (chunk) => {
                    return (await ref.where(key, operator, chunk).get()).docs;
                })
            );

            //flatten
            return flatten(res);
        } else {
            return (await ref.get()).docs;
        }
    } catch (error) {
        throw error;
    }
};

export const updateSlot = async (subscriptionID, docID, data) => {
    try {
        return await db
            .collection(COLLECTIONS.SUBSCRIPTION)
            .doc(subscriptionID)
            .collection(COLLECTIONS.SUBSCRIPTION_SLOT)
            .doc(docID)
            .update(data);
    } catch (error) {
        throw error;
    }
};
