const useStyles = (theme) => ({
    Container: {
        width: "100%",
        padding: "10px 20px",
    },

    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3",
    },

    textCenter: {
        textAlign: "center",
    },

    Content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        width: "100%",
        minWidth: "560px",
        padding: "10px",
        borderRadius: "5px",
    },

    header: {
        color: "black",
        fontSize: "2.5em",
        textAlign: "center",
        margin: "0 0 10px 0",
    },
});

export default useStyles;
