import { serverTimestamp } from "../../helpers/firebase-helpers";
import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";
import { getCurrentPrivacy } from "./Privacy_Policies";

export const addNewPrivacyAgreement = async (uid, user_type = "admin") => {
    try {
        const currentPrivacy = await getCurrentPrivacy();
        const ref = db.collection(COLLECTIONS.PRIVACY_AGREEMENTS).doc();

        return ref.set({
            id: ref.id,
            terms_id: currentPrivacy.id,
            user_id: uid,
            user_type: user_type,
            agreement_date: serverTimestamp(),
        });
    } catch (error) {
        throw error;
    }
};
