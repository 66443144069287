import styles from "./styles";

import PieChart from "../../../core-ui/charts/pie-chart";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { TASKS_STATUS } from "../../../constants/constants-lagacy";
import { useAuth } from "../../../contexts/auth-context";
import { useRef } from "react";
import { CircularLoader, DashboardCard } from "../../../core-ui/custom";
import { Link, useHistory } from "react-router-dom";
import PATHS from "../../../constants/paths";
import { formatDate } from "../../../helpers/date-utils";

const generatePieData = (tasks = []) => {
    const total = {
        unassigned: tasks.filter((task) => task.state === TASKS_STATUS.OPEN).length,
        booked: tasks.filter((task) => [TASKS_STATUS.BOOKED, TASKS_STATUS.RESERVED].includes(task.state)).length,
        underProcess: tasks.filter((task) => task.state === TASKS_STATUS.UNDER_PROCESS).length,
        finished: tasks.filter((task) => [TASKS_STATUS.AUDITING, TASKS_STATUS.FINISHED].includes(task.state)).length,
        completed: tasks.filter((task) => task.state === TASKS_STATUS.COMPLETED).length,
        noShow: tasks.filter((task) => task.state === TASKS_STATUS.NO_SHOW).length,
        cancelled: tasks.filter((task) => task.state === TASKS_STATUS.CANCELED).length,
    };
    return [
        {
            id: "unassigned",
            label: `Not Assigned (${total.unassigned})`,
            value: total.unassigned,
            color: "#575757",
        },
        {
            id: "booked",
            label: `scheduled (${total.booked})`,
            value: total.booked,
            color: "#4bb543",
        },
        {
            id: "under-process",
            label: `Under Process (${total.underProcess})`,
            value: total.underProcess,
            color: "#36a2eb",
        },
        {
            id: "finished",
            label: `Finished (${total.finished})`,
            value: total.finished,
            color: "#a67a44",
        },
        {
            id: "no-show",
            label: `No Show (${total.noShow})`,
            value: total.noShow,
            color: "#cf0404",
        },
        {
            id: "cancelled",
            label: `Cancelled (${total.cancelled})`,
            value: total.cancelled,
            color: "#a0a0a0",
        },
    ];
};

let pieSettings = {
    // margin: { top: 20, right: 0, bottom: 20, left: 70 },
    margin: { top: 30, right: 0, bottom: 70, left: 0 },
    innerRadius: 0.7,
    enableArcLinkLabels: false,
    theme: {
        legends: {
            text: {
                fontSize: 9,
                fontFamily: "Roboto",
            },
        },
    },
    // legends: [
    //     {
    //         // anchor: "top-left",
    //         anchor: "bottom",
    //         // direction: "column",
    //         direction: "row",
    //         justify: false,
    //         // translateX: -55,
    //         translateX: 0,
    //         translateY: 25,
    //         itemsSpacing: 70,
    //         itemWidth: 0,
    //         itemHeight: 0,
    //         itemTextColor: "#000",
    //         itemDirection: "top-to-bottom",
    //         itemOpacity: 1,
    //         symbolSize: 12,
    //         symbolShape: "circle",
    //         effects: [
    //             {
    //                 on: "hover",
    //                 style: {
    //                     itemTextColor: "#000",
    //                 },
    //             },
    //         ],
    //     },
    // ],
    fill: [
        { match: { id: "unassigned" }, id: "lines" },
        { match: { id: "booked" }, id: "dots" },
        { match: { id: "under-process" }, id: "dots" },
        { match: { id: "finished" }, id: "dots" },
        { match: { id: "completed" }, id: "lines" },
        { match: { id: "lisp" }, id: "lines" },
        { match: { id: "elixir" }, id: "lines" },
        { match: { id: "javascript" }, id: "lines" },
    ],
};

const DailyVisitsPie = ({ tasks, loading = false, filterValues = {} }) => {
    const classes = styles();
    const history = useHistory();
    // const { companyData } = useAuth();

    const [chartData, setChartData] = useState(generatePieData());
    let tasksUnsub = useRef(() => {});

    const init = useCallback(() => {
        try {
            setChartData(generatePieData(tasks));
        } catch (error) {
            console.log(error);
        }
    }, [tasks]);

    const calculateTotal = useCallback((data) => {
        let total = 0;
        data.forEach((item) => {
            total += item.value;
        });
        return total;
    }, []);

    const generateQueryParams = (values) => {
        const { date, supplierBranch, project, outlet, outletBranch, city } = values;
        const urlParams = new URLSearchParams({});

        if (date) {
            urlParams.append("from", formatDate(date.startDate, "-", true));
            urlParams.append("to", formatDate(date.endDate, "-", true));
        }
        if (supplierBranch) urlParams.append("supplier_branch", supplierBranch.value);
        if (project) urlParams.append("project", project.value);
        if (outlet) urlParams.append("outlet", outlet.value);
        if (outletBranch) urlParams.append("outlet_branch", outletBranch.value);
        if (city) urlParams.append("city", city.value);

        return urlParams;
    };

    const pieClickHandler = (node, filterValues) => {
        const urlParams = generateQueryParams(filterValues);
        urlParams.append("status", node.id);
        history.push({
            pathname: PATHS.INSOURCE_VISITS_MANAGEMENT,
            search: urlParams.toString(),
        });
    };

    useEffect(() => {
        init();
    }, [init]);

    useEffect(() => {
        return () => {
            if (tasksUnsub.current) tasksUnsub.current();
        };
    }, []);

    return (
        <DashboardCard
            title={"Visits"}
            classes={{
                cardContainer: classes.cardContainer,
                card: classes.card,
                cardHeader: classes.cardHeader,
                cardContent: classes.cardContent,
                cardActionArea: classes.cardActionArea,
            }}
            footerComponent={
                <Link
                    className={classes.link}
                    to={{
                        pathname: PATHS.INSOURCE_VISITS_MANAGEMENT,
                        search: generateQueryParams(filterValues).toString(),
                    }}
                >
                    {`View Details >`}
                </Link>
            }
        >
            <div className={classes.centerText}>{calculateTotal(chartData)} Visits</div>
            <PieChart
                data={chartData}
                settings={pieSettings}
                responsive={true}
                onClick={(node, event) => pieClickHandler(node, filterValues)}
            />
            {loading && <CircularLoader className={classes.loader} />}

            <div className={classes.legendsContainer}>
                {chartData.map((data) => {
                    return (
                        <div key={`${data.id}-legend`} className={classes.legend}>
                            <span className={classes.legendSymbol} style={{ backgroundColor: data.color }}></span>
                            <span className={classes.legendLabel}>{data.label}</span>
                        </div>
                    );
                })}
            </div>
        </DashboardCard>
    );
};

export default DailyVisitsPie;
