import * as Yup from "yup";
import { USER_TYPES } from "../../constants/global";

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;

const phoneRegExp =
    /^((\+?\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

Yup.addMethod(Yup.string, "phone", function (msg) {
    return this.matches(phoneRegExp, msg);
});

export const USER_TYPES_LIST = [{ label: "Branch Manager", value: USER_TYPES.BRANCH_MANAGER }];

export const FORM_SCHEMA = Yup.object().shape({
    first_name: Yup.string().max(15, "Must be 15 characters or less").required("Required"),
    surname: Yup.string().max(20, "Must be 20 characters or less").required("Required"),
    phone: Yup.string().phone("Invalid phone number"),
    email: Yup.string().email("Invalid email"),
    password: Yup.string()
        .matches(lowercaseRegex, "One lowercase required")
        .matches(uppercaseRegex, "One uppercase required")
        .matches(numericRegex, "One number required")
        .min(6, "Must be at least 6 characters")
        .required("Required"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Password must match")
        .required("Required"),

    user_type: Yup.object().typeError("Required").shape({
        label: Yup.string(),
        value: Yup.string(),
    }),

    branch_ids: Yup.array().min(1, "Required"),
});

export const INITIAL_FORM_VALUES = {
    first_name: "",
    surname: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",

    user_type: USER_TYPES_LIST[0],

    branch_ids: [],
};
